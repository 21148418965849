import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import messageActions from '../../message/actions';
import store from "../../store";
import { dataURItoBlob, fileToBase64, blobToBase64 } from '../../../helpers/functions';
import { getGOClient, getGraphClient, getCommonBlogItems, getTenantBlogItems } from '../../selectors'

export function* getTenantBlogs() {
    yield takeEvery(actions.GET_TENANT_BLOGS, function* (action) {

        const blogItems = yield select(getTenantBlogItems);
        const Client = yield select(getGOClient);
        const GraphClient = yield select(getGraphClient);

        if (!Client || !GraphClient) return;
        const blogs = yield Client.api("/common/blog/tenant");

        if (blogs == null || !blogs) return;

        let tasks = blogs.map(async (n, i) => {
            if (n.authorId !== null && n.authorId !== '00000000-0000-0000-0000-000000000000') {
                const avatar = await GraphClient.api(`/users/${n.authorId}/photo/$value`, 'arrayBuffer');
                if (avatar == null) return;

                let image = 'data:image/png;base64, ' + new Buffer(avatar, 'binary').toString('base64');
                let blob = dataURItoBlob(image);
                let objectUrl = URL.createObjectURL(blob);

                n.image = objectUrl;
                n.blob = blob;
            }
        });

        if (tasks.length) {
            yield Promise.all(tasks);
        }

        if (blogs.length == 0 || (blogs.length && ((blogItems.length === 0) || (blogItems && blogItems[0].Id !== blogs[0].id)))) {
            //localforage.clear(); //clear local storage

            let old = blogItems.filter(n => !blogs.find(nn => nn.id == n.id));
            if (old != null && old.length > 0) {
                //yield newsItems.forEach(n => {
                for (let n of old) {
                    yield localforage.removeItem(n.id);
                }
            }

            //if (blogItems) {
            //    //yield blogItems.forEach(n => {
            //    for (let n of blogItems) {
            //        yield localforage.removeItem(n.id);
            //    }
            //}

            //yield blogs.forEach(n => {
            for (let n of blogs) {
                if (n.blob == null) continue; // do nothing, maybe request failed
                yield localforage.setItem(n.id, n.blob);
                delete n.image;
                delete n.blob;
            }


            yield put({
                type: actions.SET_TENANT_BLOGS,
                payload: blogs
            });
        }
    });
}


export function* postBlog() {
    yield takeEvery(actions.POST_TENANT_BLOG, function* (action) {

        const GoClient = yield select(getGOClient);

        let formFields = {
            title: action.payload.title,
            link: action.payload.link,
            author: action.payload.author,
            authorId: action.payload.authorId,
            date: new Date(action.payload.date).toISOString(),
            content: action.payload.content
        }

        let method = 'POST';
        if (action.payload.file) {
            const base64 = yield fileToBase64(action.payload.file[0]);

            formFields = {
                ...formFields,
                image: base64
            }
        }

        if (action.payload.id) {
            method = 'PUT';
            formFields = {
                ...formFields,
                id: action.payload.id,
                authorName: action.payload.authorName,
                authorId: action.payload.authorId
            }

            if (!action.payload.file && action.payload.currentImage) {
                const base64 = yield blobToBase64(action.payload.currentImage);

                formFields = {
                    ...formFields,
                    image: base64
                }
            }
        }

        //const result = yield GoClient.api('/common/blog', {
        //    method: method,
        //    body: JSON.stringify(formFields)
        //},
        //    { 'Content-Type': 'application/json' },
        //    true
        //);

        let result = false;
        if (method === 'POST') {
            result = yield GoClient.post('/common/blog', formFields, true);
        } else {
            result = yield GoClient.put('/common/blog', formFields, true);
        }

        if ( result ) {
            if (result.ok) {
                yield put({
                    type: actions.GET_TENANT_BLOGS
                })
                if (method === 'PUT') {
                    yield put({
                        type: messageActions.MESSAGE,
                        payload: {
                            message: 'Blog geupdated',
                            type: 'success'
                        }
                    });
                } else {
                    yield put({
                        type: messageActions.MESSAGE,
                        payload: {
                            message: 'Blog toegevoegd',
                            type: 'success'
                        }
                    });
                }
                store.history.push('/self-service/blog');
            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}


export function* deleteBlog() {
    yield takeEvery(actions.DELETE_BLOGS_ITEM, function* (action) {

        const GoClient = yield select(getGOClient);

        let formFields = {
            id: action.payload,
        }

        const result = yield GoClient.delete('/common/blog?id=' + action.payload,action.payload, true);

        if (result.ok) {
            yield put({
                type: actions.GET_TENANT_BLOGS
            })

            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Blog verwijderd',
                    type: 'success'
                }
            });

            store.history.push('/self-service/blog');

        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}



export default function* rootSaga() {
    yield all([
        fork(getTenantBlogs),
        fork(postBlog),
        fork(deleteBlog)
    ]);
}
