const actions = {
	GET_SELF_USER : 'GET_SELF_USER',
	SET_SELF_USER : 'SET_SELF_USER',
	SET_ACTIVE_USER : 'SET_ACTIVE_USER',
	POST_SELF_USER : 'POST_SELF_USER',
	GET_TENANT_USER : 'GET_TENANT_USER',
	SET_TENANT_USER : 'SET_TENANT_USER',
	GET_USER_FROM_CHANNEL : 'GET_USER_FROM_CHANNEL',
	DELETE_SELF_USER : 'DELETE_SELF_USER',
	TEST_SELF_USER : 'TEST_SELF_USER',
	SELF_USER_REQUEST_COMPLETED: 'SELF_USER_REQUEST_COMPLETED',

	POST_USER_REQUEST : 'POST_USER_REQUEST',
	SET_USER_REQUEST : 'SET_USER_REQUEST',
	GET_USER_REQUEST : 'GET_USER_REQUEST',
	DELETE_USER_REQUEST : 'DELETE_USER_REQUEST',
	UPDATE_USER_REQUEST : 'UPDATE_USER_REQUEST',
	CANCEL_USER_REQUEST : 'CANCEL_USER_REQUEST',

	postUserRequest : (payload) => ({
		type : actions.POST_USER_REQUEST,
		payload : payload
	}),

	setUserRequest : (payload) => ({
		type : actions.SET_USER_REQUEST,
		payload : payload
	}),

	getUserRequest : () => ({
		type : actions.GET_USER_REQUEST,
		payload : payload
	}),

	cancelUserRequest : (payload) => ({
		type : actions.CANCEL_USER_REQUEST,
		payload : payload
	}),

	deleteUserRequest : (payload) => ({
		type : actions.DELETE_USER_REQUEST,
		payload : payload
	}),

	updateUserRequest : (payload, id) => ({
		type : actions.UPDATE_USER_REQUEST,
		payload : {payload, id}
	}),

	// getTenantNews : () => ({
	// 	type : actions.GET_TENANT_USER
	// }),

	// getCommonNews : ( payload ) => ({
	// 	type : actions.GET_SELF_USER,
	// 	payload : payload
	// }),

	// setActiveNewsItem : ( payload ) => ({
	// 	type : actions.SET_ACTIVE_USER,
	// 	payload : payload
	// }),

	// deleteItem : (payload) => ({
	// 	type : actions.DELETE_SELF_USER,
	// 	payload : payload
	// }),
	// setUser : (payload) => ({
	// 	type : actions.SET_SELF_USER,
	// 	payload : payload
	// })
};

export default actions;
