import actions from './actions';

const initState = {
    rightsRequest: []
};


export default function selfRightsReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_RIGHTS_REQUEST:
            return {
                ...state,
                rightsRequest: action.payload
            }
        default:
            return state;
    }
}
