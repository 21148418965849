import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from './styled';
import actions from '../../../redux/user/actions';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import HomeSlider from './sliders/home';
import OnlineHulpSlider from './sliders/onlineHulp';
import SelfServiceSlider from './sliders/selfService';
import StoreSlider from './sliders/store';

import 'tiny-slider/dist/tiny-slider.css';
 

const SliderRenderer = (props) => {
    const _conditionallyRenderFeatureSlider = () => {
        
        if ( props.featureSliders ) {
            switch ( props.location.pathname ) {

                case '/' :
                    if ( props.featureSliders[props.location.pathname]?.visible ) {
                        //if ( window.tenantConfig.version != 'development') {
                            return <HomeSlider />
                        //}
                    }
                break;
                case '/online-hulp' :
                    if ( props.featureSliders[props.location.pathname]?.visible ) {
                        return <OnlineHulpSlider />
                    }
                break;
                case '/self-service/nieuws' :
                    if ( props.featureSliders[props.location.pathname]?.visible ) {
                        return <SelfServiceSlider />
                    }
                break;
                case '/store' :
                    if ( props.featureSliders[props.location.pathname]?.visible ) {
                        return <StoreSlider />
                    }
                break;
            }
        }
        return null;
    }

    return (
        <React.Fragment>
            { /*_conditionallyRenderFeatureSlider() */}
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        featureSliders: state.User.featureSliders,
        sliderHidden: state.Layout.sliderHidden,
        isLoaded: state.Layout.IsLoaded,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...actions
    }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderRenderer));
