import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import actions from '../../../../redux/slideOut/actions';
import { ScrollTo } from "react-scroll-to";
import { bindActionCreators } from 'redux';
import * as Styled from './styled';

class SearchBar extends Component {
    render() {
        return (
            <Styled.SearchWrapper>
                <Styled.Magnifier>
                    <FontAwesomeIcon icon={faSearch} />
                </Styled.Magnifier>
                    <Styled.SearchInput type="text" onChange={this.props.setSearchTerm} value={this.props.searchTerm} />
            </Styled.SearchWrapper >
        )
    }
}

export default SearchBar
