import actions from './actions';

const initState = {
	newsItems : [],
	activeNewsItem : false,
	requestCompleted: false,
};


export default function multiChannelNewsReducer(state = initState, action) {

	switch (action.type) {
		case actions.NEWS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_MULTICHANNEL_NEWS_ITEMS:
			return {
				...state,
				newsItems : action.payload
			}
		case actions.SET_ACTIVE_NEWS_ITEM:
            return {
                ...state,
                activeNewsItem: action.payload
			}
		case actions.CLEAR_NEWS_STATE:
			return {
				...state,
				newsItems: []
			}
		default:
			return state;
	}
}
