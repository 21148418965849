import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import { toast } from 'react-toastify';
import { checkIfClaim } from '../../helpers/functions';
import { excludeAppsFromList } from '../../helpers/apps';
import { getGOClient, getLiquitClient, getUserProfile, getUserProfileSettingData, getGraphClient } from '../selectors'

export function* getApps() {
    yield takeEvery(actions.GET_APPS, function* (action) {

        const Client = yield select(getGOClient);
        const GraphClient = yield select(getGraphClient);
        const UserProfile = yield select(getUserProfile);

        if (!Client) return;

        try {
            let apps = [];

            if (window.tenantConfig.liquit && window.tenantConfig.liquit !== '' && window.tenantConfig.liquitID !== '') {
                let access = yield checkIfLiquitClaim(GraphClient,UserProfile.claims);
                if (!access) {
                    apps = yield Client.api(action.payload.apiUrl); //no Liquit access
                } else {
                    console.log('Fetching Liquit token....');                   

                    const LiquitClient = yield select(getLiquitClient);                    
                    let token = yield LiquitClient.getToken();
                    if (token == null) console.log('error no token found');

                    yield startLiquit(UserProfile.userPrincipalName, function () { console.log('Session has expired'); }, token).then(async () => {
                        apps = await getLiquitList(UserProfile.userPrincipalName);

                        await put({
                            type: actions.SET_APPS,
                            payload: {
                                [!action.payload.category ? 'default' : action.payload.category]: apps
                            }
                        });
                    });
                }
            }

            //fallback
            if(action.payload) {
                if (window.tenantConfig.liquit == null || window.tenantConfig.liquit === '') {
                    apps = yield Client.api(action.payload.apiUrl);
                }

                if (apps == null || !apps || apps === []) return;


                // -- maybe we need to exclude some apps
                apps = excludeAppsFromList(UserProfile, apps);

                yield put({
                    type: actions.SET_APPS,
                    payload: {
                        [!action.payload.category ? 'default' : action.payload.category]: apps
                    }
                });
            }
        }
        catch (error) {
            console.error(error);
        }


    });
}

const checkIfLiquitClaim = async (Client,claims) => {
    if (window.tenantConfig.liquitClaims == null || window.tenantConfig.liquitClaims === '' || window.tenantConfig.liquitClaims === '[""]') return true;
    let array = window.tenantConfig.liquitClaims;
    console.log('LiquitClaims: ', array);
    if (typeof array === 'string') array = JSON.parse(window.tenantConfig.liquitClaims);

    if (Array.isArray(array)) {
        for (let claim of array) {
            const hasRights = await Client.api(`/me/memberOf/microsoft.graph.group?$filter=id eq '${claim}'`);
            if (hasRights != null && hasRights.value.find(g => g.id == claim) != null) return true;
        }
    }
    return false;
}

const startLiquit = (userPrincipal, relogin, token) => new Promise(async (resolve, reject) => {
    try {
        //if (window.Liquit.Session.ID == null) {
        window.Liquit.Initialize(window.tenantConfig.liquit, {
            version: 3,
            relogin: relogin,
            token: token
        });
        let liquitToken = await startSSO(userPrincipal, null, token);
        resolve(liquitToken);
        // }
        //resolve();
    } catch (error) {
        resolve();
    }
});

const startSSO = (userPrincipalName, action, token) => new Promise((resolve, reject) => {
    try {
        window.Liquit.SSO({
            username: userPrincipalName,
            identitySource: (window.tenantConfig.clientId == "5b1ee7fe-b3b5-4864-902c-9aba578cff6c" ? 'Ontdek365' : "Azure AD"),
            token: token
        }, function (liquitToken, fault) {

            if (liquitToken == null) {
                let error = "";
                if(window.userSettings?.locale === "nl") {
                    error = "Liquit SSO inloggen is niet gelukt, token was leeg"
                } else {
                    error = "Liquit SSO login failed, token was empty"
                }
                console.log(error);
                toast(error, {autoClose: 1000*60*60*24});
                resolve(null);
            }

            // Check if authentication failed.
            if (fault != null) {
                //reject('Error ' + fault.code + ': ' + fault.message);
                resolve(null);
            }

            if (action != null) {
                action();
            }


            resolve(liquitToken);
        });

    } catch (error) { resolve(null); }
});


const getLiquitList = (userPrincipalName) => new Promise((resolve, reject) => {
    window.Liquit.Applications.List({ sort: 1 }, function (fault, result) {

        // Check if the request failed.
        if (fault != null) {
            //alert('Error ' + fault.code + ': ' + fault.message);
            reject('Error ' + fault.code + ': ' + fault.message);
        }
        resolve(result.map(a => { return { ...a, image: a.icon, offlineLink: true } }));
    });
});


export function* getLiquitApps() {
    yield takeEvery(actions.GET_LIQUIT_APPS, function* (action) {

        if (window.Liquit == null || window.Liquit.Session == null || window.Liquit.Session.ID == null) return;

        try {

            const getCatelog = () => new Promise(async (resolve, reject) => {

                //let sessionId = await startSSO(state.User.profile.userPrincipalName);
                window.Liquit.Catalog.List(function (fault, result) {

                    // Check if the request failed.
                    if (fault != null) {
                        reject('Error ' + fault.code + ': ' + fault.message);
                    }

                    // The result object will contain an array of all catalog items.
                    resolve(result);

                });
            });

            let result = yield getCatelog();

            yield put({
                type: actions.SET_LIQUIT_APPS,
                payload: result
            });
        } catch (error) { }
    });
}

export function* setViewedItems() {
    yield takeEvery(actions.SET_VIEWED, function* (action) {
        if (action.payload == null) return;
        yield put({
            type: userActions.UPDATE_USER_SETTINGS,
            payload: {
                value: action.payload,
                setting: 'Tiles'
            }
        });

    });
}

export function* removeViewedItems() {
    yield takeEvery(actions.REMOVE_VIEWED, function* (action) {
        if (action.payload == null) return;
        const userProfileData = yield select(getUserProfileSettingData); 
        if(userProfileData?.settings?.viewedTiles) {
            const newViewedTiles = userProfileData?.settings?.viewedTiles.filter(tile => {
                tile.id != action.payload
            })
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: newViewedTiles,
                    setting: 'Tiles'
                }
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getApps),
        fork(getLiquitApps),
        fork(setViewedItems),
        fork(removeViewedItems)
    ]);
}
