const actions = {
	SET_APP_ONLINE : 'SET_APP_ONLINE',
    CHECK_APP_ONLINE : 'CHECK_APP_ONLINE',
	CHANGE_APP_CONNECTIVITY_STATUS : 'CHANGE_APP_CONNECTIVITY_STATUS',
	setAppOnline : (payload) => ({
		type : actions.SET_APP_ONLINE,
		payload : payload
	}),

    checkAppOnline : (payload) => ({
		type : actions.CHECK_APP_ONLINE,
		payload : payload
	}),
};

export default actions;
