import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getSharepointClient, getGOClient, getGraphClient, getMultiChannelBlogItems } from '../../selectors';
import BlogOBject from './blogObject';
import moment from 'moment/min/moment-with-locales';
import { Buffer } from 'buffer';
import * as rssParser from 'react-native-rss-parser';

export function* getBlogsFromChannel() {
    yield takeEvery(actions.GET_MULTICHANNEL_BLOG_ITEMS, function* (action) {

        try {
            // -- Switch here, select news conditionally.
            yield put({
                type: actions.BLOGS_REQUEST_COMPLETED,
                payload: false
            })
            let newItems = [];
            let containsGo = false;
            const title = action.payload.title;
            let currentItems = yield select(getMultiChannelBlogItems);
            //parse the different sources configured
            if (action.payload && action.payload.payload) {
                for (let source of action.payload.payload) {
                    if (source.source == "sharepoint") {
                        let items = yield call(getSharepointBlogs, source.site, source.url, title, source.source);
                        if (items?.length > 0) {
                            items = items.map(i => {
                                i.widget = title
                                return i;
                            });
                        }
                        if (items) {
                            newItems = [
                                ...newItems,
                                ...items
                            ];
                        }
                    }

                    if (source.source == "json") {
                        let items = yield call(getJsonBlogs, source.url);
                        if (items?.length > 0) {
                            items = items.map(i => {
                                i.widget = title
                                return i;
                            });
                        }
                        if (items) {
                            newItems = [
                                ...newItems,
                                ...items
                            ];
                        }
                    }

                    if (source.source == "rss") {
                        let items = yield call(getRssBlogs, source.url, title);
                        if (items?.length > 0) {
                            items = items.map(i => {
                                i.widget = title
                                return i;
                            });
                        }
                        if (items) {
                            newItems = [
                                ...newItems,
                                ...items
                            ];
                        }
                    }

                    if (source.source == "go" && source.url == true) {
                        containsGo = true;
                    }
                }


                //    if (source.source == "yammer") {
                //        const items = yield call(getYammerBlogs);
                //        if (items) {
                //            newsItems = [
                //                ...newsItems,
                //                ...news
                //            ]
                //        }
                //    }
                //}
            }

            const sortOnDate = ((a, b) => {
                if (a.unix > b.unix) {
                    return -1;
                }

                if (a.unix < b.unix) {
                    return 1;
                }

                return 0;
            });

            //only if common
            if (window.tenantConfig.common  || containsGo) {
                if(window.tenantConfig.common) {
                    const websiteBlogs = yield call(getWebsiteBlogs);
                    if (websiteBlogs) {
                        newItems = [
                            ...newItems,
                            ...websiteBlogs
                        ]
                    }
                }
                let commonBlogs = yield call(getCommonBlogs, title);
                if(!window.tenantConfig.common) {
                    if (commonBlogs?.length > 0) {
                        commonBlogs = commonBlogs.map(i => {
                        i.widget = title
                        return i; });
                    }
                    commonBlogs = [...commonBlogs, ...currentItems.filter(i => i.type == "go" && i.widget == title)];
                    if (commonBlogs) {
                        newItems = [
                            ...newItems,
                            ...commonBlogs
                        ];
                    }
                    newItems.sort(sortOnDate);
                } else {
                newItems.sort(sortOnDate);

                    if (commonBlogs?.length) {
                            commonBlogs = commonBlogs.map(i => {
                            i.widget = title
                            return i; });
                        if (commonBlogs.find(n => n.tenantId != null)) {
                            newItems = commonBlogs;
                        } else {
                            newItems = commonBlogs.concat(newItems);
                        }
                    }
                }

            } else {
                newItems.sort(sortOnDate);
            }

            currentItems = yield select(getMultiChannelBlogItems);
            currentItems = currentItems.filter(i => i.widget == title);
            newItems = newItems.splice(0, 30); //max items
            let old = currentItems.filter(n => !newItems.find(nn => nn.id == n.id));
            if (old?.length > 0 && newItems.length > 0) {
                for (let n of old) {
                    yield localforage.removeItem(n.id);
                }
            }
            let keys = yield localforage.keys();
            let localforageKeys = keys.filter(k => k.indexOf('sp_blogs') != -1)?.length;
            let currentItemsKeys = currentItems.filter(i => i.widget == title).length;
            
            let modified = newItems.filter(data => {
                return currentItems.find(current => {
                    if(data.dateModified != current.dateModified && current.type == "sharepoint" && current.id == data.id) {
                        return data
                    }
                })
            })

            if ((old?.length > 0 && newItems.length > 0) || (modified?.length > 0 && newItems.length > 0) || (currentItems?.length == 0 && newItems?.length > 0) || (currentItems?.length !== newItems?.length && newItems.length > 0)) {

                //postprocessing..
                newItems = yield call(postprocess, newItems);
                let allItems = yield select(getMultiChannelBlogItems);
                allItems = allItems.filter(i => i.widget != title);
                allItems = allItems.concat(newItems);
                allItems.sort(sortOnDate);

                yield put({
                    type: actions.SET_MULTICHANNEL_BLOG_ITEMS,
                    payload: allItems
                });
            }



        if(localforageKeys != currentItemsKeys) { 
                currentItems = yield select(getMultiChannelBlogItems);
                currentItems = currentItems.filter(i => i.widget == title);
                currentItems = yield call(postprocess, currentItems);
                let allItems = yield select(getMultiChannelBlogItems);
                allItems = allItems.filter(i => i.widget != title);
                allItems = allItems.concat(currentItems);
                allItems.sort(sortOnDate);
                
                yield put({
                    type: actions.SET_MULTICHANNEL_BLOG_ITEMS,
                    payload: allItems
                });

            }
            yield put({
                type: actions.BLOGS_REQUEST_COMPLETED,
                payload: true
            })
        } catch (ex) {
            console.log(ex);
        }
    })
}

function* getCommonBlogs(title) {
    const Client = yield select(getGOClient);
    const GraphClient = yield select(getGraphClient);
    const blogItems = yield select(getMultiChannelBlogItems);
    if (!Client || !GraphClient) return;
    const blogs = yield Client.api("/common/blog/list");


    if (blogs == null || !blogs) return;

    let tasks = blogs.map(async (n, i) => {

        if (n.image == null) return;
        n.blob = dataURItoBlob(n.image);
        return;

        if (n.authorId !== null && n.authorId !== '00000000-0000-0000-0000-000000000000') {
            const avatar = await GraphClient.api(`/users/${n.authorId}/photo/$value`, 'arrayBuffer');
            let image = 'data:image/png;base64, ' + new Buffer.from(avatar, 'binary').toString('base64');
            let blob = dataURItoBlob(image);
            let objectUrl = URL.createObjectURL(blob);
            n.authorBlob = blob;
        }
    });
    if (tasks.length) {
        yield Promise.all(tasks);
    }



    if (blogs.length && ((blogItems.length === 0) || (blogItems && blogItems[0].id !== blogs[0].id))) {
        //localforage.clear(); //clear local storage

        let old = blogItems.filter(n => !blogs.find(nn => nn.id == n.id));
        if (old != null && old.length > 0) {
            //yield newsItems.forEach(n => {
            for (let n of old) {
                yield localforage.removeItem(n.id);
            }
        }

        //yield blogs.forEach(n => {
        for (let n of blogs) {
            if (n.blob == null) continue; // do nothing, maybe request failed
            yield localforage.setItem(n.id, n.blob);
            delete n.image;
            delete n.blob;
        }
    }

    let parsedBlogs = yield parseCommonBlogs(blogs, title);
    return parsedBlogs;

}

async function parseCommonBlogs(blogs, title) {
    let parsedBlogs = [];
    for (let key in blogs) {

        if (blogs[key]) {
            const item = blogs[key];
            let blogObject = new BlogOBject;

            blogObject.setID(item.id);
            blogObject.setAuthor(item.author);
            blogObject.setContent(item.content);
            blogObject.setDate(item.date);
            blogObject.setTitle(item.title);
            blogObject.setType('common');
            blogObject.setImageUrl('parse-from-localForage');
            blogObject.setTenantId(item.tenantId);
            blogObject.setImageUrl(item.image);
            if (blogObject.getBlogObject()) {
                parsedBlogs.push(blogObject.getBlogObject());
            }
        }
    }
    return parsedBlogs;
}

function* getJsonBlogs(url) {

    try {
        const response = yield fetch(url);
        const blogs = yield response.json();

        let parsedBlogs = [];

        blogs.forEach((item, i) => {

            let blogObject = new BlogOBject;
            blogObject.setID(item.id);
            blogObject.setAuthor(item.author);
            blogObject.setContent(item.content);
            blogObject.setDate(item.date); //, 'YYYY-MM-DD HH:mm:ss');
            blogObject.setTitle(item.title);
            blogObject.setImageUrl(item.image);
            blogObject.setUrl(item.url ? item.url : item.link);
            blogObject.setType('json');

            if (blogObject.getBlogObject()) {
                parsedBlogs.push(blogObject.getBlogObject());
            }
        });

        return parsedBlogs;

    } catch (e) {
        console.log(e);
    }
}

function* getRssBlogs(url, title) {

    const GoClient = yield select(getGOClient);
    let currentItems = yield select(getMultiChannelBlogItems);
    currentItems = currentItems.filter(i => i.widget == title);
    let items = [];

    try {
        let data = yield GoClient.api('/app/proxy?url=' + encodeURIComponent(url), {}, 'text')
            .then((responseData) => rssParser.parse(responseData));

        data.items = data.items.slice(0, 20); //max messages

        let newItems = data.items.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => i.type == "rss" && i.id == d.id && i.widget == title))));
        let parsedItems = yield parseRssItems(newItems);

        //add existing (already parsed) items to array
        parsedItems = parsedItems.concat(currentItems.filter(i => i.type == "rss" && i.widget == title));
        return parsedItems;

    } catch (error) {
        console.log('rss error: ' + error);
        return [];
    }
}

async function parseRssItems(items) {
    let parsedItems = [];
    for (let key in items) {
        if (items[key]) {
            let item = items[key];

            let blogObject = new BlogOBject;
            blogObject.setAuthor((item.authors[0] ? item.authors[0].name : feed.title));
            blogObject.setContent((item.content ? item.content : item.description));
            blogObject.setDate(item.published);
            blogObject.setTitle(item.title);
            blogObject.setImageUrl(item.enclosures[0]?.url);
            blogObject.setUrl(item.links[0]?.url);
            blogObject.setID(item.id);
            blogObject.setType('rss');

            if (blogObject.getBlogObject()) {
                parsedItems.push(blogObject.getBlogObject());
            }

        }
    }
    return parsedItems;
}

function* getWebsiteBlogs() {

    try {
        const response = yield fetch('https://avantage.nl/wp-json/rendered-posts/all?8');
        const blogs = yield response.json();

        let parsedBlogs = [];

        blogs.forEach((item, i) => {

            let blogObject = new BlogOBject;
            blogObject.setID(item.id);
            blogObject.setAuthor(item.author);
            blogObject.setContent(item.content);
            blogObject.setDate(item.date, 'YYYY-MM-DD HH:mm:ss');
            blogObject.setTitle(item.title);
            blogObject.setImageUrl(item.image);
            blogObject.setUrl(item.link);
            blogObject.setType('website');

            if (blogObject.getBlogObject()) {
                parsedBlogs.push(blogObject.getBlogObject());
            }
        });

        return parsedBlogs;

    } catch (e) {
        console.log(e);
    }
}

function* getSharepointBlogs(site, api, title, source) {
    if (!api) {
        console.log("Sharepoint API URL Required");
        return;
    }
    
    try {
        let changedItems = [];
        let currentItems = yield select(getMultiChannelBlogItems);
        currentItems = currentItems.filter(i => i.widget == title && i.type == source);
        const Client = yield select(getSharepointClient);
        const GoClient = yield select(getGOClient);
        const GraphClient = yield select(getGraphClient);
        if (!Client) {
            console.log("no sharepoint client activated");
            return;
        }
    
        let newsResponse = yield Client.api(api.includes('?') ? api : api + '/?$select=Author/Title,Author/Id,BlogAutheurId,BannerImageUrl,Id,Modified,FirstPublishedDate,Title,Description,CanvasContent1,Created,FileRef,FileLeafRef,EncodedAbsUrl,LinkFilename&$expand=Author,File&$orderby=Created desc&$top=20');
    
        //fallback when no BlogAutheurId field is present in SharePoint
        if (newsResponse == null || !newsResponse || !newsResponse.value) {
            newsResponse = yield Client.api((api.includes('?') ? api.split('?')[0] : api) + '/?$select=Author/Title,Author/Id,BannerImageUrl,Id,Modified,FirstPublishedDate,Title,Description,CanvasContent1,Created,FileRef,FileLeafRef,EncodedAbsUrl,LinkFilename&$expand=Author,File&$orderby=Created desc&$top=20');
        }
    
        if (newsResponse == null || !newsResponse || !newsResponse.value) return;
        if (newsResponse.value.length === 0) return;
    
        let data = newsResponse.value;
       
        let newItems = data.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => i.type == "sharepoint" && i.id == 'sp_blogs_' + d.Id.toString()))));
      // let newItems = data.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => {
        //     i.type == "sharepoint" && i.id == 'sp_blogs_' + d.Id.toString()
        // }))));
        let oldItems = currentItems.filter(i => !data.find(d => 'sp_blogs_' + d.Id == i.id) && !newItems.find(n => 'sp_blogs_' + n.Id == i.id));
    
        //set ID for sharepoint.. still necessary?
        if (newItems?.length > 0) {
            newItems = newItems.map(function (n) {
                return { ...n, Id: 'sp_blogs_' + n.Id?.toString(), site: site };
            });
        }
        //parse news items to GO news items
        let parsedItems = yield parseSharepointBlogs(newItems);
        if (parsedItems?.length > 0) {
            parsedItems = parsedItems.map(i => {
            i.widget = title
            return i; })
        }
        //remove old items
        if (oldItems?.length > 0) {
            currentItems = currentItems.filter(c => !oldItems.find(o => o.id == c.id));
        }
        //Check for modified items
        changedItems = data.filter(data => {
            return currentItems.find(current => {
                if(data.Modified != current.dateModified && current.type == "sharepoint" && current.id == 'sp_blogs_'+ data.Id.toString()) {
                    return data
                }
            })
        })
    
        if (changedItems?.length > 0) {
            changedItems = changedItems.map(function (n) {
                return { ...n, Id: 'sp_blogs_' + n.Id.toString(), site: site };
            });
        }
    
        let parsedChangedItems = yield parseSharepointBlogs(changedItems);
        if (parsedChangedItems?.length > 0) {
            parsedChangedItems = parsedChangedItems.map(i => {
            i.widget = title
            return i; })
        }
        if (parsedChangedItems?.length > 0) {
            currentItems = currentItems.filter(current => !parsedChangedItems.find(parsed => current.type == "sharepoint" && current.id == parsed.id))
        }
    
        //add existing (already parsed) items to array
       // parsedItems.push({...currentItems[0], Id: 'sp_news_test', id: 'sp_news_test'});
       return [...parsedItems, ...currentItems, ...parsedChangedItems];
    } catch (ex) { console.error(ex); console.log('error getting sharepoint blogs'); return []; }
    }


function* postprocess(newItems) {

    let tasks = [];
    const SharepointClient = yield select(getSharepointClient);
    let sharepointToken;
    if (SharepointClient) sharepointToken = yield SharepointClient.getToken();

    const GoClient = yield select(getGOClient);
    let goToken = yield GoClient.getToken();
    let sharepointUser;

    tasks = newItems.map(async (item, index) => {

        if (item.type == "sharepoint") {

            //get author based on sharepoint user id
            if (!isNaN(parseInt(item.author))) {
                sharepointUser = await SharepointClient.api(item.site + '/_api/web/getuserbyid(' + item.author + ')');

                //set auteur
                if (sharepointUser) {
                    if (sharepointUser.Title) {
                        //item.author = sharepointUser.Title;
                        newItems[index].author = sharepointUser.Title;
                    }
                }
            }

            //get image
            const getResponse = async () => {
                if (item.imageUrl == 'parse-from-localForage') return { imageUrl: null }; //no image found in cache 
                if (item.imageUrl?.indexOf("sharepoint.com") == -1) return { imageUrl: item.imageUrl }; //do nothing

                let pictureUrl = parsePictureUrl(item.orginalUrl ? item.orginalUrl : item.imageUrl, 3);
                let url = encodeURIComponent(pictureUrl);

                let response;
                response = await fetch(`/api/getimage?url=${url}`, {
                    method: 'GET',
                    contentType: 'image/jpeg',
                    headers: {
                        'Authorization': 'Bearer ' + goToken, 'AuthorizationProxy': sharepointToken
                    }
                }).catch((err) => { return null; });

                if (!response || !response.ok) return null;

                const content = await response.text();
                let objectUrl = "";
                let blob;

                try {
                    if (content) blob = dataURItoBlob(content);
                } catch (ex) { return null };

                if (!blob && sharepointUser) {
                    let avatar = await GraphClient.api('/users/' + sharepointUser['UserPrincipalName'] + '/photo/$value', 'arrayBuffer');
                    let image = 'data:image/png;base64, ' + new Buffer(avatar, 'binary').toString('base64');
                    blob = dataURItoBlob(image);
                }

                if (!blob) return null;
                await localforage.setItem(item.id, blob);

                return {
                    imageUrl: 'parse-from-localForage',
                    orginalUrl: item.orginalUrl ? item.orginalUrl : item.imageUrl
                };
            };

            let pictureData = await localforage.getItem(newItems[index].id);
            if (pictureData == null || pictureData == '') pictureData = await getResponse();
            if (pictureData) newItems[index] = { ...newItems[index], ...pictureData };
        }
    });

    if (tasks.length) {
        yield Promise.all(tasks);
    }

    return newItems;
}

async function parseSharepointBlogs(blogs) {
    let parsedBlogs = [];
    for (let key in blogs) {
        if (blogs[key]) {
            const item = blogs[key];

            let url = parseSharepointUrl(item.File['odata.id']);
            let blogObject = new BlogOBject;
            blogObject.setAuthor(item['BlogAutheurId'] ? item['BlogAutheurId'] : item['AuthorId'] ? item['AuthorId'] : item.Author ? item.Author['Id'] : item.Author);
            blogObject.setContent(item.CanvasContent1 ? parseSharePointContent(item.CanvasContent1, url, item.File?.ServerRelativeUrl, key): item.Description ? item.Description : "");
            blogObject.setDate(item.FirstPublishedDate ? item.FirstPublishedDate : item.Created);
            blogObject.setTitle(item.Title);
            blogObject.setDateModified(item.Modified);
            blogObject.setID(item.Id)
            blogObject.setType('sharepoint');
            blogObject.setUrl(url.concat(item.File?.ServerRelativeUrl));
            blogObject.setOpenExternal(false);
            blogObject.setImageUrl(item.BannerImageUrl?.Url);
            blogObject.setSite(item.site);

            if (blogObject.getBlogObject()) {
                parsedBlogs.push(blogObject.getBlogObject());
            }
        }
    }
    return parsedBlogs;
}

function parseSharePointContent(content, root, extra, key) {
    if (!content) return '';
    const urls = /href=\"\//g;
    const src = /src=\"\//g;
    const ids = /<div data-sp-componentid=\"\">[a-z,0-9,-]+<\/div>/g;
    const video = /<a data-sp-prop-name=\"videoSource\" href=\"(.*)\"><\/a>/g;
    const videoThumb = /<img data-sp-prop-name=\"streamVideoThumbnail\.serverRelativeUrl\" src=\"[^>]*\" \/>/g;
    const imagesRegex = /<img data-sp-prop-name=\"imageSource\".*?\/>/g
    const divPlaceRegex = /<div data-webpart-id=\"image\".*?>/g;
        let images = [];
        let divPlace = [];
        images = content.match(imagesRegex);
        divPlace = content.match(divPlaceRegex);
        if(images && divPlace) {
            content = content.replace(imagesRegex, "");
            for(let i = 0; i<images.length; i++) {
                if(images[i] && divPlace[i]) {
                    content = content.replace(divPlace[i], function(match, token) {
                       return  divPlace[i]+images[i]})
                }
            }
        }
    content = content.replace(urls, function (match, token) {
        return 'target="_blank" href="' + root + '/';
    });

    content = content.replace(src, function (match, token) {
        return 'src="' + root + '/';
    });

    content = content.replace(videoThumb, "");

    content = content.replace(ids, "");

    content = content.replace(/&#8232;/g, "\n")

    let test;
    while (test = video.exec(content)) {
        let embed = test[1].replace("/video/", "/embed/video/");
        if (test[1]) content = content.replace(test[0], `<div style='max-width: 640px'><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe width="640" height="360" src="${decodeURI(embed)}?autoplay=false&showinfo=true" allowfullscreen style="border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;"></iframe></div></div>`)
    }
    
    return content;
}

export default function* rootSaga() {
    yield all([
        fork(getBlogsFromChannel)
    ]);
}
