import  moment  from 'moment';
class EventObject {
    constructor() {
        this._eventItem = {
            id : false,
            type : false,
            author : false,
            site: false,
            description : false,
            title : false,
            picture : false,
            url : false,
            tenantId: false,
            externalUrl: false,
            
            allEventData: false,            
            widget: false,                      
            unix : false,
            moment : false,
            location: false,
        }
    }
    setID = (ID) => {
        this._eventItem = {
            ...this._eventItem,
            id : ID
        }
    }

    setType = (type) => {
        this._eventItem = {
            ...this._eventItem,
            type : type
        }
    }

    setAuthor = (author) => {
        this._eventItem = {
            ...this._eventItem,
            author : author
        }
    }

    setSite = (site) => {
        this._eventItem = {
            ...this._eventItem,
            site: site
        }
    }

    setContent = (description) => {
        this._eventItem = {
            ...this._eventItem,
            description : description
        }
    }

    setDate = (date, format = false) => {
        const parsedDate = moment(date, format);
        this._eventItem = {
            ...this._eventItem,
            unix : parsedDate.unix(),
            moment : parsedDate
        }
    }

    setTitle = (title) => {
        this._eventItem = {
            ...this._eventItem,
            title : title
        }
    }

    setPicture = (picture) => {
        this._eventItem = {
            ...this._eventItem,
            picture : picture
        }
    }

    setUrl = (URL) => {
        this._eventItem = {
            ...this._eventItem,
            url : URL
        }
    }

    setTenantId = (tenantId) => {
        this._eventItem = {
            ...this._eventItem,
            tenantId: tenantId
        }
    }

    setOpenExternal = (bool) => {
        this._eventItem = {
            ...this._eventItem,
            openExternal : bool
        }
    }

    setAllEventData = (allEventData) => {
        this._eventItem = {
            ...this._eventItem,
            allEventData: allEventData
        }
    }

    setWidget = (widget) => {
        this._eventItem = {
            ...this._eventItem,
            widget: widget
        }
    }

    setLocation = (location) => {
        this._eventItem = {
            ...this._eventItem,
            location: location
        }
    }

    setExternalUrl = (externalUrl) => {
        this._eventItem = {
            ...this._eventItem,
            externalUrl: externalUrl,
            location: location
        }
    }

    getEventObject = () => {
        for ( const property in this._eventItem ) {
            if ( this._eventItem.hasOwnProperty(property) ) {
                if( property !== 'url' && property !== 'imageUrl' && property !== 'picture'&& property !== 'openExternal' && property !== 'tenantId' && property !== 'site') {
                    if ( !this._eventItem[property] ) {
                        console.log("Missing eventItem: " + property);
                        return false;
                    }
                }

            }
        }
        return this._eventItem;
    }
}

export default EventObject;
