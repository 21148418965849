import styled from 'styled-components'
import * as Variables from '../../../../ThemeVariables';
export const SearchInput = styled.input`
	width: calc(100% - 50px);
	line-height: ${Variables.headerHeight};
	font-size: 16px;
	background: transparent;
	float: left;
	&:focus {
		outline : none;
	}
`;

export const SearchWrapper = styled.div`
	position: absolute;
	width: 100%;
	top: 65px;
	background: white;
	z-index: 10;
	max-height: 0;
	padding: 0;
	overflow: hidden;
	transition: all .5s;

	&.active {
		padding: 15px;
		max-height: 90px;
	}

	@media ( min-width: ${Variables.xlg} ) {
		display: none;
	}
`;

export const Magnifier = styled.div`
	width: 50px;
	float: left;
	line-height: ${Variables.headerHeight};
	text-align: center;

`;
