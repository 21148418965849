import { all, put, fork, takeEvery,select } from 'redux-saga/effects';
import actions from './actions';
import { getGOClient } from '../selectors';
export function* setReports() {

	yield takeEvery(actions.GET_TILES, function*(action) {

        const GoClient = yield select(getGOClient);

        if (!GoClient) return;

        const tiles = yield GoClient.api('/dashboard/tiles');

        if (!tiles) return;

        yield put({
            type: actions.SET_TILES,
            payload: {
            	tiles: tiles
            }
        });

	});

}

export default function* rootSaga() {
	yield all([
		fork(setReports)
	]);
}
