import { combineReducers } from 'redux'
import User from './user/reducer';
import SlideOut from './slideOut/reducer';
import Layout from './layout/reducer';
import Auth from './auth/reducer';
import Document from './document/reducer';
import Reports from './reports/reducer';
import Birthday from './birthday/reducer';
import Client from './client/reducer';

import News from './news/reducer';
import Tasks from './tasks/reducer';
import Calendar from './calendar/reducer';
import Notifications from './notification/reducer';
import Events from './events/reducer';
import Blog from './blog/reducer';
import Widgets from './widgets/reducer';
import Apps from './apps/reducer';
import Search from './search/reducer';
import Message from './message/reducer';
import CommonNews from './common/news/reducer';
import ITNews from './common/itnews/reducer';
import CommonEvents from './common/events/reducer';
import CommonBlogs from './common/blog/reducer';
import Calls from './common/calls/reducer';
import KnowledgeBase from './knowledgeBase/reducer';
import Learning from './common/learning/reducer';
import App from './app/reducer';
import Solution from'./solution/reducer';
import DocumentsWizard from './documentsWizard/reducer';
import TipsTricks from './tipsTricks/reducer';
import SiteKnowledge from './siteKnowledge/reducer';
import NotificationCollector from './notificationCollector/reducer';
import Popup from './popup/reducer';
import YearOverview from './yearOverview/reducer';

import UserRequest from './common/userRequest/reducer';
import RightsRequest from './common/rightsRequest/reducer';
import WorkplaceRequest from './common/workplaceRequest/reducer';

import { IntlReducer as Intl } from 'react-redux-multilingual'

import Teams from './widgets/teams/reducer';
import Sharepoint from './widgets/sharepoint/reducer';
import People from './widgets/people/reducer';
import MultiChannelNews from './widgets/multiChannelNews/reducer';
import MultiChannelBlog from './widgets/multiChannelBlog/reducer';
import MultiChannelShout from './widgets/multiChannelShout/reducer';
import MultiChannelFeed from './widgets/multiChannelFeed/reducer';
import MultiChannelSocial from './widgets/multiChannelSocial/reducer';
import MultiChannelEvent from './widgets/multiChannelEvent/reducer';

import localForage from "localforage";
import actions from "./actions"

const appReducer = combineReducers({
	User,
	Popup,
	YearOverview,
	SlideOut,
	Layout,
	Auth,
	Document,
    Client,
	Birthday,
	MultiChannelNews,
	MultiChannelBlog,
	MultiChannelFeed,
	MultiChannelShout,
	MultiChannelSocial,
	MultiChannelEvent,
	News,
	Tasks,
	Calendar,
	Notifications,
	Widgets,
	Apps,
	Events,
    Blog,
	Reports,
	Calls,
	Search,
	CommonNews,
	ITNews,
    CommonEvents,
	CommonBlogs,
	KnowledgeBase,
	Message,
	Learning,
	Teams,
	Sharepoint,
	App,
	Solution,
	DocumentsWizard,
	TipsTricks,
	SiteKnowledge,
	People,
	NotificationCollector,
	Intl,
	UserRequest,
	RightsRequest,
	WorkplaceRequest
});

const rootReducer = (state, action) => {
	if(action.type == actions.CLEAR) {
		localForage.removeItem('persist:root')
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default rootReducer;
