const actions = {
	GET_COMMON_EVENTS : 'GET_COMMON_EVENTS',
	SET_COMMON_EVENTS : 'SET_COMMON_EVENTS',
	SET_ACTIVE_COMMON_EVENT: 'SET_ACTIVE_COMMON_EVENT',
	GET_TENANT_EVENTS: 'GET_TENANT_EVENTS',
	SET_TENANT_EVENTS: 'SET_TENANT_EVENTS',
	COMMON_EVENTS_REQUEST_COMPLETED : 'COMMON_EVENTS_REQUEST_COMPLETED',
	POST_COMMON_EVENT: 'POST_COMMON_EVENT',
	DELETE_EVENTS_ITEM: 'DELETE_EVENTS_ITEM',

	setActiveCommonEvent : (payload) => ({
		type: actions.SET_ACTIVE_COMMON_EVENT,
		payload : payload
	}),

	getTenantEvents: () => ({
		type: actions.GET_TENANT_EVENTS
	}),

	getCommonEvents : ( payload ) => ({
		type : actions.GET_COMMON_EVENTS,
		payload : payload
	}),

	postCommonEvent : (payload) => ({
		type : actions.POST_COMMON_EVENT,
		payload : payload
	}),

	deleteItem: (payload) => ({
		type: actions.DELETE_EVENTS_ITEM,
		payload: payload
	})
};

export default actions;
