import actions from './actions';

const initState = {
	activePopup : false,
	data : false,
};

export default function yearOverview(state = initState, action) {
	switch (action.type) {
		case actions.SET_ACTIVE_YEAR_OVERVIEW_POPUP:
			return {
				...state,
				activePopup : action.payload.item,
				data : action.payload.data,
			}
		default:
			return state;
	}
}
