const actions = {
	SET_MULTICHANNEL_SOCIAL_ITEMS : 'SET_MULTICHANNEL_SOCIAL_ITEMS',
	GET_MULTICHANNEL_SOCIAL_ITEMS : 'GET_MULTICHANNEL_SOCIAL_ITEMS',
	SET_ACTIVE_SOCIAL_ITEM : 'SET_ACTIVE_SOCIAL_ITEM',
	SOCIAL_REQUEST_COMPLETED: 'SOCIAL_REQUEST_COMPLETED',
	SET_LAST_SOCIAL_VIEWED: 'SET_LAST_SOCIAL_VIEWED',
	getMultichannelSocialItems : ( payload, title ) => ({
		type : actions.GET_MULTICHANNEL_SOCIAL_ITEMS,
		payload: { title: title, payload: payload }
	}),
	setActiveSocialItem : ( payload ) => ({
		type : actions.SET_ACTIVE_SOCIAL_ITEM,
		payload : payload
	}),
	setLastViewed: (payload) => ({
		type: actions.SET_LAST_SOCIAL_VIEWED,
		payload: payload
	})
};

export default actions;
