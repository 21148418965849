import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from '../styled';
import actions from '../../../../redux/user/actions';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import TinySlider from "tiny-slider-react";

const settings = {
    lazyload: false,
    nav: false,
    mouseDrag: false,
    controls: false // remove built-in nav buttons
}

const SliderContext = React.createContext(false);
class Slider extends Component {

    render() {
        const onGoTo = dir => this.ts.slider.goTo(dir)
        const children = React.Children.map(this.props.children, (child, index) => {
            if (child == null) return;
            return React.cloneElement(child, {
                goTo: onGoTo,
                alwaysVisible: this.props.alwaysVisible
            });
        });
        return (
            <Styled.SliderWrapper className={(this.props.fadeIn ? 'fade-in' : '')}>
                <TinySlider settings={settings} ref={ts => this.ts = ts}>
                    {children}
                </TinySlider>
            </Styled.SliderWrapper>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        sliderHidden: state.Layout.sliderHidden,
        isLoaded: state.Layout.IsLoaded,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...actions
    }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));
