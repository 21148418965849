import styled, { css } from 'styled-components'
import * as Variables from '../../../ThemeVariables';

//heading
const headingStyle = `
    display: flex;
    justify-content: space-between;

`
export const Heading = styled.div`
	margin-bottom: ${props => !props.marginBottom ? "20px" : props.marginBottom + 'px'};

       ${props => !props.positionBottom ? headingStyle : ''};


	${props => props.position ? 'float: ' + props.position + ';' : ''}
	${props => props.border ? "border-bottom: 2px solid #e4e4e4;padding-bottom: 15px;" : ""}
`;

export const ListHeading = styled.div`
	margin-bottom: 20px;
    @media(min-width: ${Variables.mxs}) {
        display: flex;
        justify-content: space-between;
    }
`;


export const Title = styled.h2`
	font-size: ${props => !props.small ? "1.1em" : "0.8em"};
	text-align: left;
	color : ${Variables.headingColor.lightMode};

	&.darkmode {
		color : ${Variables.headingColor.darkMode};
	}
`;

export const LinkContainer = styled.div`
	display: inline-block;
`;

export const UnderLinkContainer = styled.div`
	margin-bottom: 30px;
	
`;


export const Link = styled.a`
	text-decoration: none;
	font-size: 12px;
	cursor: pointer;
    &:not(:first-of-type) {
        margin-left: 16px;
    }

	color : ${Variables.headingColor.lightMode};

	&:after {
		content: '';
		width: 0;
		height: 1px;
		background: ${Variables.headingColor.lightMode};
		position: absolute;
		bottom: -5px;
		left: 0;
		transition: width .5s;
	}

	&.darkmode {
		color : ${Variables.headingColor.darkMode};
		&:after {
			background : ${Variables.headingColor.darkMode};
		}
	}

	text-align: right;

	&:hover {
		&:after {
			width: 100%;
		}
	}

	&.active-link {
		&:after {
			width: 100%;
		}
	}
`;
