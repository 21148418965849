import actions from './actions';

const initState = {
	events : [],
	tenantEventsItems : [],
	activeEvent : false,
	requestCompleted : false
};


export default function commonEventsReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_COMMON_EVENTS:
			return {
				...state,
				events : action.payload
			}
		case actions.COMMON_EVENTS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_TENANT_EVENTS:
			return {
				...state,
				tenantEventsItems: action.payload
			}
		case actions.SET_ACTIVE_COMMON_EVENT :
			return {
				...state,
				activeEvent: action.payload
			}
		default:
			return state;
	}
}
