import { AccountInfo, Configuration, AuthenticationResult, PublicClientApplication, SilentRequest, RedirectRequest, EndSessionRequest } from "@azure/msal-browser"

class AuthService {

    constructor(msalConfig) {
        if (!msalConfig) {
            throw new Error('the msal settings was not provided');
        }

        this.msalConfig = msalConfig;
        this.msalApplication = new PublicClientApplication(msalConfig);

        //this.account = new AccountInfo;

        //let msalConfig = this.GetMsalClientConfiguration();
        //this.msalApplication = new PublicClientApplication(msalConfig);
    }

    //// msal application object
    //msalApplication: PublicClientApplication;

    //// settings service
    //appSettings: AppSettingsService;

    //// cached account info
    //account?: AccountInfo;

    HandlePageLoadEvent = () => {
        // let exceptions bubble up to the caller to handle
        return this.msalApplication.handleRedirectPromise().then((authResult) => {
            this.HandleRedirectResponse(authResult);
        });
    }

    HandleRedirectResponse = (authResult) => {
        // if this page load is redirect from the Microsoft Identity platform then the
        // authResult will be populated. Otherwise null on other page loads.

        if (authResult !== null) {
            // save the fresh account info from the result.
            this.account = authResult.account;
        }
        else {
            // see if we have cached accounts.
            const currentAccounts = this.msalApplication.getAllAccounts();

            if (currentAccounts === null) {
                // no cached accounts.
                // user will need to click the sign-in button and redirect to login.
                return;
            }
            else if (currentAccounts.length > 1) {
                // there are some situations where the user may have multiple (different) cached logins.
                // this code sample does not cover that scenario but just logs a warning here.
                // this conditional block would need to be updated to support multiple accounts.
                // otherwise it will just grab the first one below.
                console.warn("Multiple accounts detected in MSAL account cache.");
                this.account = currentAccounts[0];
            }
            else if (currentAccounts.length === 1) {
                // we have exactly 1 cached account.
                // set the account info. user may not need to sign in.
                this.account = currentAccounts[0];
            }
        }
    }

    GetToken = async (scopes) => {

        let tokenRequest = {
            account: this.account,
            scopes: scopes,
            forceRefresh: false
        }
        // msal will return the cached token if present, or call to get a new one
        // if it is expired or near expiring.
        //return await this.msalApplication.acquireTokenSilent(tokenRequest);
        if (window.applicationOnline) {
            return this.msalApplication.acquireTokenSilent(tokenRequest).then((response) => {

                return response;
            })
                .catch((error) => {
                    console.log('Raw error: ', error);
                    //

                    this.msalApplication.acquireTokenRedirect(tokenRequest).then((response) => {
                        return response;
                    }).catch(function (error) {
                        if (error instanceof InteractionRequiredAuthError) {

                        }
                    });
                })
        } else {
            console.log("Offline");
        }
        return null;
    }



    GetIdToken = async (scopes) => {

        // msal will return the cached token if present, or call to get a new one
        // if it is expired or near expiring.
        //return await this.account.getIdToken();
        //let test = this.msalApplication.getAccount();
        //return test.idToken;
        //this.account

        let test = this.account;
        return null;
    }



    SignIn() {
        let request = {
            scopes: [
                'User.ReadBasic.All',
                'email',
                'profile',
                'User.Read',
                'Mail.Read',
                'People.Read',
                'offline_access'
            ],
            prompt: "select_account"
        }

        if (this.account) request.loginHint = this.account.username;


        // this will redirect the web application to the Microsoft Identity platform sign in pages.
        // no code will execute after this point.
        this.msalApplication.loginRedirect(request);
    }

    SignOut() {
        if (!this.account) {
            // no cached login to signout
            return;
        }

        let accountInfo = this.msalApplication.getAccountByUsername(this.account?.username);

        if (accountInfo !== null) {
            let logoutRequestPayload = {
                account: accountInfo
            }

            this.msalApplication.logout(logoutRequestPayload)
        }
    }
}

export default AuthService;
