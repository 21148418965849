import styled from 'styled-components';
import * as Variables from '../../../ThemeVariables';
const headingStyle = `
	display: flex;
	justify-content: space-between;
`

export const Heading = styled.div`
	margin-top: 5px;
	margin-bottom: 35px;
	cursor: pointer;

	${props => !props.positionBottom ? headingStyle : 'display: inline-block;'};

	${props => props.position ? 'float: '+props.position+';' : '' }
	${props => props.border ? "border-bottom: 2px solid #e4e4e4;padding-bottom: 15px;" : ""}
	${props => props.marginBottom ? 'margin-bottom: ' +props.marginBottom + 'px;' : ''};
	${props => props.marginTop ? 'margin-top: ' +props.marginTop + 'px;' : ''};
	&.task-tutorial-active, &.calendar-tutorial-active {
		h2 {
			&:after {
			top: 0;
		    left: 0;
		    content: '';
		    position: absolute;
		    border: 3px solid red;
		    border-radius: 25px;
		    width: calc(100% + 25px);
		    height: calc(100% + 25px);
		    margin-top: -15px;
		    margin-left: -15px;
		}
		}

	}
`;

export const Title = styled.h2`
	font-size: ${props => !props.small ? "1.1em" : "0.8em"};
	text-align: left;

	color : ${Variables.headingColor.lightMode};

	&.darkmode {
		color : ${Variables.headingColor.darkMode};
	}
`;

export const Link = styled.a`
	text-decoration: none;
	font-size: 12px;
	color : ${Variables.headingColor.lightMode};

	&.darkmode {
		color : ${Variables.headingColor.darkMode};
	}
	text-align: right;
	&:hover{
		text-decoration: underline;
	}
`;

export const NoLink = styled.span`
	text-decoration: none;
	font-size: 12px;
	color : ${Variables.headingColor.lightMode};

	&.darkmode {
		color : ${Variables.headingColor.darkMode};
	}
	text-align: right;
	&:hover{
		text-decoration: underline;
	}
`;
