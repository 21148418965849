const actions = {
	GET_CALENDAR : 'GET_CALENDAR',
	SET_CALENDAR : 'SET_CALENDAR',
	ORDER_CALENDAR : 'ORDER_CALENDAR',
	getCalendar: (payload) => ({ 
		type: actions.GET_CALENDAR,
		payload : payload
	})
};

export default actions;
