import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const Button = props => (
    <Styled.Button style={props.style} onClick={props.onClick} position={props.position} color={props.color}>
		{ props.text } 
	</Styled.Button>
)

Button.propTypes = {
    position: PropTypes.string,
	onClick : PropTypes.func,
    text: PropTypes.string,
    color: PropTypes.string
}

export default Button;