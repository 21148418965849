const actions = {
	MESSAGE: 'MESSAGE',
	ADD_MESSAGE: 'ADD_MESSAGE',
	setMessage : (payload) => ({
		type: actions.MESSAGE,
		payload : payload
	}),
};

export default actions;
