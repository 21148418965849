import { all, put, fork, select,cancel,cancelled, delay, take  } from 'redux-saga/effects';
import actions from './actions';
import moment from 'moment';

var SunCalc = require('suncalc');
export function* setColumns() {

    /*yield put({
		type: actions.SET_COLUMNS,
		payload: {
			columns: 3
		}		    	
    });	*/
}


export function* watchCurrentTime() {
   
    try {
		while(true) {
			// -- Get time by locale string
			const times = SunCalc.getTimes(new Date(), 53.219509, 5.483870);

			// -- Parse sunrise & sunset to moment objects
			const sunriseMoment = moment(times.sunrise.toLocaleString(), 'D-M-YYYY HH:mm:ss');
			const sunsetMoment = moment(times.sunset.toLocaleString(), 'D-M-YYYY HH:mm:ss');

			const now = moment();

			// -- Check if before or after sunset
			let darkmode = false;
			if ( now.isAfter(sunsetMoment) || now.isBefore(sunriseMoment)) {
				darkmode = true;
			}

			const state = yield select();
			const darkModeNow = state.Layout.darkmode;

			if ( darkModeNow !== darkmode ) {
                yield put({
                    type: actions.SET_DARK_MODE,
                    payload: darkmode
                });
			}

            yield delay(5000);
		}
	} finally {
		if ( yield cancelled() ) {
			
		}
	}
 
}

export function* checkDarkmodeAuto() {
  while ( yield take(actions.START_DARKMODE_AUTO) ) {

    // -- Start watcher task
    const bgSyncTask = yield fork(watchCurrentTime);
    
    // -- Take every stop action, until that time, code execution stops here.
    yield take(actions.STOP_DARKMODE_AUTO)
   	yield cancel(bgSyncTask);
  }

}


export default function* rootSaga() {
	yield all([
		fork(setColumns),
		fork(checkDarkmodeAuto)
	]);
}
