import styled from 'styled-components'
import * as Variables from '../../../../ThemeVariables';

import CircleIcon  from '../../../styled/CircleIcon';

export const SearchButton = styled(CircleIcon) `
	${props => props.gridArea ? 'grid-area:' + props.gridArea : ''};
	font-size: 15px;

	@media ( min-width: ${Variables.mxs} ) {
		display: none;
	}

`;
