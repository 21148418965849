import actions from './actions';
const initState = {
    days_left: 0,
    user_avatar: false,
    profile: {
        data: { settings: {}}},
    messages: false,
    tasks: false,
    calendarItems: false,
    requestCompleted: false,
    logging: [],
    lastDebug: false,
    applications: false,
    featureSliders : false,
    viewedTutorials : {
        tasks : false,
        powerApps : false,
        goApps : false,
        calendar : false
    }
};

export default function userReducer(state = initState, action) {
    switch (action.type) {
        case actions.DATA_REQUEST_COMPLETED:
            return {
                ...state,
                requestCompleted: action.payload
            }
        case actions.SET_FEATURE_SLIDERS :
            return {
                ...state,
                featureSliders : action.payload
            }
        case actions.SET_USER_PROFILE:
            return {
                ...state,
                profile: action.payload
            };
        case actions.SET_USER_AVATAR:
            return {
                ...state,
                user_avatar: action.payload
            };
        case actions.SET_DAYS_LEFT:
            return {
                ...state,
                days_left: action.payload
            };
        case actions.SET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            };
        case actions.SET_USER_APPLICATIONS:
            return {
                ...state,
                applications: action.payload
            };
        case actions.GET_USER_DATA:
            return {
                ...state,
                profile: { ...state.profile, data: action.payload }
            };
        case actions.ADD_DEBUG_DATA:
            return {
                ...state,
                logging: action.payload //logging.push(action.payload)
            };
        case actions.SET_LAST_DEBUG:
            return {
                ...state,
                lastDebug: action.payload,
                logging: []
            };
        case actions.SET_USER_CALENDARITEMS:
            return {
                ...state,
                calendarItems: action.payload
            };
        case actions.SET_VIEWED_TUTORIAL:

            return {
                ...state,
                viewedTutorials : {
                    ...state.viewedTutorials,
                    [action.payload] : true
                }
            }
        case actions.SET_USER_TASKS:
            return {
                ...state,
                tasks: action.payload
            };
        default:
            return state;
    }
}
