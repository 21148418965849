import styled from 'styled-components';
import * as Variables from '../../ThemeVariables';

const Box = styled.div`

	border-radius: ${Variables.borderRadius};
	width: 100%;
	box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03);

	transform: translateX(0) rotateX(0) rotateZ(0deg)

	padding: 14.4px;

	transition: all .3s;
	&:hover {
		box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.06);
	}

	background: ${Variables.tileBackground.lightMode};
	color: ${Variables.mainTextColor.lightMode};

	&.darkmode {
		background: ${Variables.tileBackground.darkMode};
		color: ${Variables.mainTextColor.darkMode};
		&:hover {
			box-shadow: 0px 0px 5px 4px rgba(254, 254, 254, 0.03);
		}
	}
`;

export default Box;