const actions = {
	GET_APPS : 'GET_APPS',
	SET_APPS: 'SET_APPS',
	GET_LIQUIT_APPS: 'GET_LIQUIT_APPS',
	SET_LIQUIT_APPS: 'SET_LIQUIT_APPS',
	SET_VIEWED: 'SET_VIEWED',
	REMOVE_VIEWED : 'REMOVE_VIEWED',
	SET_POSITION: 'SET_POSITION',
	
	getApps : (payload) => ({
		type : actions.GET_APPS,
		payload : payload
	}),
	setApps : (payload) => ({
		type : actions.SET_APPS,
		payload : payload
	}),
	getLiquitApps: (payload) => ({
		type: actions.GET_LIQUIT_APPS,
		payload: payload
	}),
	setTileSettings: (payload) => ({
		type: actions.SET_VIEWED,
		payload: payload
    }),
	removeViewedTiles: (payload) => ({
		type: actions.REMOVE_VIEWED,
		payload: payload
    }),
	setTilePosition: (payload) => ({
		type: actions.SET_POSITION,
		payload:payload
	})
};

export default actions;
