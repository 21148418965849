import actions from './actions';

const initState = {
	shoutItems : [],
	activeShoutItem : false,
	requestCompleted : false
};


export default function multiChannelShoutReducer(state = initState, action) {

	switch (action.type) {
		case actions.SHOUT_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_MULTICHANNEL_SHOUT_ITEMS:
			return {
				...state,
				shoutItems : action.payload
			}
		case actions.SET_ACTIVE_SHOUT_ITEM:
            return {
                ...state,
                activeShoutItem: action.payload
            }
		default:
			return state;
	}
}
