import actions from './actions';

const initState = {
    tenantNewsItems: []
};


export default function commonNewsReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_COMMON_NEWS:
            return {
                ...state,
                newsItems: action.payload
            }
        case actions.COMMON_NEWS_REQUEST_COMPLETED:
          return {
            ...state,
            requestCompleted : action.payload
          }
        case actions.SET_TENANT_NEWS:
            return {
                ...state,
                tenantNewsItems: action.payload
            }
        case actions.SET_ACTIVE_NEWS_ITEM:
            return {
                ...state,
                activeNewsItem: action.payload
            }
        default:
            return state;
    }
}
