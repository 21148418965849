import styled, { css } from 'styled-components'
import CircleIcon  from '../../../styled/CircleIcon';
import * as Variables from '../../../../ThemeVariables';
export const ButtonWrapper = styled.div`
	grid-area: notifications;
	${props => props.parent === 'header-col-right' && css` 
		display: block;
		@media ( min-width: ${Variables.xs}) {
			display: block;
		}
		
	`}
`;
export const Notifications = styled(CircleIcon) `
	grid-area: notifications;
	transform: rotate(0);
	&.shake {
		animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	}


	@keyframes shake {
	  10%, 90% {
	    transform: rotate(-5deg);
	  }
	  
	  20%, 80% {
	    transform: rotate(6deg);
	  }

	  30%, 50%, 70% {
	    transform: rotate(-8deg);
	  }

	  40%, 60% {
	    transform: rotate(8deg);
	  }
	}
`;