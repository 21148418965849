import { all, fork, takeEvery, select, put, call, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import notificationCollectorActions from '../notificationCollector/actions';
//import * as SignalR from '@aspnet/signalr';
import { toast } from 'react-toastify';
import { dataURItoBlob } from '../../helpers/functions';
import { getGOClient, getNotificationItems, getSharepointClient, getAuth, getNotificationsHub, getIsNotificationsIsConnected } from '../selectors';

var notificationHub = null;
export function* getNotifications() {
    yield takeEvery(actions.GET_NOTIFICATIONS, function* (action) {

    });
}

export function* startNotifications() {
    yield takeEvery(actions.START_NOTIFICATIONS, function* (action) {
        const goClient = yield select(getGOClient);

        if (window.tenantConfig.notificationEnabled === 'false' || !window.tenantConfig.notificationEnabled) return;

        const channel = yield call(websocketInitChannel, goClient);

        yield put({
            type: actions.SET_HUB,
            payload: notificationHub
        });

        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    });
}

export function* setNumberOfNotifications() {
    yield takeEvery(actions.SET_NOTIFICATIONS, function* (action) {

        // -- Count the total number of notifications in all categories
        let counter = 0;

        for (const category in action.payload) {
            if (action.payload.hasOwnProperty(category)) {
                counter += action.payload[category].length;
            }
        }

        yield put({
            type: actions.SET_NUMBER_OF_NOTIFICATIONS,
            payload: counter
        })
    });
}


export function* receiveNotification() {
    yield takeEvery(actions.RECEIVE_NOTIFICATION, function* (action) {

        const Client = yield select(getSharepointClient);
        let notifications = yield select(getNotificationItems);
        const Auth = yield select(getAuth);
        let type = 'news';

        let notification = {
            message : '',
            type : ''
        };

        if (typeof (action.payload) !== 'string') {

            const imageUrl = yield fetch(`/api/getimage?token=${(yield Client.getToken())}&url=${encodeURIComponent(action.payload.picture)}`, {
                method: 'GET',
                contentType: 'image/jpeg',
                headers: { 'Authorization': 'Bearer ' + Auth.jwtIdToken }
            }).then((res) => res.text())
                .then((content) => {
                    let url = false;
                    try {
                        url = URL.createObjectURL(dataURItoBlob(content));
                    } catch (error) {
                        console.error(error);
                    }

                    if (url) {
                        return url;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    return false;
                });

            type = action.payload.type;

            notification.message = action.payload.title;

            action.payload.picture = imageUrl;
            // -- Show user a toast with the latest notification
        } else {
            notification.message = action.payload;
            type = 'notification';
        }
        notification.type = type;

        // -- Add to notifications object
        if (notifications[type]) {
            notifications[type].push(action.payload);
        } else {
            if (typeof (action.payload) !== 'string') {
                notifications = {
                    ...notifications,
                    [type]: [{ ...action.payload }]
                };
            } else {
                notifications = {
                    ...notifications,
                    [type]: [action.payload]
                };
            }
        }

        yield put({
            type : notificationCollectorActions.PUSH_NOTIFICATION,
            payload : notification
        })

        yield put({
            type: actions.SET_NOTIFICATIONS,
            payload: notifications
        });
    });
}


export function* removeSingleNotification() {
    yield takeEvery(actions.REMOVE_SINGLE_NOTIFICATION, function* (action) {

        let notifications = yield select(getNotificationItems);

        if (notifications[action.payload.category]) {
            let newForCategory = [];
            for (let i = 0; i <= notifications[action.payload.category].length; i++) {
                if (notifications[action.payload.category][i]) {
                    if (i !== action.payload.key) {
                        newForCategory.push(notifications[action.payload.category][i]);
                    }
                }
            }

            // -- If the category still contains items, add all items to that object. Otherwise, remove the object.
            if (newForCategory.length) {
                notifications[action.payload.category] = newForCategory;
            } else {
                let newNotifications = {};
                for (const category in notifications) {
                    if (notifications.hasOwnProperty(category)) {
                        if (category !== action.payload.category) {
                            newNotifications = {
                                ...newNotifications,
                                [category]: {
                                    ...notifications[category]
                                }
                            };
                        }
                    }
                }
                notifications = newNotifications;
            }
        }

        yield put({
            type: actions.SET_NOTIFICATIONS,
            payload: notifications
        });

    });
}


export function* receiveCommand() {
    yield takeEvery(actions.RECEIVE_COMMAND, function* (action) {

        if (action.payload.messageType === 7) {
            yield put({
                type: actions.SET_APPLICATIONS,
                payload: JSON.parse(action.payload.message)
            });
        } else if (action.payload.messageType === 8) {
            yield put({
                type: actions.SET_APPLICATION,
                payload: JSON.parse(action.payload.message)
            });
        }

        //else {

        //    yield put({
        //        type: actions.GET_COMMANDS,
        //        payload: action.payload
        //    });
        //}
    });
}


export function* sendExecuteCommand() {
    yield takeEvery(actions.SEND_EXECUTE_COMMAND, function* (action) {
        yield put({
            type: actions.SEND_COMMAND,
            payload: {
                commandType: 0,
                senderId: "", //state.Commands.Hub.connection.id,
                senderUpn: "GO-",
                recipientId: "",
                recipientUpn: "",
                commandContent: action.payload
            }
        });
    });
}

export function* sendListCommand() {
    yield takeEvery(actions.SEND_LIST_COMMAND, function* (action) {
        yield put({
            type: actions.SEND_COMMAND,
            payload: {
                commandType: 7,
                senderId: "", // state.Commands.Hub.connection.id,
                senderUpn: "GO-",
                recipientId: "",
                recipientUpn: "",
                commandContent: ""
            }
        });
    });
}

export function* sendDetailCommand() {
    yield takeEvery(actions.SEND_DETAIL_COMMAND, function* (action) {
        yield put({
            type: actions.SEND_COMMAND,
            payload: {
                commandType: 8,
                senderId: "", // state.Commands.Hub.connection.id,
                senderUpn: "GO-",
                recipientId: "",
                recipientUpn: "",
                commandContent: action.payload
            }
        });
    });
}


export function* sendCheckCommand() {
    yield takeEvery(actions.SEND_CHECK_COMMAND, function* (action) {
        let connected = false;

        const notificationsHub = yield select(getNotificationsHub);
        const notificationsIsConnected = yield select(getIsNotificationsIsConnected);

        if (notificationsHub && notificationsIsConnected) {
            try {
                const isConnected = yield notificationsHub.invoke("IsServiceConnected");
                connected = (typeof (isConnected) === "string" && isConnected === "True");
            } catch (error) { }
        } else {
            connected = false;
        }

        yield put({
            type: actions.SERVICES_CONNECTED,
            payload: connected
        });
    });
}


export function* sendCommand() {
    yield takeEvery(actions.SEND_COMMAND, function* (command) {

        const notificationsHub = yield select(getNotificationsHub);
        const notificationsIsConnected = yield select(getIsNotificationsIsConnected);
        //if (!state.Commands.Hub) return;
        if (notificationsIsConnected) {
            try {
                notificationsHub.invoke("SendCommand", command.payload);
            } catch (error) { }
        }
    });
}

export function* setConnected() {
    yield takeEvery(actions.SET_CONNECTED, function* (action) {

        if (action.payload === true) {
            yield put({
                type: actions.SEND_CHECK_COMMAND
            });
        } else {
            yield put({
                type: actions.SERVICES_CONNECTED,
                payload: false
            });
        }
    });
}

function websocketInitChannel(goClient) {
    //const token = state.Auth.jwtIdToken;
    //let token = state.Client.GoClient.getToken();

    return eventChannel(emitter => {

        /* notificationHub = new SignalR
             .HubConnectionBuilder()
 
             .withUrl(window.tenantConfig.notificationUrl + "/notification?PartitionKey=0&PartitionKind=Int64Range&TargetReplicaSelector=PrimaryReplica", {
                 accessTokenFactory: async () => await goClient.getToken()
             })
             //.configureLogging(SignalR.LogLevel.Debug)
             .build();
 
         notificationHub.on('ReceiveNotification', function (message) {
             if (typeof (message) !== "string") {
                 if (!message.type) {
                     message.type = 'Nieuws';
                 }
             }
             return emitter(
                 {
                     type: actions.RECEIVE_NOTIFICATION,
                     payload: message
                 }
             );
         });
 
         notificationHub.on('ReceiveCommand', function (messageType, message) {
             return emitter(
                 {
                     type: actions.RECEIVE_COMMAND,
                     payload: { messageType: messageType, message: message }
                 }
             );
         });
 
         //notificationHub.start().then(function (er) {
         //    notificationHub.invoke("Login", "GO-").then(function (err) {
 
         //    }).catch(function (err) {
         //    toast(this.props.translate('commandHubInvokeGoLoginError') + err, {autoClose: 1000*60*60*24});
         //    });
         //});
 
         let timer = null;
         var restartHub = function () {
             notificationHub
                 .start()
                 .then(function (er) {
                     notificationHub.invoke("Login", "GO-").then(con => {
                         return emitter(
                             {
                                 type: actions.SET_CONNECTED,
                                 payload: true
                             }
                         );
                     }).catch(function (err) {
                         notificationHub.stop();
                         toast(this.props.translate('commandHubInvokeGoLoginError') + err, {autoClose: 1000*60*60*24});
                         return emitter(
                             {
                                 type: actions.SET_CONNECTED,
                                 payload: false
                             }
                         );
                     });
                 })
                 .catch(function (err) {
                     notificationHub.stop();
                     console.log('Error while starting connection: ' + err);
 
                     clearTimeout(timer);
                     timer = setTimeout(restartHub, 9000);
 
                     return emitter(
                         {
                             type: actions.SET_CONNECTED,
                             payload: false
                         }
                     );
                 });
         };
 
         restartHub();
 
         notificationHub.onclose(async function (error) {
             console.log("DISCONNECTED: " + error);
 
             clearTimeout(timer);
             timer = setTimeout(restartHub, 9000);
 
             return emitter(
                 {
                     type: actions.SET_CONNECTED,
                     payload: false
                 }
             );
         });
 
         return () => {
             // -- do whatever to interrupt the socket communication here
         }*/
    });
}

export default function* rootSaga() {
    yield all([
        fork(getNotifications),
        fork(startNotifications),
        fork(removeSingleNotification),
        fork(setNumberOfNotifications),
        fork(receiveNotification),
        fork(sendListCommand),
        fork(sendDetailCommand),
        fork(sendCheckCommand),
        fork(sendExecuteCommand),
        fork(receiveCommand),
        fork(sendCommand),
        fork(setConnected)
    ]);
}
