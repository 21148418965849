import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../../message/actions';
import userActions from '../../user/actions';
import store from "../../store";
import { getCommonNewsItems, getGOClient, getUserProfile, getUserProfileSettingData, getTenantNewsItems, getAuth } from '../../selectors'

export function* getWorkplaceRequest() {
    yield takeEvery(actions.GET_WORKPLACE_REQUEST, function* (action) {

        const workplaceRequests = yield select(getWorkplaceRequest);
        const Client = yield select(getGOClient);
        if (!Client) return;
        const requests = null;
        // const requests = yield Client.api("/selfService/workplace/tenant");

        if(requests == null | !requests) return;

        if (requests.length == 0 || (requests.length && ((workplaceRequests.length === 0) || (workplaceRequests && workplaceRequests[0].Id !== requests[0].id)))) {

            yield put({
                type: actions.SET_WORKPLACE_REQUEST,
                payload: requests
            });
        }
    });
}

export function* postWorkplaceRequest() {
    yield takeEvery(actions.POST_WORKPLACE_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;

        let incidentRequest = {
            calltypeid: 2,
            subject: action.payload.title,
            body: action.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
            phonenumber: userProfile.mobilePhone ? userProfile.mobilePhone : '',
        }

        let formFields = {
            omschrijving: action.payload.omschrijving
        }

        // if (action.payload.id) {
        //     method = 'PUT';
        //     formFields = {
        //         ...formFields,
        //         id: action.payload.id,
        //         authorName: action.payload.authorName,
        //         authorId: action.payload.authorId
        //     }
        // }

        let result = false;

        result = yield GoClient.post('/app/incident/request', incidentRequest);
        //userRequests.push(formFields);


        if (result) {
            const userProfileData = yield select(getUserProfileSettingData);
            let userRequests = userProfileData?.settings?.calls
            if(!userRequests) userRequests = [];
            userRequests.push({
                callId: result.callId,
                status: result.status,
                type: incidentRequest.calltypeid,
                creationDate: Date.now(),
                lastUpdate: Date.now(),
                request: "WorkplaceRequest",
            })
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: userRequests,
                    setting: 'calls'
                }
            });

            const requests = yield select(getWorkplaceRequest);
            let request = requests
            formFields['callId'] = result.callId
            request.push(formFields)
            yield put({
                type: actions.SET_WORKPLACE_REQUEST,
                payload:request
            })

            // yield put({
            //     type: actions.GET_USER_REQUEST
            // })

            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Workplace Request toegevoegd',
                    type: 'success'
                }
            });

            store.history.push('/self-service/werkplek');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* updateWorkPlaceRequest() {
    yield takeEvery(actions.UPDATE_WORKPLACE_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;
        let incidentRequest = {
            callid: action.payload.id,
            calltypeid: 2,
            subject: action.payload.payload.title,
            body: action.payload.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
            phonenumber: userProfile.mobilePhone ? userProfile.mobilePhone : '',
        }

        let formFields = {
            omschrijving: action.payload.payload.omschrijving
        }
        
        //yield GoClient.post('/app/incident/action', incidentRequest);
        const userProfileData = yield select(getUserProfileSettingData);
        let rightsRequests = userProfileData?.settings?.calls
        let index = rightsRequests.findIndex(request => request.callId == incidentRequest.callid)

        if(index > -1) {
            if(!rightsRequests[index]) rightsRequests = [];
            rightsRequests[index] = {
                ...rightsRequests[index],
                    type: incidentRequest.calltypeid,
                    lastUpdate: Date.now(),
                }
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: rightsRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getWorkplaceRequest);
            let request = requests
            let index2 = request.findIndex(request => request.callId == incidentRequest.callid)
                request[index2] = formFields
                yield put({
                    type: actions.SET_WORKPLACE_REQUEST,
                    payload:request
                })
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Workplace Request geupdated',
                        type: 'success'
                    }
                });
            store.history.push('/self-service/werkplek');
         } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* deleteWorkplaceReqest() {
    yield takeEvery(actions.DELETE_WORKPLACE_REQUEST, function* (action) {

        const GoClient = yield select(getGOClient);
        if (!GoClient) return;

        // const result = yield GoClient.delete('/common/news?id=' + action.payload);
        if (result) {
            if (result.ok) {
                yield put({
                    type: actions.GET_WORKPLACE_REQUEST
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Workplace Request verwijderd',
                        type: 'success'
                    }
                });

            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
            store.history.push('/self-service/workplace');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }

    });
}

export default function* rootSaga() {
    yield all([
        fork(getWorkplaceRequest),
        fork(postWorkplaceRequest),
        fork(deleteWorkplaceReqest)
    ]);
}
