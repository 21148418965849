import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getSharepointClient, getGOClient, getGraphClient, getMultichannelFeeds } from '../../selectors';
import FeedObject from './FeedObject';
import * as rssParser from 'react-native-rss-parser';

const checkDifferences = (array1, array2) => {
    let length = Math.min(array1.length, array2.length);
    let countMatched = 0, countNotMatched = 0;
    let newItems = [];

    for (var index = 0; index < length; index++) {
        if (!array2.find(a => a.id == array1[index].id)) {
            countNotMatched++;
            newItems.push(array1[index].id);
        }
    }
    return { notMatched: countNotMatched, newItems: newItems };
}

export function* getFeedsByChannel() {
    yield takeEvery(actions.GET_MULTICHANNEL_FEEDS, function* (action) {
        // -- Switch here, select news conditionally.
        let newsItems = [];
        yield put({
            type: actions.FEED_REQUEST_COMPLETED,
            payload: false
        })
        const Client = yield select(getGOClient);

        let currentFeeds = yield select(getMultichannelFeeds);

        const feedsConfig = JSON.parse(window.tenantConfig?.feeds);
        if (feedsConfig == null) return;

        let feeds = [];
        const title = action.payload.title;
        
        for (let config of feedsConfig) {
            let feed = yield
             Client.api('/app/rss?url=' + config.url, {}, 'text')
                .then((responseData) => rssParser.parse(responseData));

            if (currentFeeds.find(f => f.title == feed.title)) {
                let oldFeed = currentFeeds.feeds.find(f => f.title == feed.title);
                feed.differences = oldFeed.differences;
                feed.newItems = oldFeed.newItems;

                let difference = checkDifferences(feed.items, oldFeed.items);
                feed.differences = feed.differences + difference.notMatched;
                feed.newItems.push(difference.newItems);
            } else {
                feed.differences = 0;
                feed.newItems = [];
            }
            feeds.push(feed);
        }

        yield put({
            type: actions.SET_MULTICHANNEL_FEEDS,
            payload: feeds
        })
        yield put({
            type: actions.FEED_REQUEST_COMPLETED,
            payload: true
        })
    })
}


export default function* rootSaga() {
    yield all([
        fork(getFeedsByChannel)
    ]);
}
