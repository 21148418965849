import actions from './actions';

const initState = {
    appOnline: true,
};

export default function appReducer(state = initState, action) {

    switch (action.type) {
        case actions.SET_APP_ONLINE:
            return {
                ...state,
                appOnline : action.payload
            };
        default:
            return state;
    }
}
