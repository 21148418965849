import { all, call, put, fork, takeEvery, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import { getAppOnline } from '../selectors';
import messageActions from '../message/actions';
export function* checkAppOnline() {
    yield takeEvery(actions.CHECK_APP_ONLINE, function* (action) {

        try {
            yield call(checkStatusChannel);
         } catch (e) {
             console.log(e);
         }
    });
}

function * checkStatusChannel() {
    let offlineTimeout = null;
    const channel = eventChannel( emit => {
        const handleConnectivityChange = (isConnected) => {
            // Clear any existing timeout
            if (offlineTimeout) {
                clearTimeout(offlineTimeout);
                offlineTimeout = null;
            }
            // -- Emit connection state
            if (navigator.onLine) {
                emit(true);
            } else {
                // Start a timeout for 10 seconds
                offlineTimeout = setTimeout(() => {
                    // After 10 seconds, check if still offline
                    if (!navigator.onLine) {
                        emit(false);
                    }
                }, 15000); // 10 seconds
            }
        }
        // -- Initialise the connectivity listener
        window.addEventListener("online", handleConnectivityChange);
        window.addEventListener("offline", handleConnectivityChange);

        // Return the unregister event listener.
        return () => {
            window.removeEventListener("online", handleConnectivityChange);
            window.removeEventListener("offline", handleConnectivityChange);
        }
    });

    while ( true ) {
        const connectionInfo = yield take(channel);
        yield put({
            type : actions.CHANGE_APP_CONNECTIVITY_STATUS,
            payload : connectionInfo
        })
    }
}


export function* showUserNotificationOnConnectivityChange() {
    yield takeEvery(actions.CHANGE_APP_CONNECTIVITY_STATUS, function* (action) {
        const previousOnlineState = yield select(getAppOnline);

        if ( previousOnlineState === true && action.payload === false ) {
            // -- User lost connection, display a toast
            yield put({
                type : messageActions.MESSAGE,
                payload : {
                    type : 'error',
                    message : 'Internetverbinding verloren'
                }
            })
        }

        if ( previousOnlineState === false && action.payload === true ) {
            // -- User regained connection, display a toast
            yield put({
                type : messageActions.MESSAGE,
                payload : {
                    type : 'success',
                    message : 'Internetverbinding hersteld'
                }
            })
        }

        yield put({
            type : actions.SET_APP_ONLINE,
            payload : action.payload
        })
    });
}


export default function* rootSaga() {
    yield all([
        fork(checkAppOnline),
        fork(showUserNotificationOnConnectivityChange)
    ]);
}
