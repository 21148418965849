import React, { Component } from 'react';
import Boot from "./redux/boot";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store from "./redux/store";
import Main from './components/main';
import translations from './translate/translations'
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import { Router } from 'react-router-dom';

import localForage from "localforage";

import { library } from '@fortawesome/fontawesome-svg-core' //allows later to just use icon name to render-them
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas);
library.add(far);

const onBeforeLift = () => {
}

class App extends Component {

    componentDidMount() {
        Boot(store.store);
    }

    render() {
        return (
            <React.Fragment>
                <Provider store={store.store}>
                    <PersistGate onBeforeLift={onBeforeLift} loading={null} persistor={store.persistor}>
                        <IntlProvider translations={translations} locale='nl'>
                            <Router history={store.history}>
                                <Main store={store.store} failure={this.props.failure} authService={this.props.authService} />
                            </Router>
                        </IntlProvider>
                    </PersistGate>
                </Provider>
            </React.Fragment>
        );
    }
}

export default App;
