import actions from './actions';

const initState = {
	items : [],
	activeITNewsItem : false,
	requestCompleted : false
};


export default function commonNewsReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_IT_NEWS:
			return {
				...state,
				items : action.payload
			}
		case actions.COMMON_IT_NEWS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_ACTIVE_IT_NEWS_ITEM:
			return {
				...state,
				activeITNewsItem : action.payload
			}
		default:
			return state;
	}
}
