import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import { NavLink } from 'react-router-dom';
import * as Variables from '../../../ThemeVariables';

export const Birthdays = styled.div`
	display: grid;
	row-gap: 10px;

	${props => props.gridColumns ? 'grid-template-columns: repeat(2, 1fr); grid-gap: 10px;' : ''}

	&.darkmode {
		p { 
			color: white;
		}
	}
`;

export const WidgetHeading = styledContainerQuery.div`
	margin-bottom: 20px;

	&:container(min-width: 340px) {
		display: flex;
		justify-content: space-between;
	}
`;

export const AddLink = styled(NavLink)`
	text-decoration: none;
	font-size: 12px;
	cursor: pointer;
	color: #40454c;
	text-align: right;

	&:after {
		content: ' | ';
	}

	&.darkmode {
		color: white;
	}

`;

export const Popup = styled.div`
	padding-bottom: 80px;
	height: 100%;
`;

export const AddBirthdayButton = styled.span`
	text-decoration: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	background: ${Variables.mainColor};
	line-height: 25px;
	text-align: center;
	color: white;
	border-radius:  ${Variables.borderRadius};
	font-weight: bold;
`;

export const AddBirthdayWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 25px;
	margin-bottom: 15px;
	grid-gap: 10px;
`;
export const Dismissbirthdaybutton = styled.div`
	height: 100%;
	width: 25px;
	background: ${Variables.mainColor};
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	border-radius:  ${Variables.borderRadius};
	font-weight: bold;
	cursor: pointer;
`;


export const ScrollWrapperElement = styled.div`
	width: calc(100% + 35px);

	.List {
		padding-right: 20px;
	}
`;
