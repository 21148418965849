import { all, put, fork, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import slideoutActions from '../slideOut/actions';
import moment from 'moment';
import { getActiveSlideOut, isUnmounting } from '../selectors';

export function* slideOutDeactivated() {
    yield takeLatest(slideoutActions.SET_ACTIVE_SLIDEOUT, function* (action) {

        if ( !action.payload.item ) {
            yield put({
                type : actions.SET_SEARCH_TERM,
                payload : ''
            })
        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(slideOutDeactivated),
    ]);
}
