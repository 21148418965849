import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import moment from 'moment';
import { getGraphClient, getTasks } from '../selectors';

export function* orderTasks() {
    yield takeEvery(actions.ORDER_TASKS, function* (action) {

        /*let orderedItems = {
            today: [],
            tomorrow: [],
            next: [],
            noDate: []
        };
        const today = moment().format("DD-MM-YYYY");
        const tomorrow = moment().add(1, 'day').format("DD-MM-YYYY");*/

        let orderedItems = {
            count: 0,
            today: [],
            tomorrow: [],
            next: [],
            lastWeek: [],
            oldTasks: [],
            noDate: [],
            completed: [],
            categorizedLists: {

            }
        }
        const today = moment().format("YYYY-MM-DD");
        const tomorrow = moment().add(1, 'day').format("YYYY-MM-DD");
        const lastWeek = moment().add(-7, 'day').format("YYYY-MM-DD");

        const taskLists = action.payload;
        for (const listID in taskLists) {
            if (taskLists.hasOwnProperty(listID)) {
                let tasks = taskLists[listID].tasks;
                let unaddedTasks = [];

                tasks.forEach((item, i) => {

                    const task = {
                        ...item,
                        taskType: taskLists[listID].taskType,
                        listName: taskLists[listID].name,
                        listId: listID
                    }
                    let added = false;
                    if (task.taskType == 'planner') {
                    }
                    if (task.status != "completed" && task.percentComplete != 100) {
                        if (task.taskType == "email" && task.flag?.flagStatus == "complete") {
                            // Do nothing
                        } else {
                            orderedItems.count++;

                            if (task.dueDateTime) {
                                const dueDate = moment(task.dueDateTime).format("DD-MM-YYYY");
                                if (dueDate === today) {
                                    orderedItems.today.push(task);
                                    //return;
                                } else if (dueDate === tomorrow) {
                                    orderedItems.tomorrow.push(task);
                                    //return;
                                } else {
                                    orderedItems.oldTasks.push(task);
                                }
                            } else

                                //if (!task.dueDateTime) {

                                if (task.createdDateTime != null) {

                                    const createdDate = moment(task.createdDateTime).format("YYYY-MM-DD");
                                    if (new Date(createdDate) > new Date(today)) {
                                        orderedItems.lastWeek.push(task);
                                        //return;
                                    } else {
                                        orderedItems.oldTasks.push(task);
                                    }
                                    //return;
                                } else {

                                    orderedItems.noDate.push(task);

                                }
                            //return;

                            //unaddedTasks.push(task);


                            //}
                        }
                    }
                    // orderedItems.count++;

                    if (task.taskType != "email") {
                        if (task.completedDateTime) {
                            let completedDate;
                            task.taskType == "toDo" ? completedDate = moment(task.completedDateTime.dateTime).format("YYYY-MM-DD") : completedDate = moment(task.completedDateTime).format("YYYY-MM-DD");
                            if (new Date(completedDate).getTime() == new Date(today).getTime()) {
                                orderedItems.completed.push(task);
                            }
                        }
                    } else {
                        if (task.flag?.flagStatus == "complete") {
                            const dueDate = moment(task.lastModifiedDateTime.dateTime).format("YYYY-MM-DD");
                            if (new Date(dueDate).getTime() >= new Date(today).getTime()) {
                                orderedItems.completed.push(task);
                            }
                        }
                    }
                    //unaddedTasks.push(task)
                });

                if (unaddedTasks.length) {
                    orderedItems = {
                        ...orderedItems,
                        categorizedLists: {
                            ...orderedItems.categorizedLists,
                            [taskLists[listID].name]: unaddedTasks
                        }
                    }
                }

            }
        }

        yield put({
            type: actions.SET_TASKS,
            payload: orderedItems
        });

        yield put({
            type: userActions.SET_USER_TASKS,
            payload: orderedItems.count
        });

        /*
        for (const item in tasks) {
            if (tasks.hasOwnProperty(item)) {
                const currentTask = tasks[item];

                if (!currentTask.dueDateTime) {
                    orderedItems.noDate.push(currentTask);
                    continue;
                }

                const endDate = moment(currentTask.dueDateTime).format("DD-MM-YYYY");

                if (endDate === today) {
                    orderedItems.today.push(currentTask);
                    continue;
                }

                if (endDate === tomorrow) {
                    orderedItems.tomorrow.push(currentTask);
                    continue;
                }

                orderedItems.next.push(currentTask);
            }
        }

        yield put({
            type: actions.SET_TASKS,
            payload: orderedItems
        });

        let countTasks = 0;
        countTasks = orderedItems.today.length + orderedItems.tomorrow.length + orderedItems.next.length; // + orderedItems.noDate.length;

        yield put({
            type: userActions.SET_USER_TASKS,
            payload: countTasks
        });*/

    });
}

export function* setTasks() {

    yield takeEvery(actions.GET_TASKS, function* (action) {

        const Client = yield select(getGraphClient);

        if (!Client) return;

        try {
            let taskLists = {};
            let count = 0;

            const toDoLists = yield Client.api('/me/todo/lists');
            if (toDoLists) {
                if (toDoLists.value) {
                    for (let i = 0; i <= toDoLists.value.length; i++) {
                        if (toDoLists.value[i]) {
                            const toDoListTasks = yield Client.api('/me/todo/lists/' + toDoLists.value[i].id + '/tasks');

                            if (!toDoListTasks) continue;

                            count = + toDoListTasks.value?.length;

                            taskLists = {
                                ...taskLists,
                                [toDoLists.value[i].id]: {
                                    ...taskLists[toDoLists.value[i].id],
                                    name: toDoLists.value[i].displayName,
                                    tasks: toDoListTasks.value,
                                    taskType: 'toDo',

                                }
                            }
                        }
                    }
                }
            }

            let tasks = yield Client.api('/me/planner/tasks');

            if (tasks == null || !tasks) return;
            taskLists = {
                ...taskLists,
                plannerTasks: {
                    name: 'Planner',
                    tasks: tasks.value,
                    taskType: 'planner'
                }
            }
            /*
            const today = moment();
            const weekStart = today.startOf('week');

            tasks = tasks.value.filter(item => {
                return item.completedDateTime == null || moment(item.completedDateTime) > weekStart;
            });

            yield put({
                type: actions.ORDER_TASKS,
                payload: tasks
            });*/


            let emailTasks = yield Client.api("/me/messages?$filter=flag/flagStatus ne 'notFlagged'")
            if (emailTasks == null || !emailTasks) return;
            taskLists = {
                ...taskLists,
                emailTasks: {
                    name: 'Email',
                    tasks: emailTasks.value,
                    taskType: 'email'
                }
            }

            yield put({
                type: actions.ORDER_TASKS,
                payload: taskLists
            });
        }
        catch (error) {
            console.error(error);
        }

    });

}

export function* updateTask() {
    yield takeEvery(actions.UPDATE_TASK, function* (action) {

        const Client = yield select(getGraphClient);
        if (!Client) return;
        try {
            let toDoTask = {};
            let value = action.payload.payload[action.payload.key]
            toDoTask[action.payload.key] = value;
            const response = yield Client.patch('/me/todo/lists/' + action.payload.payload.listId + '/tasks/' + action.payload.payload.id, JSON.stringify(toDoTask))

            if (response) {
                yield put({
                    type: actions.GET_TASKS
                });
            }


        } catch (error) {
            console.error(error);
        }
    })
}

export function* updateSubTask() {
    yield takeEvery(actions.UPDATE_SUB_TASK, function* (action) {
        const Client = yield select(getGraphClient);
        if (!Client) return;
        try {
            let toDoTask = {};
            let value = action.payload.subTask[action.payload.key];
            toDoTask[action.payload.key] = value;
            const response = yield Client.patch('/me/todo/lists/' + action.payload.payload.listId + '/tasks/' + action.payload.payload.id + '/checklistItems/' + action.payload.subTask.id, JSON.stringify(toDoTask))

            if (response) {
                yield put({
                    type: actions.GET_TASKS
                });
            }

        } catch (error) {
            console.error(error);
        }
    })
}

export function* updateTaskEmail() {
    yield takeEvery(actions.UPDATE_TASK_EMAIL, function* (action) {

        const Client = yield select(getGraphClient);
        if (!Client) return;
        try {
            let toDoTask = {};
            let value = '';

            switch (action.payload.key) {
                case 'flag.flagstatus':
                    value = action.payload.payload.flag.flagStatus;
                    toDoTask.flag = {};
                    toDoTask.flag.flagStatus = value;
                default:
            }

            const response = yield Client.patch('/me/messages/' + action.payload.payload.id, JSON.stringify(toDoTask))

            if (response) {
                yield put({
                    type: actions.GET_TASKS
                });
            }


        } catch (error) {
            console.error(error);
        }
    })
}

export function* updateTaskPlanner() {
    yield takeEvery(actions.UPDATE_TASK_PLANNER, function* (action) {
        // @odata.etag
        const Client = yield select(getGraphClient);
        if (!Client) return;
        try {
            let toDoTask = {};
            let value = action.payload.payload[action.payload.key]
            toDoTask[action.payload.key] = value;
            const response = yield Client.patch('/planner/tasks/' + action.payload.payload.id, JSON.stringify(toDoTask), "v1.0", action.payload.payload['@odata.etag'])

            if (response) {
                yield put({
                    type: actions.GET_TASKS
                });
            }


        } catch (error) {
            console.error(error);
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(setTasks),
        fork(orderTasks),
        fork(updateTask),
        fork(updateTaskEmail),
        fork(updateSubTask),
        fork(updateTaskPlanner)
    ]);
}
