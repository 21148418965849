import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../../message/actions';
import userActions from '../../user/actions';
import store from "../../store";
import { getUserRequest, getGOClient, getUserProfileSettingData, getUserProfile, getTenantNewsItems, getAuth } from '../../selectors'

export function* getUsersRequest() {
    yield takeEvery(actions.GET_USER_REQUEST, function* (action) {

        const userRequests = yield select(getUserRequest);
        const Client = yield select(getGOClient);
        if (!Client) return;
        const requests = null;
        // const requests = yield Client.api("/selfService/user/tenant");

        if(requests == null | !requests) return;

        if (requests.length == 0 || (requests.length && ((userRequests.length === 0) || (userRequests && userRequests[0].Id !== requests[0].id)))) {

            yield put({
                type: actions.SET_USER_REQUEST,
                payload: requests
            });
        }
    });
}

function generateID() {
    return parseInt(Math.round(Math.random()*1000));
}

export function* postUserRequest() {
    yield takeEvery(actions.POST_USER_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;

        let incidentRequest = {
            calltypeid: 10,
            subject: action.payload.title,
            body: action.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
            phonenumber: userProfile.mobilePhone ? userProfile.mobilePhone : '',
        }

        let formFields = {
            voornaam: action.payload.voornaam,
            tussenvoegsel: action.payload.tussenvoegsel,
            achternaam: action.payload.achternaam,
            afdeling: action.payload.afdeling,
            rol: action.payload.rol,
            emailadres: action.payload.emailadres,
            telefoonnummer: action.payload.telefoonnummer,
            functie: action.payload.functie,
            omschrijving: action.payload.omschrijving,
            datum: action.payload.datum,
            typeLicentie: action.payload.typeLicentie,
            activateDefender: action.payload.activateDefender,
            officeBackup: action.payload.officeBackup,
            addToGroups: action.payload.addToGroups

        }

        // if (action.payload.id) {
        //     method = 'PUT';
        //     formFields = {
        //         ...formFields,
        //         id: action.payload.id,
        //         authorName: action.payload.authorName,
        //         authorId: action.payload.authorId
        //     }
        // }

        let result = false;

        result = yield GoClient.post('/app/incident/request', incidentRequest);
        //userRequests.push(formFields);
        let name = formFields.voornaam;
        if(formFields.tussenvoegsel) name += " " + formFields.tussenvoegsel;
        name += " " + formFields.achternaam;

        if (result) {
            const userProfileData = yield select(getUserProfileSettingData);
            let userRequests = userProfileData?.settings?.calls
            if(!userRequests) userRequests = [];
            userRequests.push({
                callId: result.callId,
                name: name,
                status: result.status,
                type: incidentRequest.calltypeid,
                typeDesc: "Aanmaken",
                creationDate: Date.now(),
                lastUpdate: Date.now(),
                request: "UserRequest",
            })
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: userRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getUserRequest);
            let request = requests
            formFields['callId'] = result.callId
            request.push(formFields)
            yield put({
                type: actions.SET_USER_REQUEST,
                payload:request
            })
            // yield put({
            //     type: actions.SET_USER_REQUEST,
            //     payload:userRequest
            // })

            // yield put({
            //     type: actions.GET_USER_REQUEST
            // })

            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'User Request toegevoegd',
                    type: 'success'
                }
            });

            store.history.push('/self-service/gebruiker');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* updateUserRequest() {
    yield takeEvery(actions.UPDATE_USER_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;
        let incidentRequest = {
            callid: action.payload.id,
            subject: action.payload.payload.title,
            body: action.payload.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
        }

        let formFields = {
            voornaam: action.payload.voornaam,
            tussenvoegsel: action.payload.tussenvoegsel,
            achternaam: action.payload.achternaam,
            afdeling: action.payload.afdeling,
            rol: action.payload.rol,
            emailadres: action.payload.emailadres,
            telefoonnummer: action.payload.telefoonnummer,
            functie: action.payload.functie,
            omschrijving: action.payload.omschrijving,
            datum: action.payload.datum
        }
        
        //yield GoClient.post('/app/incident/action', incidentRequest);
        const userProfileData = yield select(getUserProfileSettingData);
        let rightsRequests = userProfileData?.settings?.calls
        let index = rightsRequests.findIndex(request => request.callId == incidentRequest.callid)

        let name = formFields.voornaam;
        if(formFields.tussenvoegsel) name += " " + formFields.tussenvoegsel;
        name += " " + formFields.achternaam;


        if(index > -1) {
            if(!rightsRequests[index]) rightsRequests = [];
            rightsRequests[index] = {
                ...rightsRequests[index],
                    name: name,
                    type: incidentRequest.calltypeid,
                    lastUpdate: Date.now(),
                }
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: rightsRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getUserRequest);
            let request = requests
            let index2 = request.findIndex(request => request.callId == incidentRequest.callid)
                request[index2] = formFields
                yield put({
                    type: actions.SET_USER_REQUEST,
                    payload:request
                })
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'User Request geupdated',
                        type: 'success'
                    }
                });
            store.history.push('/self-service/gebruiker');
         } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* deleteUserReqest() {
    yield takeEvery(actions.DELETE_USER_REQUEST, function* (action) {

        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;

        let incidentRequest = {
            calltypeid: 10,
            subject: action.payload.title,
            body: action.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailadres: "",
            phonenumber: userProfile.mobilePhone ? userProfile.mobilePhone : '',
        }

        let formFields = {
            voornaam: action.payload.voornaam,
            tussenvoegsel: action.payload.tussenvoegsel,
            achternaam: action.payload.achternaam,
            emailadres: action.payload.emailadres,
            datum: action.payload.datum,
            sharedMailbox: action.payload.sharedMailbox,
            linkMailbox: action.payload.linkMailbox,
            forwardMailTo: action.payload.forwardMailTo
        }

        // if (action.payload.id) {
        //     method = 'PUT';
        //     formFields = {
        //         ...formFields,
        //         id: action.payload.id,
        //         authorName: action.payload.authorName,
        //         authorId: action.payload.authorId
        //     }
        // }

        let result = false;

        result = yield GoClient.post('/app/incident/request', incidentRequest);
        //userRequests.push(formFields);
        let name = formFields.voornaam;
        if(formFields.tussenvoegsel) name += "" + formFields.tussenvoegsel;
        name += formFields.achternaam;
        
        if (result) {
            const userProfileData = yield select(getUserProfileSettingData);
            let userRequests = userProfileData?.settings?.calls
            if(!userRequests) userRequests = [];
            userRequests.push({
                callId: result.callId,
                name: name,
                status: result.status,
                type: incidentRequest.calltypeid,
                typeDesc: "Verwijderen",
                creationDate: Date.now(),
                lastUpdate: Date.now(),
                request: "UserRequest",
            })
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: userRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getUserRequest);
            let request = requests
            formFields['callId'] = result.callId
            request.push(formFields)
            yield put({
                type: actions.SET_USER_REQUEST,
                payload:request
            })
            // yield put({
            //     type: actions.SET_USER_REQUEST,
            //     payload:userRequest
            // })

            // yield put({
            //     type: actions.GET_USER_REQUEST
            // })

            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'User delete Request toegevoegd',
                    type: 'success'
                }
            });

            store.history.push('/self-service/gebruiker');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* cancelUserRequest() {
    yield takeEvery(actions.CANCEL_USER_REQUEST, function* (singleAction) {
        const GoClient = yield select(getGOClient);
        if (!GoClient) return;


        let action = {
            callid: singleAction.payload,
            subject: "Aanvraag annuleren",
            body: `
Aanvraag van gebruiker kan geannuleerd worden
`,
            sentiment: "",
            emotionscore: 0,
            emailaddress: "",
        }
        let result = false;
        result = yield GoClient.post('/app/incident/action', action);

        const userProfileData = yield select(getUserProfileSettingData);
        let userRequests = userProfileData?.settings?.calls

        let index = userRequests.findIndex(request => request.callId == singleAction.payload)
        if(index > -1) {
            userRequests[index].status = "Geannuleerd";

            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: userRequests,
                    setting: 'calls'
                }
            });
        }

                yield put({
                    type: actions.GET_USER_REQUEST
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'User Request geannuleerd',
                        type: 'success'
                    }
                });

            store.history.push('/self-service/gebruiker');
    });
}

export default function* rootSaga() {
    yield all([
        fork(getUsersRequest),
        fork(postUserRequest),
        fork(deleteUserReqest),
        fork(cancelUserRequest),
        fork(updateUserRequest)
    ]);
}
