import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../../user/actions';
import localforage from 'localforage';
import { dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getGOClient, getGraphClient, getYammerClient, getSharepointClient, getMultiChannelSocialItems, getSocialRequestCompleted, getUserRequestCompleted, getUserProfileSettingData } from '../../selectors';
import SocialObject from './socialObject';
import moment from 'moment/min/moment-with-locales';

import embraceOutput from './embraceOutput';


export function* getSocialFromChannel() {
    yield takeEvery(actions.GET_MULTICHANNEL_SOCIAL_ITEMS, function* (action) {
        // -- Switch here, select social items conditionally.
        yield put({
            type: actions.SOCIAL_REQUEST_COMPLETED,
            payload: false
        })
        let newItems = [];
        let currentItems = yield select(getMultiChannelSocialItems);

        const title = action.payload.title;
        const sources = action.payload.payload;

        //parse the different sources configured
        if (sources) {
            for (let source of sources) {

                if (source.source == "embrace") {
                    let items = yield call(getEmbraceItems, source.site, source.url, source.content, source.opendirect, title);
                    if (items?.length == 0) {
                        items = currentItems.filter(i => i.widget == title);
                    } else if (items?.length > 0) {
                        items = items.map(i => { i.widget = title; return i; });
                    }

                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }
                }

                if (source.source == "yammer") {
                    let items = yield call(getYammerItems, title);
                    if (items?.length == 0) {
                        items = currentItems.filter(i => i.widget == title);
                    } else if (items?.length > 0) {
                        items = items.map(i => { i.widget = title; return i; });
                    }

                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }
                }
            }
        }

        const sortOnDate = ((a, b) => {
            if (a.unix > b.unix) {
                return -1;
            }

            if (a.unix < b.unix) {
                return 1;
            }

            return 0;
        });

        currentItems = yield select(getMultiChannelSocialItems);
        currentItems = currentItems.filter(i => i.widget == title);

        //sort all new items on date
        newItems.sort(sortOnDate);

        //max items for one widget
        newItems = newItems.splice(0, 30);

        let old = currentItems.filter(n => !newItems.find(nn => nn.id == n.id));
        if (old?.length > 0 && newItems.length > 0) {
            for (let n of old) {
                yield localforage.removeItem(n.id);
            }
        }

        if ((old?.length > 0 && newItems.length > 0) || (currentItems?.length == 0 && newItems?.length > 0) || (currentItems?.length !== newItems?.length && newItems.length > 0)) {

            //postprocessing..
            //newItems = yield call(postprocess, newItems);

            let allItems = yield select(getMultiChannelSocialItems);
            allItems = allItems.filter(i => i.widget != title);
            allItems = allItems.concat(newItems);

            yield put({
                type: actions.SET_MULTICHANNEL_SOCIAL_ITEMS,
                payload: allItems
            });
        }

        yield put({
            type: actions.SOCIAL_REQUEST_COMPLETED,
            payload: true
        });
    })
}

const parseSocialItemContent = (content) => {
    const urls = /<(.*)>\(“(.*)”\)/g

    content = content.replace(urls, function (match, token) {
        let title = match.match(/<(.*)>/);
        let link = match.match(/\(“(.*)”\)/);
        if (title[1] == null || link[1] == null) return "";
        return ` <a target="_blank" href="" onclick="window.open('${title[1]}', 'popup', 'width=1024,height=700,scrollbars=no,resizable=no'); return false;">${link[1]}</a> `;
    });

    content = content.replace(/(?:\r\n|\r|\n)/g, ' <br />');

    return content;
}


function* getEmbraceItems(site, apiURL, content, openDirect, title) {

    if (!apiURL) {
        console.log("Embrace API URL Required");
        return;
    }
    try {
        const socialItems = yield select(getMultiChannelSocialItems);
        const GoClient = yield select(getGOClient);
        let oldestItem = moment().valueOf();

        let offset = new Date().getTimezoneOffset();
        oldestItem = oldestItem + (-offset * 60 * 1000);


        /*        if (socialItems?.length > 0) {
                    let items = socialItems.filter(i => i.widget == title);
                    if (items[0]?.unix) oldestItem = items[0]?.unix;
                }*/

        //build api url
        if (apiURL.indexOf("?") == -1) {
            if (apiURL[apiURL.length - 1] != '/') apiURL += '/';
            apiURL += 'api/v1/Microblogs?oldestItem=' + oldestItem + '&label=0';
        }

        if (!GoClient) return;
        const items = yield GoClient.api('/app/proxy?url=' + encodeURIComponent(apiURL) + '&type=embrace', {}, 'text')
        if (items) {
            let embraceItems = JSON.parse(items)?.Items;

            // -- Embrace fetch logic;
            if (embraceItems?.length) {
                return yield parseEmbraceSocial(embraceItems, site, apiURL, content, openDirect);
            }
        }

    } catch (error) {
        console.log(error);
    }

    return false;
}


function* getYammerItems(title) {
    //if (!apiURL) {
    //    console.log("Yammer API URL Required");
    //    return;
    //}
    //try {
    let currentItems = yield select(getMultiChannelSocialItems);
    currentItems = currentItems.filter(i => i.widget == title);
    const Client = yield select(getYammerClient);
    const GoClient = yield select(getGOClient);
    const GraphClient = yield select(getGraphClient);

    if (!Client) return;

    //const shouts = yield Client.api(apiURL);
    let shouts = yield Client.api('https://www.yammer.com/api/v1/messages.json?threaded=true');
    //let test = yield Client.api('/users/1522724127.json');
    let messages = [];

    if (shouts == null || !shouts) return;
    if (shouts.length === 0) return;

    if (shouts != null) {
        let items = shouts.references.filter(m => m.announcement !== true && m.type == "thread");  //no announcements! 
        messages = shouts.messages.filter(m => items.find(a => a.id == m.id));

        //messages.takeEvery(m => announcements.find(a => a.id == m.id))
        for (let m of messages) {
            let a = items.find(a => a.id == m.id);
            m.reference = a;

            let user = shouts.references.find(r => r.id == m.sender_id);
            m.user = user;

            let topics = shouts.references.find(r => r.id == m.id)?.topics;
            if (topics) {
                //m.topics = topics;
                m.topics = [];
                for (let t of topics) {
                    let topic = yield Client.api(`https://www.yammer.com/api/v1/topics/${t.id}.json`);
                    if (topic != null) m.topics.push(topic);
                }
            }
        }
    }

    if (messages.length && ((currentItems.length === 0) || (currentItems && currentItems[0].id !== messages[0].id))) {
        return yield parseYammerItems(messages);
    } else {
        return yield parseYammerItems(messages);
    }
    return false
}

async function parseYammerItems(items) {
    let parsedItem = [];
    for (let key in items) {
        if (items[key]) {   
            const item = items[key];

            let socialObject = new SocialObject;
            socialObject.setAuthor(item.user?.full_name);
            socialObject.setContent(parseYammerContent(item.body?.rich));
            socialObject.setTimestamp(moment(item.created_at)?.valueOf());
            socialObject.setUrl(item.web_url);
            socialObject.setID("" + item.id);
            socialObject.setLikes(item.liked_by?.count);
            socialObject.setType('yammer');

            if (item.topics != null && item.topics) {
                socialObject.setTopics(item.topics);
            }

            socialObject.setImageUrl(item.user?.mugshot_url);

            if (socialObject.getSocialObject()) {
                parsedItem.push(socialObject.getSocialObject());
            }
        }
    }
    return parsedItem;
}

const parseYammerContent = (content) => {
    const urls = /<(.*)>\(“(.*)”\)/g
    const links = /(.*)&lt;<a (.*)<\/a\>/

    content = content.replace(urls, function (match, token) {
        let title = match.match(/<(.*)>/);
        let link = match.match(/\(“(.*)”\)/);
        if (title[1] == null || link[1] == null) return "";
        return ` <a target="_blank" href="" onclick="window.open('${title[1]}', 'popup', 'width=1024,height=700,scrollbars=no,resizable=no'); return false;">${link[1]}</a> `;
    });

    content = content.replace(links, function (match, token) {
        let title = match.match(/(.*)&lt;<a/);
        return match;
        //let link = match.match(/\(“(.*)”\)/);
        //if (title[1] == null || link[1] == null) return "";
        //return ` <a target="_blank" href="" onclick="window.open('${title[1]}', 'popup', 'width=1024,height=700,scrollbars=no,resizable=no'); return false;">${link[1]}</a> `;
    });

    content = content.replace(/(?:\r\n|\r|\n)/g, ' <br />');

    return content;
}

export function* setLastViewedItems() {
    yield takeEvery(actions.SET_LAST_SOCIAL_VIEWED, function* (action) {
        if (action.payload == null) return;

        yield put({
            type: userActions.UPDATE_USER_SETTINGS,
            payload: {
                value: action.payload,
                setting: 'viewedSocial'
            }
        });

    });
}


export function* parseLastViewed() {

    const parser = function* () {

        const items = yield select(getMultiChannelSocialItems);
        const data = yield select(getUserProfileSettingData);
        const completed = yield select(getUserRequestCompleted);
        const socialCompleted = yield select(getSocialRequestCompleted);

        if (data == null || !completed || !socialCompleted || items?.length == 0) return;

        let viewedItems = data.settings?.viewedSocial;
        if (!viewedItems) {
            viewedItems = [];
        }

        let newViewed = [];

        for (let item of items) {

            // -- User has never seen any social item, set everything to true
            if (!viewedItems.length) {
                item.viewed = true;
            } else {
                // -- Already items in user object, new items are unviewed.
                let viewedItem = viewedItems.find((i) => i.id === item.id);
                item.viewed = false;
                if (viewedItem) {
                    if (viewedItem.viewed) {
                        item.viewed = true;
                    }
                }
            }

            if (!viewedItems.find((i) => i.id === item.id)) {
                newViewed.push({
                    id: item.id,
                    viewed: item.viewed,
                    widget: item.widget
                });
            }
        }

        // -- Cleanup with only present items.
        for (let item of viewedItems) {
            let available = items.find((i) => i.id === item.id);
            if (available) {
                newViewed.push({
                    id: item.id,
                    widget: item.widget,
                    viewed: item.viewed
                })
            }
        }


        // -- Conditionally update user settings object
        if ((newViewed.length == 0 && viewedItems.length == 0) ||
            (JSON.stringify(newViewed.map(n => n.id)) != JSON.stringify(viewedItems.map(n => n.id))) ||
            !data.settings?.viewedSocial) {

            console.log('set viewed socials');
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: newViewed,
                    setting: 'viewedSocial'
                }
            });
        }
    }

    // -- Fires after user data has been fetched, checks for social request completion and parses items
    yield takeEvery(userActions.DATA_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getSocialRequestCompleted);

        if (completed) {
            yield parser();
        }
    });

    // -- Fires after social data has been fetched, checks for user data presence and parses items
    yield takeEvery(actions.SOCIAL_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getUserRequestCompleted);

        if (action.payload === true && completed) {
            yield parser();
        }
    });
}


function parseEmbraceContent(content) {

    const urls = /href=/gi;

    content = content.replace(urls, function (match, token) {
        return 'target="_blank" href=';
    });

    return content;
}

async function parseEmbraceSocial(embraceItems, site, url, content, openDirect) {
    let parsedSocial = [];
    for (let key in embraceItems) {
        if (embraceItems[key]) {
            const item = embraceItems[key];

            let socialObject = new SocialObject;
            socialObject.setAuthor(item.PersonFullName);
            socialObject.setContent(parseEmbraceContent(item.Message));

            let offset = new Date().getTimezoneOffset();
            let timestamp = item.Timestamp - (-offset * 60 * 1000);

            socialObject.setTimestamp(timestamp);
            socialObject.setUrl(site);
            socialObject.setOpenDirect(openDirect);
            socialObject.setID('embrace_' + item.Id);
            socialObject.setType('embrace');
            socialObject.setLikes(item.Likes);

            if (item.PersonAvatar == null) {
                socialObject.setImageUrl('parse-from-localForage');
            } else {
                socialObject.setImageUrl(site + item.PersonAvatar.replace('Avatar55', 'Avatar150'));
            }

            if (socialObject.getSocialObject()) {
                parsedSocial.push(socialObject.getSocialObject());
            }
        }
    }
    return parsedSocial;
}

export default function* rootSaga() {
    yield all([
        fork(getSocialFromChannel),
        fork(parseLastViewed),
        fork(setLastViewedItems)
    ]);
};
