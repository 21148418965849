import React from 'react';
import * as Styled from './styled';


const HeaderLoader = props => (
	<Styled.HeaderWrapper>
		<Styled.Header className={(props.darkmode ? 'darkmode' : '')} position={props.position} />
	</Styled.HeaderWrapper>
)



export default HeaderLoader; 