import styled from 'styled-components'

export const SlideTitle = styled.h1 `
  font-size: 18px;
  margin-top: 5px;
  font-weight: 700;
  margin-bottom: 25px;
`;

export const SlideDescription = styled.p`
    text-align: left;
    font-size: 14px;
    margin-bottom: 25px;

    p {
        margin-bottom: 15px;
        font-size: 14px;
    } 
`;


export const DarkModeSelector = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

`;

export const DarkModeSelectorColumn = styled.div`
 img {
    width: 100%;
    max-width: 100%;
  }

  p {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }

    cursor: pointer;
`;

export const DarkModeCheckbox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  margin-top: 25px;
  text-align: center;

  p {
    font-weight: 700;
  }
`;
