const actions = {
	SET_ACTIVE_POPUP : 'SET_ACTIVE_POPUP',
	SET_POPUP_DATA: 'SET_POPUP_DATA',

	setActivePopup: (payload, data = false) => ({
		type: actions.SET_ACTIVE_POPUP,
		payload : {
			item : payload,
			data : data
		}
	}),
};

export default actions;
