const actions = {
	SET_ACTIVE_SLIDEOUT : 'SET_ACTIVE_SLIDEOUT',
	SET_ACTIVE_OVERLAY_SLIDEOUT: 'SET_ACTIVE_OVERLAY_SLIDEOUT',
	SET_SLIDEOUT_DATA: 'SET_SLIDEOUT_DATA',
	HANDLE_SLIDEOUT_MOUNTING : 'HANDLE_SLIDEOUT_MOUNTING',
	SET_IS_UNMOUNTING : 'SET_IS_UNMOUNTING',
	SET_IS_MOUNTING : 'SET_IS_MOUNTING',

	setActiveSlideOut: (payload, data = false, image = false, authorImage = false, authorName = false) => ({
		type: actions.HANDLE_SLIDEOUT_MOUNTING,
		payload : {
			item : payload,
			image : image,
			authorImage : authorImage,
			authorName: authorName,
			data: data
		}
	}),

	setActiveOverlaySlideOut : (payload, image = false, authorImage = false, authorName = false) => ({
		type: actions.SET_ACTIVE_OVERLAY_SLIDEOUT,
		payload : {
			item : payload
		}
	}),

	setSlideOutData: (payload) => ({
		type: actions.SET_SLIDEOUT_DATA,
		payload: payload
	})
};

export default actions;
