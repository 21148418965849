const buildMsalConfig = () => ({
    auth: {
        clientId: window.tenantConfig.clientId,
        authority: 'https://login.microsoftonline.com/' + (window.tenantConfig.clientId === "68a590d3-db74-4205-9bdc-bf3286663216" ? 'common' : window.tenantConfig.tenant),
        knownAuthorities: [],
        cloudDiscoveryMetadata: "",
        redirectUri: window.location.origin,
        //postLogoutRedirectUri: "enter_postlogout_uri_here",
        navigateToLoginRequestUrl: true,
        clientCapabilities: ["CP1"] //conditional access, needs to be implemented continious evaluation access
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {
        //loggerOptions: {
        //    loggerCallback: (level, message, containsPii) => {
        //        if (containsPii) {
        //            return;
        //        }
        //        switch (level) {
        //            case LogLevel.Error:
        //                console.error(message);
        //                return;
        //            case LogLevel.Info:
        //                console.info(message);
        //                return;
        //            case LogLevel.Verbose:
        //                console.debug(message);
        //                return;
        //            case LogLevel.Warning:
        //                console.warn(message);
        //                return;
        //        }
        //    },
        //    piiLoggingEnabled: false
        //},
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    }
});

export default buildMsalConfig;

//const msalInstance = new PublicClientApplication(msalConfig());