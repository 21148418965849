import customProtocolCheck from "custom-protocol-check";
import moment from 'moment';
/**
    @Description    Determines the way to open an application.
    @param          { bool }    defaultStartInPopup     caller function may have already determined app should open in a popup. Defaults to false.
*/
export const getUserApplicationSettings = (defaultStartInPopup = false) => {


    var userAgent = navigator.userAgent,
        mobile = function() {
          return /\b(iPhone|iP[ao]d)/.test(userAgent) ||
            /\b(iP[ao]d)/.test(userAgent) ||
            /Android/i.test(userAgent) ||
            /Mobile/i.test(userAgent);
        },
        screenX = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
        screenY = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
        outerWidth = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.documentElement.clientWidth,
        outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : document.documentElement.clientHeight - 22,
        targetWidth = mobile() ? null : 1024,
        targetHeight = mobile() ? null : 800,
        V = screenX < 0 ? window.screen.width + screenX : screenX,
        left = parseInt(V + (outerWidth - targetWidth) / 2, 10),
        right = parseInt(screenY + (outerHeight - targetHeight) / 2.5, 10),
        features = [];
    if (targetWidth !== null) {
      features.push('width=' + targetWidth);
    }
    if (targetHeight !== null) {
      features.push('height=' + targetHeight);
    }
    features.push('left=' + left);
    features.push('top=' + right);
    features.push('scrollbars=1');


    let openAppInTarget = 'popup';
    let windowProperties = features.join(',');
    let startInPopup = defaultStartInPopup;

    if (window.userSettings) {
        if (window.userSettings.hasOwnProperty('startLocalApps')) {
            if (window.userSettings.startLocalApps === false) {
                startInPopup = true;
            }
        } else {
            startInPopup = true;
        }

        if (window.userSettings.hasOwnProperty('startAppsInNewWindow')) {
            if (window.userSettings.startAppsInNewWindow === true) {
                openAppInTarget = '_BLANK';
                windowProperties = '';
            }
        }
    }

    return {
        openAppInTarget,
        windowProperties,
        startInPopup
    }
}

/**
    @Description    Conditionally opens an application. We need a webDavUrl as provided by the MS Graph API to make this work. Otherwise, this will fall back on the original link and open that in a popup.
    @param          { string }    webDavUrl         Link to a document, usually also enables us to open a shared document locally.
    @param          { string }    originalLink      Original link to document, usable as href.
    @param          { string }    appName           MS App name, if not set, application will not try to open locally
    @param          { string }    dataType          Document data type
    @param          { object }    dataType          Document data type
*/

export const launchApp = (webDavUrl = false, originalLink = false, appName = false, dataType = false) => {

    return new Promise((resolve, reject) => {

        // -- If appName isset, default to trying to open app locally.
        const { openAppInTarget, windowProperties, startInPopup } = getUserApplicationSettings((appName ? false : true));

        const popupLink = originalLink + (dataType !== "#microsoft.graph.listItem" ? "?web=1" : "");
        if (startInPopup) {
            openPopup(popupLink, openAppInTarget, windowProperties, appName);
            resolve();
        }

        let newLink = false;
        if (webDavUrl && appName) {
            newLink = `ms-${appName.toLowerCase()}:ofe|u|${webDavUrl}`;
        }


        if (newLink) {
            customProtocolCheck(
                newLink,
                () => {
                    openPopup(popupLink, openAppInTarget, windowProperties);
                    resolve();
                },
                () => {
                    resolve();
                }, 5000
            );
        } else {
            openPopup(popupLink, openAppInTarget, windowProperties, appName);
            resolve();
        }
    });
}

/**
    @Description    Conditionally opens an application. We need a webDavUrl as provided by the MS Graph API to make this work. Otherwise, this will fall back on the original link and open that in a popup.
    @param          { string }    popupLink         Link to open
    @param          { string }    openAppInTarget   Window target (popup, _BLANK, parent...)
    @param          { string }    windowProperties  May contain additional values like popup size, scrollbars
    @param          { string }    windowTitle       Possible title to set to the opened window.
*/
export const openPopup = (popupLink = false, openAppInTarget = false, windowProperties = false, windowTitle = false, openNewWindow = false) => {
    let openedWindow = window.open(popupLink, openNewWindow ? "" : openAppInTarget+JSON.stringify(popupLink), windowProperties);
    /*if (windowTitle) {
        openedWindow.onload = () => {
            openedWindow.document.title = windowTitle;
        }
    }*/
}
