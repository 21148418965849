import actions from './actions';

const initState = {
	blogItems : [],
	activeBlogItem : false,
	requestCompleted : false,
};


export default function multiChannelBlogReducer(state = initState, action) {

	switch (action.type) {
		case actions.BLOGS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_MULTICHANNEL_BLOG_ITEMS:
			return {
				...state,
				blogItems : action.payload
			}
		case actions.SET_ACTIVE_BLOG_ITEM:
            return {
                ...state,
                activeBlogItem: action.payload
            }
		default:
			return state;
	}
}
