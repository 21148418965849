import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { getGOClient, getLearningItems } from '../../selectors';
import { dataURItoBlob } from '../../../helpers/functions';

export function* getLearning() {
    yield takeEvery(actions.GET_LEARNING, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        //const cachedItems = yield select(getLearningItems);

        if (!Client) return;
        const items = yield Client.api("/learning/list");
        if (items == null || !items) return;

        //if (items.length && ((cachedItems != null && cachedItems.length === 0) || (cachedItems && cachedItems[0].Id !== items[0].id))) {
            yield put({
                type: actions.SET_LEARNING,
                payload: items
            });
       //}

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getRandomLearningPaths() {
    yield takeEvery(actions.GET_RANDOM_LEARNINGPATHS, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const cachedItems = yield select(getRandomLearningPaths);

        if (!Client) return;
        const items = yield Client.api(`/learning/randomlearningpaths?learningId=${action.payload}`);
        if (items == null || !items) return;

        if (items.length && (cachedItems[0] == null || (cachedItems != null && cachedItems.length === 0) || (cachedItems[0] != null && cachedItems[0].uid !== items[0].uid))) {

            yield put({
                type: actions.SET_RANDOM_LEARNINGPATHS,
                payload: items
            });
        }

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getLearningPaths() {
    yield takeEvery(actions.GET_LEARNINGPATHS, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const cachedItems = yield select(getLearningPaths);

        if (!Client) return;

        const items = yield Client.post(`/learning/preferencelearningpaths`, action.payload);
        if (items == null) return;

        if (!items.length || (items.length && (cachedItems[0] == null || (cachedItems != null && cachedItems.length === 0) || (cachedItems[0] != null && cachedItems[0].uid !== items[0].uid)))) {
            yield put({
                type: actions.SET_LEARNINGPATHS,
                payload: items
            });
        }

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getRoles() {
    yield takeEvery(actions.GET_ROLES, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const cachedItems = yield select(getRoles);

        if (!Client) return;
        const items = yield Client.api(`/learning/roles?learningId=${action.payload}`);
        if (items == null || !items) return;

        if (items.length && (cachedItems[0] == null || (cachedItems != null && cachedItems.length === 0) || (cachedItems[0] != null && cachedItems[0].uid !== items[0].uid))) {
            yield put({
                type: actions.SET_ROLES,
                payload: items
            });
        }

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getProducts() {
    yield takeEvery(actions.GET_PRODUCTS, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const cachedItems = yield select(getProducts);

        if (!Client) return;
        const items = yield Client.api(`/learning/products?learningId=${action.payload}`);
        if (items == null || !items) return;

        if (items.length && (cachedItems[0] == null || (cachedItems != null && cachedItems.length === 0) || (cachedItems[0] != null && cachedItems[0].uid !== items[0].uid))) {
            yield put({
                type: actions.SET_PRODUCTS,
                payload: items
            });
        }

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getLevels() {
    yield takeEvery(actions.GET_LEVELS, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const cachedItems = yield select(getLevels);

        if (!Client) return;
        const items = yield Client.api(`/learning/levels?learningId=${action.payload}`);
        if (items == null || !items) return;

        if (items.length && (cachedItems[0] == null || (cachedItems != null && cachedItems.length === 0) || (cachedItems[0] != null && cachedItems[0].uid !== items[0].uid))) {
            yield put({
                type: actions.SET_LEVELS,
                payload: items
            });
        }

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getModule() {
    yield takeEvery(actions.GET_MODULE, function* (action) {
        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        //const cachedItems = yield select(getModule);

        if (!Client) return;
        const items = yield Client.api(`/learning/module?learningId=${action.payload.learningId}&moduleId=${action.payload.moduleId}`);
        if (items == null || !items) return;

        //if (items.length && ((cachedItems != null && cachedItems.length === 0) || (cachedItems && cachedItems[0].id !== items[0].id))) {
            yield put({
                type: actions.SET_MODULE,
                payload: items
            });
        //}

        yield put({
            type: actions.LEARNING_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getLearning),
        fork(getRandomLearningPaths),
        fork(getLearningPaths),
        fork(getRoles),
        fork(getProducts),
        fork(getLevels),
        fork(getModule),
    ]);
}
