import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import authActions from '../auth/actions';
import moment from 'moment';
import { getGraphClient } from '../selectors';

export function* bootTipsTricks() {
    yield takeEvery(authActions.READY_FOR_BOOT, function* (action) {
        yield put({
            type : actions.GET_TIPS_TRICKS
        })
    })
}

export function* getTipsTricks() {
    yield takeEvery(actions.GET_TIPS_TRICKS, function* (action) {
        try {
            const response = yield fetch('https://avantage.nl/wp-json/tipstricks/all');
            const data = yield response.json();

            if ( data ) {
                yield put({
                    type : actions.SET_TIPS_TRICKS,
                    payload : data
                })
            }
        } catch ( e ) {
            console.log(e);
        }

    });

}

export default function* rootSaga() {
    yield all([
        fork(getTipsTricks),
        fork(bootTipsTricks)
    ]);
}
