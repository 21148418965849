const actions = {
	TOGGLE_SEARCHBAR_ACTIVE : 'TOGGLE_SEARCHBAR_ACTIVE',
	SET_SEARCH_TERM : 'SET_SEARCH_TERM',
	
	toggleSearchBarActive: (payload) => ({
		type: actions.TOGGLE_SEARCHBAR_ACTIVE,
		payload : payload
	}),

	setSearchTerm: (payload) => ({
		type: actions.SET_SEARCH_TERM,
		payload : payload
	}),
};

export default actions;
