const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    USER_UNAUTHENTICATED: 'USER_UNAUTHENTICATED',
    USER_JUST_LOGGED_IN: 'USER_JUST_LOGGED_IN',
    USER_AUTHENTICATED: 'USER_AUTHENTICATED',
    READY_FOR_BOOT: 'READY_FOR_BOOT',
    SET_AUTH_INFO: 'SET_AUTH_INFO',
    LOG_OUT: 'LOG_OUT',

    checkAuthorization: () => ({
        type: actions.CHECK_AUTHORIZATION
    }),

    unAuthenticated: () => ({
        type: actions.USER_UNAUTHENTICATED
    }),

    userJustLoggedIn: () => ({
        type: actions.USER_JUST_LOGGED_IN
    }),

    userAuthenticated: () => ({
        type: actions.USER_AUTHENTICATED
    }),

    setAuthInfo: (info) => ({
        type: actions.SET_AUTH_INFO,
        payload: info
    }),    

    readyForBoot: (payload) => ({
        type: actions.READY_FOR_BOOT,
        payload: payload
    }),   

    logout: () => ({
        type: actions.LOG_OUT
    }),    
};

export default actions;
