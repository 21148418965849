import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';

const BirthdayItem = props => (
    <Styled.BirthdayItem className={(props.darkmode ? 'darkmode' : '')} >
        <Styled.Wrapper flex={(!props.small)}>
            {props.none ? null : <Styled.Icon src={props.icon} />}
            {props.none
                ?
                    <Styled.Text>{props.translate("noBirthdays")}.</Styled.Text>
                :
                props.small
                    ?
                    <Styled.Text fullWidth><strong>{props.name}</strong><br />{props.when}</Styled.Text>
                    :
                    <Styled.Text>
                        {props.translate("hisBirthday", { when: props.when, name: props.name })}. {props.translate('congratulations')}!
                    </Styled.Text>
            }

        </Styled.Wrapper>
    </Styled.BirthdayItem>
)

BirthdayItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    none: PropTypes.bool,
    popup: PropTypes.bool,
    demo: PropTypes.bool
}


const mapStateToProps = (state, ownProps) => {
    return {
        darkmode: state.Layout.darkmode
    };
};

export default withTranslate(connect(mapStateToProps)(BirthdayItem));
