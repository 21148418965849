import { all, fork, takeEvery, select, put } from 'redux-saga/effects';
import actions from './actions';
import { getGraphClient } from '../selectors';


export function* getRecentDocumentsForApp() {
    yield takeEvery(actions.GET_RECENT_DOCUMENTS_FOR_APP, function* (action) {


        const Client = yield select(getGraphClient);

        if (!Client) return;

        const recentDocuments = yield Client.api('/me/drive/recent?$orderby=lastModifiedDateTime desc&$top=2000');

        yield put({
            type : actions.SET_RECENT_DOCUMENTS_LOADING,
            payload : true
        })
        yield put({
            type : actions.SET_RECENT_DOCUMENTS_FOR_APP,
            payload : []
        })
        let documentsForResult = [];
        if ( recentDocuments.value ) {
            switch ( action.payload ) {
                case 'Word':
                    documentsForResult = recentDocuments.value.filter((item) => (item.name.includes('.doc')));
                break;
                case 'Excel':
                    documentsForResult = recentDocuments.value.filter((item) => (item.name.includes('.xls')));
                break;
                case 'PowerPoint':
                    documentsForResult = recentDocuments.value.filter((item) => (item.name.includes('.ppt')));
                break;
            }

            yield put({
                type : actions.SET_RECENT_DOCUMENTS_FOR_APP,
                payload : documentsForResult
            })

            yield put({
                type : actions.SET_RECENT_DOCUMENTS_LOADING,
                payload : false
            })
        } else {
            yield put({
                type : actions.SET_RECENT_DOCUMENTS_LOADING,
                payload : false
            })
        }


    });
}


export default function* rootSaga() {
    yield all([
        fork(getRecentDocumentsForApp),
    ]);
}
