import styled from 'styled-components'
import { DocumentsList, Section } from '../../../widget/documentsList/styled';
import { DocumentsListItem } from '../../../widget/documentsList/documentsListItem/styled';
import { Loader } from '../styled';

export const List = styled(DocumentsList)`
	margin-bottom: 40px;
`;

export const DocumentSection = styled(Section)`

`;

export const Item = styled(DocumentsListItem)`
	${Loader}
	height: 20px;
	width: 80%;
`;
