const actions = {
	GET_BIRTHDAYS : 'GET_BIRTHDAYS',
	SET_BIRTHDAYS: 'SET_BIRTHDAYS',
	SET_UPCOMING_BIRTHDAYS: 'SET_UPCOMING_BIRTHDAYS',
	GET_UPCOMING_BIRTHDAYS: 'GET_UPCOMING_BIRTHDAYS',	
	SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
	GET_PERSON_AVATAR: 'GET_PERSON_AVATAR',
	SET_BIRTHDAYS_AVATARS: 'SET_BIRTHDAYS_AVATARS',
		
	getBirthDays : ( payload ) => ({
		type : actions.GET_BIRTHDAYS,
		payload : payload
	}),
	getUpcomingBirthDays: (payload) => ({
		type: actions.GET_UPCOMING_BIRTHDAYS,
		payload: payload
	}),
	fetchNextPage: (payload) => ({
		type: actions.GET_NEXT_PAGE,
		payload: payload
	}),
	findAvatar: (payload) => ({
		type: actions.GET_PERSON_AVATAR,
		payload: payload
	})
};

export default actions;
