import styled from 'styled-components'
import * as Variables from '../../../../ThemeVariables';

export const DocumentsListItem = styled.a`
	display: flex;
	text-decoration: none;
	cursor: pointer;
	color: ${Variables.mainTextColor.lightMode};
	border-radius: 6px;
	padding:5px;
	&.darkmode {
		color: ${Variables.mainTextColor.darkMode};
	}
	&:hover{
		text-decoration: underline;
	}
	&.popup {
		background : #ebedf0;
	}
	&.darkmode.popup  {
		background: ${Variables.tileBackground.darkMode};
	}
`;

export const Icon = styled.img`
	height: 22px;
	width: auto;
	margin-right: 12px;
`;

export const Info = styled.span`
	font-size: 12px;
	line-height: 22px;
	&.darkmode {
		color: ${Variables.mainTextColor.darkMode};
	}
`;

export const Author = styled.span`
	font-size: 12px;
	line-height: 22px;
	&.darkmode {
		color: ${Variables.mainTextColor.darkModeSoft};
	}
`;
