import actions from './actions';

const initState = {};

export default function calendarReducer(state = initState, action) {
	switch (action.type) {  
		case actions.SET_CALENDAR:
			return {...state, calendar: action.payload}
		default:
			return state;
	}
}
