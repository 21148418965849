import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { getGOClient, getFaqsItems, getKnowledgeBaseItems } from '../selectors';
import { dataURItoBlob } from '../../helpers/functions';

export function* getFAQs() {
    yield takeEvery(actions.GET_FAQS, function* (action) {
        yield put({
            type: actions.FAQS_REQUEST_COMPLETED,
            payload: false
        });

        const Client = yield select(getGOClient);
        const items = yield select(getFaqsItems);

        if (!Client) return;
        const faqs = yield Client.api("/knowledgebase/faqs?take=40");

        if (faqs == null || !faqs) return;


        if (faqs.length && ((items.length === 0) || (items && items[0].Id !== faqs[0].id))) {

            yield put({
                type: actions.SET_FAQS,
                payload: faqs
            });
        }

        yield put({
            type: actions.FAQS_REQUEST_COMPLETED,
            payload: true
        });
    });
}

export function* getKnowledgeBases() {
    yield takeEvery(actions.GET_FAQS, function* (action) {
        yield put({
            type: actions.KNOWLEDGEBASES_REQUEST_COMPLETED,
            payload: false
        });

        const Client = yield select(getGOClient);
        const items = yield select(getKnowledgeBaseItems);

        if (!Client) return;
        const kbs = yield Client.api("/knowledgebase/list");
        if (kbs == null || !kbs) return;

        if (kbs.length && ((items.length === 0) || (items && items[0].Id !== kbs[0].id))) {

            yield put({
                type: actions.SET_KNOWLEDGEBASES,
                payload: kbs
            });
        }

        yield put({
            type: actions.KNOWLEDGEBASES_REQUEST_COMPLETED,
            payload: true
        });
    });
}


export default function* rootSaga() {
    yield all([
        fork(getFAQs),
        fork(getKnowledgeBases)
    ]);
}
