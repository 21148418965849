const actions = {
	GET_EVENTS : 'GET_EVENTS',
	SET_EVENTS : 'SET_EVENTS',
	SET_ACTIVE_EVENT: 'SET_ACTIVE_EVENT',
	EVENTS_REQUEST_COMPLETED: 'EVENTS_REQUEST_COMPLETED',
	GET_YEAR_EVENTS: 'GET_YEAR_EVENTS',
	SET_YEAR_EVENTS: 'SET_YEAR_EVENTS',
	SET_SOURCE_URL: 'SET_SOURCE_URL',

	setActiveEvent : (payload) => ({
		type: actions.SET_ACTIVE_EVENT,
		payload : payload
	}),

	getEvents : ( payload, title ) => ({
		type : actions.GET_EVENTS,
		payload : { payload : payload, title:title }
	}),

	getYearEvents : ( source, year ) => ({
		type : actions.GET_YEAR_EVENTS,
		payload : { source : source, year:year }
	})
};

export default actions;
