const actions = {
	GET_SELF_RIGHTS : 'GET_SELF_RIGHTS',
	SET_SELF_RIGHTS : 'SET_SELF_RIGHTS',
	SET_ACTIVE_RIGHTS : 'SET_ACTIVE_RIGHTS',
	POST_SELF_RIGHTS : 'POST_SELF_RIGHTS',
	GET_TENANT_RIGHTS : 'GET_TENANT_RIGHTS',
	SET_TENANT_RIGHTS : 'SET_TENANT_RIGHTS',
	GET_RIGHTS_FROM_CHANNEL : 'GET_RIGHTS_FROM_CHANNEL',
	DELETE_SELF_RIGHTS : 'DELETE_SELF_RIGHTS',
	TEST_SELF_RIGHTS : 'TEST_SELF_RIGHTS',
	SELF_RIGHTS_REQUEST_COMPLETED: 'SELF_RIGHTS_REQUEST_COMPLETED',

	POST_RIGHTS_REQUEST : 'POST_RIGHTS_REQUEST',
	SET_RIGHTS_REQUEST : 'SET_RIGHTS_REQUEST',
	GET_RIGHTS_REQUEST : 'GET_RIGHTS_REQUEST',
	DELETE_RIGHTS_REQUEST : 'DELETE_RIGHTS_REQUEST',
	UPDATE_RIGHTS_REQUEST : 'UPDATE_RIGHTS_REQUEST',
	CANCEL_RIGHTS_REQUEST : 'CANCEL_RIGHTS_REQUEST',

	postRightsRequest : (payload) => ({
		type : actions.POST_RIGHTS_REQUEST,
		payload : payload
	}),

	setRightsRequest : (payload) => ({
		type : actions.SET_RIGHTS_REQUEST,
		payload : payload
	}),

	getRightsRequest : () => ({
		type : actions.GET_RIGHTS_REQUEST,
		payload : payload
	}),
	
	cancelRightsRequest : (payload) => ({
		type : actions.CANCEL_RIGHTS_REQUEST,
		payload : payload
	}),

	deleteRightsRequest : (payload) => ({
		type : actions.DELETE_RIGHTS_REQUEST,
		payload : payload
	}),

	updateRightsRequest : (payload, id) => ({
		type : actions.UPDATE_RIGHTS_REQUEST,
		payload : {payload, id}
	}),

	// getTenantNews : () => ({
	// 	type : actions.GET_TENANT_RIGHTS
	// }),

	// getCommonNews : ( payload ) => ({
	// 	type : actions.GET_SELF_RIGHTS,
	// 	payload : payload
	// }),

	// setActiveNewsItem : ( payload ) => ({
	// 	type : actions.SET_ACTIVE_RIGHTS,
	// 	payload : payload
	// }),

	// deleteItem : (payload) => ({
	// 	type : actions.DELETE_SELF_RIGHTS,
	// 	payload : payload
	// }),
	// setUser : (payload) => ({
	// 	type : actions.SET_SELF_RIGHTS,
	// 	payload : payload
	// })
};

export default actions;
