const actions = {
	GET_NEWS : 'GET_NEWS',
	SET_NEWS : 'SET_NEWS',

	getNews : ( payload ) => ({
		type : actions.GET_NEWS,
		payload : payload
	})
};

export default actions;
