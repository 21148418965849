/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import buildMsalConfig from './msalConfig';
import AuthService from './AuthService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PublicClientApplication } from '@azure/msal-browser';
import AppWrapper from './AppWrapper';
import localForage from "localforage";

const isLocalhost = Boolean(
    window.location === 5000 ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);


window.addEventListener("load", () => {
    function handleNetworkChange(event) {
        if (navigator.onLine) {
            window.applicationOnline = true;
        } else {
            window.applicationOnline = false;
        }
    }
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
});

if (navigator.onLine) {
    window.applicationOnline = true;
} else {
    window.applicationOnline = false;
}

if ( isLocalhost ) {
    window.apiURL = 'http://localhost:1337';
} else {
    window.apiURL = '';
}

let startApp = () => {
    ReactDOM.render(<AppWrapper />, document.getElementById('root'));
}

let setup = async (startAfterSetup = true) => {
    const headers =  {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin':'*'
        }
    }
    await fetch(window.apiURL + '/api/setup', headers).then(response => response.json()).then(data => {
        data.notificationEnabled = false; //force

        if (window.tenantConfig?.version && window.tenantConfig?.version !== data.version) {
            console.log('reset!');
            localStorage.clear();
            localForage.clear();
            window.clearPersist = true;
        }

        window.tenantConfig = { ...window.tenantConfig, ...data };

        if (window.tenantConfig.common == "true") {
            window.tenantConfig.common = true;
        } else {
            window.tenantConfig.common = false;
        }
        window.tenantConfig.popup = true;

        window.tenantConfig.selfServiceGroup == data.selfServiceGroup

        //legacy
        window.tenantConfig.newsTreshold = 40;
        window.tenantConfig.blogTreshold = 4;
        window.tenantConfig.eventsTreshold = 4;

        window.userSettings = {};
        window.userSettings.rights = {};

        localStorage.setItem('tenantConfig', JSON.stringify(window.tenantConfig));

        if ( startAfterSetup ) {
            startApp();
        }
    });
}

if ( localStorage.getItem('tenantConfig') ) {
    window.tenantConfig = JSON.parse(localStorage.getItem('tenantConfig') );

    if ( window.applicationOnline ){
        setup(false);
    }

    startApp();
} else {
    setup();
}

serviceWorkerRegistration.register();