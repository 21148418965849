import actions from './actions';
const initState = {
	sharepoint : [],
	sharePointSet: false,
	sharepointDrive : [],
	sharepointDriveSet: false,
	sharepointDriveChildren : [],
	sharepointDriveChildrenSet: false,
	sharepointFolder : [],
	sharepointFolderSet: false,
	isLoading: false,
};

export default function sharepointReducer(state = initState, action) {
	switch (action.type) {
        case actions.SET_SHAREPOINT :
        	return {
        		...state,
        		sharepoint : action.payload,
				sharePointSet : true
        	}
		case actions.SET_SHAREPOINT_DRIVE :
			return {
				...state,
				sharepointDrive : action.payload,
				sharepointDriveSet : true
			}
		case actions.SET_SHAREPOINT_DRIVE_CHILDREN :
			return {
				...state,
				sharepointDriveChildren : action.payload,
				sharepointDriveChildrenSet : true
			}
		case actions.SET_SHAREPOINT_FOLDER :
			return {
				...state,
				sharepointFolder : action.payload,
				sharepointFolderSet : true
			}
		case actions.CLEAR_SHAREPOINT :
			return {
				...state,
				sharePointSet : false
			}
		case actions.CLEAR_SHAREPOINT_DRIVE :
			return {
				...state,
				sharepointDriveSet : false
			}
		case actions.CLEAR_SHAREPOINT_DRIVE_CHILDREN :
			return {
				...state,
				sharepointDriveChildrenSet : false
			}
		case actions.CLEAR_SHAREPOINT_FOLDER :
			return {
				...state,
				sharepointFolderSet : false
				}
		case actions.SET_IS_LOADING :
			return {
				...state,
				isLoading : action.payload
				}
		default:
			return state;
	}
}
