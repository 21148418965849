const actions = {
	SET_MULTICHANNEL_BLOG_ITEMS : 'SET_MULTICHANNEL_BLOG_ITEMS',
	GET_MULTICHANNEL_BLOG_ITEMS : 'GET_MULTICHANNEL_BLOG_ITEMS',
	SET_ACTIVE_BLOG_ITEM : 'SET_ACTIVE_BLOG_ITEM',
	BLOGS_REQUEST_COMPLETED : 'BLOGS_REQUEST_COMPLETED',

	getMultichannelBlogItems : ( payload, title ) => ({
		type : actions.GET_MULTICHANNEL_BLOG_ITEMS,
		payload : { title: title, payload: payload } 
	}),
	setActiveBlogItem : ( payload ) => ({
		type : actions.SET_ACTIVE_BLOG_ITEM,
		payload : payload
	}),
};

export default actions;
