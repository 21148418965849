import actions from './actions';

const initState = {
	newsItems : []
};


export default function newsReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_NEWS:
			return {
				...state,
				newsItems : action.payload
			}
		default:
			return state;
	}
}
