import styled from 'styled-components';
import * as Variables from '../../../ThemeVariables';

export const TeamsList = styled.ul`
    list-style: none;
    margin-left: 30px;
    .save-button {
        padding: 2px 5px;
        background: #f10000;
        color: white;
        font-size: 12px;
        font-weight: bold;
        border: none;


        cursor: pointer;
        display: inline-block;
    }

    .folder-active {
        font-weight: bold;
    }
`;


export const TeamsChannelList = styled.ul`
    list-style: none;
    margin-left: 65px;
    display: none;

    &.active {
        display: block;
    }

    &.small {
        margin-left: 35px;
        li {
            span {
                margin-bottom: 10px;
                display: block;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        ul {
            padding-left: 25px;

            li {
                cursor: pointer;
                margin-bottom: 10px;

                div {
                    margin-bottom: 10px;
                }
            }
        }
    }

`;

export const TeamsChannelListItem = styled.li`
    margin-bottom: 15px;

    &.explorermode {
        &:before {
            content: '';
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            content: '\f07b';
            left: 0;
            font-weight: 900;
        }
        &.document {
            &:before {
                content: '\f15b';
            }
        }

        ul {
            list-style: none;
            li {
                &:before {
                    content: '';
                    position: absolute;
                    font-family: 'Font Awesome 5 Free';
                    content: '\f07b';
                    left: -25px;
                    font-weight: 900;
                }
                div {

                }
            }
            &.document {
                &:before {
                    content: '\f15b';
                }
            }
        }
        padding-left: 25px;

        &.darkmode {
            &:before {
                color: white;

            }

            span {
                color: white;
            }

            ul {
                li {
                    &:before {
                        color: white;

                    }
                    div {
                        color: white;
                    }
                }
            }

        }
    }

    a {
        text-decoration: none;
        color: black;

        &:hover {
            text-decoration: underline;
        }

        &.darkmode {
            color: white;
        }
    }
`;

export const TeamsListItem = styled.li`
    display: grid;
    margin-bottom: 25px;
    grid-template-columns: 50px 1fr 25px;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    & > img {
        max-width: 35px;
        grid-area: image;
        border-radius: 8px;
    }
    &.small {
        grid-template-columns: 25px 1fr 25px;
        grid-column-gap: 10px;
        margin-bottom: 15px;
        & > img {
            max-width: 25px;
            grid-area: image;
            border-radius: 8px;
        }
    }


    grid-template-areas: "image name arrow"
    "channels channels channels";

    & > a, & > div, & > span {
        &.darkmode {
            color: white;
        }
    }




    & > a, & > div {
        grid-area: name;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: black;
        cursor: pointer;
    }

    & > span {
        grid-area: arrow;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
    }

    & > ul {
        grid-area: channels;
    }
    &.darkmode {
        &:before {
            color: white;

        }
    }
`;

export const BreadCrumbs = styled.ul`
    list-style: none;
    margin:5px 15px 5px 15px;
`;

export const BreadCrumbsList = styled.li`
    display:inline-block;
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
    b {

    }
`;

export const Sharepoint = styled.div`
    height: calc(100% - 10px);
`;

export const TopInfo = styled.div`
    border-radius: 6px;
    padding:5px 15px 5px 0px;
`;

export const Search = styled.div`
    display: flex;
    justify-content: space-between;
`;

// export const SearchInput = styled.input`
//     //padding: 5px 15px;  
//     width: 60%;
//     background: white;
//     display: inline-block;
//     border-radius: 6px;
//     color: #000000;
//     border: 2px solid #E4E4E4;
// `;

export const SearchButton = styled.button`
    padding: 5px 15px;
    color: #1b1b1b;
    font-weight: bold;
    width: 30%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    -webkit-text-decoration: none;
    -webkit-text-decoration: none;
    text-decoration: none;
`;

export const ListTeam = styled.div`
    /* display: flex;
    flex-direction:column;
    gap:25px;
    width:100%;
    height:200px;
    flex-wrap:wrap;
    gap:10px 50px;
    overflow:auto;
    margin-top:10px; */

    display: grid;
    grid-auto-flow:column;
    grid-auto-columns: minmax(200px, 1fr);
    gap:25px;
    //width:500px;
    height:calc(100% - 100px);
    flex-wrap:wrap;
    gap:10px 20px;
    overflow-x:auto;
    grid-template-columns: fit-content(200px);
    grid-template-rows: repeat(auto-fill, 30px);

    &.darkmode {
        b{
        color:rgb(191, 188, 188);
        }
    }

    @media(max-width: ${Variables.md}) {
        grid-auto-flow:row;
    }
`

export const Team = styled.div`
    /* overflow-wrap: break-word;
    box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.09);
    padding:10px; */
    cursor: pointer;
    max-height:100px;
    display:flex;
    gap:10px;
    align-items:center;
    img {
        height:20px;
    }
    svg {
        height:20px;
    }

    div {
        display: flex;
        align-items: center;
        gap: 10px;
        width:100%;
        p{
            font-size: 10pt;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`


//SEARCH
export const SearchInput = styled.input`
	width: calc(100% - 50px);
	line-height: ${Variables.headerHeight};
	font-size: 16px;
	background: transparent;
	float: left;

	&:focus {
		outline : none;
	}
`;

export const SearchWrapper = styled.div`
	/*width: calc(100% - 50px);*/
	width: 100%;

	background: #ebedf0;
	border-radius: 25px;
	overflow: hidden;
    margin:10px 0;
	&.greyout {
		opacity: .3;
	}


	height: ${Variables.headerHeight};

	@media ( max-width: ${Variables.mxs}) {
		display: none;
	}



	@media ( min-width: ${Variables.md} ) {
		${props => props.gridArea ? 'grid-area:' + props.gridArea : ''};
		width: 25vw;

		//margin-left: 25px;
	}

	@media ( min-width: ${Variables.lgMin} ) {
		width: 25vw;
	}

	${props => props.wrapped ? 'display: block !important;' : ''};

	@media ( min-width: ${Variables.xlg} ) {
		display: block;
		width: 25vw;
		grid-area: search;
	}
`;

export const Magnifier = styled.div`
	width: 50px;
	float: left;
	line-height: ${Variables.headerHeight};
	text-align: center;

`;
