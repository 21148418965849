import styled from 'styled-components'
import * as Variables from './ThemeVariables';
//import { WrappedToastContainer } from '../layout/WrappedToastContainer';
export const Wrapper = styled.div`
	&.darkmode {
		background: ${Variables.outerColumnBackground.darkMode};
	}
`;

export const Body = styled.div`
	min-height: ${Variables.appHeight};
    width: 100%;
    overflow-x: hidden;
	@media(min-width: ${Variables.md}) {
		max-height: ${Variables.appHeight};
		overflow: hidden;
	}
    background: #f2f3f5;

    &.darkmode {
		background: ${Variables.outerColumnBackground.darkMode};
	}
`;

export const Loader = styled.div`
    width: 100%;
    height: 100vh;
    background: #F10000;
    text-align: center;
    padding-top: 40vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all .5s;

    &.loader--hidden {
      margin-top: -100vh;
      opacity: 0;
    }
    &--visible {
      margin-top: 0;
      opacity: 1;
    }

`;

export const LoaderText = styled.p`
    color: #FFF;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 2em;

    small {
        font-size: 12px;
        font-weight: normal;
        margin-top: 40px;
    }
`;

/*
export const StyledToastContainer = styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  	&--top-right {
  		top: 75px;
  	}
  }
  .Toastify__toast {
  	background: white;
  	color: black;
  	padding: 25px 15px;
  }
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {
  	background: ${Variables.mainColor}
  }
  .Toastify__close-button {
  	opacity: 1;

  }
`;*/
