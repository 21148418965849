import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../../user/actions';
import localforage from 'localforage';
import { dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getSharepointClient, getGOClient, getGraphClient, getNewsItems, getMultiChannelNews, getMultiChannelNewsItems, getUserRequestCompleted, getNewsRequestCompleted, getUserProfileSettingData } from '../../selectors';
import NewsObject from './newsObject';
import moment from 'moment/min/moment-with-locales';
import * as rssParser from 'react-native-rss-parser';

export function* getNewsFromChannel() {
    yield takeEvery(actions.GET_MULTICHANNEL_NEWS_ITEMS, function* (action) {
        // -- Switch here, select news conditionally.
        yield put({
            type: actions.NEWS_REQUEST_COMPLETED,
            payload: false
        })
        let newItems = [];
        let containsGo = false;
        const title = action.payload.title;
        let currentItems = yield select(getMultiChannelNewsItems);
        //parse the different sources configured
        if (action.payload && action.payload.payload) {
            for (let source of action.payload.payload) {
                if (source.source == "sharepoint") {
                    let items = yield call(getSharepointNews, source.site, source.url, title, source.source);
                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }
                }

                if (source.source == "rss") {
                    let items = yield call(getRssNews, source.url, title);
                    if (items?.length > 0) {
                        items = items.map(i => {
                        i.widget = title
                        return i; });
                    }
                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }
                }

                if (source.source == "go" && source.url == true) {
                    containsGo = true;
                }
            }
        }

        const sortOnDate = ((a, b) => {
            if (a.unix > b.unix) {
                return -1;
            }

            if (a.unix < b.unix) {
                return 1;
            }

            return 0;
        });

        //only if common
        if (window.tenantConfig.common || containsGo) {
            if(window.tenantConfig.common) {
            const items = yield call(getWebsiteNews);
            if (items) {
                newItems = [
                    ...newItems,
                    ...items
                ]
            }
            }

            
            let commonNews = yield call(getCommonNews);
            if(!window.tenantConfig.common) {
                if (commonNews?.length > 0) {
                    commonNews = commonNews.map(i => {
                    i.widget = title
                    return i; });
                }
                commonNews = [...commonNews, ...currentItems.filter(i => i.type == "go" && i.widget == title)];
                if (commonNews) {
                    newItems = [
                        ...newItems,
                        ...commonNews
                    ];
                }
                newItems.sort(sortOnDate);
            } else {
                newItems.sort(sortOnDate);
                if (commonNews?.length) {
                    commonNews = commonNews.map(i => {
                        i.widget = title
                        return i; });
                    if (commonNews.find(n => n.tenantId != null)) {
                        newItems = commonNews
                    } else {
                        newItems = commonNews.concat(newItems);
                    }
                }
            }
        } else {
            newItems.sort(sortOnDate);
        }

        currentItems = yield select(getMultiChannelNewsItems);
        currentItems = currentItems.filter(i => i.widget == title);
        newItems = newItems.splice(0, 30); //max items

        let old = currentItems.filter(n => !newItems.find(nn => nn.id == n.id));
        if (old?.length > 0 && newItems.length > 0) {
            for (let n of old) {
                yield localforage.removeItem(n.id);
            }
        }

        let modified = newItems.filter(data => {
            return currentItems.find(current => {
                if(data.dateModified != current.dateModified && current.type == "sharepoint" && current.id == data.id) {
                    return data
                }
            })
        })
        
        let keys = yield localforage.keys();
        let localforageKeys = keys.filter(k => k.indexOf('sp_news') != -1)?.length;
        let currentItemsKeys = currentItems.filter(i => i.widget == title).length;
        
        if ((old?.length > 0 && newItems.length > 0) || (modified?.length > 0 && newItems.length > 0) || (currentItems?.length == 0 && newItems?.length > 0) || (currentItems?.length !== newItems?.length && newItems.length > 0)) {
            newItems = yield call(postprocess, newItems);
            let allItems = yield select(getMultiChannelNewsItems);
            allItems = allItems.filter(i => i.widget != title);
            allItems = allItems.concat(newItems);
            yield put({
                type: actions.SET_MULTICHANNEL_NEWS_ITEMS,
                payload: allItems
            });
        } 

        if(localforageKeys != currentItemsKeys) { 
            currentItems = yield select(getMultiChannelNewsItems);
            currentItems = currentItems.filter(i => i.widget == title);
            currentItems = yield call(postprocess, currentItems);
            let allItems = yield select(getMultiChannelNewsItems);
            allItems = allItems.filter(i => i.widget != title);
            allItems = allItems.concat(currentItems);
            allItems.sort(sortOnDate);

            yield put({
                type: actions.SET_MULTICHANNEL_NEWS_ITEMS,
                payload: allItems
            });
        }
    
        yield put({
            type: actions.NEWS_REQUEST_COMPLETED,
            payload: true
        })
    })
}

function* getCommonNews() {

    const newsItems = yield select(getMultiChannelNewsItems);
    const Client = yield select(getGOClient);
    if (!Client) return;

    const news = yield Client.api("/common/news/list");

    if (news == null || !news) return;

    if (news.length && ((newsItems != null && newsItems.length === 0) || (newsItems && newsItems[0].id !== news[0].id))) {
        //localforage.clear(); //clear local storage

        let oldNews = newsItems.filter(n => !news.find(nn => nn.id == n.id));

        //yield news.forEach(n => {
        for (let n of news) {
            try {
                let blob = dataURItoBlob(n.image);
                if (blob == null || blob == '') continue; // do nothing, maybe request failed
                yield localforage.setItem(n.id, blob);
                delete n.image;
            } catch {
                delete n.image;
            }
        }
    }

    let parsedNews = yield parseCommonNewsItems(news);
    return parsedNews;
}

async function parseCommonNewsItems(news) {
    let parsedNews = [];
    for (let key in news) {
        if (news[key]) {
            const item = news[key];

            let newsObject = new NewsObject;
            newsObject.setAuthor(item.authorName);
            newsObject.setContent(item.content);
            newsObject.setDate(item.date);
            newsObject.setTitle(item.title);
            newsObject.setID(item.id);
            newsObject.setType('common');
            newsObject.setTenantId(item.tenantId);
            newsObject.setImageUrl('parse-from-localForage');

            //newsObject.setImageUrl(item.image);
            if (newsObject.getNewsObject()) {
                parsedNews.push(newsObject.getNewsObject());
            }
        }
    }
    return parsedNews;
}

function* getWebsiteNews() {
    try {
        const response = yield fetch('https://avantage.nl/wp-json/rendered-posts/all?8');
        const news = yield response.json();

        let parsedNews = [];

        news.forEach((item, i) => {

            let newsObject = new NewsObject;
            newsObject.setAuthor(item.author);
            newsObject.setContent(item.content);
            newsObject.setDate(item.date, 'YYYY-MM-DD HH:mm:ss');
            newsObject.setTitle(item.title);
            newsObject.setImageUrl(item.image);
            newsObject.setUrl(item.link);
            newsObject.setID(item.id);
            newsObject.setType('website');
            if (newsObject.getNewsObject()) {
                parsedNews.push(newsObject.getNewsObject());
            }
        });

        return parsedNews;

    } catch (e) {
        console.log(e);
    }
}


function* getRssNews(url, title) {

    const GoClient = yield select(getGOClient);
    let currentItems = yield select(getMultiChannelNewsItems);
    currentItems = currentItems.filter(i => i.widget == title);
    let items = [];
    let imgUrl = "";
    try {
        let data = yield GoClient.api('/app/proxy?url=' + encodeURIComponent(url), {}, 'text')
            .then((responseData) => rssParser.parse(responseData));
        if(data?.image && data?.image?.url) {
            imgUrl = data?.image?.url;
        }
        data.items = data.items.slice(0, 20); //max messages

        let newItems = data.items.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => i.type == "rss" && i.id == d.id && i.widget == title))));
        let parsedItems = yield parseRssItems(newItems, imgUrl);

        //add existing (already parsed) items to array
        parsedItems = parsedItems.concat(currentItems.filter(i => i.type == "rss" && i.widget == title));
        return parsedItems;

    } catch (error) {
        console.log('rss error: ' + error);
        return [];
    }
}

function* getSharepointNews(site, api, title, source) {
    if (!api) {
        console.log("Sharepoint API URL Required");
        return;
    }

    try {
        let currentItems = yield select(getMultiChannelNewsItems);
        currentItems = currentItems.filter(i => i.widget == title && i.type == source);
        let changedItems = [];
        const Client = yield select(getSharepointClient);
        const GoClient = yield select(getGOClient);
        const GraphClient = yield select(getGraphClient);
        if (!Client) {
            console.log("no sharepoint client activated");
            return;
        }

        const newsResponse = yield Client.api((api.includes('?') ? api.split('?')[0] : api) + "/?$expand=File&$filter=PromotedState eq '2'&$top=20&$orderBy=Created desc");
        if (newsResponse == null || !newsResponse || !newsResponse.value) return;
        if (newsResponse.value.length === 0) return;

        //only parse new items
        let data = newsResponse.value;
        let newItems = data.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => i.type == "sharepoint" && i.id == 'sp_news_' + d.Id.toString()))));
        let oldItems = currentItems.filter(i => !data.find(d => 'sp_news_' + d.Id == i.id) && !newItems.find(n => 'sp_news_' + n.Id == i.id));
       
        //set ID for sharepoint.. still necessary?
        if (newItems?.length > 0) {
            newItems = newItems.map(function (n) {
                return { ...n, Id: 'sp_news_' + n.Id.toString(), site: site };
            });
        }
        //parse news items to GO news items
        let parsedItems = yield parseSharepointNewsItems(newItems, api);
        if (parsedItems?.length > 0) {
            parsedItems = parsedItems.map(i => {
            i.widget = title
            return i; })
        }
        //remove old items
        if (oldItems?.length > 0) {
            currentItems = currentItems.filter(c => !oldItems.find(o => o.id == c.id));
        }
        //Check for modified items
        changedItems = data.filter(data => {
            return currentItems.find(current => {
                if(data.Modified != current.dateModified && current.type == "sharepoint" && current.id == 'sp_news_'+ data.Id.toString()) {
                    return data
                }
            })
        })


        if (changedItems?.length > 0) {
            changedItems = changedItems.map(function (n) {
                return { ...n, Id: 'sp_news_' + n.Id.toString(), site: site };
            });
        }

        let parsedChangedItems = yield parseSharepointNewsItems(changedItems, api);
        if (parsedChangedItems?.length > 0) {
            parsedChangedItems = parsedChangedItems.map(i => {
            i.widget = title
            return i; })
        }
        if (parsedChangedItems?.length > 0) {
            currentItems = currentItems.filter(current => !parsedChangedItems.find(parsed => current.type == "sharepoint" && current.id == parsed.id))
        }

        //add existing (already parsed) items to array
       // parsedItems.push({...currentItems[0], Id: 'sp_news_test', id: 'sp_news_test'});
        return [...parsedItems, ...currentItems, ...parsedChangedItems];
    } catch (ex) { console.log('error getting sharepoint news: '+ ex.message); return []; }
}

function* postprocess(newItems) {
    let tasks = [];
    const SharepointClient = yield select(getSharepointClient);
    let sharepointToken;
    if (SharepointClient) sharepointToken = yield SharepointClient.getToken();

    const GoClient = yield select(getGOClient);
    let goToken = yield GoClient.getToken();

    //task to get images
    tasks = newItems.map(async (item, index) => {

        if (item.type == "sharepoint") {
            //get author based on sharepoint user id
            if (!isNaN(parseInt(item.author))) {
                const sharepointUser = await SharepointClient.api(item.site + '/_api/web/getuserbyid(' + item.author + ')');
                //set auteur
                if (sharepointUser) {
                    if (sharepointUser.Title) {
                        //item.author = sharepointUser.Title;
                        newItems[index].author = sharepointUser.Title;
                    }
                }
            }

            //get image
            const getResponse = async () => {
                if (item.imageUrl == 'parse-from-localForage') return { imageUrl: null }; //no image found in cache 
                if (item.imageUrl?.indexOf("sharepoint.com") == -1) return { imageUrl: item.imageUrl }; //do nothing
                // if (item.imageUrl?.indexOf("sharepoint.com") == 1) { imageUrl: item.imageUrl }; //do nothing
                
                let pictureUrl = parsePictureUrl(item.orginalUrl ? item.orginalUrl : item.imageUrl, 3);
                let url = encodeURIComponent(pictureUrl);

                let response;
                response = await fetch(`/api/getimage?url=${url}`, {
                    method: 'GET',
                    contentType: 'image/jpeg',
                    headers: {
                        'Authorization': 'Bearer ' + goToken, 'AuthorizationProxy': sharepointToken
                    }
                }).catch((err) => { return null; });

                if (!response || !response.ok) return null;

                const content = await response.text();
                let objectUrl = "";
                let blob;

                try {
                    blob = dataURItoBlob(content);
                    //objectUrl = URL.createObjectURL(blob);
                } catch (ex) { return null; }
                if (!blob) return null;
                try{
                await localforage.setItem(item.id, blob);

                }
                catch (ex) { return null;}
                return {
                    imageUrl: 'parse-from-localForage',
                    orginalUrl: item.orginalUrl ? item.orginalUrl : item.imageUrl
                };
            };

            let pictureData = await localforage.getItem(newItems[index].id);
            if (pictureData == null || pictureData == '') pictureData = await getResponse();
            if (pictureData) newItems[index] = { ...newItems[index], ...pictureData };
        }


    });

    //wait for all tasks (getting images) completed
    if (tasks.length) {
        yield Promise.all(tasks);
    }

    return newItems;
}

export function* setLastViewedItems() {
    yield takeEvery(actions.SET_LAST_NEWS_VIEWED, function* (action) {
        if (action.payload == null) return;

        console.log('set viewed news');
        yield put({
            type: userActions.UPDATE_USER_SETTINGS,
            payload: {
                value: action.payload,
                setting: 'viewedNews'
            }
        });

    });
}


export function* parseLastViewed() {

    const parser = function* () {

        //state.User.profile.data?.settings?.viewedNews,
        const items = yield select(getMultiChannelNewsItems);
        const data = yield select(getUserProfileSettingData);
        if (data == null) return;

        let viewedItems = data.settings?.viewedNews;
        if (!viewedItems) {
            viewedItems = [];
        }

        let newViewed = [];
        let newItems = 0;
        
        //items.forEach((item, i) => {
        for (let item of items) {

            if (moment().unix() - item.unix >= 8640000) {
                // -- Item is older than 24 hours.
                item.viewed = true;
            } else {
                item.viewed = false;

                // -- Check if item has already been viewed in viewed
                let viewedItem = viewedItems.find((i) => i.url === item.url);
                if (viewedItem) {
                    if (viewedItem.viewed) {
                        item.viewed = true;
                    }
                }
            }

            if (item.viewed == false) {
                newItems++;
            }
            if (!viewedItems.find((i) => i.url === item.url)) {
                viewedItems.push({
                    url: item.url,
                    viewed: item.viewed,
                    title: item.widget
                })
            }
        }

        // -- Cleanup with only present items.
        //viewedItems.forEach((item, i) => {
        for (let item of viewedItems) {
            let available = items.find((i) => i.url === item.url);
            if (available) {
                newViewed.push({
                    url: item.url,
                    viewed: item.viewed,
                    title: item.title
                })
            }
        }

        if ((newViewed.length == 0 && viewedItems.length == 0) ||
            (JSON.stringify(newViewed.map(n => n.url)) != JSON.stringify(viewedItems.map(n => n.url))) ||
            !data.settings?.viewedNews) {

            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: newViewed,
                    setting: 'viewedNews'
                }
            });

        }
    }


    yield takeEvery(userActions.DATA_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getNewsRequestCompleted);
        //const state = yield select();
        //const completed = yield state.MultiChannelNews?.requestCompleted;

        if (action.payload === true && completed) {
            yield parser();
        }
    });

    yield takeEvery(actions.NEWS_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getUserRequestCompleted);
        //const state = yield select();
        //const completed = yield state.User?.requestCompleted;

        if (action.payload === true && completed) {
            yield parser();
        }
    });
}

async function parseSharepointNewsItems(items, api) {
    let parsedItems = [];
    for (let key in items) {
        if (items[key]) {
            const item = items[key];

            let url = parseSharepointUrl(item.File['odata.id']);
            let newsObject = new NewsObject;
            if(item['OData__AuthorBylineId'] && item['OData__AuthorBylineId'][0]) {
                newsObject.setAuthor(item['OData__AuthorBylineId'][0]);            
            } else {
                newsObject.setAuthor(item.AuthorId);
            }
            newsObject.setContent(item.CanvasContent1 ? parseSharePointContent(item.CanvasContent1, url, item.File?.ServerRelativeUrl, key): item.Description ? item.Description : "");
            newsObject.setDate(item.FirstPublishedDate ? item.FirstPublishedDate : item.Created);
            newsObject.setDateModified(item.Modified);
            newsObject.setTitle(item.Title);
            newsObject.setUrl(url.concat(item.File?.ServerRelativeUrl));
            newsObject.setID(item.Id);
            newsObject.setType('sharepoint');
            newsObject.setImageUrl(item.BannerImageUrl?.Url);
            newsObject.setSite(item.site);
            newsObject.setApi(api);
            /*   if (newsObject.imageUrl == null) {
                   newsObject.setImageUrl('parse-from-localForage');
               }*/

            if (newsObject.getNewsObject()) {
                parsedItems.push(newsObject.getNewsObject());
            }

        }
    }
    return parsedItems;
}

async function parseRssItems(items, imgUrl) {
    let parsedItems = [];
    for (let key in items) {
        if (items[key]) {
            const item = items[key];
            let newsObject = new NewsObject;
            newsObject.setAuthor((item.authors[0] ? item.authors[0].name : feed.title));
            newsObject.setContent((item.content ? item.content : item.description));
            newsObject.setDate(item.published);
            newsObject.setTitle(item.title);
            newsObject.setImageUrl(item.enclosures[0]?.url ? item.enclosures[0]?.url : imgUrl);
            newsObject.setUrl(item.links[0]?.url);
            newsObject.setID(item.id);
            newsObject.setType('rss');

            if (newsObject.getNewsObject()) {
                parsedItems.push(newsObject.getNewsObject());
            }

        }
    }
    return parsedItems;
}

function parseSharePointContent(content, root, extra, key) {
    if (!content) return '';
    const urls = /href=\"\//g;
    const src = /src=\"\//g;
    const ids = /<div data-sp-componentid=\"\">[a-z,0-9,-]+<\/div>/g;
    const video = /<a data-sp-prop-name=\"videoSource\" href=\"(.*)\"><\/a>/g;
    const videoThumb = /<img data-sp-prop-name=\"streamVideoThumbnail\.serverRelativeUrl\" src=\"[^>]*\" \/>/g;
    const imagesRegex = /<img data-sp-prop-name=\"imageSource\".*?\/>/g
    const divPlaceRegex = /<div data-webpart-id=\"image\".*?>/g;
        let images = [];
        let divPlace = [];
        images = content.match(imagesRegex);
        divPlace = content.match(divPlaceRegex);
        if(images && divPlace) {
            content = content.replace(imagesRegex, "");
            for(let i = 0; i<images.length; i++) {
                if(images[i] && divPlace[i]) {
                    content = content.replace(divPlace[i], function(match, token) {
                       return  divPlace[i]+images[i]})
                }
            }
        }
    // Regular expression to match the imagePlugin divs
    const regex = /<div class="imagePlugin .*?".*?data-imageurl="(.*?)".*?<\/div>/sg;
    // Regular expression to match the imagePlugin divs
    const regexextra = /<div class="imagePlugin".*?data-imageurl="(.*?)".*?<\/div>/sg;
    // Replace the imagePlugin divs with img tags
    content = content.replace(regex, (match, imageUrl) => {
    return `<img src="${imageUrl}" />`;
    });
    // Replace the imagePlugin divs with img tags
    content = content.replace(regexextra, (match, imageUrl) => {
    return `<img src="${imageUrl}" />`;
    });

    content = content.replace(urls, function (match, token) {
        return 'target="_blank" href="' + root + '/';
    });

    content = content.replace(src, function (match, token) {
        return 'src="' + root + '/';
    });

    content = content.replace(videoThumb, "");

    content = content.replace(ids, "");


    // Regular expression to match the SharePoint images
    const sharePointImages = /<img data-sp-prop-name="imageSource" src="https:\/\/avantage365\.sharepoint\.com\/_LAYOUTS\/IMAGES\/VISUALTEMPLATEIMAGE\d+\.JPG".*?>/g;
    // Remove the SharePoint images
    content = content.replaceAll(sharePointImages, "");


    content = content.replace(/&#8232;/g, "\n")
    content = content.replaceAll(/(<br>){3,}/g, "<br><br>");
    content = content.replaceAll("<p>&#160;</p>","")
    let test;
    while (test = video.exec(content)) {
        let embed = test[1].replace("/video/", "/embed/video/");
        if (test[1]) content = content.replace(test[0], `<div style='max-width: 640px'><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe width="640" height="360" src="${decodeURI(embed)}?autoplay=false&showinfo=true" allowfullscreen style="border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;"></iframe></div></div>`)
    }
    
    return content;
}


export default function* rootSaga() {
    yield all([
        fork(getNewsFromChannel),
        fork(parseLastViewed),
        fork(setLastViewedItems)
    ]);
}
