import styled, { keyframes, css } from 'styled-components';
import * as Variables from '../../../ThemeVariables';
import styledContainerQuery from 'styled-container-query';
import { LinkContainer } from '../../../components/layout/widget/styled';

export const DocumentsList = styledContainerQuery.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 25px;
	grid-row-gap:3px;
	min-height:310px;
	@media(min-width: ${Variables.md}) and (max-width: ${Variables.xlg}) {
		min-height:249px;
	}
	&:container(min-width: 365px) {
		grid-template-columns: repeat(2, 1fr);
	}

	a {
		display: inline-flex;

		@media(min-width: ${Variables.md}) and (max-width: ${Variables.xlg}) {
			&:nth-of-type(1n+9) {
				display: none;
			}
		}
	}
`;

export const AddLink = styled.div`
	display: inline;
	background: #636a73;
	border-radius: 8px;
	font-size: 12px;
	padding: 0 5px;
	cursor: pointer;
	color: ${Variables.mainTextColor.lightMode};
	span {
		font-weight: regular;
	}
`;

export const AddNoLink = styled.span`
	text-decoration: none;
	font-size: 12px;
	cursor: pointer;
	color: #40454c;
	text-align: right;

	&:not(:last-of-type) {
		&:after {
			content: ' | ';
		}
	}

	&.darkmode {
		color: white;
	}
`;

export const ListHeading = styledContainerQuery.div`
	margin-bottom: 20px;

	&:container(min-width: 340px) {
		display: flex;
        justify-content: space-between;
	}
`;


export const Section = styled.div`
	&:nth-of-type(2) {
		display: none;
	}

	@media(max-width: ${Variables.md}) {
		&:nth-of-type(2) {
			display: block;
		}
	}

	@media(min-width: ${Variables.xlg}) {
		&:nth-of-type(2) {
			display: block;
		}
	}
`;

export const DocumentsListSlide = styled.div`
	display: grid;
`;

export const Container = styled.div`
	padding: 20px 0px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: minmax(30px, max-content);
	grid-row-gap:3px;
`;

export const DocumentWrapper = styled.div`
	display: flex;
	text-decoration: none;
	cursor: pointer;
	justify-content:space-between;
	color: ${Variables.mainTextColor.lightMode};
	border-radius: 6px;
	padding-right:5px;
	&.darkmode {
		color: ${Variables.mainTextColor.darkMode};
	}
	&:hover{
		text-decoration: underline;
	}
	&.popup {
		background : #ebedf0;
	}
	&.darkmode.popup  {
		background: ${Variables.tileBackground.darkMode};
	}
`

export const PDFIcon = styled.div`
	padding:5px; 
	display:flex; 
	align-items:center;
	&:hover > svg {
		color:#636A73;
	}
`