const actions = {
	SET_MULTICHANNEL_NEWS_ITEMS : 'SET_MULTICHANNEL_NEWS_ITEMS',
	GET_MULTICHANNEL_NEWS_ITEMS : 'GET_MULTICHANNEL_NEWS_ITEMS',
	SET_ACTIVE_NEWS_ITEM : 'SET_ACTIVE_NEWS_ITEM',
	NEWS_REQUEST_COMPLETED: 'NEWS_REQUEST_COMPLETED',
	SET_LAST_NEWS_VIEWED: 'SET_LAST_NEWS_VIEWED',
	CLEAR_NEWS_STATE: 'CLEAR_NEWS_STATE',


	getMultichannelNewsItems : ( payload, title ) => ({
		type : actions.GET_MULTICHANNEL_NEWS_ITEMS,
		payload : { title: title, payload: payload } 
	}),
	setActiveNewsItem : ( payload ) => ({
		type : actions.SET_ACTIVE_NEWS_ITEM,
		payload : payload
	}),
	setLastViewed: (payload) => ({
		type: actions.SET_LAST_NEWS_VIEWED,
		payload: payload
    }),
	clearState: () => ({
		type: actions.CLEAR_NEWS_STATE
	}),
};

export default actions;
