import { all, takeEvery,  fork, put, select } from 'redux-saga/effects';
import actions from './actions';
import { getAuth } from '../selectors';

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        //yield put({ type: actions.READY_FOR_BOOT });
	});
}



export function* logout() {
	yield takeEvery(actions.LOG_OUT, function* (action) {
		//yield put({ type: actions.READY_FOR_BOOT });
		const auth = yield select(getAuth);
		auth.authProvider.SignOut();
	});
}

export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(logout)
	]);
}