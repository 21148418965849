import styled from 'styled-components'
import * as Variables from '../../../../ThemeVariables';
export const SearchInput = styled.input`
	width: calc(100% - 50px);
	line-height: 40px;
	font-size: 16px;
	background: transparent;
	float: left;

	&:focus {
		outline : none;
	}
`;

export const SearchWrapper = styled.div`
	width: 100%;
	background: #ebedf0;
	border-radius: 25px;
	overflow: hidden;
	height: 40px;
	margin-bottom: 25px;
`;

export const Magnifier = styled.div`
	width: 50px;
	float: left;
	line-height: ${Variables.headerHeight};
	text-align: center;

`;
