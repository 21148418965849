import actions from './actions';

const initState = {
    notifications: [],
};

export default function notificationCollectorReducer(state = initState, action) {

    switch (action.type) {
        case actions.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        case actions.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications : []
            };
        default:
            return state;
    }
}
