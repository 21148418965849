import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../../user/actions';
import localforage from 'localforage';
import { findAll, dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getWidgets, getGOClient, getGraphClient, getYammerClient, getSharepointClient, getMultiChannelShoutItems, getShoutsRequestCompleted, getUserRequestCompleted, getUserProfileSettingData } from '../../selectors';
import ShoutObject from './shoutObject';
import moment from 'moment/min/moment-with-locales';

export function* getShoutFromChannel() {
    yield takeEvery(actions.GET_MULTICHANNEL_SHOUT_ITEMS, function* (action) {
        // -- Switch here, select shout conditionally.
        yield put({
            type: actions.SHOUT_REQUEST_COMPLETED,
            payload: false
        })
        let newItems = [];
        let currentItems = yield select(getMultiChannelShoutItems);

        const title = action.payload.title;
        let sources = action.payload.payload;

        if (!sources) {
            const widgets = yield select(getWidgets);
            let widget = findAll(widgets, title);
            if (!widget) return;
            sources = widget.sources;
        }

        //parse the different sources configured
        if (sources) {
            for (let source of sources) {

                if (source.source == "sharepoint") {
                    if (source.url.indexOf("?") == -1 && source.parameters != null && source.parameters != '') { //optional configured parameters
                        source.url += source.parameters;
                    }

                    let items = yield call(getSharepointShout, source.site, source.url, source.content, title);
                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }

                    // if (items?.length == 0) {
                    //     items = currentItems.filter(i => i.widget == title);
                    // } else if (items?.length > 0) {
                    //     items = items.map(i => { i.widget = title; return i; });
                    // }

                    // if (items) {
                    //     newItems = [
                    //         ...newItems,
                    //         ...items
                    //     ];
                    // }
                }

                //if (source.source == "rss") {
                //    const items = yield call(getRss, source.url);
                //    if (items) {
                //        shoutItems = [
                //            ...shoutItems,
                //            ...items
                //        ]
                //    }
                //}

                if (source.source == "yammer") {
                    let items = yield call(getYammerShout);
                    if (items?.length == 0) {
                        items = currentItems.filter(i => i.widget == title);
                    } else if (items?.length > 0) {
                        items = items.map(i => { i.widget = title; return i; });
                    }

                    if (items) {
                        newItems = [
                            ...newItems,
                            ...items
                        ];
                    }
                }
            }
        }

        const sortOnDate = ((a, b) => {
            if (a.unix > b.unix) {
                return -1;
            }

            if (a.unix < b.unix) {
                return 1;
            }

            return 0;
        });

        //if (shoutItems != null && shoutItems.length > 0) {

        currentItems = yield select(getMultiChannelShoutItems);
        currentItems = currentItems.filter(i => i.widget == title);

        //sort all new items on date
        newItems.sort(sortOnDate);

        //max items for one widget
        newItems = newItems.splice(0, 30);

        let old = currentItems.filter(n => !newItems.find(nn => nn.id == n.id));
        if (old?.length > 0 && newItems.length > 0) {
            for (let n of old) {
                yield localforage.removeItem(n.id);
            }
        }

        let modified = newItems.filter(data => {
            return currentItems.find(current => {
                if(data.dateModified != current.dateModified && current.type == "sharepoint" && current.id == data.id) {
                    return data
                }
            })
        })
        let keys = yield localforage.keys();
        let localforageKeys = keys.filter(k => k.indexOf('sp_shouts') != -1)?.length;
        let currentItemsKeys = currentItems.filter(i => i.widget == title).length;

        if ((old?.length > 0 && newItems.length > 0) || (modified?.length > 0 && newItems.length > 0) || (currentItems?.length == 0 && newItems?.length > 0) || (currentItems?.length !== newItems?.length && newItems?.length > 0)) {

            //postprocessing..
            newItems = yield call(postprocess, newItems);

            let allItems = yield select(getMultiChannelShoutItems);
            allItems = allItems.filter(i => i.widget != title);
            allItems = allItems.concat(newItems);
            yield put({
                type: actions.SET_MULTICHANNEL_SHOUT_ITEMS,
                payload: allItems
            });
        }

        if(localforageKeys != currentItemsKeys) { 
            currentItems = yield select(getMultiChannelShoutItems);
            currentItems = currentItems.filter(i => i.widget == title);
            currentItems = yield call(postprocess, currentItems);
            let allItems = yield select(getMultiChannelShoutItems);
            allItems = allItems.filter(i => i.widget != title);
            allItems = allItems.concat(currentItems);
            allItems.sort(sortOnDate);

            yield put({
                type: actions.SET_MULTICHANNEL_SHOUT_ITEMS,
                payload: allItems
            });
        }


        //}

        yield put({
            type: actions.SHOUT_REQUEST_COMPLETED,
            payload: true
        });
    })
}

const parseShoutContent = (content) => {
    const urls = /<(.*)>\(“(.*)”\)/g

    content = content.replace(urls, function (match, token) {
        let title = match.match(/<(.*)>/);
        let link = match.match(/\(“(.*)”\)/);
        if (title[1] == null || link[1] == null) return "";
        return ` <a target="_blank" href="" onclick="window.open('${title[1]}', 'popup', 'width=1024,height=700,scrollbars=no,resizable=no'); return false;">${link[1]}</a> `;
    });

    content = content.replace(/(?:\r\n|\r|\n)/g, ' <br />');
    
    return content;
}

function* getYammerShout() {
    //if (!apiURL) {
    //    console.log("Yammer API URL Required");
    //    return;
    //}
    //try {
    const shoutItems = yield select(getMultiChannelShoutItems);
    const Client = yield select(getYammerClient);
    const GoClient = yield select(getGOClient);
    const GraphClient = yield select(getGraphClient);

    if (!Client) return;

    //const shouts = yield Client.api(apiURL);
    let shouts = yield Client.api('https://www.yammer.com/api/v1/messages.json?threaded=true');
    //let test = yield Client.api('/users/1522724127.json');
    let messages = [];

    if (shouts == null || !shouts) return;
    if (shouts.length === 0) return;

    if (shouts != null) {
        let announcements = shouts.references.filter(m => m.announcement === true);
        messages = shouts.messages.filter(m => announcements.find(a => a.id == m.id));

        //messages.takeEvery(m => announcements.find(a => a.id == m.id))
        for (let m of messages) {
            let a = announcements.find(a => a.id == m.id);
            m.reference = a;

            let user = shouts.references.find(r => r.id == m.sender_id);
            m.user = user;

            let topics = shouts.references.find(r => r.id == m.id)?.topics;
            if (topics != null) {
                //m.topics = topics;
                m.topics = [];
                for (let t of topics) {
                    let topic = yield Client.api(`https://www.yammer.com/api/v1/topics/${t.id}.json`);
                    if (topic != null) m.topics.push(topic);
                }
            }
        }
    }

    if (messages.length && ((shoutItems.length === 0) || (shoutItems && shoutItems[0].id !== messages[0].id))) {
        return yield parseYammerShout(messages);
    } else {
        return yield parseYammerShout(messages);
    }
    return false
}

async function parseYammerShout(shout) {
    let parsedShout = [];
    for (let key in shout) {
        if (shout[key]) {
            const item = shout[key];

            let shoutObject = new ShoutObject;
            shoutObject.setAuthor(item.user?.full_name);

            shoutObject.setContent(parseShoutContent(item.body?.rich));
            shoutObject.setDate(item.created_at);
            //shoutObject.setTitle(item.title);
            shoutObject.setUrl(item.web_url);
            shoutObject.setID("" + item.id);
            shoutObject.setType('yammer');

            if (item.topics != null && item.topics) {
                shoutObject.setTopics(item.topics);
            }

            shoutObject.setImageUrl(item.user?.mugshot_url);

            if (shoutObject.getShoutObject()) {
                parsedShout.push(shoutObject.getShoutObject());
            }
        }
    }
    return parsedShout;
}


function* getSharepointShout(site, api, selector, title) {
    if (!api) {
        console.log("Sharepoint API URL Required");
        return;
    }
    let changedItems = [];
    // try {
    let currentItems = yield select(getMultiChannelShoutItems);
    currentItems = currentItems.filter(i => i.widget == title);
    const Client = yield select(getSharepointClient);
    const GoClient = yield select(getGOClient);
    // const GraphClient = yield select(getGraphClient);

    if (!Client) return;

    if (api.indexOf('?') == -1) {
        api += "?$orderBy=Created desc&$top=20";
    }

    const newsResponse = yield Client.api(api);

    if (newsResponse == null || !newsResponse || !newsResponse.value) return;
    if (newsResponse.value.length === 0) return;

    let data = newsResponse.value;
    let newItems = data.filter(d => (currentItems?.length == 0 ? true : (!currentItems?.find(i => i.type == "sharepoint" && i.id == 'sp_shouts_' + d.Id.toString()))));
    let oldItems = currentItems.filter(i => !data.find(d => 'sp_shouts_' + d.Id == i.id) && !newItems.find(n => 'sp_shouts_' + n.Id == i.id));

    //data = data.slice(0, 16);
    if (newItems?.length > 0) {
        newItems = newItems.map(function (n) {
            return { ...n, Id: 'sp_shouts_' + n.Id.toString(), site: site };
        });
    }

    //remove old items
    if (oldItems?.length > 0) {
        currentItems = currentItems.filter(c => !oldItems.find(o => o.id == c.id));
    }
    //Check for modified items
    changedItems = data.filter(data => {
        return currentItems.find(current => {
            if(data.Modified != current.dateModified && current.type == "sharepoint" && current.id == 'sp_shouts_'+ data.Id.toString()) {
                return data
            }
        })
    })

    if (changedItems?.length > 0) {
        changedItems = changedItems.map(function (n) {
            return { ...n, Id: 'sp_shouts_' + n.Id.toString(), site: site };
        });
    }

    //use selectors to get the right data
    let selected = null;
    if (selector) {
        selected = JSON.parse(selector);
        if (!selected) return;
    }

    //how to know.. if the items from.. still valid?


    //parse items to GO items with parsed selectors
    let parsedItems = yield parseSharepointShout(newItems, selected);
    if (parsedItems?.length > 0) {
        parsedItems = parsedItems.map(i => {
        i.widget = title
        return i; })
    }
    //remove old items
    if (oldItems?.length > 0) currentItems = currentItems.filter(c => !oldItems.find(o => o.id == c.Id));

    // //add existing (already parsed) items to array
    // parsedItems = parsedItems.concat(currentItems.filter(i => i.type == "sharepoint" && i.widget == title));
    // parsedItems = parsedItems.slice(0, 20); //max?


    let parsedChangedItems = yield parseSharepointShout(changedItems, selected);
    if (parsedChangedItems?.length > 0) {
        parsedChangedItems = parsedChangedItems.map(i => {
        i.widget = title
        return i; })
    }
    if (parsedChangedItems?.length > 0) {
        currentItems = currentItems.filter(current => !parsedChangedItems.find(parsed => current.type == "sharepoint" && current.id == parsed.id))
    }

    //add existing (already parsed) items to array
   // parsedItems.push({...currentItems[0], Id: 'sp_news_test', id: 'sp_news_test'});
    return [...parsedItems, ...currentItems, ...parsedChangedItems];

    // return parsedItems;
}

function* postprocess(newItems) {
    let tasks = [];

    const SharepointClient = yield select(getSharepointClient);
    let sharepointToken;
    if (SharepointClient) sharepointToken = yield SharepointClient.getToken();

    const GoClient = yield select(getGOClient);
    let goToken = yield GoClient.getToken();


    //task to get images
    tasks = newItems.map(async (item, index) => {

        if (item.type == "sharepoint") {

            //get author based on sharepoint user id
            if (!isNaN(parseInt(item.author))) {
                const sharepointUser = await SharepointClient.api(item.site + '/_api/web/getuserbyid(' + item.author + ')');

                //set auteur
                if (sharepointUser) {
                    if (sharepointUser.Title) {
                        //item.author = sharepointUser.Title;
                        newItems[index].author = sharepointUser.Title;
                    }
                }
            }

            const getResponse = async (item) => {
                if (item.imageUrl == null) return { imageUrl: null };
                if (item.imageUrl == 'parse-from-localForage') return { imageUrl: null }; //no image found in cache 
                if (item.imageUrl.indexOf("sharepoint.com") == -1) return { imageUrl: item.imageUrl }; //do nothing

                let pictureUrl = parsePictureUrl(item.orginalUrl ? item.orginalUrl : item.imageUrl, 3);
                let url = encodeURIComponent(pictureUrl);

                let response = await fetch(`/api/getimage?url=${url}`, {
                    method: 'GET',
                    contentType: 'image/jpeg',
                    headers: {
                        'Authorization': 'Bearer ' + goToken,
                        'AuthorizationProxy': sharepointToken
                    }
                }).catch((err) => { return null; });

                if (!response?.ok) return null;

                const content = await response.text();
                let objectUrl = "";
                let blob;

                try {
                    blob = dataURItoBlob(content);
                } catch (ex) { return null; }

                if (!blob) return null;
                await localforage.setItem(item.id, blob);

                return {
                    imageUrl: 'parse-from-localForage',
                    orginalUrl: item.orginalUrl ? item.orginalUrl : item.imageUrl
                };
            };

            let pictureData = await localforage.getItem(newItems[index].id);
            if (pictureData == null || pictureData == '') pictureData = await getResponse(newItems[index]);
            if (pictureData) newItems[index] = { ...newItems[index], ...pictureData };
        }
    });

    if (tasks.length) {
        yield Promise.all(tasks);
    }

    return newItems;
}



export function* setLastViewedItems() {
    yield takeEvery(actions.SET_LAST_SHOUT_VIEWED, function* (action) {
        if (action.payload == null) return;


        //this.props.actions.setSettingValue(newViewed, 'viewedShouts');

        console.log('set viewed shouts');
        yield put({
            type: userActions.UPDATE_USER_SETTINGS,
            payload: {
                value: action.payload,
                setting: 'viewedShouts'
            }
        });

    });
}


export function* parseLastViewed() {

    const parser = function* () {

        //state.User.profile.data?.settings?.viewedShouts,
        const items = yield select(getMultiChannelShoutItems);
        const data = yield select(getUserProfileSettingData);
        const completed = yield select(getUserRequestCompleted);
        const shoutsCompleted = yield select(getShoutsRequestCompleted);

        if (data == null || !completed || !shoutsCompleted || items?.length == 0) return;

        let viewedItems = data.settings?.viewedShouts;
        if (!viewedItems) {
            viewedItems = [];
        }

        let newViewed = [];

        //items.forEach((item, i) => {
        for (let item of items) {

            if (moment().unix() - item.unix >= 8640000) {
                // -- Item is older than 24 hours.
                item.viewed = true;
            } else {
                item.viewed = false;

                // -- Check if item has already been viewed in viewed
                let viewedItem = viewedItems.find((i) => i.url === item.url);
                if (viewedItem) {
                    if (viewedItem.viewed) {
                        item.viewed = true;
                    }
                }
            }

            if (!viewedItems.find((i) => i.url === item.url)) {
                viewedItems.push({
                    url: item.url,
                    widget: item.widget,
                    viewed: item.viewed
                });
            }
        }

        // -- Cleanup with only present items.
        for (let item of viewedItems) {
            let available = items.find((i) => i.url === item.url);
            if (available) {
                newViewed.push({
                    url: item.url,
                    widget: item.widget,
                    viewed: item.viewed
                })
            }
        }
        if ((newViewed.length == 0 && viewedItems.length == 0) ||
            (JSON.stringify(newViewed.map(n => n.url)) != JSON.stringify(viewedItems.map(n => n.url))) ||
            !data.settings?.viewedShouts) {

            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: newViewed,
                    setting: 'viewedShouts'
                }
            });

        }
    }


    yield takeEvery(userActions.DATA_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getShoutsRequestCompleted);

        if (completed) {
            yield parser();
        }
    });

    yield takeEvery(actions.SHOUT_REQUEST_COMPLETED, function* (action) {
        const completed = yield select(getUserRequestCompleted);

        if (action.payload === true && completed) {
            yield parser();
        }
    });
}


async function parseSharepointShout(shout, selected) {
    let parsedShout = [];
    let content;
    let description;


    for (let key in shout) {
        if (shout[key]) {
            let item = shout[key];
            let content = null;
            let description = null;

            if (selected) {
                if (selected.content?.indexOf("%") != -1) {
                    content = selected.content.replace(/%(.*?)%/g, function (match, token) {
                        if (item[token]) return item[token];
                        return '';
                    });
                }
                if (selected.description?.indexOf("%") != -1) {
                    description = selected.description.replace(/%(.*?)%/g, function (match, token) {
                        if (item[token]) return item[token];
                        return '';
                    });
                }
            }
            item = {
                ...item,
                id: item.Id,
                author: item.AuthorId,
                title: selected?.title ? item[selected.title] : item.Title,
                content: (!content ? item['CanvasContent1'] : content),
                message: (!description ? item['Description'] : description),
                imageUrl: selected?.image ? item[selected.image] : item.BannerImageUrl?.Url,
                url: item.site + (item['Title'] ? "/SitePages/" + item['Title'].replaceAll(" ", "-") + ".aspx" : ""),
                //url: item.site,
                date: selected?.date ? item[selected.date] : item.Created,
                dateModifed: item?.Modified ? item.Modified : item.Created
            };

            if (item.imageUrl && typeof (item.imageUrl) === 'object' && item.imageUrl?.Url) item.imageUrl = item.imageUrl.Url;

            let shoutObject = new ShoutObject;
            shoutObject.setAuthor(item.author);
            shoutObject.setContent(parseSharePointContent(item.content, item.url));
            shoutObject.setDate(item.date);
            shoutObject.setUrl(item.url);
            shoutObject.setTitle(item.title);
            shoutObject.setDescription(item.message);
            shoutObject.setID(item.id);
            shoutObject.setType('sharepoint');
            shoutObject.setSite(item.site);
            shoutObject.setDateModified(item.dateModifed)

            if (item.imageUrl == null) {
                shoutObject.setImageUrl('parse-from-localForage');
            } else {
                shoutObject.setImageUrl(item.imageUrl);
            }

            if (shoutObject.getShoutObject()) {
                parsedShout.push(shoutObject.getShoutObject());
            }
        }
    }
    return parsedShout;
}


function parseSharePointContent(content, url) {
    if (content == null) return;
    const urls = /href=\"\//g
    const src = /src=\"\//g;
    const ids = /<div data-sp-componentid=\"\">[a-z,0-9,-]+<\/div>/g;
    const video = /<a data-sp-prop-name=\"videoSource\" href=\"(.*)\"><\/a>/g;
    const videoThumb = /<img data-sp-prop-name=\"streamVideoThumbnail\.serverRelativeUrl\" src=\"[^>]*\" \/>/g;

    content = content.replace(urls, function (match, token) {
        return 'target="_blank" href="' + parseSharepointUrl(url) + '/';
    });

    content = content.replace(src, function (match, token) {
        return 'src="' + parseSharepointUrl(url) + '/';
    });

    content = content.replace(videoThumb, "");

    content = content.replace(ids, "");

    let test;
    while (test = video.exec(content)) {
        let embed = test[1].replace("/video/", "/embed/video/");
        if (test[1]) content = content.replace(test[0], `<div style='max-width: 640px'><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe width="640" height="360" src="${decodeURI(embed)}?autoplay=false&showinfo=true" allowfullscreen style="border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;"></iframe></div></div>`)
    }

    return content;
}


export default function* rootSaga() {
    yield all([
        fork(getShoutFromChannel),
        fork(parseLastViewed),
        fork(setLastViewedItems)
    ]);
};
