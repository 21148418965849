import styled from 'styled-components';

export const PageLoader = styled.div`
	position: fixed;
	height: 100%;
	background-color: rgba(255, 255, 255, .5);
	width: 100%;
	top: 0;
	left: 0;

	&.darkmode {
		background-color: rgba(0, 0, 0, .5);
	}
`;
