import actions from './actions';

const initState = {
	blogs : [],
};


export default function blogReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_BLOGS:
			return {
				...state,
				blogs : action.payload
			}
		default:
			return state;
	}
}
