import actions from './actions';

const initState = { 
	birthdays: [],
	upcoming: []	
};


export default function birthdayReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_BIRTHDAYS:
			return {
				...state,
				birthdays : action.payload
			}
		case actions.SET_BIRTHDAYS_AVATARS:
			return {
				...state,
				birthdays: action.payload
			}
		case actions.SET_UPCOMING_BIRTHDAYS:
			return {
				...state,
				upcoming: action.payload
			}
		default:
			return state;
	}
}
