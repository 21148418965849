import React, { Component } from 'react';

import DarkmodeSlide from '../../slideTypes/darkmodeSlide';
import ListSlide from '../../slideTypes/listSlide';
import VideoSlide from '../../slideTypes//videoSlide';
import SettingSlide from '../../slideTypes/settingSlide';
import ContentSlide from '../../slideTypes/contentSlide';
import Slide from '../../slider/slide';
import Slider from '../../slider';


class Slides extends Component {

    render() {
        return (
            <Slider>
                <Slide next>
                    <ContentSlide
                        title={`Store`}
                        description={"Welkom op het GO platform! <b>GO</b> is de centrale plek waarop productiviteit, organisatie en IT samenkomt. Je applicaties, belangrijke berichten en slimme hulp zijn altijd, overal en direct voor je beschikbaar. <img style='width:100px;padding:14px;border-radius:20px;display:block;margin:0 auto;margin-top:20px;background:#f10000;' src='https://gocnd.azureedge.net/gowebsite/Go.svg' />"}
                    />
                </Slide>

                <Slide next prev>
                    <ContentSlide
                        title={`GoGo, de slimme hulp`}
                        description={"Heb je een vraag over videovergaderen in Teams of werkt je laptop niet? Stel deze aan GoGo, de slimme hulp! Hij zoekt voor je in de kennisbanken en schakelt wanneer nodig de servicedesk in. <img style='width:50px;display:block;margin:0 auto;margin-top:20px;' src='https://gocnd.azureedge.net/gowebsite/Isaac_bot_GO-01.svg' />"}
                    />
                </Slide>
                <Slide next prev>
                    <DarkmodeSlide />

                </Slide>

                <Slide next prev>
                    <SettingSlide
                        title="Heb je office lokaal geïnstalleerd?"
                        description="Als je Office op je PC hebt geïnstalleerd, kunnen we applicaties direct vanuit de browser voor je openen."
                        trueVal="Ja"
                        falseVal="Nee"
                        setting="startLocalApps"
                    />
                </Slide>

                <Slide prev close>
                    <SettingSlide
                        title="Openen in popup of nieuw tabblad?"
                        description="Applicaties die niet op je PC geïnstalleerd staan openen in een popup of nieuw tabblad?"
                        trueVal="Nieuw tabblad"
                        falseVal="Popup"
                        setting="startAppsInNewWindow"
                    />
                </Slide>

            </Slider>
        );
    }
}

export default Slides;
