import moment from 'moment';

class SocialObject {
    constructor() {
        this._socialItem = {
            author : false,
            unix : false,
            moment : false,
            title : "",
            imageUrl : false,
            url : false,
            id : false,
            type: false,
            likes : 0,
            openDirect: false,
            topics: false
        }
    }

    setID = (ID) => {
        this._socialItem = {
            ...this._socialItem,
            id : ID
        }
    }

    setOpenDirect = (bool) => {
        this._socialItem = {
            ...this._socialItem,
            openDirect : bool
        }
    }

    setLikes = (int) => {
        this._socialItem = {
            ...this._socialItem,
            likes : int
        }
    }

    setAuthor = (author) => {
        this._socialItem = {
            ...this._socialItem,
            author : author
        }
    }

    setTopics = (topics) => {
        this._socialItem = {
            ...this._socialItem,
            topics: topics
        }
    }

    setContent = (content) => {
        this._socialItem = {
            ...this._socialItem,
            content : content
        }
    }
    setTimestamp = (timestamp) => {
        this._socialItem = {
            ...this._socialItem,
            unix : timestamp,
            moment: moment.unix(timestamp),
        }
    }

    setTitle = (title) => {
        this._socialItem = {
            ...this._socialItem,
            title : title
        }
    }

    setType = (type) => {
        this._socialItem = {
            ...this._socialItem,
            type : type
        }
    }

    setImageUrl = (imageUrl) => {
        this._socialItem = {
            ...this._socialItem,
            imageUrl : imageUrl
        }
    }

    setUrl = (URL) => {
        this._socialItem = {
            ...this._socialItem,
            url : URL
        }
    }

    getSocialObject = () => {

        return this._socialItem;
    }
}

export default SocialObject;
