const actions = {
    GET_FAQS: 'GET_FAQS',
    SET_FAQS: 'SET_FAQS',
    FAQS_REQUEST_COMPLETED: 'FAQS_REQUEST_COMPLETED',
    GET_KNOWLEDGEBASES: 'GET_KNOWLEDGEBASES',
    SET_KNOWLEDGEBASES: 'SET_KNOWLEDGEBASES',
    KNOWLEDGEBASES_REQUEST_COMPLETED: 'KNOWLEDGEBASES_REQUEST_COMPLETED',

    getFAQs: (payload) => ({
        type: actions.GET_FAQS,
        payload: payload
    }),

	getKnowledgeBases: (payload) => ({
        type: actions.GET_KNOWLEDGEBASES,
        payload: payload
    })
};

export default actions;
