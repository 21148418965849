import styled, { css } from 'styled-components'
import CircleIcon  from '../../../styled/CircleIcon';
import * as Variables from '../../../../ThemeVariables';

export const Wrapper = styled.div`
	svg { 
		font-size: 20px;
		color: grey;
		position: absolute;
		bottom: 6px;
		right: 0;
		z-index: 1000;
	}

	svg path { 
	  stroke: white;
	  stroke-width: 30px;
	}
`;


export const Avatar = styled(CircleIcon) `

	height: ${props => props.large ? "100px" : ""};
	width: ${props => props.large ? "100px" : ""};
	${props => props.gridArea ? 'grid-area:' + props.gridArea : ''};
	transform: rotate(0);
	transition: box-shadow .5s, transform .5s;
	&:hover {
		${props => props.large ? 'transform: rotate(-5deg)' : 'transform: rotate(-5deg);box-shadow: 0 0 5px black;'};
	}
	img {
		width: 100%;
		height: 100%;
	}

	${props => props.parent === 'header-col-right' && css`
		display: block;
		@media ( min-width: ${Variables.md}) {
			display: block;
		}

	`}



`;
