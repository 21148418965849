import { all, fork, takeEvery, select, put } from 'redux-saga/effects';
import actions from './actions';
import slideOutActions from '../slideOut/actions';
import { getSharepointClient } from '../selectors';
import { parseSharepointUrl } from '../../helpers/functions';

export function* setActiveEvent() {
    yield takeEvery(actions.SET_ACTIVE_EVENT, function* (action) {
        yield put({
            type: slideOutActions.SET_ACTIVE_SLIDEOUT,
            payload: 'event'
        });
    });
}

export function* getEvents() {
    yield takeEvery(actions.GET_EVENTS, function* (action) {
        try {
            yield put({
                type: actions.EVENTS_REQUEST_COMPLETED,
                payload: false
            })

            const Client = yield select(getSharepointClient);
            if (!Client) return;

            const eventResponse = yield Client.api(action.payload);

            if (eventResponse == null || !eventResponse || !eventResponse.value) return;
            if (eventResponse.value.length === 0) return;

            let root = "";
            if(eventResponse['odata.metadata']) {
                root = parseSharepointUrl(eventResponse['odata.metadata']);
            }
            let data = eventResponse.value;
            let events = [];
            let tasks = [];

            tasks = data.map(async (d, index) => {
                
                let parsedDescription;
                if(root !== "") { 
                    parsedDescription = parseDescription(d.Description, root)
                } else {
                    parsedDescription = d.Description;
                }
                if (!d.BannerUrl) {
                    events.push({
                        id: index,
                        title: d.Title,
                        description: parsedDescription,
                        picture: 'https://placehold.it/500/500',
                        date: d.Created,
                        allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                        externalUrl: d.Workspace
                    });
                }
                else {
                    let url = decodeURIComponent(d.BannerUrl.Url);

                    if (url) {
                        events.push({
                            id: index,
                            title: d.Title,
                            description: parsedDescription,
                            picture: url,
                            allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                            externalUrl: d.Workspace,
                            date: d.Created
                        });
                        return url;
                    }
                }

            });
        /*    const date = new Date();

            for (var i = 0; i < 4; i++) {
                events.unshift({
                    id: Math.random(),
                    title: "test evenementen",
                    description: "tijdens deze sessie gaan jullie leren om",
                    picture: 'https://placehold.it/500/500',
                    date: date.setDate(date.getDate() + 1),
                    allEventData: { Location: "Rotterdam", EventDate: date.setDate(date.getDate() + 1), EndDate: date.setDate(date.getDate() + 1) },
                    externalUrl: "https://www.google.nl"
                });
            }*/

            if (tasks.length) {
                yield Promise.all(tasks);
            }

            //if (events.length) {
                yield put({
                    type: actions.SET_EVENTS,
                    payload: events.reverse()
                });

                yield put({
                    type: actions.EVENTS_REQUEST_COMPLETED,
                    payload: true
                })
            //}
        } catch (error) { console.log(error); }
    });
}


function parseDescription(description, root) {
    if (!description) return '';
    const anchorContent = /<a.[^>]*?href=\"\/.*\".*?>.*?<\/a>/g;
    const urls = /href=\"(\/.*?)\"/;
    let anchorContentMatch = [];
    let urlsMatch = [];
    anchorContentMatch = description.match(anchorContent);
    if(anchorContentMatch) {
        for(let i = 0; i < anchorContentMatch.length; i++) {
            urlsMatch = anchorContentMatch[i].match(urls);
            if(urlsMatch) {
                description = description.replace(urls, function (match, token) {
                    return 'target="_blank" href="' + root + urlsMatch[1] + '"';
                });
            }
        }

    }

 return description;
}

export default function* rootSaga() {
    yield all([
        fork(getEvents),
        fork(setActiveEvent)
    ]);
}
