import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import moment from 'moment';
import { getGOClient, getGraphClient, getAllBirthdays, birthdaysAlreadyFetching } from '../selectors';
import { Buffer } from 'buffer';
import birthdays from '../../components/widget/birthdays';


export function* getBirthdays() {
    yield takeEvery(actions.GET_BIRTHDAYS, function* (action) {

        yield put({
            type: actions.SET_BIRTHDAYS,
            payload: []
        });

        const Client = yield select(getGraphClient);
        const GoClient = yield select(getGOClient);

        if (!Client || !GoClient) return;

        const birthdays = yield GoClient.api(action.payload);
        if (birthdays == null || !birthdays) return;

        let parsedBirthdays = [];
        let parsedBirthdaysToday = [];
        let parsedBirthdaysTomorrow = [];
        let parsedBirthdaysUpcoming = [];
        const today = moment();
        const tomorrow = moment(new Date()).add(1, 'days');

        if (birthdays) {
            for (let i = 0; i <= birthdays.length; i++) {
                if (birthdays[i]) {
                    let birthday = birthdays[i];
                    const birthdate = moment(birthday.date);

                    if (today.format('D-M') === birthdate.format('D-M')) {
                        let parsedUserPhoto = yield fetchUserPhoto(Client, birthday.id);
                        birthday = {
                            ...birthday,
                            avatar: parsedUserPhoto,
                            when: 'Vandaag'
                        };

                        parsedBirthdaysToday.push(birthday);
                    } else if (tomorrow.format('D-M') === birthdate.format('D-M')) {
                        let parsedUserPhoto = yield fetchUserPhoto(Client, birthday.id);
                        birthday = {
                            ...birthday,
                            avatar: parsedUserPhoto,
                            when: 'Morgen'
                        };

                        parsedBirthdaysTomorrow.push(birthday);
                    } else if (parseInt(birthdate.format('YYYY')) > 1920) {

                        parsedBirthdays.push({
                            ...birthday,
                            avatar: null,
                            when: 'Upcoming',
                            day: birthdate
                        });
                    }
                }
            }

            // parsedBirthdays = parsedBirthdaysToday;
     

            parsedBirthdays = parsedBirthdaysTomorrow.concat(parsedBirthdays);
            parsedBirthdays = parsedBirthdaysToday.concat(parsedBirthdays);

            if (parsedBirthdaysTomorrow.length > 0) {
                parsedBirthdaysUpcoming = parsedBirthdaysTomorrow.concat(parsedBirthdaysUpcoming);
            }
            if (parsedBirthdaysToday.length > 0) {
                parsedBirthdaysUpcoming =  parsedBirthdaysToday.concat(parsedBirthdaysUpcoming);
            }

            
            yield put({
                type: actions.SET_BIRTHDAYS,
                payload: parsedBirthdays
            });

            yield put({
                type: actions.SET_UPCOMING_BIRTHDAYS,
                payload: parsedBirthdaysUpcoming
            });

        }
    });
}

function* fetchUserPhoto(Client, id) {
    try {
        // -- Fetch user avatars
        const userPhoto = yield Client.api('/users/' + id + '/photo/$value', 'arrayBuffer');
        let parsedUserPhoto = null;
        if (userPhoto !== null) parsedUserPhoto = 'data:image/png;base64, ' + new Buffer.from(userPhoto, 'binary').toString('base64');

        return parsedUserPhoto;

    } catch (error) {
        console.error(error);
    }
}


export function* getAvatar() {
    yield takeEvery(actions.GET_PERSON_AVATAR, function* (action) {
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;
        const avatar = yield GraphClient.api(`/users/${action.payload}/photos/48x48/$value`, 'arrayBuffer');


        let image = 'none';
        if (avatar) {
            image = 'data:image/png;base64, ' + new Buffer(avatar, 'binary').toString('base64');
        }

        let birthdays = yield select(getAllBirthdays);

        let personIndex = birthdays?.findIndex((item) => item.id === action.payload);
        if (personIndex != null && personIndex != -1) {
            birthdays[personIndex].avatar = image;

            yield put({
                type: actions.SET_BIRTHDAYS_AVATARS,
                payload: birthdays
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getBirthdays),
        fork(getAvatar)
    ]);
}
