import React, { Component, Suspense, useEffect, useState } from 'react';
import App from './App';
import buildMsalConfig from './msalConfig';
import AppStatus from './components/appStatus';
import AuthService from './AuthService';
import { connect } from 'react-redux';
import * as Styled from './styled';
import { Offline, Online } from "react-detect-offline";
import { GlobalStyle } from './GlobalStyle';

const AppWrapper = (props) => {
    const [authService, setAuthService] = useState(false);
    const [error, setError] = useState(false);
    const [isAppOnline, setIsAppOnline] = useState(window.applicationOnline);

    const _handleAuthentication = async () => {
        let config = buildMsalConfig();
        let authService = new AuthService(config);
        authService.HandlePageLoadEvent().then(() => {
            setAuthService(authService);
            setError(false);

        }).catch((error) => {
            setAuthService(authService);
            setError(error.stack);
        });
    }


    useEffect(() => {

        if (isAppOnline && !authService) {
            _handleAuthentication();
        }

        document.querySelector('title').innerHTML = window.tenantConfig.title;
        if (window.tenantConfig.favicon) {
            document.querySelector('link[rel="shortcut icon"]').setAttribute('href', window.tenantConfig.favicon);
        }
        // -- Unmount
        return () => {

        }
    }, [isAppOnline])

    const _setAppOnlineState = (bool) => {

        if (bool && !window.applicationOnline) {
            window.applicationOnline = bool
        }

        setIsAppOnline(bool);
    }

    if (authService) {
        return (
            <React.Fragment>
                <GlobalStyle />
                <App authService={authService} failure={error} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <GlobalStyle />

            <Offline onChange={(status) => _setAppOnlineState(status)} polling={{ url: "https://ipv4.icanhazip.com" }}>
                <Styled.Loader className={'loader loader--visible'}>
                    <Styled.LoaderText>Internetverbinding verloren</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Styled.Loader>
            </Offline>
            <Online onChange={(status) => _setAppOnlineState(status)} polling={{ url: "https://ipv4.icanhazip.com" }}>
                <Styled.Loader className={'loader loader--visible'}>
                    <Styled.LoaderText>Bezig met aanmelden</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Styled.Loader>
            </Online>
        </React.Fragment>
    )

}


export default AppWrapper;
