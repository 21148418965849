import styled from 'styled-components'
import * as Variables from '../../../../../ThemeVariables';

export const SlideTitle = styled.h1 `
  font-size: 18px;
  margin-top: 5px;
  font-weight: 700;
  margin-bottom: 25px;
`;
export const List = styled.ul`
 list-style: none;
`;

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 20px;
  img {
    width: 100%;
  }
  margin-bottom: 15px;
`;

export const ListItemContent = styled.div`
  h3, p {
    font-size: 14px;
  }
`;

export const ListItemImage = styled.div`
  color: ${Variables.mainColor};
  font-size: 25px;
`;
