import actions from './actions';

const initState = {
	items : [],
	activeItem : false,
	requestCompleted : false
};


export default function commonCallsReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_CALLS:
			return {
				...state,
				items : action.payload
			}
		case actions.CALLS_REQUEST_COMPLETD:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_ACTIVE_CALLS_ITEM:
			return {
				...state,
				activeItem : action.payload
			}
		default:
			return state;
	}
}
