import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';


export function* setMessage() {
    yield takeEvery(actions.MESSAGE, function* (action) {
        // -- Possibly intercept message before passing on to reducer
        yield put({
            type : actions.ADD_MESSAGE,
            payload: {
    			message : action.payload.message,
    			type : action.payload.type
    		}
        })
    });
}


export default function* rootSaga() {
    yield all([
        fork(setMessage)
    ]);
}
