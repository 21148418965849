import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from './styled';
import { bindActionCreators } from 'redux';
import dark from '../../../../../assets/img/dark.svg';
import light from '../../../../../assets/img/light.svg';
import Checkbox from '../../../checkbox';
import layoutActions from '../../../../../redux/layout/actions';
import userActions from '../../../../../redux/user/actions';

class SettingSlide extends Component {

    componentDidMount() {
        if (this.props.defaultVal != null) {
            this.props.actions.setSettingValue(this.props.defaultVal, this.props.setting);
        }
    }

    _settingChanged(value, type) {
        // -- Maybe normalize values
        this.props.actions.setSettingValue(value, type)
    }

    render() {

        return (
            <React.Fragment>
                <Styled.SlideTitle>{this.props.title}</Styled.SlideTitle>
                <Styled.SlideDescription dangerouslySetInnerHTML={{ __html: this.props.description }} />
                <Styled.SelectCheckboxColumn className={(this.props.darkmode ? 'darkmode' : '')}>
                    <p>{this.props.falseVal}</p>
                    <Checkbox onChange={(e) => this._settingChanged(e.target.checked, this.props.setting)} checked={(this.props.settings?.[this.props.setting] ? 'checked' : '')} />
                    <p>{this.props.trueVal}</p>
                </Styled.SelectCheckboxColumn>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.User.profile,
        settings: state.User.profile.data?.settings
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...layoutActions,
        ...userActions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingSlide);
