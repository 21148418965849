import actions from './actions';

const initState = {
	socialItems : [],
	activeSocialItem : false,
	requestCompleted : false
};

export default function multiChannelSocialReducer(state = initState, action) {
	switch (action.type) {
		case actions.SOCIAL_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_MULTICHANNEL_SOCIAL_ITEMS:
			return {
				...state,
				socialItems : action.payload
			}
		case actions.SET_ACTIVE_SOCIAL_ITEM:
            return {
                ...state,
                activeSocialItem: action.payload
            }
		default:
			return state;
	}
}
