const actions = {
    GET_USER_PROFILE: 'GET_USER_PROFILE',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    SET_USER_AVATAR: 'SET_USER_AVATAR',
    SET_DAYS_LEFT: 'SET_DAYS_LEFT',
    GET_MESSAGES: 'GET_MESSAGES',
    SET_MESSAGES: 'SET_MESSAGES',
    GET_USER_APPLICATIONS: 'GET_USER_APPLICATIONS',
    SET_USER_APPLICATIONS: 'SET_USER_APPLICATIONS',
    GET_USER_TASKS: 'GET_USER_TASKS',
    SET_USER_TASKS: 'SET_USER_TASKS',
    GET_USER_CALENDARITEMS: 'GET_USER_CALENDARITEMS',
    SET_USER_CALENDARITEMS: 'SET_USER_CALENDARITEMS',
    SET_VIEWED_TUTORIAL: 'SET_VIEWED_TUTORIAL',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_DEBUG_DATA: 'SET_DEBUG_DATA',
    ADD_DEBUG_DATA: 'ADD_DEBUG_DATA',
    SET_LAST_DEBUG: 'SET_LAST_DEBUG',
    GET_USER_DATA: 'GET_USER_DATA',
    DATA_REQUEST_COMPLETED: 'DATA_REQUEST_COMPLETED',
    GET_APP_ROLES : 'GET_APP_ROLES',
    UPDATE_USER_SETTINGS : 'UPDATE_USER_SETTINGS',
    SET_USER_VIEWED_CALL : 'SET_USER_VIEWED_CALL',
    REMOVE_USER_VIEWED_CALL : 'REMOVE_USER_VIEWED_CALL',
    HIDE_FEATURE_SLIDER : 'HIDE_FEATURE_SLIDER',
    SET_FEATURE_SLIDERS : 'SET_FEATURE_SLIDERS',
    SET_FEATURE_SLIDER_VISIBLE : 'SET_FEATURE_SLIDER_VISIBLE',

    getMessages: () => ({
        type: actions.GET_MESSAGES
    }),


    getUserProfile: () => ({
        type: actions.GET_USER_PROFILE
    }),

    setUserViewedCall : (callID) => ({
        type : actions.SET_USER_VIEWED_CALL,
        payload : callID
    }),

    removeUserViewedCall : callID => ({
        type : actions.REMOVE_USER_VIEWED_CALL,
        payload : callID
    }),

    setSettingValue : (value, setting) => ({
        type : actions.UPDATE_USER_SETTINGS,
        payload : {
            value : value,
            setting : setting
        }
    }),

    getUserTasks: () => ({
        type: actions.GET_USER_TASKS
    }),
    getUserCalendarItems: () => ({
        type: actions.GET_USER_CALENDARITEMS
    }),
    getUserApplications: () => ({
        type: actions.GET_USER_APPLICATIONS
    }),

    setUserData: (payload) => ({
        type: actions.SET_USER_DATA,
        payload: payload
    }),

    addDebugData: (payload) => ({
        type: actions.SET_DEBUG_DATA,
        payload: payload
    }),

    setViewedTutorial : (payload) => ({
        type: actions.SET_VIEWED_TUTORIAL,
        payload : payload
    }),

    setTutorialVisible : (payload) => ({
        type: actions.SET_TUTORIAL_VISIBLE,
        payload : payload
    }),


    setFeatureSliderVisible : (link, visible) => ({
        type: actions.SET_FEATURE_SLIDER_VISIBLE,
        payload : {
            link,
            visible
        }
    }),
};

export default actions;
