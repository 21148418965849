import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import localForage from "localforage";
import { routerMiddleware } from 'react-router-redux';
const createHistory = require("history").createBrowserHistory;
const history = createHistory();
const routersMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routersMiddleware];

//import { IntlActions } from 'react-redux-multilingual'
//import translations from './translations'
//import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

const persistConfig = {
    key: 'root',
    storage: localForage,
    blacklist: ['Notifications', 'Client','Widgets', 'Auth', 'Message', 'SlideOut', 'Popup', 'YearOverview', 'Sharepoint']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(initialState) {
    const store = createStore(
        persistedReducer,
        compose(applyMiddleware(...middlewares)));

    let persistor = persistStore(store);
    if(window.clearPersist) {
        persistor.purge();
    }
    sagaMiddleware.run(rootSaga);

    return { store, persistor, history };
}


export default configureStore();
