import sharepoint from "../../../components/widget/sharepoint";

const actions = {
	GET_SHAREPOINT : 'GET_SHAREPOINT',
    SET_SHAREPOINT : 'SET_SHAREPOINT',
	FETCH_SHAREPOINT_SITES : 'FETCH_SHAREPOINT_SITES',
	FETCH_SHAREPOINT_DRIVE : 'FETCH_SHAREPOINT_DRIVE',
	SET_SHAREPOINT_DRIVE : 'SET_SHAREPOINT_DRIVE',
	FETCH_SHAREPOINT_DRIVE_CHILDREN : 'FETCH_SHAREPOINT_DRIVE_CHILDREN',
	SET_SHAREPOINT_DRIVE_CHILDREN : 'SET_SHAREPOINT_DRIVE_CHILDREN',
	FETCH_SHAREPOINT_FOLDER : 'FETCH_SHAREPOINT_FOLDER',
	SET_SHAREPOINT_FOLDER : 'SET_SHAREPOINT_FOLDER',
	CLEAR_SHAREPOINT : 'CLEAR_SHAREPOINT',
	CLEAR_SHAREPOINT_DRIVE : 'CLEAR_SHAREPOINT_DRIVE',
	CLEAR_SHAREPOINT_DRIVE_CHILDREN : 'CLEAR_SHAREPOINT_DRIVE_CHILDREN',
	CLEAR_SHAREPOINT_FOLDER : 'CLEAR_SHAREPOINT_FOLDER',
	SET_IS_LOADING : 'SET_IS_LOADING',
	FILE_FROM_TEMPLATE: 'FILE_FROM_TEMPLATE',

    getSharepointSites: () => ({
        type: actions.GET_SHAREPOINT
    }),

	fetchSharepointSites: (search) => ({
		type : actions.FETCH_SHAREPOINT_SITES,
		payload : search
	}),

	fetchSharepointDrive: (sharepointItem) => ({
		type : actions.FETCH_SHAREPOINT_DRIVE,
		payload : sharepointItem
	}),

	fetchSharepointDriveChildren: (sharepointItem) => ({
		type : actions.FETCH_SHAREPOINT_DRIVE_CHILDREN,
		payload : sharepointItem
	}),

	fetchSharepointFolder: (sharepointItem) => ({
		type : actions.FETCH_SHAREPOINT_FOLDER,
		payload : sharepointItem
	}),

	clearSharepoint: (payload) => ({
		type : actions.CLEAR_SHAREPOINT,
		payload : payload
	}),

	fileFromTeplate: (payload) => ({
		type : actions.FILE_FROM_TEMPLATE,
		payload : payload
	}),

	setSharepointDriveChildren: (payload) => ({
		type : actions.SET_SHAREPOINT_DRIVE_CHILDREN,
		payload : payload
	})
};

export default actions;
