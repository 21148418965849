const actions = {
	GET_SELF_WORKPLACE : 'GET_SELF_WORKPLACE',
	SET_SELF_WORKPLACE : 'SET_SELF_WORKPLACE',
	SET_ACTIVE_WORKPLACE : 'SET_ACTIVE_WORKPLACE',
	POST_SELF_WORKPLACE : 'POST_SELF_WORKPLACE',
	GET_TENANT_WORKPLACE : 'GET_TENANT_WORKPLACE',
	SET_TENANT_WORKPLACE : 'SET_TENANT_WORKPLACE',
	GET_WORKPLACE_FROM_CHANNEL : 'GET_WORKPLACE_FROM_CHANNEL',
	DELETE_SELF_WORKPLACE : 'DELETE_SELF_WORKPLACE',
	TEST_SELF_WORKPLACE : 'TEST_SELF_WORKPLACE',
	SELF_WORKPLACE_REQUEST_COMPLETED: 'SELF_WORKPLACE_REQUEST_COMPLETED',

	POST_WORKPLACE_REQUEST : 'POST_WORKPLACE_REQUEST',
	SET_WORKPLACE_REQUEST : 'SET_WORKPLACE_REQUEST',
	GET_WORKPLACE_REQUEST : 'GET_WORKPLACE_REQUEST',
	DELETE_WORKPLACE_REQUEST : 'DELETE_WORKPLACE_REQUEST',
	UPDATE_WORKPLACE_REQUEST : 'UPDATE_WORKPLACE_REQUEST',

	postWorkplaceRequest : (payload) => ({
		type : actions.POST_WORKPLACE_REQUEST,
		payload : payload
	}),

	setWorkplaceRequest : (payload) => ({
		type : actions.SET_WORKPLACE_REQUEST,
		payload : payload
	}),

	getWorkplaceRequest : () => ({
		type : actions.GET_WORKPLACE_REQUEST,
		payload : payload
	}),

	deleteWorkplaceRequest : (payload) => ({
		type : actions.DELETE_WORKPLACE_REQUEST,
		payload : payload
	}),

	updateWorkplaceRequest : (payload) => ({
		type : actions.UPDATE_WORKPLACE_REQUEST,
		payload : payload
	}),

	// getTenantNews : () => ({
	// 	type : actions.GET_TENANT_WORKPLACE
	// }),

	// getCommonNews : ( payload ) => ({
	// 	type : actions.GET_SELF_WORKPLACE,
	// 	payload : payload
	// }),

	// setActiveNewsItem : ( payload ) => ({
	// 	type : actions.SET_ACTIVE_WORKPLACE,
	// 	payload : payload
	// }),

	// deleteItem : (payload) => ({
	// 	type : actions.DELETE_SELF_WORKPLACE,
	// 	payload : payload
	// }),
	// setWorkplace : (payload) => ({
	// 	type : actions.SET_SELF_WORKPLACE,
	// 	payload : payload
	// })
};

export default actions;
