import actions from './actions';

const initState = {
    apps: {},
    appStore: null
};

export default function appsReducer(state = initState, action) {

    switch (action.type) {
        case actions.SET_APPS:
            return {
                ...state,
                apps: {
                    ...state.apps,
                    ...action.payload
                }
            };
        case actions.SET_LIQUIT_APPS:
            return {
                ...state,
                appStore: action.payload
            }
        default:
            return state;
    }
}
