import actions from './actions';

const initState = {
	searchbarActive : false,
	searchTerm : ''
};

export default function searchReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_SEARCH_TERM:
			return {
				...state,
				searchTerm : action.payload
			}
        case actions.TOGGLE_SEARCHBAR_ACTIVE:
            return {
            	...state,
            	searchbarActive: !state.searchbarActive
            };
		default:
			return state;
	}
}
