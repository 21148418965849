const actions = {
	GET_CALLS : 'GET_CALLS',
	SET_CALLS : 'SET_CALLS',
	SET_ACTIVE_CALLS_ITEM : 'SET_ACTIVE_CALLS_ITEM',
	CALLS_REQUEST_COMPLETD : 'CALLS_REQUEST_COMPLETD',
	getCalls : ( payload ) => ({
		type : actions.GET_CALLS,
		payload : payload
	}),

	setActiveCallsItem : ( payload ) => ({
		type : actions.SET_ACTIVE_CALLS_ITEM,
		payload : payload
	})
};

export default actions;
