import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withTranslate } from 'react-redux-multilingual';

import actions from '../../../redux/message/actions';

class Notifier extends Component {

	render() {
		if(this.props.message){
			if ( this.props.message.message && this.props.message.type ) {
				toast(this.props.message.message, {type: this.props.message.type, autoClose: 1000*60*60*24});
			}
		}
      	return(
      		<ToastContainer />
      	);
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        message: state.Message
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(Notifier))
