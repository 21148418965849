import moment from 'moment';

class ShoutObject {
    constructor() {
        this._shoutItem = {
            author : false,
            content : false,
            unix : false,
            moment : false,
            title : "",
            imageUrl : false,
            url : false,
            id : false,
            type: false,
            tenantId: false,
            description: false,
            topics: false,
            site: false,
            dateModified: false,
        }
    }

    setID = (ID) => {
        this._shoutItem = {
            ...this._shoutItem,
            id : ID
        }
    }

    setAuthor = (author) => {
        this._shoutItem = {
            ...this._shoutItem,
            author : author
        }
    }

    setContent = (content) => {
        this._shoutItem = {
            ...this._shoutItem,
            content : content
        }
    }

    setSite = (site) => {
        this._shoutItem = {
            ...this._shoutItem,
            site: site
        }
    }

    setDescription = (description) => {
        this._shoutItem = {
            ...this._shoutItem,
            description: description
        }
    }

    setDate = (date, format = false) => {
        const parsedDate = moment(date, format);
        this._shoutItem = {
            ...this._shoutItem,
            unix : parsedDate.unix(),
            moment: parsedDate,
            date: date
        }
    }

    setTitle = (title) => {
        this._shoutItem = {
            ...this._shoutItem,
            title : title
        }
    }

    setType = (type) => {
        this._shoutItem = {
            ...this._shoutItem,
            type : type
        }
    }


    setTopics = (topics) => {
        this._shoutItem = {
            ...this._shoutItem,
            topics: topics
        }
    }

    setImageUrl = (imageUrl) => {
        this._shoutItem = {
            ...this._shoutItem,
            imageUrl : imageUrl
        }
    }

    setTenantId = (tenantId) => {
        this._shoutItem = {
            ...this._shoutItem,
            tenantId: tenantId
        }
    }

    setUrl = (URL) => {
        this._shoutItem = {
            ...this._shoutItem,
            url : URL
        }
    }

    setDateModified = (date) => {
        this._shoutItem = {
            ...this._shoutItem,
            dateModified : date
        }
    }

    getShoutObject = () => {
        //for ( const property in this._shoutItem ) {
        //    if ( this._shoutItem.hasOwnProperty(property) ) {
        //        if (property !== 'url' && property !== 'tenantId' ) {
        //            if ( !this._shoutItem[property] ) {
        //                console.log('Missing in shoutobject: ' + property)
        //                return false;
        //            }
        //        }

        //    }
        //}
        return this._shoutItem;
    }
}

export default ShoutObject;
