//const tenantConfig = window.tenantConfig;
export const mainColor = () => window.tenantConfig.color;

export const borderRadius = '8px';

// -- Breakpoints
export const xxs = '360px';
export const mxs = '500px';
export const xs = '768px';
export const md = '1024px';
export const mdPlus = '1025px';
export const lgMin = '1200px';
export const lg = '1500px';
export const xlg = '1600px';

export const headerHeight = '40px';

export const appHeight = 'calc(100vh - ('+ headerHeight +' + 26px))';

export const borderColor = {
	lightMode : '#E4E4E4',
	darkMode : '#13181e'

}

export const mainTextColor = {
	lightMode : '#000000',
	darkMode : '#FFFFFF',
	darkModeSoft: '#bfbcbc'
}

export const tileBackground = {
	lightMode : '#FFFFFF',
	darkMode : '#1B2B3C'

}

export const headingColor = {
	lightMode : '#40454c',
	darkMode : '#FFFFFF'
}

export const headerBackground = {
	lightMode : '#FFFFFF',
	darkMode : '#121a24'
}

export const centerColumnBackground = {
	lightMode : '#FFFFFF',
	darkMode : '#121a24'
}

export const outerColumnBackground = {
	lightMode : '#f2f3f5',
	darkMode : '#16202c'
}

export const eventBackground = {
	lightMode : '#f8f8f8',
	darkMode : '#1B2B3C'
}
