import styled from 'styled-components'

import * as Variables from '../../../ThemeVariables';

export const Bubble = styled.span`
	color: white;
	background: ${Variables.mainColor};
	font-weight: 600;
	display: inline-block;
	text-align: center;
	line-height: 22px;
	width: 22px;
	border-radius: 100%;
`;