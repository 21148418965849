import actions from './actions';

const initState = {
	siteKnowledge : []
};

export default function siteKnowledgeReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_SITE_KNOWLEDGE:
			return {
				...state,
				siteKnowledge: action.payload
			}
		default:
			return state;
	}
}
