import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuestion, faBrush, faStore } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Styled from './styled';
import actions from '../../../../redux/slideOut/actions';
import { withTranslate } from 'react-redux-multilingual'

class MenuButtonList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedWidgets: false,
            go: false
        };
    }

    componentDidUpdate() {
        if (!this.state.checkedWidgets && this.props.widgets.widgets?.left?.length>0) {
            this._checkGOSource();
        }
    }

    _checkGOSource = () => {       
        let widgets = this.findAll(this.props.widgets.widgets, "go");
        this.setState({ checkedWidgets: true, go: (widgets?.length>0) });
    }

    findAll = (obj, searchKey, results = []) => {
        const r = results;
        if (obj == null) return;
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value === searchKey && typeof value !== 'object') {
                r.push(obj);
            } else if (typeof value === 'object') {
                this.findAll(value, searchKey, r);
            }
        });
        return r;
    };

    render() {
        return (
            <Styled.MenuButtonList parent={this.props.parent}>
                <Styled.MenuButtonListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                    <Styled.SkewedNavLink className={(this.props.darkmode ? 'darkmode' : '')} exact to="/">
                        <Styled.NavLinkInner onClick={() => {
                            if (this.props.parent == "slideout") this.props.actions.setActiveSlideOut(false);
                        }}>
                            <span><FontAwesomeIcon icon={faStar} /> <span>{this.props.translate('startMenu')}</span></span>
                        </Styled.NavLinkInner>
                    </Styled.SkewedNavLink>
                </Styled.MenuButtonListItem>

                {window.tenantConfig.hulpDeactivated !== "true" &&
                    <Styled.MenuButtonListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                        <Styled.SkewedNavLink className={(this.props.darkmode ? 'darkmode' : '')} to="/online-hulp">
                            <Styled.NavLinkInner onClick={() => {
                                if (this.props.parent == "slideout") this.props.actions.setActiveSlideOut(false);
                            }}>
                                <span><FontAwesomeIcon icon={faQuestion} /> <span>{this.props.translate('helpMenu')}</span></span>
                            </Styled.NavLinkInner>
                        </Styled.SkewedNavLink>
                    </Styled.MenuButtonListItem>
                }

                {((this.props.widgets.hasGOWidget && window?.userSettings?.rights?.selfserviceIntranet) || window?.userSettings?.rights?.selfserviceBeheer) &&
                    <React.Fragment>
                        <Styled.MenuButtonListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                            <Styled.SkewedNavLink className={(this.props.darkmode ? 'darkmode' : '')} to="/self-service">
                                <Styled.NavLinkInner onClick={() => {
                                    if (this.props.parent == "slideout") this.props.actions.setActiveSlideOut(false);
                                }}>
                                    <span><FontAwesomeIcon icon={faBrush} /> <span>{this.props.translate('selfMenu')}</span></span>
                                </Styled.NavLinkInner>
                            </Styled.SkewedNavLink>
                        </Styled.MenuButtonListItem>
                    </React.Fragment>
                }
                {window.tenantConfig.version == 'development' &&
                    <React.Fragment>
                        <Styled.MenuButtonListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                            <Styled.SkewedNavLink className={(this.props.darkmode ? 'darkmode' : '')} to="/store">
                                <Styled.NavLinkInner onClick={() => {
                                    if (this.props.parent == "slideout") this.props.actions.setActiveSlideOut(false);
                                }}>
                                    <span><FontAwesomeIcon icon={faStore} /> <span>{this.props.translate('storeMenu')}</span></span>
                                </Styled.NavLinkInner>
                            </Styled.SkewedNavLink>
                        </Styled.MenuButtonListItem>
                    </React.Fragment>
                }
            </Styled.MenuButtonList>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        user: state.User,
        darkmode: state.Layout.darkmode,
        widgets: state.Widgets
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(MenuButtonList));