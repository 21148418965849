import { all, put, fork, takeEvery, select, call, take, cancel, takeLatest, cancelled, delay } from 'redux-saga/effects';
import actions from './actions';
import { getGraphClient, getTeams, getAppOnline, getPeople, getPeoplePage, peopleAlreadyFetching, getPeoplePageLength } from '../../selectors';
import { getBirthdays } from '../../birthday/saga';
//const tenantConfig = window.tenantConfig;
import { Buffer } from 'buffer';


export function* setPeople() {
    yield takeEvery(actions.SET_PEOPLE, function* (action) {
        let people = action.payload;
        const currentPage = yield select(getPeoplePage);
        const pageLength = yield select(getPeoplePageLength);
        yield put({
            type: actions.SET_CURRENT_PAGE,
            payload: currentPage + pageLength
        })
        yield put({
            type: actions.SET_PEOPLE_FETCHING,
            payload: false
        })
    });
}
export function* fetchPeople() {
    yield takeEvery(actions.GET_PEOPLE, function* (action) {
        
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }

        const GraphClient = yield select(getGraphClient);
        let people = yield select(getPeople);
        let currentPage = 0;
        if (GraphClient == null) return;
        const pageLength = yield select(getPeoplePageLength);
        //const peopleResult = yield GraphClient.api(`/me/people?$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'&$top=${pageLength}`);
        //const peopleResult = yield GraphClient.api(`/users?$count=true&$select=userPrincipalName,displayName,givenName,surname,jobTitle,mail,mobilePhone,officeLocation,id,department,imAddress&$filter=userType eq 'Member' and jobTitle ne null and mail ne null&$top=${pageLength}`);
        
        let peopleResult;
        if(window.tenantConfig.colleagueGroup) {
            peopleResult = yield GraphClient.api(`/groups/${window.tenantConfig.colleagueGroup}/members?$count=true&$select=displayName,userPrincipalName,givenName,userType,surname,jobTitle,accountEnabled,mail,mobilePhone,businessPhones,officeLocation,id,department,imAddress&$top=${pageLength}&$orderby=displayName`);
            if(!peopleResult.value.length) {
                peopleResult = yield GraphClient.api(`/users?$count=true&$select=displayName,userPrincipalName,givenName,userType,surname,jobTitle,accountEnabled,mail,mobilePhone,businessPhones,officeLocation,id,department,imAddress&$filter=userType ne 'Guest' and officeLocation ne 'Administrator' and officeLocation ne 'GoFilterGeenCollega' and accountEnabled eq true and givenName ne null and NOT endsWith(userPrincipalName, 'onmicrosoft.com') and mail ne null&$top=${pageLength}&$orderby=displayName`);
            }
        } else {
            peopleResult = yield GraphClient.api(`/users?$count=true&$select=displayName,userPrincipalName,givenName,userType,surname,jobTitle,accountEnabled,mail,mobilePhone,businessPhones,officeLocation,id,department,imAddress&$filter=userType ne 'Guest' and officeLocation ne 'Administrator' and officeLocation ne 'GoFilterGeenCollega' and accountEnabled eq true and givenName ne null and NOT endsWith(userPrincipalName, 'onmicrosoft.com') and mail ne null&$top=${pageLength}&$orderby=displayName`);
        }
        if(peopleResult['@odata.nextLink']) {
            yield fetchNextPeoplePage(peopleResult['@odata.nextLink'], peopleResult.value);
        } else {
            yield fork(parsePeople, peopleResult);
        }
        
    })
}


function* fetchNextPeoplePage(nextLink, peopleResultValue) {
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }

        // const alreadyFetching = yield select(peopleAlreadyFetching);
        // console.log("alreadyFetching",alreadyFetching)
        // if (alreadyFetching) {
        //     return;
        // }

        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;
        let replacedLink = nextLink.replace("https://graph.microsoft.com/v1.0","")

        let peopleResult = yield GraphClient.api(replacedLink);
        peopleResult.value = peopleResultValue.concat(peopleResult.value);
        if(peopleResult['@odata.nextLink']) {
            yield fetchNextPeoplePage(peopleResult['@odata.nextLink'], peopleResult.value)
        } else {
            yield fork(parsePeople, peopleResult);
        }
        
}

function* parsePeople(peopleResponse) {
    let peopleData = false;

    if (!peopleResponse || !peopleResponse['@odata.nextLink']) {
        yield put({
            type: actions.SET_NEXT_PAGE_AVAILABLE,
            payload: false
        })
    }
    if (peopleResponse?.value.length) {
        peopleData = peopleResponse.value;
    } else {
        yield put({
            type: actions.SET_PEOPLE_FETCHING,
            payload: false
        })
        return;
    }

    const GraphClient = yield select(getGraphClient);
    if (GraphClient == null) return;

    let people = yield select(getPeople);
    if (!people) {
        people = [];
    }

    //const birthdays = yield select(getBirthdays);
    let tasks = [];
    let peopleWithoutAvatar = [];

    for (let i = 0; i <= peopleData.length; i++) {
        if (peopleData[i]) {

            let findAvatar = true;
            let person = peopleData[i];

            if (person.givenName) {
                const existingPerson = people.find((statePerson) => statePerson.id == person.id);

                if (!existingPerson) {
                    people.push(person);
                }
            }
        }
    }

    yield put({
        type: actions.SET_PEOPLE,
        payload: people
    })
}

export function* anyPeopleRequest() {
    yield takeLatest([actions.GET_NEXT_PEOPLE_PAGE, actions.GET_PEOPLE], function* (action) {
        yield put({
            type: actions.SET_PEOPLE_FETCHING,
            payload: true
        })
    })
}

export function* getAvatar() {
    yield takeEvery(actions.GET_PERSON_AVATAR, function* (action) {
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;
        const avatar = yield GraphClient.api(`/users/${action.payload}/photos/48x48/$value`, 'arrayBuffer');
        let image = 'none';
        if (avatar) {
            image = 'data:image/png;base64, ' + new Buffer.from(avatar, 'binary').toString('base64');
        }

        let people = yield select(getPeople);

        let personIndex = people.findIndex((item) => item.id === action.payload);
        if (personIndex != -1) {
            people[personIndex].avatar = image;

            yield put({
                type: actions.SET_PEOPLE_AVATARS,
                payload: people
            });
        }

    })
}

export function* getUserStatus() {
    yield takeEvery(actions.GET_USER_STATUS, function* (action) {
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;

        try {

            const status = yield GraphClient.api(`/users/${action.payload}/presence`);
            if (status == null) return;
            // -- Statuses do not work yet.

            let people = yield select(getPeople);

            let personIndex = people.findIndex((item) => item.id == action.payload);

            people[personIndex].status = status.availability;
            people[personIndex].activity = status.availability;

            yield put({
                type: actions.SET_PEOPLE,
                payload: people
            })
        } catch (ex) { }

    })
}


/**
*	@name			Redux/Status/Saga/startStopStatusUpdates
*	@Description 	Starts a background sync job and waits for actions.STOP_STATUS_UPDATES to stop.
*/
export function* startStopStatusUpdates() {
    while (yield take(actions.START_STATUS_JOB)) {

        // starts the task in the background
        const bgSyncTask = yield fork(backgroundSync)

        yield take(actions.STOP_STATUS_JOB)
        yield cancel(bgSyncTask)
    }
}

/**
*	@name			Redux/Status/Saga/backgroundSync
*	@Description 	Background task. Continuously calls fetchLogsContinuously, fetchIndexStatusContinuously and fetchIndexDetailsContinuously
*/
function* backgroundSync() {
    try {
        while (true) {
            let userIDs = yield select(getPeople);
            if (userIDs.length) {
                userIDs = userIDs.slice(0, 6);
            }

            for (let i = 0; i <= userIDs.length; i++) {
                if (userIDs[i]) {
                    yield put({
                        type: actions.GET_USER_STATUS,
                        payload: userIDs[i].id
                    })
                }
            }

            yield delay(30000)
        }
    } finally {
        if (yield cancelled())
            console.log("Sync stopped");
    }
}


export default function* rootSaga() {
    yield all([
        fork(fetchPeople),
        fork(setPeople),
        fork(anyPeopleRequest),
        fork(getAvatar),
        fork(startStopStatusUpdates),
        fork(getUserStatus),
    ]);
}


/*
/*tasks.push(
    new Promise((resolve, reject) => {

        resolve(avatar);
    })
)
let promises = yield Promise.allSettled(tasks);
promises.forEach((item, index) => {
    if ( item.status == 'fulfilled' && item.value) {
        let image = 'data:image/png;base64, ' + new Buffer(item.value, 'binary').toString('base64');
        peopleWithoutAvatar[index].avatar = image
    } else {
        peopleWithoutAvatar[index].avatar = 'none';
    }

    people.push(peopleWithoutAvatar[index]);
});*/
