import { all, fork, takeEvery, select, put } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import { getNotifications } from '../selectors';
import messageActions from '../message/actions';
export function* pushNotification() {
    yield takeEvery(actions.PUSH_NOTIFICATION, function* (action) {

        // -- Toast it here
        toast(this.props.translate('commandHubInvokeGoLoginError') + action.payload.message, {type: action.payload.type, autoClose: 1000*60*60*24});
        let notifications = yield select(getNotifications);
        notifications.push(action.payload);

        yield put({
            type : actions.SET_NOTIFICATIONS,
            payload : notifications
        })
    });
}

// -- Intercept messages
export function* addMessage() {
    yield takeEvery(messageActions.MESSAGE, function* (action) {

        let notifications = yield select(getNotifications);
        notifications.push(action.payload);

        yield put({
            type : actions.SET_NOTIFICATIONS,
            payload : notifications
        })
    });
}


export default function* rootSaga() {
    yield all([
        fork(pushNotification),
        fork(addMessage)
    ]);
}
