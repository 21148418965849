import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const Bubble = props => (
	<Styled.Bubble className="bubble" style={props.style} small={props.small}>
		{ props.number }
	</Styled.Bubble>
)

Bubble.propTypes = {
	number : PropTypes.any,
	style : PropTypes.object
}

export default Bubble;
