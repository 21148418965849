import React, { Component } from 'react';
import { connect } from 'react-redux';

import DarkmodeSlide from '../../slideTypes/darkmodeSlide';
import ListSlide from '../../slideTypes/listSlide';
import VideoSlide from '../../slideTypes//videoSlide';
import SettingSlide from '../../slideTypes/settingSlide';
import ContentSlide from '../../slideTypes/contentSlide';
import Slide from '../../slider/slide';
import Slider from '../../slider';


class Slides extends Component {

    render() {

        return (
            <Slider>
                <Slide next>
                    <ContentSlide
                        title={"Hi  " + (this.props.user == null ? '' : this.props.user.givenName) + ","}
                        description={"Welkom op de <b>moderne servicedesk</b> van Avantage! Bekijk je openstaande tickets, stel je vraag of probleem en laat je door GoGo helpen. <img style='display:block;margin:0 auto;margin-top:20px;' src='https://www.avantage.nl/wp-content/uploads/2018/06/Avantage_logo_133x45_rood-01-1.svg' />"}
                    />
                </Slide>

                <Slide next prev>
                    <ContentSlide
                        title={`GoGo`}
                        description={"Is de slimme hulp. Heb je een vraag over videovergaderen in Teams of werkt je laptop niet? Stel deze aan GoGo. Hij zoekt voor je in de kennisbanken en schakelt wanneer nodig de Avantage servicedesk in. <img style='width:50px;display:block;margin:0 auto;margin-top:20px;' src='https://gocnd.azureedge.net/gowebsite/Isaac_bot_GO-01.svg' />"}
                    />
                </Slide>

                <Slide next prev>
                    <ContentSlide
                        title={`Meldingen`}
                        description={"Alle meldingen die je hebt aangemeld bij de Avantage servicedesk vindt je terug onder <b>'mijn meldingen'</b>. Hier kun je detailinformatie ophalen en meldingen bijwerken. <img style='display:block;margin:0 auto;margin-top:20px;' src='https://gocnd.azureedge.net/gowebsite/melding.PNG' />"}
                    />
                </Slide>

                <Slide prev close>
                    <ContentSlide
                        title={"Kennisbank"}
                        description={"Gebruik de kennisbanken om zelf direct te zoeken naar een antwoord"}
                    />
                </Slide>

            </Slider>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.profile,
    };
};


export default connect(mapStateToProps)(Slides);