const actions = {
    SET_COLUMNS: 'SET_COLUMNS',
    SET_IS_LOADED: 'SET_IS_LOADED',
    SET_DARK_MODE : 'SET_DARK_MODE',
    START_DARKMODE_AUTO : 'START_DARKMODE_AUTO',
    STOP_DARKMODE_AUTO : 'STOP_DARKMODE_AUTO',
    HIDE_SLIDER : 'HIDE_SLIDER',
    setDarkMode : (payload) => ({
		type: actions.SET_DARK_MODE,
		payload : payload
	}),

	hideSlider : (payload) => ({
		type : actions.HIDE_SLIDER,
		payload : payload
	}),

	startDarkModeAuto: (payload) => ({
		type: actions.START_DARKMODE_AUTO,
		payload : payload
	}),

	stopDarkModeAuto: (payload) => ({
		type: actions.STOP_DARKMODE_AUTO,
		payload : payload
	}),

};

export default actions;
