import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import { connect } from 'react-redux';

const HeadingSlideout = props => (
	<Styled.Heading className={props.useClass} marginTop={props.marginTop} marginBottom={props.marginBottom} position={props.position ? props.position : ''} positionBottom={props.positionBottom} border={props.border} >
		<Styled.Title className={(props.darkmode ? 'darkmode' : '')} small={props.small}>{props.title}</Styled.Title>
		{props.linkTitle && props.linkUrl ?
			<Styled.Link className={(props.darkmode ? 'darkmode' : '')} target="_blank" onClick={(props.onClick != null ? props.onClick : null)} href={props.linkUrl}>{props.linkTitle}</Styled.Link>
			:
			<Styled.NoLink className={(props.darkmode ? 'darkmode' : '')} onClick={(props.onClick != null ? props.onClick : null)}>{props.linkTitle}</Styled.NoLink>
		}
	</Styled.Heading>
)

HeadingSlideout.propTypes = {
	title : PropTypes.string,
	linkTitle : PropTypes.string,
	linkUrl : PropTypes.string,
	marginBottom: PropTypes.number
}



const mapStateToProps = (state, ownProps) => {
    return {
        darkmode : state.Layout.darkmode
    };
};

export default connect(mapStateToProps)(HeadingSlideout);
