import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import actions from '../../../../redux/slideOut/actions';
import searchActions from '../../../../redux/search/actions';
import { ScrollTo } from "react-scroll-to";
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import * as Styled from './styled';
import { withTranslate } from 'react-redux-multilingual';

class SearchBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchValue: false
        }
    }

    _changeSearchInput = (e) => {
        this.props.actions.setSearchTerm(e.target.value);
    }

    handleSubmit(event, reset = false) {
        if (event) event.preventDefault();
        this.props.actions.setActiveSlideOut('goapps/search', { search: this.props.searchTerm, dashboard: true });

        if ( this.props.searchbarActive ) {
            this.props.actions.toggleSearchBarActive(false);
        }
    }

    render() {
        return (
            <React.Fragment>
            { window.tenantConfig.version != 'development' ? <ReactTooltip /> : null }
                <Styled.SearchWrapper data-tip={this.props.translate("search")} wrapped={this.props.wrapped} slideout={this.props.slideout} gridArea={this.props.gridArea}>

                    <Styled.Magnifier>
                        <FontAwesomeIcon icon={faSearch} />
                    </Styled.Magnifier>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <Styled.SearchInput onChange={this._changeSearchInput} placeholder={this.props.translate("searchingFor")} value={this.props.searchTerm ? this.props.searchTerm : ''} />
                    </form>
                </Styled.SearchWrapper >
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        darkmode: state.Layout.darkmode,
        searchTerm : state.Search.searchTerm,
        SlideOutData: state.SlideOut.data,
        searchbarActive: state.Search.searchbarActive,
        activeSlideOut: state.SlideOut.activeSlideOut
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...searchActions }, dispatch) });

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
