import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Avatar = props => (
    <Styled.Wrapper onClick={props.onClick}>
        <FontAwesomeIcon icon={['fas', 'cog']} />
        <Styled.Avatar parent={props.parent}  large={props.large} gridArea={props.gridArea}>
            <img alt="Avatar" src={props.source} />
        </Styled.Avatar>
    </Styled.Wrapper>
)

Avatar.propTypes = {
    onClick: PropTypes.func,
    large: PropTypes.bool,
    gridArea: PropTypes.string,
    source: PropTypes.string.isRequired
}

export default Avatar;