import actions from './actions';
const initState = { 
	widgets : {
		left : [],
		center : [],
		right: []
	},
	layout: {},
	clients: [],
	hasGOWidget: false
};

export default function widgetReducer(state = initState, action) {
	switch (action.type) {
        case actions.SET_WIDGETS :
        	return {
        		...state,
        		widgets : action.payload
			}
		case actions.SET_CLIENTS :
			return {
				...state,
				clients: action.payload
			}
		case actions.SET_WIDGETS_LAYOUT :
			return {
				...state,
				layout: action.payload
			}
		case actions.SET_HAS_GO_WIDGET :
			return {
				...state,
				hasGOWidget: action.payload
			}
		default:
			return state;
	}
}

