import React from 'react';
import * as Styled from './styled';
import { connect } from 'react-redux';

const PageLoader = props => (
	<Styled.PageLoader className={(props.darkmode ? 'darkmode' : '')}>

	</Styled.PageLoader>
)



const mapStateToProps = (state, ownProps) => {
    return {
        darkmode: state.Layout.darkmode
    };
};

export default connect(mapStateToProps)(PageLoader);
