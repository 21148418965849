import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import messageActions from '../../message/actions';
import store from "../../store";
import { dataURItoBlob, fileToBase64, blobToBase64 } from '../../../helpers/functions';
import { getCommonNewsItems, getGOClient, getTenantNewsItems, getAuth } from '../../selectors'

export function* getTenantNews() {
    yield takeEvery(actions.GET_TENANT_NEWS, function* (action) {

        const tenantNewsItems = yield select(getTenantNewsItems);
        //const newsItems = yield select(getCommonNewsItems);
        const Client = yield select(getGOClient);
        if (!Client) return;

        const news = yield Client.api("/common/news/tenant");

        if (news == null || !news) return;


        if (news.length == 0 || (news.length && ((tenantNewsItems.length === 0) || (tenantNewsItems && tenantNewsItems[0].Id !== news[0].id)))) {
         
            let oldNews = tenantNewsItems.filter(n => !news.find(nn => nn.id == n.id));
            if (oldNews != null && oldNews.length > 0) {
                //yield newsItems.forEach(n => {
                for (let n of oldNews) {
                    yield localforage.removeItem(n.id);
                }
            }

            for (let n of news) {
                try {
                    let blob = dataURItoBlob(n.image);
                    if (blob == null) continue; // do nothing, maybe request failed
                    yield localforage.setItem(n.id, blob);
                    delete n.image;
                } catch  {
                    delete n.image;
                }
            }

            yield put({
                type: actions.SET_TENANT_NEWS,
                payload: news
            });
        }


    });
}


export function* deleteNews() {
    yield takeEvery(actions.DELETE_NEWS_ITEM, function* (action) {

        const GoClient = yield select(getGOClient);
        if (!GoClient) return;

        const result = yield GoClient.delete('/common/news?id=' + action.payload, action.payload, true);
        if (result) {
            if (result.ok) {
                yield put({
                    type: actions.GET_TENANT_NEWS
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Nieuwsitem verwijderd',
                        type: 'success'
                    }
                });

            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
            store.history.push('/self-service/nieuws');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }

    });
}

export function* postNews() {
    yield takeEvery(actions.POST_COMMON_NEWS, function* (action) {

        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        if (!GoClient) return;

        let formFields = {
            title: action.payload.title,
            link: action.payload.link,
            authorName: Auth.authProvider.account.name,
            authorId: Auth.authProvider.account.localAccountId,
            date: new Date(action.payload.date).toISOString(),
            content: action.payload.content

        }
        let method = 'POST';
        if (action.payload.file) {
            const base64 = yield fileToBase64(action.payload.file[0]);

            formFields = {
                ...formFields,
                image: base64
            }
        }

        if (action.payload.id) {
            method = 'PUT';
            formFields = {
                ...formFields,
                id: action.payload.id,
                authorName: action.payload.authorName,
                authorId: action.payload.authorId
            }

            if (!action.payload.file && action.payload.currentImage) {
                const base64 = yield blobToBase64(action.payload.currentImage);

                formFields = {
                    ...formFields,
                    image: base64
                }
            }
        }

        let result = false;
        if (method === 'POST') {
            result = yield GoClient.post('/common/news', formFields, true);
        } else {
            result = yield GoClient.put('/common/news', formFields, true);
        }
        if (result.ok) {
            yield put({
                type: actions.GET_TENANT_NEWS
            })
            if (method === 'PUT') {

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Nieuwsitem geupdated',
                        type: 'success'
                    }
                });
            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Nieuwsitem toegevoegd',
                        type: 'success'
                    }
                });
            }
            store.history.push('/self-service/nieuws');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(postNews),
        fork(getTenantNews),
        fork(deleteNews),
    ]);
}
