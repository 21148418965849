import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getGOClient, getAuthProvider, getClientsActivated } from '../redux/selectors';
import actions from '../redux/widgets/actions';
import clientActions from '../redux/client/actions';

export const getGraphClient = async (provider) => {


    //let scopes = ['https://graph.microsoft.com/.default'];

    let scopes = [];


    if (window.tenantConfig.common) {
        scopes = [
            'https://graph.microsoft.com/Team.ReadBasic.All',
            'https://graph.microsoft.com/Calendars.Read',
            'https://graph.microsoft.com/Tasks.Read',
            'https://graph.microsoft.com/Mail.Read',
            'https://graph.microsoft.com/User.Read.All',
            'https://graph.microsoft.com/Files.ReadWrite.All',
            'https://graph.microsoft.com/Sites.Read.All'];

    } else {
        scopes = [
            'https://graph.microsoft.com/Team.ReadBasic.All',
            'https://graph.microsoft.com/Calendars.Read',
            'https://graph.microsoft.com/Tasks.ReadWrite',
            'https://graph.microsoft.com/Group.Read.All',
            'https://graph.microsoft.com/Mail.ReadWrite',
            //'https://graph.microsoft.com/Mail.Send',
            'https://graph.microsoft.com/User.Read.All',
            'https://graph.microsoft.com/Files.ReadWrite.All',
            'https://graph.microsoft.com/Sites.Read.All'];
    }

    const token = async () => {
        try {
            const token = await provider.GetToken(scopes);
            if (token == null) return "";
            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    const apiURL = 'https://graph.microsoft.com/';

    let test = await token();
    if (test == null) return null;

    return {

        api: async function (url, type, version = "v1.0") {
            try {


                let consistency = {};
                if (url.indexOf("filter") != -1 || url.indexOf("count") != -1 || url.indexOf("search") != -1 || url.indexOf("orderBy") != -1) {
                    consistency = { 'ConsistencyLevel': 'eventual' };
                }

                const response = await fetch(apiURL + version + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token()),
                            // 'Prefer': 'outlook.body-content-type="text"',
                            ...consistency
                        }
                    });

                if (!response.ok) {
                    console.log('response failed',response);
                    return null;
                }

                const responseType = type ? type : 'json';
                const responseData = await response[responseType]().catch(err => {
                    return [];
                });

                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        post: async function (url, data, version = "v1.0") {
            try {
                const response = await fetch(apiURL + version + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'post',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return null;
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        put: async function (url, data, version = "v1.0") {
            try {
                const response = await fetch(apiURL + version + url,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'put',
                        body: data
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        patch: async function (url, data, version = "v1.0", header = "") {
            try {
                const response = await fetch(apiURL + version + url,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token()),
                            'If-Match' : header
                        },
                        method: 'PATCH',
                        body: data
                    }
                    );

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        getToken: async function () {
            console.log("Get Graph authservice");
            //return token.accessToken;
            return await token();
        }
    };
};

export const getGoClient = async (provider) => {

    const apiURL = window.apiURL + '/api';

    let origin = document.location.origin;
    if (window.tenantConfig.appRegistrationUrl) origin = window.tenantConfig.appRegistrationUrl;

    let scopes = [
        //'api://' + window.tenantConfig.clientId + '/webread'
        origin + '/user_impersonation'
    ];

    const token = async () => {
        try {
            let token = await provider.GetToken(scopes);
            if (token == null) return null;
            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    let test = await token();
    if (test == null) return null;

    return {

        api: async function (url, headers = {}, responseType = 'json') {
            try {
                const response = await fetch(apiURL + url,
                    {
                        ...headers,
                        headers: { 'Authorization': 'Bearer ' + (await token()) }
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    response = await this.HandleClaimsChallenge(response, scopes);

                    if(response === null)
                         return null;
                }                
                let responseData;
                try {
                    if (responseType === 'blob') {
                        responseData = await response.blob();
                    } else {
                        responseData = await response[responseType]();
                    }
                } catch (err) {
                    console.error(`'fetch parse error: ${err}' happened!`,response);
                    return null;
                }

                // responseData = await response[responseType]().catch(err => {
                //     console.error(`'fetch parse error: ${err}' happened!`,response);
                //     return null;
                // });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        post: async function (url, data, noData = false) {
            try {
                const response = await fetch(apiURL + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'post',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if(noData) {
                    return response;
                }
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        put: async function (url, data, noData = false) {
            try {
                const response = await fetch(apiURL + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'PUT',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                if(noData) {
                    return response;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        delete: async function (url, data, noData = false) {
            try {
                const response = await fetch(apiURL + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'DELETE',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    //throw new Error('Network response was not ok.');
                    console.error('response failed',response);
                    return null;
                }

                if(noData) {
                    return response;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        getToken: async function () {
            return await token();
        },
        HandleClaimsChallenge: async function (response, scopes)  {
            
            if (response.status === 401) {

                if (response.headers.get('www-authenticate')) {
                    const authenticateHeader = response.headers.get("www-authenticate");

                    const claimsChallenge = authenticateHeader.split(" ")
                        .find(entry => entry.includes("claims=")).split('="')[1].split('",')[0];

                    if (claimsChallenge) {
                        console.log('Attempting to handle claim challenge..')
                        console.log('[DEBUG]: ClaimsChallenge: ', claimsChallenge);
                        console.log('[DEBUG]: Scopes: ', scopes);
                        try {
                            await this.msalApplication.acquireTokenPopup({
                                claims: window.atob(claimsChallenge), // decode the base64 string
                                scopes: scopes
                            });
                        } catch (error) {
                            // catch if popups are blocked
                            if (error instanceof BrowserAuthError &&
                                (error.errorCode === "popup_window_error" || error.errorCode === "empty_window_error")) {

                                await this.msalApplication.acquireTokenRedirect({
                                    claims: window.atob(claimsChallenge),
                                    scopes: scopes
                                });
                            }
                        }
                    }
                    else {
                        console.log('[AuthenticationError]: ', authenticateHeader);
                        return null;
                    }

                } else {
                    console.log('401 but no claim challenge.');
                    return null;
                }
            }

            return response;
        }
    };
};

export const getSharepointClient = async (provider, site) => {

    const scopes = [parseSharepointUrl(site) + '/AllSites.Read'];

    const token = async () => {
        try {
            const token = await provider.GetToken(scopes);
            if (token == null) return "";
            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    let test = await token();
    if (test == null) return null;

    return {

        api: async function (url, options) {
            try {
                if (url.indexOf("https") == -1) {
                    url = parseSharepointUrl(site) + url;
                }

                const response = await fetch(url,
                    {
                        headers: {
                            'Accept': 'application/json;',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        ...options
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    console.error(`'fetch parse error: ${err}' happened!`,response);
                    return null;
                });

                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }

        },
        put: async function (url, data) {
            try {
                const response = await fetch(apiURL + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'PUT',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        post: async function (url, data) {
            try {
                const response = await fetch(url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        },
                        method: 'POST',
                        body: JSON.stringify(data)
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    return [];
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }
        },
        getToken: async function () {
            console.log("Get SP Token");
            //return token.accessToken;
            return await token();
            //return (await provider.GetToken(scopes)).accessToken;
        }
    };
};


export const getYammerClient = async (provider) => {

    const apiURL = window.apiURL + '/api/app/yammer';
    const scopes = ['https://api.yammer.com/user_impersonation'];

    const token = async () => {
        try {
            const token = await provider.GetToken(scopes);
            if (token == null) return "";

            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    let test = await token();
    if (test == null) return null;

    return {

        api: async function (url) {
            try {
                let GoClient = await getGoClient(provider);
                let goToken = await GoClient.getToken();

                const response = await fetch(apiURL + '?url=' + encodeURI(url), // + '&bearerToken='+ (await token()),
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + goToken,
                            'AuthorizationProxy': "Bearer " + (await token())
                        }
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    console.error(`'fetch parse error: ${err}' happened!`,response);
                    return null;
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { 
                console.error(`'fetching yammer error: ${error}' happened!`);
            }

        },
        getToken: async function () {
            console.log("Get Yammer Token");
            //return token.accessToken;
            return await token();
            //return (await provider.GetToken(scopes)).accessToken;
        }
    };
};


export const getPowerClient = async (provider) => {

    const scopes = ['https://analysis.windows.net/powerbi/api/Workspace.Read.All'];

    const token = async () => {
        try {
            const token = await provider.GetToken(scopes);
            if (token == null) return "";
            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };
    const apiURL = '';

    let test = await token();
    if (test == null) return null;

    return {

        api: async function (url) {
            try {
                //let token = (await provider.GetToken(scopes)).accessToken;
                const response = await fetch(apiURL + url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + (await token())
                        }
                    });

                if (!response.ok) {
                    console.error('response failed',response);
                    return null;
                }

                const responseData = await response.json().catch(err => {
                    console.error(`'fetch parse error: ${err}' happened!`,response);
                    return null;
                });
                if (responseData === []) return null;
                return responseData;
            } catch (error) { return null; }

        },
        getToken: async function () {
            return await token();
        }
    };
};


export const getLiquitClient = async (provider) => {

    let origin = document.location.origin;
    if (window.tenantConfig.appRegistrationUrl) origin = window.tenantConfig.appRegistrationUrl;

    let scopes = [
        origin + '/user_impersonation'
    ];

    if (window.tenantConfig.liquitID) {
        scopes = [window.tenantConfig.liquitID + '/user_impersonation'];
    }

    const token = async () => {
        try {
            let token = await provider.GetToken(scopes);
            if (token == null) return null;
            return token.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    let test = await token();
    if (test == null) return null;

    return {
        getToken: async function () {
            return await token();
        }
    };
};

const parseSharepointUrl = (url) => {
    if (url == null || url === "") return "";
    if (url.includes('.com')) {
        let split = url.split('.com');
        return split[0] + '.com';
    }
    return url;
};


export function* activateClients(widgets) {
    let activeSources = [];
    const authProvider = yield select(getAuthProvider);
    const activated = yield select(getClientsActivated);

    if (!widgets?.config) return;
    if (activated) return;

    console.log('activating clients');

    yield put({
        type: clientActions.CLIENTS_ACTIVATED,
        payload: true
    });

    //const activeClients = yield select(getActiveClients);
    for (let property in widgets.config) {
        if (widgets.config.hasOwnProperty(property)) {
            //widgets.config[property].forEach((item, i) => {
            if(property != "layout") {
            for (let item of widgets.config[property]) {
                if (item == null) continue;

                if (item.component == "Reports" && activeSources.indexOf("Reports") == -1) {
                    const powerClient = yield getPowerClient(authProvider);
                    if (powerClient != null) {
                        console.log('activating powerbi client');
                        yield put({
                            type: clientActions.SET_POWER_CLIENT,
                            payload: powerClient
                        });
                    }
                    activeSources.push("powerbi");
                }

                if (item.sources == null) continue;
                for (let s of item.sources) {
                    if (activeSources.indexOf(s.source) != -1) continue;

                    if (s.source == "sharepoint") {
                        const sharePointClient = yield getSharepointClient(authProvider, s.site);
                        if (sharePointClient != null) {
                            console.log('activating sharepoint client');
                            yield put({
                                type: clientActions.SET_SHAREPOINT_CLIENT,
                                payload: sharePointClient
                            });
                        }
                    }

                    if (s.source == "yammer") {
                        const yammerClient = yield getYammerClient(authProvider);
                        if (yammerClient != null) {
                            console.log('activating yammer client');
                            yield put({
                                type: clientActions.SET_YAMMER_CLIENT,
                                payload: yammerClient
                            });
                        }
                    }
                    activeSources.push(s.source);
                }
            }
        }
        }
    }
    console.log('clients activated');
    console.log('setting clients');

    //set active clients in store
    yield put({
        type: actions.SET_CLIENTS,
        payload: activeSources
    });
}