import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { Buffer } from 'buffer';
import { getGraphClient, getTeams, getAppOnline } from '../../selectors';
//const tenantConfig = window.tenantConfig;

export function* fetchTeams() {
    yield takeEvery(actions.GET_TEAMS, function* (action) {

        const appOnline = yield select(getAppOnline);

        if (!appOnline) {
            return;
        }

        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;

        const joinedTeams = yield GraphClient.api('/me/joinedTeams');
        if (joinedTeams == null) return;

        let teams = [];

        let teamsData = [];
        let teamsPhotos = [];

        if (joinedTeams.value) {
            for (var i = 0; i <= joinedTeams.value.length; i++) {
                if (joinedTeams.value[i]) {
                    let team = joinedTeams.value[i];

                    try {
                        teamsData.push(new Promise((resolve, reject) => {
                            let data = {};
                            GraphClient.api('/teams/' + team.id + '/photo/$value', 'arrayBuffer','beta').then((teamPhoto) => {
                                if (teamPhoto) {
                                    data.image = 'data:image/png;base64, ' + new Buffer.from(teamPhoto, 'binary').toString('base64');
                                }
                                GraphClient.api('/teams/' + team.id).then((teamData) => {
                                    if (teamData != null) resolve({ ...data, ...teamData });
                                    reject();
                                });
                            });
                        }));

                        //teamsPhotos.push(GraphClient.api('/teams/' + team.id + '/photo/$value', 'arrayBuffer'));
                    } catch (error) {
                        console.log(error);
                    }

                }
            }
        }

        let promises = yield Promise.allSettled(teamsData);

        promises.forEach((team) => {
            if (team.status != "fulfilled") return;
            teams.push({
                name: team.value.displayName,
                teamID: team.value.id,
                channelsFetched: false,
                photo: team.value.image,
                webUrl: team.value.webUrl ? team.value.webUrl : '',
                channels: []
            });
        });

        yield put({
            type: actions.SET_TEAMS,
            payload: teams
        });

    })
}

export function* fetchTeamChannels() {
    yield takeEvery(actions.FETCH_TEAM_CHANNELS, function* (action) {
        const GraphClient = yield select(getGraphClient);
        const teams = yield select(getTeams);

        const teamChannels = yield GraphClient.api('/teams/' + action.payload + '/channels');

        let parsedChannels = [];
        let generalItem = false;
        let newTeams = [];
        let tasks = [];

        if (teamChannels.value) {
            for ( let i = 0; i <= teamChannels.value.length; i++ ) {
                if (teamChannels.value[i]) {

                    tasks.push(new Promise((resolve, reject) => {
                        const item = teamChannels.value[i];
                        item.teamID = action.payload;
                        item.channelID = 
                        resolve(item);
                        //GraphClient.api(`/teams/${action.payload}/channels/${item.id}/filesFolder`).then((filesFolder) => {
                        //    item.parentDriveId = filesFolder.parentReference.driveId;
                        //    item.filesFolder = filesFolder;
                        //    resolve(item);
                        //});
                        //reject();
                    }));
                }
            }

            let promises = yield Promise.allSettled(tasks);
            promises.forEach((item) => {
                if (item.displayName !== 'General') {
                    parsedChannels.push(item);
                } else {
                    item.displayName = 'Algemeen';
                    parsedChannels.push(item);
                }
            });

            teams.forEach((team, i) => {
                if (team.teamID === action.payload) {
                    team = {
                        ...team,
                        channelsFetched: true,
                        channels: teamChannels.value
                    }
                }

                newTeams.push(team);
            });
        } else {


            teams.forEach((team, i) => {
                if (team.teamID === action.payload) {
                    team = {
                        ...team,
                        channelsFetched: true,
                        channels: parsedChannels
                    }
                }

                newTeams.push(team);
            });
        }

        yield put({
            type: actions.SET_TEAMS,
            payload: newTeams
        })


        /*const joinedTeams = yield GraphClient.api('/me/joinedTeams');

        let teams = [];
        if ( joinedTeams.value ) {
            for ( var i = 0; i <= joinedTeams.value.length; i++ ) {
                if ( joinedTeams.value[i] ) {
                    let team = joinedTeams.value[i];

                    const teamData = yield GraphClient.api('/teams/'+team.id);

                    const teamPhoto = yield GraphClient.api('/teams/'+team.id+'/photo/$value', 'arrayBuffer');
                    const image = 'data:image/png;base64, ' + new Buffer(teamPhoto, 'binary').toString('base64');

                    //const groupData = yield GraphClient.api('/groups/'+team.id);

                    let oTeam = {
                        name : team.displayName,
                        teamID : team.id,
                        channelsFetched : false,
                        photo : image,
                        webUrl : teamData ? teamData.webUrl : '',
                        channels : []
                    }

                    teams.push(oTeam);
                }
            }
        }

        yield put({
            type : actions.SET_TEAMS,
            payload : teams
        })*/
    })
}

export default function* rootSaga() {
    yield all([
        fork(fetchTeams),
        fork(fetchTeamChannels)
    ]);
}

//"https://teams.microsoft.com/l/team/" + encodedChannelId + "/conversations?groupId=" + groupId + "&tenantId=" + tennantId;
