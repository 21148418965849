import actions from './actions';

const initState = {
	blogs: [],
	tenantBlogs : [],
	activeBlogItem: false,
	requestCompleted : false
};


export default function commonBlogReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_COMMON_BLOGS:
			return {
				...state,
				blogs : action.payload
			}
		case actions.COMMON_BLOGS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_TENANT_BLOGS:
			return {
				...state,
				tenantBlogs : action.payload
			}
		case actions.SET_ACTIVE_BLOG_ITEM:
			return {
				...state,
				activeBlogItem: action.payload
			}
		default:
			return state;
	}
}
