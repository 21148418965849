import actions from './actions';
const initState = {
	teams : []
};

export default function teamsReducer(state = initState, action) {
	switch (action.type) {
        case actions.SET_TEAMS :
        	return {
        		...state,
        		teams : action.payload
        	}
		default:
			return state;
	}
}
