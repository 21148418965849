import actions from './actions';

const initState = {
	requestCompleted: true,
	status: "green",
	refreshHidden: false,
	update: ''
};

export default function documentReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_DOCUMENTS:
			return {...state, documents: action.payload}
		case actions.DOCUMENTS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_STATUS_DOCUMENTS:
			return {
				...state,
				status: action.payload
			}
		case actions.SET_REFRESH_HIDDEN_DOCUMENTS:
			return {
				...state,
				refreshHidden: action.payload
			}
		case actions.SET_LAST_UPDATE_DOCUMENTS:
			return {
				...state,
				update: action.payload
			}
		default:
			return state;
	}
}
