import React, { Component } from 'react';
import { connect } from 'react-redux';
import Search from '../search/bar';
import CollapsibleSearch from '../search/collapsible';
import SearchButton from '../search/button';
import Menu from '../menu';
import Avatar from '../../user/profile/avatar';
import NotificationsButton from '../../user/notifications/button';
import MenuButton from '../menu/button';
import { withRouter } from 'react-router-dom';
import * as Styled from './styled';

import actions from '../../../redux/slideOut/actions';
import { bindActionCreators } from 'redux';
import MenuButtonList from '../menu/list';
//const tenantConfig = window.tenantConfig;

class Header extends Component {

    getNotifications = () => {
        const notificationsEnabled = (window.tenantConfig.notificationEnabled === 'true' ? true : false);

        if (notificationsEnabled) {
            return <NotificationsButton parent="header-col-right" />
        }

        return false;
    }
    render() {
        const notificationsEnabled = (window.tenantConfig.notificationEnabled === 'true' ? true : false);
        return (
            <Styled.Header className={(this.props.darkmode ? 'darkmode' : '') + ' ' + (this.props.fixed ? 'fixed' : '') + ' ' + (this.props.active ? 'active' : '') + ' ' + (this.props.hiddenMobileSlideout ? 'hidden-mobile-slideout' : '')} >
                <Styled.HeaderGrid notificationsEnabled={notificationsEnabled}>

                    <Styled.ColumnLeft>
                        <Styled.LogoWrapper onClick={() => {
                            this.props.history.push('/')
                        }} className={((window.tenantConfig.common || !window.tenantConfig.logo) ? 'isCommon' : '')}>
                            {(window.tenantConfig.common || !window.tenantConfig.logo) ? <Styled.ResponsiveImage src={'https://gocnd.azureedge.net/gowebsite/Go.svg'} /> : <Styled.ResponsiveImage src={window.tenantConfig.logo} />}

                        </Styled.LogoWrapper>
                        <MenuButtonList parent="header-col-left" />

                    </Styled.ColumnLeft>

                    <Styled.ColumnCenter>
                        <Menu parent="header-col-center" />
                    </Styled.ColumnCenter>


                    <Styled.ColumnRight>
                        <Menu parent="header-col-right" />
                        <Search gridArea="search" />
                        <SearchButton gridArea="searchbutton" />
                        <Avatar parent="header-col-right" gridArea="avatar" source={this.props.user.user_avatar ? this.props.user.user_avatar : process.env.PUBLIC_URL + '/images/200x200.png'} onClick={() => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'profile' ? false : 'profile'))} />

                        {this.getNotifications()}
                    </Styled.ColumnRight>
                </Styled.HeaderGrid>
                <CollapsibleSearch />
            </Styled.Header>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        user: state.User,
        darkmode: state.Layout.darkmode
    };
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
