const actions = {
	GET_COMMON_BLOGS : 'GET_COMMON_BLOGS',
	SET_COMMON_BLOGS: 'SET_COMMON_BLOGS',
	GET_TENANT_BLOGS : 'GET_TENANT_BLOGS',
	SET_TENANT_BLOGS : 'SET_TENANT_BLOGS',
	SET_ACTIVE_BLOG_ITEM: 'SET_ACTIVE_BLOG_ITEM',
	POST_TENANT_BLOG : 'POST_TENANT_BLOG',
	DELETE_BLOGS_ITEM : 'DELETE_BLOGS_ITEM',
	COMMON_BLOGS_REQUEST_COMPLETED : 'COMMON_BLOGS_REQUEST_COMPLETED',

	getTenantBlogs : () => ({
		type : actions.GET_TENANT_BLOGS
	}),

	postBlog : (payload) => ({
		type : actions.POST_TENANT_BLOG,
		payload : payload
	}),

	getCommonBlogs : ( payload ) => ({
		type : actions.GET_COMMON_BLOGS,
		payload : payload
	}),

	setActiveBlogItem: (payload) => ({
		type: actions.SET_ACTIVE_BLOG_ITEM,
		payload: payload
	}),

	deleteItem: (payload) => ({
		type: actions.DELETE_BLOGS_ITEM,
		payload: payload
	})
};

export default actions;
