const actions = {
	GET_MULTICHANNEL_FEEDS : 'GET_MULTICHANNEL_FEEDS',
	SET_MULTICHANNEL_FEEDS : 'SET_MULTICHANNEL_FEEDS',
	SET_ACTIVE_FEED_ITEM : 'SET_ACTIVE_FEED_ITEM',
	FEED_REQUEST_COMPLETED : 'FEED_REQUEST_COMPLETED',
	getMultichannelFeeds : ( payload, title ) => ({
		type : actions.GET_MULTICHANNEL_FEEDS,
		payload : { title: title, payload: payload } 
	}),
	setActiveFeedItem : ( payload ) => ({
		type : actions.SET_ACTIVE_FEED_ITEM,
		payload : payload
	}),
};

export default actions;
