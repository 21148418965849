import { all, fork, takeEvery, select, put, call } from 'redux-saga/effects';
import actions from './actions';
import slideOutActions from '../../slideOut/actions';
import localforage from 'localforage';
import EventObject from './EventObject';
import { dataURItoBlob, parseSharepointUrl, parsePictureUrl } from '../../../helpers/functions';
import { getMultiChannelEvent, getSharepointClient, getGOClient, getGraphClient } from '../../selectors';

export function* setActiveEvent() {
    yield takeEvery(actions.SET_ACTIVE_EVENT, function* (action) {
        yield put({
            type: slideOutActions.SET_ACTIVE_SLIDEOUT,
            payload: 'event'
        });
    });
}

export function* getEvents() {
    yield takeEvery(actions.GET_EVENTS, function* (action) {
        try {
            yield put({
                type: actions.EVENTS_REQUEST_COMPLETED,
                payload: false
            })
            let eventResponse;
            let currentItems = [];
            let events = [];
            let tasks = [];
            let updatedItems = [];
            let containsGo = false;
            let data;
            let url;
            if(typeof currentItems == 'undefined') {
                currentItems = [];
            }
            const title = action.payload.title;
            currentItems = yield select(getMultiChannelEvent);
            if (action.payload && action.payload.payload) {
                for (let source of action.payload.payload) {
                    if (source.source == "sharepoint") {
                        const Client = yield select(getSharepointClient);
                        if (!Client) return;
                        if (source.url.indexOf("?") == -1 && source.parameters != null && source.parameters != '') { //optional configured parameters
                            url = source.url
                            source.url += source.parameters;
                        }
                        eventResponse = yield Client.api(source.url)
            
                        if (eventResponse == null || !eventResponse || !eventResponse.value) return;
                        if (eventResponse.value.length === 0) return;
                        data = eventResponse.value;
                        let root = "";
                        if(eventResponse['odata.metadata']) {
                            root = parseSharepointUrl(eventResponse['odata.metadata']);
                        }
            
                        tasks = data.map(async (d, index) => {
                            
                            let parsedDescription;
                            if(root !== "") { 
                                parsedDescription = parseDescription(d.Description, root)
                            } else {
                                parsedDescription = d.Description;
                            }
                            if (!d.BannerUrl) {
                                events.push({
                                    id: d.Id,
                                    title: d.Title,
                                    description: parsedDescription,
                                    picture: 'https://placehold.it/500/500',
                                    date: d.Created,
                                    lastUpdated: d.Modified,
                                    allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                                    externalUrl: d.Workspace,
                                    widget: title
                                });
                            }
                            else {
                                let url = decodeURIComponent(d.BannerUrl.Url);
            
                                if (url) {
                                    events.push({
                                        id: d.Id,
                                        title: d.Title,
                                        description: parsedDescription,
                                        picture: url,
                                        allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                                        externalUrl: d.Workspace,
                                        date: d.Created,
                                        lastUpdated: d.Modified,
                                        widget: title
                                    });
                                    return url;
                                }
                            }
            
                        });
                    }

                    if (source.source == "go" && source.url == true) {
                        containsGo = true;
                    }
                }
            }
            
        /*    const date = new Date();

            for (var i = 0; i < 4; i++) {
                events.unshift({
                    id: Math.random(),
                    title: "test evenementen",
                    description: "tijdens deze sessie gaan jullie leren om",
                    picture: 'https://placehold.it/500/500',
                    date: date.setDate(date.getDate() + 1),
                    allEventData: { Location: "Rotterdam", EventDate: date.setDate(date.getDate() + 1), EndDate: date.setDate(date.getDate() + 1) },
                    externalUrl: "https://www.google.nl"
                });
            }*/

            const sortOnDate = ((a, b) => {
                if (a.unix > b.unix) {
                    return -1;
                }
    
                if (a.unix < b.unix) {
                    return 1;
                }
    
                return 0;
            });

            if (containsGo) {
                let commonEvents = yield call(getCommonEvents, title);
                    if (commonEvents?.length > 0) {
                        commonEvents = commonEvents.map(i => {
                        i.widget = title
                        return i; });
                    }
                    commonEvents = [...commonEvents, ...currentItems.filter(i => i.type == "go" && i.widget == title)];
                    if (commonEvents) {
                        events = [
                            ...events,
                            ...commonEvents
                        ];
                    }
            }

            if (tasks?.length) {
                yield Promise.all(tasks);
            }

            currentItems = currentItems.filter(i => i.widget == title);
            let old = currentItems.filter(n => !events.find(nn => nn.id == n.id));
            let newItems = events.filter(n => !currentItems.find(nn => nn.id == n.id));
            updatedItems =  currentItems.filter(n => !events.find(nn => (nn.id == n.id && nn.lastUpdated == n.lastUpdated) ) );
            newItems = newItems.splice(0, 30);
            if(url) {
                yield put({
                    type: actions.SET_SOURCE_URL,
                    payload: url
                })
            }
            if ((old?.length > 0) || updatedItems?.length > 0 || newItems?.length > 0 || (currentItems?.length == 0 && events?.length > 0) || (currentItems?.length !== events?.length && events.length > 0)) {
                //postprocessing..
                let allItems = yield select(getMultiChannelEvent);
                if(typeof allItems == 'undefined') {
                    allItems = [];
                }
                updatedItems.forEach(updatedItem => {
                    let index = allItems.findIndex(allItem => allItem.id == updatedItem.id);
                    if(index > 0) {
                        allItems.splice(index, 1)
                    }
                })
                allItems = allItems.filter(i => i.widget != title);
                allItems = allItems.concat(events); 
                for(oldItem in old.length) {
                    let index = allItems.findIndex(allItem => allItem.id == oldItem.id);
                    if(index > 0) {
                        allItems = allItems.splice(index, 1)
                    }
                }
                yield put({
                    type: actions.SET_EVENTS,
                    payload: allItems.reverse()
                });
            }
            yield put({
                type: actions.EVENTS_REQUEST_COMPLETED,
                payload: true
            })
            //}
        } catch (error) { console.log(error); }
    });
}

export function* getYearEvents() {
    yield takeEvery(actions.GET_YEAR_EVENTS, function* (action) {
        try {
            const Client = yield select(getSharepointClient);
            if (!Client) return;
            let eventResponse;
            let events = [];
            let tasks = [];
            let data;
            if(action.payload) {
                eventResponse = yield Client.api(action.payload.source+"?$select=id,Title,Description,Created,Workspace,Location,Modified,EventDate,EndDate,fRecurrence,RecurrenceData&$orderBy=EventDate desc&$filter=(EventDate ge datetime'"+action.payload.year+"-01-01T00:00:00Z') and (EndDate le datetime'"+action.payload.year+"-12-31T23:59:59Z')")
            }
            //https://avantage365.sharepoint.com/sites/dev-GOnieuws//_api/Web/Lists(guid'6b58a93a-3f93-4311-bc9f-88cfe1c6688f')/items
            

            if (eventResponse == null || !eventResponse || !eventResponse.value) return;
            if (eventResponse.value.length === 0) return;
            data = eventResponse.value;
            let root = "";
            if(eventResponse['odata.metadata']) {
                root = parseSharepointUrl(eventResponse['odata.metadata']);
            }

            tasks = data.map(async (d, index) => {
                
                let parsedDescription;
                if(root !== "") { 
                    parsedDescription = parseDescription(d.Description, root)
                } else {
                    parsedDescription = d.Description;
                }
                if (!d.BannerUrl) {
                    events.push({
                        id: d.Id,
                        title: d.Title,
                        description: parsedDescription,
                        picture: 'https://placehold.it/500/500',
                        date: d.Created,
                        lastUpdated: d.Modified,
                        allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                        externalUrl: d.Workspace,
                        widget: "yearOverview"
                    });
                }
                else {
                    let url = decodeURIComponent(d.BannerUrl.Url);

                    if (url) {
                        events.push({
                            id: d.Id,
                            title: d.Title,
                            description: parsedDescription,
                            picture: url,
                            allEventData: { Location: d.Location, EventDate: d.EventDate, EndDate: d.EndDate },
                            externalUrl: d.Workspace,
                            date: d.Created,
                            lastUpdated: d.Modified,
                            widget: "yearOverview"
                        });
                        return url;
                    }
                }

            });

            if (tasks?.length) {
                yield Promise.all(tasks);
            }


            yield put({
                type: actions.SET_YEAR_EVENTS,
                payload: events
            });
        } catch (error) { console.log(error); }
    });
}



function parseDescription(description, root) {
    if (!description) return '';
    const anchorContent = /<a.[^>]*?href=\"\/.*\".*?>.*?<\/a>/g;
    const urls = /href=\"(\/.*?)\"/;
    let anchorContentMatch = [];
    let urlsMatch = [];
    anchorContentMatch = description.match(anchorContent);
    if(anchorContentMatch) {
        for(let i = 0; i < anchorContentMatch.length; i++) {
            urlsMatch = anchorContentMatch[i].match(urls);
            if(urlsMatch) {
                description = description.replace(urls, function (match, token) {
                    return 'target="_blank" href="' + root + urlsMatch[1] + '"';
                });
            }
        }

    }

 return description;
}

function* getCommonEvents(title) {
    const Client = yield select(getGOClient);
    const GraphClient = yield select(getGraphClient);
    const eventItems = yield select(getMultiChannelEvent);
    if (!Client || !GraphClient) return;
    const events = yield Client.api("/common/events/list");

    if (events == null || !events) return;

    let tasks = events.map(async (n, i) => {

        if (n.image == null) return;
        n.blob = dataURItoBlob(n.image);
        return;

        if (n.authorId !== null && n.authorId !== '00000000-0000-0000-0000-000000000000') {
            const avatar = await GraphClient.api(`/users/${n.authorId}/photo/$value`, 'arrayBuffer');
            let image = 'data:image/png;base64, ' + new Buffer.from(avatar, 'binary').toString('base64');
            let blob = dataURItoBlob(image);
            let objectUrl = URL.createObjectURL(blob);
            n.authorBlob = blob;
        }
    });
    if (tasks.length) {
        yield Promise.all(tasks);
    }



    if (events.length && ((eventItems.length === 0) || (eventItems && eventItems[0].id !== events[0].id))) {
        //localforage.clear(); //clear local storage

        let old = eventItems.filter(n => !events.find(nn => nn.id == n.id));
        if (old != null && old.length > 0) {
            //yield newsItems.forEach(n => {
            for (let n of old) {
                yield localforage.removeItem(n.id);
            }
        }

        //yield blogs.forEach(n => {
        for (let n of events) {
            if (n.blob == null) continue; // do nothing, maybe request failed
            yield localforage.setItem(n.id, n.blob);
            delete n.image;
            delete n.blob;
        }
    }

    let parsedEvents = yield parseCommonEvents(events, title);
    return parsedEvents;

}

async function parseCommonEvents(events, title) {
    let parsedEvents = [];
    for (let key in events) {

        if (events[key]) {
            const item = events[key];

            let eventObject = new EventObject;
            eventObject.setID(item.id);
            eventObject.setAuthor(item.authorId);
            eventObject.setContent(item.content);
            eventObject.setAllEventData({EventDate: item.startDate,EndDate: item.endDate});
            eventObject.setTitle(item.title);
            eventObject.setType('common');
            eventObject.setPicture('parse-from-localForage');
            eventObject.setTenantId(item.tenantId);
            eventObject.setPicture(item.image);
            eventObject.setDate(item.startDate);
            eventObject.setExternalUrl(item.link)
            eventObject.setWidget(title);
            eventObject.setLocation(item.location ? item.location : "unknown")
            if (eventObject.getEventObject()) {
                parsedEvents.push(eventObject.getEventObject());
            }
        }
    }
    return parsedEvents;
}

export default function* rootSaga() {
    yield all([
        fork(getEvents),
        fork(getYearEvents),
        fork(setActiveEvent)
    ]);
}
