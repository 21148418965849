
import { keyframes } from 'styled-components';
 
export const hoverBob = keyframes`

	0% {
		transform: translateY(-8px);
	}
	50% {
		transform: translateY(-4px);
	}
	100% {
		transform: translateY(-8px);
	}

`
export const hoverBobFloat = keyframes`
	100% {
		transform: translateY(-8px);
	}
`;


export const loadingPlaceholder = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }

`;