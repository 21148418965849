import { all } from 'redux-saga/effects';

// -- Old style widgets
import AppSaga from './app/saga';
import LayoutSaga from './layout/saga';
import AuthSaga from './auth/saga';
import UserSaga from './user/saga';
import GraphSaga from './client/saga';
import TasksSaga from './tasks/saga';
import BirthdaySaga from './birthday/saga';
import NewsSaga from './news/saga';
import DocumentSaga from './document/saga';
import MessageSage from './message/saga';
import ReportsSaga from './reports/saga';
import NotificationSaga from './notification/saga';
import NotificationCollectorSaga from'./notificationCollector/saga';
import EventsSaga from './events/saga';
import BlogSaga from './blog/saga';
import SlideOutSaga from './slideOut/saga';
import SolutionSaga from './solution/saga';
import SearchSaga from './search/saga';
import CalendarSagas from './calendar/saga';
import WidgetSaga from './widgets/saga';
import AppsSaga from './apps/saga';
import DocumentsWizardSaga from './documentsWizard/saga';
import TipsTricksSaga from './tipsTricks/saga';
import SiteKnowledgeSaga from './siteKnowledge/saga';

// -- Common widgets
import CommonNewsSaga from './common/news/saga';
import ITNewsSaga from './common/itnews/saga';
import LearningSaga from './common/learning/saga';
import CommonEventsSaga from './common/events/saga';
import CommonBlogsSaga from './common/blog/saga';
import KnowledgeBaseSaga from './knowledgeBase/saga';
import CallsSaga from './common/calls/saga';

import UserRequestSaga from './common/userRequest/saga';
import RightsRequestSaga from './common/rightsRequest/saga';
import WorkplaceRequestSaga from './common/workplaceRequest/saga';

// -- Widgets in new style
import TeamsSaga from './widgets/teams/saga';
import SharepointSaga from './widgets/sharepoint/saga';
import PeopleSaga from './widgets/people/saga';
import MultiChannelNewsSaga from './widgets/multiChannelNews/saga';
import MultiChannelShoutSaga from './widgets/multiChannelShout/saga';
import MultiChannelBlogSaga from './widgets/multiChannelBlog/saga';
import MultiChannelFeedSaga from './widgets/multiChannelFeed/saga';
import MultiChannelSocialSaga from './widgets/multiChannelSocial/saga';
import MultiChannelEventsSaga from './widgets/multiChannelEvent/saga';

export default function* rootSaga(getState) {
	yield all([
		AppSaga(),
		LayoutSaga(),
		AuthSaga(),
		GraphSaga(),
		UserSaga(),
		BirthdaySaga(),
		DocumentSaga(),
		TasksSaga(),
        NotificationSaga(),
		CalendarSagas(),
		WidgetSaga(),
		AppsSaga(),
		EventsSaga(),
        BlogSaga(),
        ReportsSaga(),
		CommonNewsSaga(),
		ITNewsSaga(),
        CommonEventsSaga(),
		CommonBlogsSaga(),
		KnowledgeBaseSaga(),
		CallsSaga(),
		LearningSaga(),
		SlideOutSaga(),
		NotificationCollectorSaga(),
		SolutionSaga(),
		SearchSaga(),
		DocumentsWizardSaga(),
		TipsTricksSaga(),
		SiteKnowledgeSaga(),
		TeamsSaga(),
		SharepointSaga(),
		PeopleSaga(),
		MultiChannelNewsSaga(),
		MultiChannelBlogSaga(),
		MessageSage(),
		MultiChannelShoutSaga(),
		MultiChannelFeedSaga(),
		MultiChannelSocialSaga(),
		MultiChannelEventsSaga(),
		//newsSaga()
		UserRequestSaga(),
		RightsRequestSaga(),
		WorkplaceRequestSaga(),
	]);
}
