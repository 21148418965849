import actions from './actions';
const initState = {
	people : [],
	nextPageAvailable : true,
	fetching : false,
	currentPage : 0,
	pageLength : 500
};

export default function peopleReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_CURRENT_PAGE:
			return {
				...state,
				currentPage : action.payload
			}
        case actions.SET_PEOPLE :
		case actions.SET_PEOPLE_AVATARS:
        	return {
        		...state,
        		people : action.payload
        	}
		case actions.SET_PEOPLE_FETCHING:
			return {
				...state,
				fetching : action.payload
			}
		case actions.SET_NEXT_PAGE_AVAILABLE:
			return {
				...state,
				nextPageAvailable : action.payload
			}
		default:
			return state;
	}
}
