
export const excludeAppsFromList = (userProfile, apps) => {
    let newApps = [];

    if (!userProfile.hasOfficeSubscription) {
        let noOfficeApps = apps.filter(function (item) { return item.type !== "Office" })
        newApps = newApps.concat(noOfficeApps);
    }

    // -- it is possible that a user has all permissions so no concat is being done, we just override the newApps variable
    if (newApps.length === 0)
        newApps = apps;

    return newApps;
};


