import styled from 'styled-components';
import Box from '../../../styled/Box';

export const BirthdayItem = styled(Box)`
	padding: 15px;

`;

export const Wrapper = styled.div`
	${props => props.flex ? 'display: flex;align-items: center;' : 'display: block; text-align: center;'}

`;

export const Icon = styled.img`
	width: 76px;
	height: 76px;
	border-radius: 50%;
`;

export const Text = styled.span`
	line-height: 24px;
	font-size: 12px;

	${props => props.fullWidth ? 'width: 100%;display: block;' : 'margin-left: 20px;'}
`;
