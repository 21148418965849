const actions = {
	CLEAR : 'CLEAR',
	
	clear: () => ({
		type: actions.CLEAR
	}),

};

export default actions;
