import styled from 'styled-components'

import * as Variables from '../../ThemeVariables';

const CircleIcon = styled.div`
	border-radius: 100%;
	overflow: hidden;

	
	line-height: ${Variables.headerHeight};
	height: ${Variables.headerHeight};
	width: ${Variables.headerHeight};
	background: #dadde1;
	color: black;
	text-align: center;
	font-size: 25px;
	cursor: pointer;

	box-shadow: 0 0 0 transparent;
	transition: box-shadow .5s;
	&:hover {
		box-shadow: 0 0 5px black;

	}
`;

export default CircleIcon;
