import actions from './actions';

const initState = {
	tipsTricks : []
};

export default function tipsTricksReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_TIPS_TRICKS:
			return {
				...state,
				tipsTricks: action.payload
			}
		default:
			return state;
	}
}
