const actions = {
    GET_RECENT_DOCUMENTS_FOR_APP : 'GET_RECENT_DOCUMENTS_FOR_APP',
    SET_RECENT_DOCUMENTS_FOR_APP : 'SET_RECENT_DOCUMENTS_FOR_APP',
    SET_RECENT_DOCUMENTS_LOADING : 'SET_RECENT_DOCUMENTS_LOADING',
    SET_DOCUMENT_INFO : 'SET_DOCUMENT_INFO',
    SET_BASE64_INFO : 'SET_BASE64_INFO',

	getRecentDocumentsForApp : (payload) => ({
		type: actions.GET_RECENT_DOCUMENTS_FOR_APP,
		payload : payload
	}),

    setRecentDocumentsForApp : (payload) => ({
        type : actions.SET_RECENT_DOCUMENTS_FOR_APP,
        payload : payload
    }),
    setDocumentInfo : (payload) => ({
        type : actions.SET_DOCUMENT_INFO,
        payload : payload
    }),
    setDocumentBase64Info : (payload) => ({
        type : actions.SET_BASE64_INFO,
        payload : payload
    })
};

export default actions;
