import styled from 'styled-components'
import * as Variables from '../../ThemeVariables';
//import { WrappedToastContainer } from '../layout/WrappedToastContainer';
export const Wrapper = styled.div`
	&.darkmode {
		background: ${Variables.outerColumnBackground.darkMode};
	}
`;

export const Body = styled.div`
	min-height: ${Variables.appHeight};
    width: 100%;
    overflow-x: hidden;
	padding-bottom: 50px;
	@media(min-width: ${Variables.md}) {
		max-height: ${Variables.appHeight};
		overflow: hidden;
		padding-bottom: 0;
	}
    background: #f2f3f5;

    &.darkmode {
		background: ${Variables.outerColumnBackground.darkMode};
	}
`;

export const Loader = styled.div`
    width: 100%;
    height: 100vh;
    background: #F10000;
    text-align: center;
    padding-top: 40vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all .5s;

    &.loader--hidden {
      margin-top: -100vh;
      opacity: 0;
    }
    &--visible {
      margin-top: 0;
      opacity: 1;
    }

`;

export const LoaderText = styled.p`
    color: #FFF;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 2em;

    small {
        font-size: 12px;
        font-weight: normal;
        margin-top: 40px;
    }
`;

export const NavBar = styled.div`
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	background: white;
	z-index: 999;
	box-shadow: 0 0 4px rgba(0, 0, 0, .5);
	&.darkmode {
		background: #121a24;

		ul {
			li {
				a {
					color: white;

					&.active {
						color: white;
						background: #1B2B3C;
					}
				}
			}
		}
	}
	@media(min-width: ${Variables.md}) {
		display: none;
	}
`;

export const NavBarList = styled.ul`
	list-style: none;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
`;

export const NavBarListItem = styled.li`
	text-align: center;

	* {
		 user-select: none;
	}
	img {
		display: inline-block;
		width: 25px;
		height: auto;
	}
	span {
		display: block;
		font-size: 10px;
	}

	a {
		padding: 10px 0;
		display: block;
		color: black;
		text-decoration: none;

		&.active {
			color: #F10000;
		}
	}

	div {
		display: inline-block;
		width: 50px;
	}

	.bubble {
		position: absolute;
		right: 0;
		top: -4px;
		font-size: 12px;
		line-height: 18px;
		width: 18px;
	}

`;


export const Chatbot = styled.div`
	position: fixed;
	bottom: 60px;
	@media(min-width: ${Variables.md}) {
		bottom: 10px;
	}
	right: 10px;
	background: #FFF;
	padding: 16px;
	border-radius: 50%;
	box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03);
	cursor: pointer;

	&:hover {
		animation: pulse 2s infinite;
	}

	@keyframes pulse {
		0% {
			transform: scale(1.02);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(1.02);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	img {
		height: 40px;
		top: -5px;
	}

	span {
		position: absolute;
		bottom: 9px;
		left: 19px;
		font-size: 11px;
		font-weight: bold;
	}
`;
/*
export const StyledToastContainer = styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  	&--top-right {
  		top: 75px;
  	}
  }
  .Toastify__toast {
  	background: white;
  	color: black;
  	padding: 25px 15px;
  }
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {
  	background: ${Variables.mainColor}
  }
  .Toastify__close-button {
  	opacity: 1;

  }
`;*/
