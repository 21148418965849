import React, { useState, useEffect } from 'react';
import * as Styled from './styled';
import Observer from '@researchgate/react-intersection-observer';
import actions from '../../../../redux/birthday/actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faEnvelope, faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment/min/moment-with-locales';

const BirthdayItem = (props) => {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        return () => {

        }
    }, [])

    const handleIntersection = (event) => {
        if (!inView && event.isIntersecting) {
            setInView(event.isIntersecting);

            if (!props.avatar) {
                props.actions.findAvatar(props.id);
            }
        }
    }
    const options = {
        onChange: handleIntersection,
    };


    const _getAvatarLetters = () => {


        let name = props.name.split(' ');
        if (name?.length) {
            return name[0].charAt(0) + name[name.length - 1].charAt(0);
        }
    }

    const _getAvatar = () => {
        if (props.avatar) {
            if (props.avatar !== 'none') {
                return <Styled.PersonAvatar img={props.avatar} />;
            }
        }
        return (
            <Styled.LetterAvatar>
                {_getAvatarLetters()}
            </Styled.LetterAvatar>
        )
    }

    return (
        <React.Fragment>
            <Observer {...options}>
                <Styled.BirthdayItem>
                    <Styled.Wrapper className={(props.darkmode ? 'darkmode' : '')}>
                        <Styled.Image>
                            {_getAvatar()}
                        </Styled.Image>
                        <div>
                            <Styled.Name>{props.name}</Styled.Name>
                            <Styled.Date>{moment(props.day).locale('nl').format('D MMMM')}</Styled.Date>
                        </div>
                    </Styled.Wrapper>
                </Styled.BirthdayItem>
            </Observer>
        </React.Fragment>);

}

//BirthdayItem.propTypes = {
//    name: PropTypes.string,
//    icon: PropTypes.string,
//    none: PropTypes.bool,
//    demo: PropTypes.bool
//}


const mapStateToProps = (state, ownProps) => {
    return {
        darkmode: state.Layout.darkmode,
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps, false, { pure: false })(BirthdayItem);
