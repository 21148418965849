const actions = {
    GET_LEARNING: 'GET_LEARNING',
    SET_LEARNING: 'SET_LEARNING',
    SET_LEARNING_ID: 'SET_LEARNING_ID',
    SET_ACTIVE_LEARNING_ITEM: 'SET_ACTIVE_LEARNING_ITEM',
    LEARNING_REQUEST_COMPLETED: 'LEARNING_REQUEST_COMPLETED',
    GET_RANDOM_LEARNINGPATHS: 'GET_RANDOM_LEARNINGPATHS',
    SET_RANDOM_LEARNINGPATHS: 'SET_RANDOM_LEARNINGPATHS',
    GET_LEARNINGPATHS: 'GET_LEARNINGPATHS',
    SET_LEARNINGPATHS: 'SET_LEARNINGPATHS',
    GET_ROLES: 'GET_ROLES',
    SET_ROLES: 'SET_ROLES',
    GET_PRODUCTS: 'GET_PRODUCTS',
    SET_PRODUCTS: 'SET_PRODUCTS',
    GET_LEVELS: 'GET_LEVELS',
    SET_LEVELS: 'SET_LEVELS',
    GET_MODULE: 'GET_MODULE',
    SET_MODULE: 'SET_MODULE',

    getLearning: (payload) => ({
        type: actions.GET_LEARNING,
        payload: payload
    }),

    setLearningId: (payload) => ({
        type: actions.SET_LEARNING_ID,
        payload: payload
    }),

    setActiveLearningItem: (payload) => ({
        type: actions.SET_ACTIVE_LEARNING_ITEM,
        payload: payload
    }),

    getRandomLearningPaths: (payload) => ({
        type: actions.GET_RANDOM_LEARNINGPATHS,
        payload: payload
    }),

    getLearningPaths: (payload) => ({
        type: actions.GET_LEARNINGPATHS,
        payload: payload
    }),

    getRoles: (payload) => ({
        type: actions.GET_ROLES,
        payload: payload
    }),

    getProducts: (payload) => ({
        type: actions.GET_PRODUCTS,
        payload: payload
    }),

    getLevels: (payload) => ({
        type: actions.GET_LEVELS,
        payload: payload
    }),

    getModule: (payload) => ({
        type: actions.GET_MODULE,
        payload: payload
    })
};

export default actions;
