import styled from 'styled-components'
import * as Variables from '../../../ThemeVariables';

export const Header = styled.header`
	width: 100%;
	padding: 10px 0;
	z-index: 10;
	border-bottom: 2px solid ${Variables.borderColor.lightMode};
	background: ${Variables.headerBackground.lightMode};

	&.darkmode {
		background: ${Variables.headerBackground.darkMode};
		border-bottom: 2px solid ${Variables.borderColor.darkMode};
	}

	&.slideoutActive {

	}

	&.hidden-mobile-slideout {
		opacity: 0;

		@media ( min-width: ${Variables.md}) {
			opacity: 1;
		}
	}

	&.fixed {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 999;
		opacity: 0;
		transition: opacity .5s;
		&.active {
			opacity: 1;
		}
		@media ( min-width: ${Variables.md}) {
			display: none;
		}
	}

`;

export const ColumnLeft = styled.div`
	grid-area: left;
	display: grid;
	grid-template-columns: 85px 1fr;

	@media ( min-width: ${Variables.md}) {
		grid-template-columns: 145px 1fr;
	}

	grid-template-areas: "logo menuList";
	padding-left: 20px;
	grid-column-gap: 20px;

	@media ( min-width: ${Variables.xlg}) {
		//grid-column-gap: 0;
	}
`;


export const ColumnRight = styled.div`
	grid-area: right;
	padding-right: 20px;
	display: grid;
	grid-column-gap: 10px;

	grid-template-areas: "searchbutton avatar";
	/*grid-template-columns: 165px 1fr 50px 50px 50px;*/
	grid-template-columns: 50px 50px;

	@media ( min-width: ${Variables.mxs}) {
		grid-template-areas: "search avatar";
		/*grid-template-columns: 165px 1fr 50px 50px 50px;*/
		grid-template-columns: 1fr 50px;
	}
	@media ( min-width: ${Variables.md}) {
		grid-template-areas: "menuIcons search avatar";
		/*grid-template-columns: 165px 1fr 50px 50px 50px;*/
		grid-template-columns: 380px 1fr 1fr 1fr;
	}

	@media ( min-width: ${Variables.xlg}) {
		grid-template-columns: 1fr 50px;
		grid-template-areas: "search avatar";

	}
`;

export const ColumnCenter = styled.div`
	grid-area: center;

	display: none;

	@media ( min-width: ${Variables.xlg}) {
		display: block;
	}
`;

export const LogoWrapper = styled.div`
	background: white;
	width: 100%;
    max-height: 44px;
    grid-area: logo;
	z-index: 1;
	&:before {
		content: '';
	    position: absolute;
	    top: -10px;
	    left: -20px;
	    background: white;
	    width: calc(100% + 20px);
	    height: calc(100% + 20px);
	}

	&:after {
		content: '';
	    position: absolute;
	    right: -20px;
	    background: white;
	    height: 20px;
	    transform: skew(-25deg, 0deg);
	    height: calc(100% + 20px);
	    width: 40px;
	    top: -10px;
	}

	&.isCommon {
		text-align: center;
		&:before, &:after {
			background: ${Variables.mainColor};
		}
	}
`;

export const HeaderGrid = styled.div `
	display: grid;
	grid-template-areas : "left . right";
	grid-template-columns: 180px 1fr 130px;

	@media ( min-width: ${Variables.mxs}) {
		grid-template-areas : "left right";
		grid-template-columns: 200px 1fr;
	}


	@media ( min-width: ${Variables.md} ) {
		/*grid-template-columns: 740px 2fr 30%;*/
		grid-template-areas : "left left right";
		grid-template-columns: 3fr 2fr 1fr;
	}

	@media ( min-width: ${Variables.xlg}) {
		grid-template-areas : "left center right";
	}
`;

export const ResponsiveImage = styled.img `
	max-width: 100%;
	height: ${Variables.headerHeight};
	margin-top: 2px;
	width: auto;
	z-index: 5;
`;

/*
		grid-template-columns: 75px 1fr 40px 40px 40px 40px;
	@media ( min-width: ${Variables.xxs} ) {
		grid-template-columns: 130px 1fr 40px 40px 40px 40px;
	}
	grid-column-gap: 10px;
	grid-template-areas:
		${props => props.notificationsEnabled ? '"logo .   menu menu notifications avatar"' : '"logo . .  menu menu avatar"'};
		grid-row-gap: 10px;

	@media ( min-width: ${Variables.xs} ) {
		grid-template-areas:
		${props => props.notificationsEnabled ? '"logo logo  menu menu notifications avatar  "' : '"logo logo . menu menu avatar "'};
		grid-row-gap: 20px;
		grid-column-gap: 20px;
	}

	@media ( min-width: ${Variables.md} ) {
		grid-template-columns: 175px 1fr 225px 40px 40px;
		grid-template-areas:
			${props => props.notificationsEnabled ? '"logo menu search avatar notifications"' : '"logo menu search . avatar"'};
	}

	@media ( min-width: ${Variables.lg} ) {
		grid-template-columns: 175px 1fr 365px 40px 40px;
	}

*/
