import actions from './actions';

const initState = {
	solutions : [],
    categories : [],
	activeSolution : false
};

export default function solutionReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_SOLUTIONS:
			return {
				...state,
				solutions : action.payload
			}
        case actions.SET_SOLUTION_CATEGORIES:
            return {
                ...state,
                categories : action.payload
            }
		case actions.SET_ACTIVE_SOLUTION :
			return {
				...state,
				activeSolution : action.payload
			}
		default:
			return state;
	}
}
