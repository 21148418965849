import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { getGOClient, getITNewsItems } from '../../selectors';
import { dataURItoBlob } from '../../../helpers/functions';

export function* getITNews() {
    yield takeEvery(actions.GET_IT_NEWS, function* (action) {
        yield put({
            type: actions.COMMON_IT_NEWS_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);
        const itNewsItems = yield select(getITNewsItems);

        if (!Client) return;
        const news = yield Client.api("/common/itnews/list");

        if (news == null || !news) return;

        news.map((n, i) => {
            if (n.image) {
                let blob = dataURItoBlob(n.image);
                let objectUrl = URL.createObjectURL(blob);
                n.image = objectUrl;
                n.blob = blob;
            }
            return true;
        });

        if (news.lenght == 0) {
            yield put({
                type: actions.SET_IT_NEWS,
                payload: []
            });
        }


        if (news.length && ((itNewsItems != null || itNewsItems.length === 0) || (itNewsItems && itNewsItems[0].Id !== news[0].id))) {

            //if (itNewsItems) {
            //    for (let n of itNewsItems) {
            //        yield localforage.removeItem(n.id);
            //    }
            //}

            let old = itNewsItems.filter(n => !news.find(nn => nn.id == n.id));
            if (old != null && old.length > 0) {
                //yield newsItems.forEach(n => {
                for (let n of old) {
                    yield localforage.removeItem(n.id);
                }
            }

            for (let n of news) {
                if (n.blob == null) continue; // do nothing, maybe request failed
                yield localforage.setItem(n.id, n.blob);
                delete n.image;
                delete n.blob;
            }

            yield put({
                type: actions.SET_IT_NEWS,
                payload: news
            });
        }

        yield put({
            type: actions.COMMON_IT_NEWS_REQUEST_COMPLETED,
            payload: true
        });
    });
}


export default function* rootSaga() {
    yield all([
        fork(getITNews)
    ]);
}
