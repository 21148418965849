import { all, put, fork, takeEvery, select} from 'redux-saga/effects';
import actions from './actions';
import moment from 'moment';
import { getSolutions } from '../selectors';
export function* enableSolution() {
    yield takeEvery(actions.ENABLE_SOLUTION, function* (action) {

        // -- API logic here
        console.log("Enable solution");

        let solutions = yield select(getSolutions);
        let rebuiltSolutions = [];
        solutions.forEach((solution, i) => {
            if ( solution.id === action.payload ) {
                solution.active = true;
            }
            rebuiltSolutions.push(solution);
        });

        yield put({
            type : actions.SET_SOLUTIONS,
            payload : rebuiltSolutions
        })
    });
}

export function* disableSolution() {
    yield takeEvery(actions.DISABLE_SOLUTION, function* (action) {
        // -- API logic here
        console.log("Disable solution");

        let solutions = yield select(getSolutions);
        let rebuiltSolutions = [];
        solutions.forEach((solution, i) => {
            if ( solution.id === action.payload ) {
                solution.active = false;
            }
            rebuiltSolutions.push(solution);
        });

        yield put({
            type : actions.SET_SOLUTIONS,
            payload : rebuiltSolutions
        })
    });
}

export function* fetchSolutions() {
    yield takeEvery(actions.GET_SOLUTIONS, function* (action) {
        const solutions = [
            {
                id : 1,
                category : 1,
                name : 'Office 365 Back-up & Restore',
                active : false,
                link : 'https://www.avantage.nl/back-up-restore-data-microsoft-365-teams/',
                price : '€ 25 p/m',
                description : 'Om goed en veilig (thuis) te werken met Microsoft (Office) 365 en Teams is het belangrijk dat je bij deze clouddiensten dagelijks een back-up maakt. Daarmee borg je de continuïteit van je bedrijfsvoering wanneer zich onverhoopt een calamiteit voordoet. Microsoft verzorgt deze back-ups niet. Daarom hebben we de oplossing Office 365 Back-up & Restore in het leven geroepen.'
            },
            {
                id : 2,
                category : 2,
                name : 'Office 365 Back-up & Restore',
                active : false,
                link : 'https://www.avantage.nl/back-up-restore-data-microsoft-365-teams/',
                price : '€ 25 p/m',
                description : 'Om goed en veilig (thuis) te werken met Microsoft (Office) 365 en Teams is het belangrijk dat je bij deze clouddiensten dagelijks een back-up maakt. Daarmee borg je de continuïteit van je bedrijfsvoering wanneer zich onverhoopt een calamiteit voordoet. Microsoft verzorgt deze back-ups niet. Daarom hebben we de oplossing Office 365 Back-up & Restore in het leven geroepen.'
            },
            {
                id : 3,
                category : 2,
                name : 'Security & Update management',
                active : false,
                link : 'https://www.avantage.nl/security-en-update-management/',
                price : '€ 25 p/m',
                description: 'Om digitaal veilig te kunnen werken, is het belangrijk dat de meest recente software- en antivirusupdates op jouw pc of laptop zijn geïnstalleerd. Wanneer dat niet het geval is, wordt een online werkplek een eenvoudige prooi voor hackers. Onze security & update management oplossing houdt jouw device up-to-date en veilig!'
            },
            {
                id : 4,
                category : 2,
                name : 'E-mail security',
                active : false,
                link : 'https://www.avantage.nl/e-mail-security/',
                price : '€ 25 p/m',
                description: 'Maar liefst 90% van alle malware wordt verspreid door malafide e-mails. Om deze malware geen probleem te laten worden, is een e-mail security oplossing een vereiste. Onze oplossing filtert inkomende e-mail op spam, malware en andere schadelijke bijlagen en elimineert bedreigingen voordat ze jouw IT-omgeving bereiken.'
            },
            {
                id : 5,
                category : 2,
                name : 'Multi-Factor Authenticatie',
                active : false,
                link : 'https://www.avantage.nl/multifactor-authenticatie/',
                price : '€ 25 p/m',
                description: 'Veilig kunnen inloggen op je device en (bedrijfs)netwerk is belangrijk. De oplossing Multi-Factor Authenticatie (MFA) maakt dit mogelijk. MFA betreft een inlogmethode waarbij je naast je gebruikersnaam en wachtwoord inlogt met een extra beveiliging via een app op jouw smartphone. Op deze manier wordt het kwaadwillenden een stuk moeilijker gemaakt om uit jouw naam toegang te krijgen tot jouw device en/of (bedrijfs)netwerk.'
            },
            {
                id : 6,
                category : 2,
                name : 'Vulnerability scan',
                active : false,
                link : 'https://www.avantage.nl/vulnerability-scan/',
                price : '€ 25 p/m',
                description: 'IT-omgevingen en webapplicaties staan dagelijks bloot aan dreigingen via het internet. Deze kwetsbaarheden zijn zelf echter heel moeilijk waar te nemen. Door periodiek een scan uit te laten voeren krijg je inzicht en controle over de veiligheid van jouw IT-omgeving. De scan maakt het mogelijk om potentiële kwetsbaarheden proactief op te sporen, zodat kritieke beveiligingslekken tijdig gedicht kunnen worden voordat cybercriminelen ze kunnen misbruiken.'
            },
            {
                id : 7,
                category : 2,
                name : 'Cybersecurity Technical scan',
                active : true,
                link : 'https://www.avantage.nl/cybersecurity-technical-scan/',
                price : '€ 25 p/m',
                description: '‘Wat zijn voor mijn organisatie de belangrijkste risico’s als gevolg van cyberdreigingen?’ ‘En waarin moeten wij investeren om als organisatie veiliger te worden?’ Om daar antwoord op te geven kun je een Technical scan uit laten voeren. Op vijf basis cybersecurity onderdelen brengen wij de huidige situatie in kaart en ontvang je een rapport met bevindingen en aanbevelingen om het cybercriminelen zo lastig mogelijk te maken om in te breken in jouw IT-omgeving.'
            },
            {
                id : 8,
                category : 2,
                action : 'endpoint-detection-response',
                name : 'Endpoint Detection & Response',
                active : false,
                link : 'https://www.avantage.nl/endpoint-detection-response/',
                price : '€ 25 p/m',
                description: 'Cybercriminelen maken steeds vaker gebruik van geavanceerde technieken om een IT netwerk binnen te dringen. Alleen antivirus software is niet meer voldoende. Met Endpoint Detection & Response (EDR) worden gerichte aanvallen op de IT-omgeving continu door onze security specialisten gemonitord en worden bedreigingen efficiënt gedetecteerd, vakkundig beoordeeld door onze specialisten en snel geblokkeerd. Met Endpoint Detection & Response abonneer je je op onze cybersecurity alarmcentrale die jouw devices bewaakt en ingrijpt als er inbrekers zijn.'
            },
            {
                id : 9,
                category : 2,
                name : 'Identiteitsfraude preventie',
                active : false,
                link : 'https://www.avantage.nl/identiteitsfraude-preventie/',
                price : '€ 25 p/m',
                description: 'Onderzoek wijst uit dat 1 op de 5 mensen op een schadelijke link in een phishing mail klikt. Het sturen van phishing mails is een populaire methode van cybercriminelen om persoonlijke gegevens buit te maken en daarmee malafide acties uit te voeren. Om gebruikers hiertegen te wapenen bieden wij een oplossing aan die dagelijks scant en notificeert op gelekte wachtwoorden. Daarnaast ondersteunen we bij het verhogen van het securitybewustzijn door periodiek een phishing simulatie test uit te voeren en e-learning content aan te bieden.'
            },
            {
                id : 10,
                category : 2,
                name : 'Cybersecurity Assessment',
                active : false,
                link : 'https://www.avantage.nl/identiteitsfraude-preventie/',
                price : '€ 25 p/m',
                description: '‘Hoe volwassen is onze organisatie op het gebied van cybersecurity? Maar ook wat zijn onze risico’s en wat is de impact hiervan op onze bedrijfsvoering? Investeren we wel in de juiste security maatregelen passend bij onze organisatie?’. De cybersecurity assessment geeft organisaties op 20 onderdelen inzicht in het volwassenheidsniveau van cybersecurity en de relevante risico’s. Op basis van de assessment brengen onze security specialisten een advies uit met conclusies en een concreet en actiegericht plan voor de systematische verbetering van de cyberveiligheid van jouw organisatie.'
            }
        ];

            yield put({
                type : actions.SET_SOLUTIONS,
                payload : solutions
            })
    })
}

export function* getSolutionCategories() {
    yield takeEvery(actions.GET_SOLUTION_CATEGORIES, function* (action) {

        const solutionCategories = [
            {
                id : 1,
                name : 'Featured',
                column : 'left'
            },
            {
                id : 2,
                name : 'Cybersecurity',
                column : 'center'
            },
            {
                id : 3,
                name : 'GO',
                column : 'center'
            },

        ];

        yield put({
            type : actions.SET_SOLUTION_CATEGORIES,
            payload : solutionCategories
        })

        yield put({
            type : actions.GET_SOLUTIONS
        })
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchSolutions),
        fork(getSolutionCategories),
        fork(enableSolution),
        fork(disableSolution)
    ]);
}
