import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from '../../styled';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Button from '../../../button';
import actions from '../../../../../redux/user/actions';
const settings = {
    lazyload: false,
    nav: false,
    mouseDrag: false,
    controls: false // remove built-in nav buttons
}
class SlideInner extends React.Component {

    _setInvisible = (e) => {
        if (e.target.classList.contains('slideInner')) {
            this._dismissSlider();
        }
    }

    _dismissSlider() {
        if (!this.props.alwaysVisible) {
            this.props.actions.setFeatureSliderVisible(this.props.location.pathname, false)
        }
    }

    render() {
        return (
            <Styled.Slide>
                <Styled.SlideInner className="slideInner" onClick={this._setInvisible}>
                    <Styled.SlideInnerContent>
                        {!this.props.alwaysVisible ? <Styled.CloseSlide onClick={() => { this._dismissSlider() }} /> : null}
                        {this.props.children}
                        {(this.props.prev && this.props.goTo) ? <Button onClick={() => this.props.goTo('prev')} text="Vorige" /> : null}
                        {(this.props.next && this.props.goTo) ? <Button position="right" onClick={() => { console.log('next clicked'); this.props.goTo('next')}} text="Volgende" /> : null}
                        {(this.props.close) ? <Button position="right" onClick={() => this.props.actions.setFeatureSliderVisible(this.props.location.pathname, false)} text="Sluiten" /> : null}
                    </Styled.SlideInnerContent>
                </Styled.SlideInner>
            </Styled.Slide>
        )
    }

}



const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        sliderHidden: state.Layout.sliderHidden,
        isLoaded: state.Layout.IsLoaded,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...actions
    }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideInner));
