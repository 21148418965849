import styled from 'styled-components'
import { Loader } from '../styled';
export const TeamWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
`;

export const Team = styled.div `
	background: #e9ebee;
    margin: 0 13px 13px 0;
    height: 19px;
    width: 100%;

    ${props => props.position === 'left' ? 'float: left' : 'float: right'};
    ${Loader}
`;
