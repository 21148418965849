import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../../../redux/search/actions';
import { bindActionCreators } from 'redux';

const SearchButton = props => (
	<Styled.SearchButton onClick={props.actions.toggleSearchBarActive}  gridArea={props.gridArea}>
		<FontAwesomeIcon icon={faSearch} />
	</Styled.SearchButton>
)

SearchButton.propTypes = {
	onClick : PropTypes.func,
	gridArea : PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
    return {

    };
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchButton));
