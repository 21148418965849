import actions from './actions';

const initState = {
    faqs: [],
    knowledgeBases: [],
    faqRequestCompleted: false,
    knowledgeBasesRequestCompleted: false
};


export default function knowledgeReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        case actions.FAQS_REQUEST_COMPLETED:
            return {
                ...state,
                faqRequestCompleted: action.payload
            }
        case actions.SET_KNOWLEDGEBASES:
            return {
                ...state,
                knowledgeBases: action.payload
            }
        case actions.KNOWLEDGEBASES_REQUEST_COMPLETED:
            return {
                ...state,
                knowledgeBasesRequestCompleted: action.payload
            }
        default:
            return state;
    }
}
