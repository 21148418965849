import actions from './actions';

const initState = {
	learnings: [],
	learingId: false,
	learningPaths: [],
	randomLearningPaths: [],
	roles: [],
	products: [],
	levels: [],
	module: false,
	activeLearningItem : false,
	requestCompleted : false
};


export default function learningReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_LEARNING:
			return {
				...state,
				learnings : action.payload
			}
		case actions.SET_LEARNING_ID:
			return {
				...state,
				learningId: action.payload
			}
		case actions.LEARNING_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_ACTIVE_LEARNING_ITEM:
			return {
				...state,
				activeLearningItem : action.payload
			}
		case actions.SET_RANDOM_LEARNINGPATHS:
			return {
				...state,
				randomLearningPaths: action.payload
			}
		case actions.SET_LEARNINGPATHS:
			return {
				...state,
				learningPaths: action.payload
			}
		case actions.SET_ROLES:
			return {
				...state,
				roles: action.payload
			}
		case actions.SET_PRODUCTS:
			return {
				...state,
				products: action.payload
			}
		case actions.SET_LEVELS:
			return {
				...state,
				levels: action.payload
			}
		case actions.SET_MODULE:
			return {
				...state,
				module: action.payload
			}

		default:
			return state;
	}
}
