import actions from './actions';

const initState = {
	activeTask : {},
};

export default function tasksReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_TASKS:
			return {...state, tasks: action.payload}
		case actions.SET_ACTIVE_TASK:
			return {...state, activeTask: action.payload}
		default:
			return state;
	}
}
