const actions = {
	GET_NOTIFICATIONS : 'GET_NOTIFICATIONS',
	SET_NOTIFICATIONS : 'SET_NOTIFICATIONS',
	START_NOTIFICATIONS : 'START_NOTIFICATIONS',
	RECEIVE_NOTIFICATION : 'RECEIVE_NOTIFICATION',
	REMOVE_SINGLE_NOTIFICATION : 'REMOVE_SINGLE_NOTIFICATION',
	REMOVE_ALL_NOTIFICATIONS : 'REMOVE_ALL_NOTIFICATIONS',
    SET_NUMBER_OF_NOTIFICATIONS: 'SET_NUMBER_OF_NOTIFICATIONS',
    RECEIVE_COMMAND: 'RECEIVE_COMMAND',
    SEND_COMMAND: 'RECEIVE_COMMAND',
    SEND_LIST_COMMAND: 'SEND_LIST_COMMAND',
    SEND_DETAIL_COMMAND: 'SEND_DETAIL_COMMAND',
    SEND_EXECUTE_COMMAND: 'SEND_EXECUTE_COMMAND',
    SEND_CHECK_COMMAND: 'SEND_CHECK_COMMAND',
    SET_HUB: 'SET_HUB',
    SET_APPLICATIONS: 'SET_APPLICATIONS',
    SET_APPLICATION: 'SET_APPLICATION',
    SERVICES_CONNECTED: 'SERVICES_CONNECTED',
    SET_CONNECTED: 'SET_CONNECTED',

    setHub: (payload) => ({
        type: actions.SET_HUB,
        payload: payload
    }),
    setServiceConnected: (payload) => ({
        type: actions.SERVICES_CONNECTED,
        payload: payload
    }),
    setIsConnected: (payload) => ({
        type: actions.SET_CONNECTED,
        payload: payload
    }),
    setApplications: (payload) => ({
        type: actions.SET_APPLICATIONS,
        payload: payload
    }),
    setApplication: (payload) => ({
        type: actions.SET_APPLICATION,
        payload: payload
    }),
    sendCommand: (payload) => ({
        type: actions.SEND_COMMAND,
        payload: payload
    }),
    sendListCommand: () => ({
        type: actions.SEND_LIST_COMMAND
    }),
    sendCheckCommand: () => ({
        type: actions.SEND_CHECK_COMMAND
    }),
    sendDetailCommand: (payload) => ({
        type: actions.SEND_DETAIL_COMMAND,
        payload: payload
    }),
    sendExecuteCommand: (payload) => ({
        type: actions.SEND_EXECUTE_COMMAND,
        payload: payload
    }),
	removeSingleNotification : (payload) => ({
		type : actions.REMOVE_SINGLE_NOTIFICATION,
		payload : payload
	}),

	startNotifications : () => ({
		type : actions.START_NOTIFICATIONS
	}),

	removeAllNotifications : ( payload ) => ({
		type : actions.REMOVE_ALL_NOTIFICATIONS
	})
};

export default actions;
