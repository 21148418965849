import { all, put, fork, takeEvery,select } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import widgetActions from '../widgets/actions';
import calendarActions from '../calendar/actions';
import notificationsActions from '../notification/actions';
import layoutActions from '../layout/actions';
import taskActions from '../tasks/actions';
import authActions from '../auth/actions';
import teamsActions from '../widgets/teams/actions';
import { getGraphClient, getGoClient, activateClients, getLiquitClient } from '../../helpers/graph';
import { getActiveClients, getWidgets } from '../selectors';
import { authProvider } from '../../AuthProvider';

export function* getGoData() {
    yield takeEvery(actions.GET_GO_DATA, function* (action) {
        //yield put({
        //    type: widgetActions.GET_WIDGETS
        //});
    });
}

export function* setAuthProvider() {

    yield takeEvery(authActions.READY_FOR_BOOT, function* (action) {

        try {
            let provider = action.payload;

            yield put({
                type: actions.SET_AUTH_PROVIDER,
                payload: provider
            });

            const goClient = yield getGoClient(provider);
            if (goClient != null) {
                yield put({
                    type: actions.SET_GO_CLIENT,
                    payload: goClient
                });
            }

            const graphClient = yield getGraphClient(provider);
            if (graphClient != null) {
                yield put({
                    type: actions.SET_GRAPH_CLIENT,
                    payload: graphClient
                });
            }

            if (window.tenantConfig.liquit) {
                const liquitClient = yield getLiquitClient(provider);
                if (liquitClient != null) {
                    yield put({
                        type: actions.SET_LIQUIT_CLIENT,
                        payload: liquitClient
                    });
                }
            }

            yield put({
                type: widgetActions.GET_WIDGETS
            });

        } catch (error) {
            console.log(error);
        }
    });
}

export function* setGraphClient() {
    yield takeEvery(actions.SET_GRAPH_CLIENT, function* (action) {

        yield put({
            type: userActions.GET_USER_PROFILE
        });

    });
}

export function* setGoClient() {
    yield takeEvery(actions.SET_GO_CLIENT, function* (action) {

        yield put({
            type: actions.GET_GO_DATA
        });

        //yield put({
        //    type: commandActions.START_COMMANDS
        //});
    });
}

export default function* rootSaga() {
    yield all([
        //fork(getUserProfile),
        fork(getGoData),
        fork(setGraphClient),
        fork(setGoClient),
        fork(setAuthProvider),
        //fork(loginFailed),
        //fork(loginError),
        //fork(aquireAccessTokenError),
        //fork(aquireIDTokenError)
    ]);
}
