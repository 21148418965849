const actions = {
	SET_ACTIVE_YEAR_OVERVIEW_POPUP : 'SET_ACTIVE_YEAR_OVERVIEW_POPUP',
	SET_POPUP_YEAR_OVERVIEW_DATA: 'SET_POPUP_YEAR_OVERVIEW_DATA',

	setActiveYearOverviewPopup: (payload, data = false) => ({
		type: actions.SET_ACTIVE_YEAR_OVERVIEW_POPUP,
		payload : {
			item : payload,
			data : data
		}
	}),
};

export default actions;
