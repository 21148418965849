import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import TutorialSlider from '../layout/tutorialSlider';
import { Offline, Online } from "react-detect-offline";
import * as Styled from './styled';
import Header from '../layout/header';
import PageLoader from '../layout/loader/page';
import authActions from '../../redux/auth/actions';
import userActions from '../../redux/user/actions';
import graphActions from '../../redux/client/actions';
import layoutActions from '../../redux/layout/actions';
import slideOutActions from '../../redux/slideOut/actions';
import searchActions from '../../redux/search/actions';

import Bubble from '../layout/bubble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuestion, faBrush, faConciergeBell, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import Notifier from '../layout/notifier';
const Home = React.lazy(() => import('../pages/home'));
const Heading = React.lazy(() => import('../layout/headingSlideout'));
const SlideOutRenderer = React.lazy(() => import('../layout/slideOut/renderer'));
const PopupRenderer = React.lazy(() => import('../layout/popupWrapper/renderer'));
const SlideOutBottomRenderer = React.lazy(() => import('../layout/slideOutBottom/renderer'));
const SlideOutOverlayRenderer = React.lazy(() => import('../layout/slideOutOverlay/renderer'));
const YearOverview = React.lazy(() => import('../widget/multiEvent/yearOverview/index'));
const SearchBar = React.lazy(() => import('../layout/search/bar'));
const OnlineHelp = React.lazy(() => import('../pages/onlineHelp'));
const SelfService = React.lazy(() => import('../pages/selfService'));
const Store = React.lazy(() => import('../pages/store'));
const Details = React.lazy(() => import('../common/widgets/details'));
const Form = React.lazy(() => import('../pages/selfService/interface/news/form'));
const StoreActionMapper = React.lazy(() => import('../pages/store/actionMapper'));
import { IntlActions } from 'react-redux-multilingual'
import moment from 'moment/min/moment-with-locales';
import translations from '../../translate/translations'
import { findAll, parseSharepointUrl } from '../../helpers/functions';

import action from '../../redux/actions'
//import FeatureSlider from '../featureSlider';

const actions = {
    ...authActions,
    ...graphActions,
    ...slideOutActions,
    ...layoutActions,
    ...searchActions,
    ...userActions
};

class Main extends Component {
    state = {
        authenticated: false,
        loaded: false,
        debugCheck: false,
        failure: false,
        error: '',
        site: false //activate sharepoint
    };

    componentDidMount() {
        if(window.clearPersist) {
            this.props.actions.clear();
            location.reload();
        }
        toast.configure();
        //this._setLanguage();
        if (!this.props.failure) {
            this._doAuthenticationAndBoot();
        } else {
            this.setState({ failure: true, error: this.props.failure });
        }

        //window.tenantConfig.version = "3.0.0";

        document.addEventListener('scroll', this._userScrolled);
        window.addEventListener("visibilitychange", this.checkVisibility);
        this.setDebuggingMode();
        if (this.props.darkmodeAuto) {
            this.props.actions.startDarkModeAuto();
        } else {
            this.props.actions.stopDarkModeAuto();
            if(this.props.darkmodeSettings) {
                this.props.actions.setDarkMode(this.props.darkmodeSettings);
            } 
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this._userScrolled);
        window.removeEventListener("visibilitychange", this.checkVisibility);
    }

    setDebuggingMode() {
        //if (this.props.user?.debugging) {
        const debugging = this.props.user?.debugging;
        let self = this;
        let buffer = [];
        var lastDebug = moment();

        var log = console.log;
        var warning = console.warn;
        var error = console.error;

        const pushDebugging = async () => {
            this.props.actions.addDebugData(buffer);
            buffer = [];
        };

        (function () {

            var logger = function () {
                var args = Array.from(arguments);
                let curTime = moment();

                for (let i in args) {
                    if (typeof args[i] !== 'string' && args[i]?.body == null) {
                        args[i] = JSON.stringify(args[i]);
                    } else if (args[i]?.body) { //response object
                        if (args[i].url && args[i].url.indexOf('user/debugging') != -1) return;
                        args[i] = JSON.stringify({ status: args[i].status, url: args[i].url, type: args[i].type, statusText: args[i].statusText, headers: { ...args[i].headers } });
                    }

                    if (i > 0) {
                        args[0] += " | " + args[i];
                    }
                }

                const log_prefix = "[" + new Date().toISOString() + "]";

                args.unshift(log_prefix + ": ");
                buffer.push(args);

                if (this.level == 'log') log.apply(console, args);
                if (this.level == 'warning') {
                    warning.apply(console, args);

                }
                if (this.level == 'error') {
                    error.apply(console, args);
                    pushDebugging();
                }


                if (curTime.diff(lastDebug, 'seconds') > 20 || buffer.length > 480) {
                    pushDebugging();
                    lastDebug = moment(); //reset
                }
            };

            if (debugging) {
                console.log = Function.prototype.bind.call(logger, { ...console, level: 'log' });
                console.warn = Function.prototype.bind.call(logger, { ...console, level: 'warning' });
            }

            if (window.tenantConfig.version !== 'development') {
                //console.error = Function.prototype.bind.call(logger, { ...console, level: 'error' }); //always push errors to backend!
            }
        })();
    }


    checkVisibility = () => {
        if (!document.hidden) {
            this.props.actions.getUserProfile();
        }
    }

    _userScrolled = (e) => {
        if (this.props.searchbarActive) {
            this.props.actions.toggleSearchBarActive(false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.loaded && this.props.Clients.GraphClient && this.props.Clients.GoClient) {
            this._checkTenantConfigured();
            this._setLanguage();
            this.setState({ loaded: true });
        }

        if (prevProps.failure && !this.props.failure) {
            if (this.props.authService) {
                this._doAuthenticationAndBoot();
                this.setState({
                    ...this.state,
                    failure: false,
                    error: false
                })
            }
        }

        // if ((!prevProps.user && this.props.user) || prevProps.user.debugging !== this.props.user.debugging) {
        //    this.setDebuggingMode();
        //}



        /*    if (prevProps.widgets?.left?.length == 0 && this.props.widgets?.left?.length != 0) {
                this._setSharePoint();
            }*/
    }

    /*    _setSharePoint() {
            let sources = findAll(this.props.widgets, "sharepoint");
            for (let s of sources) {
                if (s.source == "sharepoint" && s.site) {
                    this.setState({ site: parseSharepointUrl(s.site) });
                    break;
                }
            }
        }*/

    _setLanguage() {
        //set user language

        let savedLocale = this.props.savedLocale;

        if (savedLocale == null) {
            let locale = this.props.locale;

            // if (savedLocale == null) {

            for (let l of navigator.languages) {
                if (translations[l] != null) {
                    locale = l;
                    break;
                }
            }

            if (locale == null) locale = 'en';
            this.props.actions.setLocale(locale);
            //this.props.actions.setSettingValue(locale, 'locale');
            moment.locale(locale);
        } else {
            //moment.locale(savedLocale);
        }
    }

    _doAuthenticationAndBoot(prevProps) {
        if (!this.state.authenticated && this.props.authService.account) {
            this.setState({ authenticated: true });
            this.props.actions.readyForBoot(this.props.authService);
        }

        //need to login
        if (!this.props.authService.account) {
            this.props.authService.SignIn();
        }
    }

    _checkTenantConfigured = async () => {
        if (!window.tenantConfig.common) {
            window.tenantConfig.isTenantConfigured = true;
            return; //skip
        }
        let token = await this.props.Clients.GoClient.getToken();

        let headers = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }
        fetch(window.apiURL + '/api/isconfigured', headers)
            .then((response) => {
                if (response.ok) {
                    return response.ok;
                } else {
                    console.log('response failed');
                }
            })
            .then((data) => {
                window.tenantConfig.isTenantConfigured = data;
            })
            .catch((error) => {
                window.tenantConfig.isTenantConfigured = false;

                console.log(`'${error}' happened!`);
            })

        /*
        if (!response.ok) {
            console.log('response failed');
            return null;
        }
    
        const data = await response.json().catch(err => {
            console.log(`'${err}' happened!`);
            return null;
        });
    
        window.tenantConfig.isTenantConfigured = data;*/
    }
    //Checks whether settings in GoPlatform allow for chatbot to be displayed
    _renderChatbot = () => {
        if(window.tenantConfig.hulpDeactivated !== "true") {
            if(window.tenantConfig.chatbot == undefined || window.tenantConfig.chatbot === "true") {
                return (
                    <Styled.Chatbot onClick={() => { this.props.actions.setActiveSlideOut('goapps/chatbot'); }}>
                        <img src="https://gocnd.azureedge.net/gowebsite/Isaac_bot_GO-01.svg" />
                        <span>Hulp?</span> 
                    </Styled.Chatbot>
                    )
            }
        }
        return null
    }

    _renderLoader = () => {
        return (
            <Styled.Loader className={'loader loader--visible'}>
                <Online polling={{ url: "https://ipv4.icanhazip.com" }}>
                    <Styled.LoaderText>Bijna klaar voor een productieve werkdag!</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Online>
                <Offline polling={{ url: "https://ipv4.icanhazip.com" }}>
                    <Styled.LoaderText>Internetverbinding verloren</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Offline>
            </Styled.Loader>
        );
    }
    _renderApplication = () => {

        if (!this.props.Clients.GraphClient || !this.props.Clients.GoClient && !this.props.isLoaded) {
            this._renderLoader()
        }

        return (
            <Styled.Wrapper >
                <Helmet>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900&display=swap" />
                    <link rel="stylesheet" href="https://shellprod.msocdn.com/shellux/o365/versionless/suiteux.shell.shared.755cc61033af641eee20c17cb76cb21e.css" />
                </Helmet>
                <Notifier />
                {!this.props.isLoaded ? this._renderLoader() : null}

                <Header hiddenMobileSlideout={(this.props.activeSlideOut ? true : false)} />
                <Header fixed={true} active={(this.props.activeSlideOut ? true : false)} />
                <Styled.Body className={(this.props.darkmode ? 'darkmode' : '')}>
                    <Switch >
                        <Route
                            exact
                            path="/"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <Home />
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/start"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <Home />
                                </Suspense>
                            }
                        />

                        <Route
                            path="/online-hulp*"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <OnlineHelp />
                                </Suspense>
                            }
                        />

                        <Route
                            exact
                            path="/self-service"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <SelfService />
                                </Suspense>
                            }
                        />

                        <Route
                            exact
                            path="/self-service/*"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <SelfService />
                                </Suspense>
                            }
                        />

                        <Route
                            exact
                            path="/store"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <Store />
                                </Suspense>
                            }
                        />
                        <Route
                            exact
                            path="/store/solution/:url"
                            render={(props) =>
                                <Suspense fallback={<PageLoader />}>
                                    <StoreActionMapper />
                                </Suspense>
                            }
                        />
                    </Switch>

                    <Suspense fallback={<div></div>}>
                        <SlideOutRenderer />
                    </Suspense>

                    <Suspense fallback={<div></div>}>
                        <SlideOutBottomRenderer />
                    </Suspense>

                    <Suspense fallback={<div></div>}>
                        <SlideOutOverlayRenderer />
                    </Suspense>

                    <Suspense fallback={<div></div>}>
                        <PopupRenderer />
                    </Suspense>

                    <Suspense fallback={<div></div>}>
                        <YearOverview />
                    </Suspense>

                    <TutorialSlider />

                    {/*this.state.site && <iframe src={this.state.site}></iframe>*/}


                </Styled.Body>
                {this._renderChatbot()}

                <Styled.NavBar className={(this.props.darkmode ? 'darkmode' : '')}>
                    <Styled.NavBarList>
                        <Styled.NavBarListItem>
                            <NavLink exact to="/start">
                                <div>
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                                <span>Start</span>
                            </NavLink>
                        </Styled.NavBarListItem>

                        {window.tenantConfig.hulpDeactivated !== "true" &&
                            <React.Fragment>
                                <Styled.NavBarListItem>
                                    <NavLink exact to="/online-hulp">
                                        <div>
                                            <FontAwesomeIcon icon={faQuestion} />
                                        </div>
                                        <span>Hulp</span>
                                    </NavLink>
                                </Styled.NavBarListItem>
                                {/*<Styled.NavBarListItem>
                                <NavLink to="/self-service">
                                    <div>
                                        <FontAwesomeIcon icon={faBrush} />
                                    </div>
                                    <span>Zelf</span>
                                </NavLink>
                            </Styled.NavBarListItem>*/}
                                <Styled.NavBarListItem>
                                    <NavLink exact to="/online-hulp/mijn-meldingen">
                                        <div>
                                            {this.props.calls ? <Bubble number={this.props.calls?.length} /> : null}
                                            <FontAwesomeIcon icon={faConciergeBell} />
                                        </div>
                                        <span>Meldingen</span>
                                    </NavLink>
                                </Styled.NavBarListItem>
                            </React.Fragment>
                        }
                    </Styled.NavBarList>
                </Styled.NavBar>
            </Styled.Wrapper>
        );
    }


    render() {
        //if (this.provider.authenticationState === "Authenticated") return this._renderApplication();

        if (this.state.failure) {
            return (
                <Styled.Loader className={'loader loader--visible'}><Styled.LoaderText>Er is een fout opgetreden! <br /><small>{this.state.error}</small></Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Styled.Loader>)
        }

        if (this.state.authenticated && this.state.loaded) {
            return this._renderApplication();
        }

        if (!this.state.authenticated && !this.state.loaded) {
            return (
                <Styled.Loader className={(this.props.isLoaded ? 'loader loader--hidden' : 'loader loader--visible')}><Styled.LoaderText>Bezig met authenticeren..</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Styled.Loader>)
        }

        if (this.state.authenticated && !this.state.loaded) {
            return (
                <Styled.Loader className={(this.props.isLoaded ? 'loader loader--hidden' : 'loader loader--visible')}><Styled.LoaderText>Bezig met authenticeren..</Styled.LoaderText>
                    <img alt={"Loader"} src="https://gocnd.azureedge.net/gowebsite/Go.svg" />
                </Styled.Loader>)
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        isUnmounting: state.SlideOut.isUnmounting,
        activeOverlaySlideOut: state.SlideOut.activeOverlaySlideOut,
        sliderHidden: state.Layout.sliderHidden,
        isLoaded: state.Layout.IsLoaded,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto,
        searchbarActive: state.Search.searchbarActive,
        Clients: state.Client,
        calls: state.Calls.items,
        appOnline: state.App.appOnline,
        locale: state.Intl.locale,
        savedLocale: state.User.profile.data?.settings?.locale,
        user: state.User.profile,
        widgets: state.Widgets.widgets,
        darkmodeSettings: state.User.profile?.data?.settings?.darkmode,
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...IntlActions, ...userActions, ...action }, dispatch) });
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
