import { all, fork, takeEvery, select, put } from 'redux-saga/effects';
import actions from './actions';
import { getGOClient, getAuth, getTenantEventsItems, getCommonEventsItems } from '../../selectors';
import messageActions from '../../message/actions';
import localforage from 'localforage';
import store from "../../store";
import { fileToBase64, blobToBase64 } from '../../../helpers/functions';


//export function* setActiveCommonEvent() {
//    yield takeEvery(actions.SET_ACTIVE_COMMON_EVENT, function* (action) {
//        yield put({
//            type: slideOutActions.SET_ACTIVE_SLIDEOUT,
//            payload: 'CommonEvent'
//        });
//    });
//}

export function* getCommonEvents() {
    yield takeEvery(actions.GET_COMMON_EVENTS, function* (action) {

        yield put({
            type: actions.COMMON_EVENTS_REQUEST_COMPLETED,
            payload: false
        });
        const Client = yield select(getGOClient);

        if (!Client) return;

        const eventResponse = yield Client.api('/common/events/list');

        yield put({
            type: actions.COMMON_EVENTS_REQUEST_COMPLETED,
            payload: true
        });

        yield put({
            type: actions.SET_COMMON_EVENTS,
            payload: eventResponse
        });

    });
}

export function* getTenantEvents() {
    yield takeEvery(actions.GET_TENANT_EVENTS, function* (action) {

        const tenantEventsItems = yield select(getTenantEventsItems);
        const eventsItems = yield select(getCommonEventsItems);
        const Client = yield select(getGOClient);

        if (!Client) return;
        const events = yield Client.api("/common/events/tenant");

        if (events == null || !events) return;


        if (events.length == 0 || (events.length && ((tenantEventsItems.length === 0) || (tenantEventsItems && tenantEventsItems[0].Id !== events[0].id)))) {
            //localforage.clear(); //clear local storage

            if (tenantEventsItems) {
                if ( eventsItems?.length ) {
                    //yield eventsItems.forEach(n => {
                    for (let n of eventsItems) {
                        yield localforage.removeItem(n.id);
                    }
                }

            }

            for (let n of events) {
                try {
                    let blob = dataURItoBlob(n.image);
                    yield localforage.setItem(n.id, blob);
                    delete n.image;
                } catch  {
                    delete n.image;
                }
            }

            yield put({
                type: actions.SET_TENANT_EVENTS,
                payload: events
            });
        }


    });
}


export function* postEvent() {
    yield takeEvery(actions.POST_COMMON_EVENT, function* (action) {

        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        if (!GoClient) return;

        let formFields = {
            title: action.payload.title,
            link: action.payload.link,
            authorName: Auth.authProvider.account.name,
            authorId: Auth.authProvider.account.localAccountId,
            startDate: new Date(action.payload.startDate).toISOString(),
            endDate: new Date(action.payload.endDate).toISOString(),
            content: action.payload.content

        }
        let method = 'POST';
        if (action.payload.file) {
            const base64 = yield fileToBase64(action.payload.file[0]);

            formFields = {
                ...formFields,
                image: base64
            }
        }

        if (action.payload.id) {
            method = 'PUT';
            formFields = {
                ...formFields,
                id: action.payload.id,
                authorName: action.payload.authorName,
                authorId: action.payload.authorId
            }

            if (!action.payload.file && action.payload.currentImage) {
                const base64 = yield blobToBase64(action.payload.currentImage);

                formFields = {
                    ...formFields,
                    image: base64
                }
            }
        }

        let result = false;
        if (method === 'POST') {
            result = yield GoClient.post('/common/events', formFields, true);
        } else {
            result = yield GoClient.put('/common/events', formFields, true);
        }
        if (result) {
            if (result.ok) {
                yield put({
                    type: actions.GET_COMMON_EVENTS
                })
                if (method === 'PUT') {
                    yield put({
                        type: messageActions.MESSAGE,
                        payload: {
                            message: 'Event geupdated',
                            type: 'success'
                        }
                    });
                } else {
                    yield put({
                        type: messageActions.MESSAGE,
                        payload: {
                            message: 'Event toegevoegd',
                            type: 'success'
                        }
                    });
                }
                store.history.push('/self-service/event');
            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}


export function* deleteEvents() {
    yield takeEvery(actions.DELETE_EVENTS_ITEM, function* (action) {

        const GoClient = yield select(getGOClient);

        const result = yield GoClient.delete('/common/events?id=' + action.payload, action.payload , true);

        if (result) {
            if (result.ok) {
                yield put({
                    type: actions.GET_TENANT_EVENTS
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Event verwijderd',
                        type: 'success'
                    }
                });
                store.history.push('/self-service/event');

            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }

    });
}


export default function* rootSaga() {
    yield all([
        fork(getCommonEvents),
        fork(getTenantEvents),
        fork(deleteEvents),
        fork(postEvent)
    ]);
}
