import actions from './actions';

const initState = {
    AuthProvider: false,
    GraphClient: false,
    GoClient: false,
    PowerClient: false,
    SharepointClient: false,
    YammerClient: false,
    LiquitClient: false,
    Auth: false,
    aadResponse: null,
    IsLoaded: false,
    clientActivated: false
};


export default function clientReducer(state = initState, action) {

    switch (action.type) {
        case actions.AAD_LOGIN_SUCCESS:
            return {
                ...state,
                aadResponse: action.payload
            };

        case actions.AAD_LOGOUT_SUCCESS:
            return {
                ...state,
                aadResponse: null
            };

        case actions.CLIENTS_ACTIVATED:
            return {
                ...state,
                clientsActivated: action.payload
            };

        case actions.AAD_ACQUIRED_ID_TOKEN_ERROR:
            return {
                ...state,
                aadResponse: action.payload
            };
        case actions.AAD_ACQUIRED_ACCESS_TOKEN_ERROR:
            return {
                ...state,
                aadResponse: action.payload
            };
        case actions.AAD_LOGIN_FAILED:
            return {
                ...state,
                aadResponse: null
            };
        case actions.AAD_LOGIN_ERROR:
            return {
                ...state,
                aadResponse: action.payload
            };

        case actions.SET_AUTH_PROVIDER:
            return {
                ...state,
                AuthProvider: action.payload
            };

        case actions.SET_AUTH:
            return {
                ...state,
                Auth: action.payload
            };

        case actions.SET_GRAPH_CLIENT:
            return {
                ...state,
                GraphClient: action.payload
            };

        case actions.SET_LIQUIT_CLIENT:
            return {
                ...state,
                LiquitClient: action.payload
            };

        case actions.SET_YAMMER_CLIENT:
            return {
                ...state,
                YammerClient: action.payload
            };

        case actions.SET_POWER_CLIENT:
            return {
                ...state,
                PowerClient: action.payload
            };

        case actions.SET_GO_CLIENT:
            return {
                ...state,
                GoClient: action.payload
            };

        case actions.SET_SHAREPOINT_CLIENT:
            return {
                ...state,
                SharepointClient: action.payload
            };

        default:
            return state;
    }
}
