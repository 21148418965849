import styled from 'styled-components'
import { Loader } from '../styled';
export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

export const Header = styled.div `
    margin: 0 13px 13px 0;
    height: 19px;
    width: 200px;

    ${props => props.position === 'left' ? 'float: left' : 'float: right'};
    ${Loader}
`;
