import React from 'react';
import * as Styled from './styled';


const TeamLoader = props => (
	<Styled.TeamWrapper>
		<Styled.Team className={(props.darkmode ? 'darkmode' : '')} position={props.position} />
	</Styled.TeamWrapper>
)



export default TeamLoader;
