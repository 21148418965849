import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import layoutActions from '../../../../../redux/layout/actions';


class VideoSlide extends Component {


    _setDarkMode = () => {
        this.props.actions.setDarkMode(!this.props.darkmode);
    }

    render() {
       return (
            <React.Fragment>
               <iframe title={"GO"} style={{marginTop: '30px'}} width="560" height="315" src="https://www.youtube.com/embed/hx6V6650UpE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User,
        activeSlideOut: state.SlideOut.activeSlideOut,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...layoutActions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoSlide);
