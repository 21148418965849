import actions from './actions';

const initState = {
	feeds : [],
	activeFeedItem : false,
	requestCompleted : false,
};


export default function multiChannelFeedReducer(state = initState, action) {

	switch (action.type) {
		case actions.FEED_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted : action.payload
			}
		case actions.SET_MULTICHANNEL_FEEDS:
			return {
				...state,
				feeds : action.payload
			}
		case actions.SET_ACTIVE_FEED_ITEM:
            return {
                ...state,
                activeFeedItem: action.payload
            }
		default:
			return state;
	}
}
