import actions from './actions';

const initState = {
    notifications: {},
    numberOfNotifications: 0,
    Hub: false,
    Send: false,
    Received: false,
    Commands: false,
    applications: {},
    application: false,
    isServiceConnected: false,
    isConnected: false
};

export default function notificationReducer(state = initState, action) {

    switch (action.type) {
        case actions.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        case actions.SET_NUMBER_OF_NOTIFICATIONS:
            return {
                ...state,
                numberOfNotifications: action.payload
            };
        case actions.REMOVE_ALL_NOTIFICATIONS:
            return {
                ...state,
                notifications: {},
                numberOfNotifications: 0
            };
        case actions.SEND_CHECK_COMMAND:
            return {
                ...state,
                Send: action.payload
            };
        case actions.SEND_COMMAND:
            return {
                ...state,
                Send: action.payload
            };
        case actions.RECEIVE_COMMAND:
            return {
                ...state,
                Received: action.payload
            };
        case actions.SET_HUB:
            return {
                ...state,
                Hub: action.payload
            };
        case actions.SERVICES_CONNECTED:
            return {
                ...state,
                isServiceConnected: action.payload
            };
        case actions.SET_CONNECTED:
            return {
                ...state,
                isConnected: action.payload
            };
        case actions.SET_APPLICATIONS:
            return {
                ...state,
                applications: action.payload
            };
        case actions.SET_APPLICATION:
            return {
                ...state,
                application: action.payload
            };
        default:
            return state;
    }
}
