import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import * as Styled from './styled';
import actions from '../../../../redux/slideOut/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Bubble from '../../../layout/bubble';

class SettingsButton extends Component {
	constructor ( props ) {
		super ( props );
		this.state = {
			shakeBubble : false
		}
	}
	componentDidUpdate(prevProps) {
		if ( this.props.numberOfNotifications > prevProps.numberOfNotifications ) {
			this.setState({
				shakeBubble : true
			})

			
			setTimeout(
			    function() {
			        this.setState({shakeBubble: false});
			    }
			    .bind(this),
			    820
			);

		}
	}

	render() {
		return (
			<Styled.ButtonWrapper parent={this.props.parent}>
				{ this.props.notifications ? <Bubble style={{position: 'absolute', right: '-5px', top: '-5px', zIndex: 1}} number={this.props.numberOfNotifications} /> : null }
				<Styled.Notifications className={(this.state.shakeBubble ? 'shake' : 'no-shake')} onClick={() => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'notifications' ? false : 'notifications'))}>
					<FontAwesomeIcon icon={faBell} />
				</Styled.Notifications>
			</Styled.ButtonWrapper>
		) 
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
    	activeSlideOut : state.SlideOut.activeSlideOut,
    	notifications : state.Notifications.notifications,
    	numberOfNotifications : state.Notifications.numberOfNotifications
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps,mapDispatchToProps, null)(SettingsButton)



