import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../../user/actions';
import { getGOClient, getUserProfileSettingData } from '../../selectors';

export function* getCalls() {
    yield takeEvery(actions.GET_CALLS, function* (action) {
        yield put({
          type : actions.CALLS_REQUEST_COMPLETD,
          payload : false
        })
        const Client = yield select(getGOClient);

        if (!Client) return;

        const calls = yield Client.api("/user/calls");

        if (calls == null || !calls) return;

        //if (calls.length) { //&& ((state.Calls.items.length === 0) || (state.Calls.items && state.Calls.items[0].Id !== calls[0].id))) {
        yield put({
            type: actions.SET_CALLS,
            payload: calls
        });

        yield put({
          type : actions.CALLS_REQUEST_COMPLETD,
          payload : true
        })
        //Updating the calls information used for self service page
        const userProfileData = yield select(getUserProfileSettingData);
        let serviceRequests = userProfileData?.settings?.calls
        if(serviceRequests) {
            calls.forEach(call => {
                let index = serviceRequests.findIndex(serviceRequest => serviceRequest.callId === call.callId )
                if(index > -1) {
                    if(serviceRequests[index].status === "Geannuleerd" && call.status === "Afgesloten") {
                        serviceRequests[index].status = call.status;   
                    } else if(serviceRequests[index].status !== "Geannuleerd") {
                        serviceRequests[index].status = call.status;
                    }
                }
            });

            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: serviceRequests,
                    setting: 'calls'
                }
            });
        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(getCalls)
    ]);
}
