const actions = {
	GET_TILES : 'GET_TILES',
	SET_TILES : 'SET_TILES',
	getTiles: (payload) => ({ 
		type: actions.GET_TILES,
		payload : payload
	}),	
};

export default actions;
