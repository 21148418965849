import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from './styled';
import { bindActionCreators } from 'redux';
import dark from '../../../../../assets/img/dark.svg';
import light from '../../../../../assets/img/light.svg';
import Checkbox from '../../../checkbox';
import layoutActions from '../../../../../redux/layout/actions';

class DarkmodeSlide extends Component {


    _setDarkMode = () => {
        this.props.actions.setDarkMode(!this.props.darkmode);
    }

    render() {
        return (
            <React.Fragment>
                <Styled.SlideTitle>GO Darkmode</Styled.SlideTitle>
                <Styled.SlideDescription>Welke modus vindt je fijner?</Styled.SlideDescription>
                <Styled.DarkModeSelector>
                    <Styled.DarkModeSelectorColumn onClick={() => { this.props.actions.setDarkMode(false) }}>
                        <img alt="Go Light" src={light} />
                        <p>Standaard hebben we GO een lichte jas aangetrokken. Wij vinden dat het uitstekend staat.</p>
                    </Styled.DarkModeSelectorColumn>
                    <Styled.DarkModeSelectorColumn onClick={() => { this.props.actions.setDarkMode(true) }}>
                        <img alt="Go Dark" src={dark} />
                        <p>Of kies voor een donkere modus, die fijner aan je ogen is in het donker</p>
                    </Styled.DarkModeSelectorColumn>
                </Styled.DarkModeSelector>

                <Styled.DarkModeCheckbox className={(this.props.darkmode ? 'darkmode' : '')}>
                    <p>Dag</p>
                    <Checkbox onChange={this._setDarkMode} checked={(this.props.darkmode ? 'checked' : '')} />
                    <p>Nacht</p>
                </Styled.DarkModeCheckbox>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User,
        activeSlideOut: state.SlideOut.activeSlideOut,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...layoutActions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DarkmodeSlide);
