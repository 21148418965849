import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from './styled';
import { bindActionCreators } from 'redux';
import dark from '../../../../../assets/img/dark.svg';
import light from '../../../../../assets/img/light.svg';
import Checkbox from '../../../checkbox';
import layoutActions from '../../../../../redux/layout/actions';
import userActions from '../../../../../redux/user/actions';

class ContentSlide extends Component {
    render() {
        return (
            <React.Fragment>
                <Styled.SlideTitle>{this.props.title}</Styled.SlideTitle>
                <Styled.SlideDescription dangerouslySetInnerHTML={{ __html: this.props.description }} />
            </React.Fragment>
        );
    }
}

export default ContentSlide;
