import React, { Component } from 'react';
import SearchBar from '../bar';
import * as Styled from './styled';
import { connect } from 'react-redux';
class CollapsibleSearch extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchValue : false
		}
	}

	_changeSearchInput = ( e ) => {
		this.setState({
			searchValue: e.target.value
		});
	}

	render() {
		return (
			<Styled.SearchWrapper  className={(this.props.searchbarActive ? 'active' : '')}>
				<SearchBar wrapped/>
			</Styled.SearchWrapper>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
		searchbarActive: state.Search.searchbarActive,
    };
}


export default connect(mapStateToProps)(CollapsibleSearch);
