const actions = {
	GET_COMMON_NEWS : 'GET_COMMON_NEWS',
	SET_COMMON_NEWS : 'SET_COMMON_NEWS',
	SET_ACTIVE_NEWS_ITEM : 'SET_ACTIVE_NEWS_ITEM',
	POST_COMMON_NEWS : 'POST_COMMON_NEWS',
	GET_TENANT_NEWS : 'GET_TENANT_NEWS',
	SET_TENANT_NEWS : 'SET_TENANT_NEWS',
	GET_NEWS_FROM_CHANNEL : 'GET_NEWS_FROM_CHANNEL',
	DELETE_NEWS_ITEM : 'DELETE_NEWS_ITEM',
	TEST_COMMON_NEWS : 'TEST_COMMON_NEWS',
	COMMON_NEWS_REQUEST_COMPLETED: 'COMMON_NEWS_REQUEST_COMPLETED',

	postCommonNews : (payload) => ({
		type : actions.POST_COMMON_NEWS,
		payload : payload
	}),

	getTenantNews : () => ({
		type : actions.GET_TENANT_NEWS
	}),

	getCommonNews : ( payload ) => ({
		type : actions.GET_COMMON_NEWS,
		payload : payload
	}),

	setActiveNewsItem : ( payload ) => ({
		type : actions.SET_ACTIVE_NEWS_ITEM,
		payload : payload
	}),

	deleteItem : (payload) => ({
		type : actions.DELETE_NEWS_ITEM,
		payload : payload
	})
};

export default actions;
