import React, { Component } from 'react';
import * as Styled from './styled';
import BirthdayItem from './birthdayItem';
import BirthdaySlideoutItem from './birthdaySlideoutItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import birthdayActions from '../../../redux/birthday/actions';
import { getMS } from '../../../helpers/functions';
import * as Layout from '../../layout/widget/styled';
import slideoutActions from '../../../redux/slideOut/actions';
import userActions from '../../../redux/user/actions';
import Heading from '../../layout/headingSlideout';
import { getUserApplicationSettings, openPopup } from '../../../helpers/launchApp';
import { withTranslate } from 'react-redux-multilingual';
import Search from '../../layout/search/slideOut';
import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScrollWrapper from '../../layout/infiniteScrollWrapper';

class Birthdays extends Component {
    constructor(props) {
        super(props);

        this.state = {
            birthdays: [],
            clients: false,
            searchTerm: '',
            listHeight: false
        };
        this.perPage = 10;
        this.page = 0;

    }

    componentDidMount() {
        this._getBirthdays();
        if (this._getListHeight()) {
            this.setState({
                ...this.state,
                listHeight: this._getListHeight()
            })
        }

        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(this._getBirthdays.bind(this), getMS(15));

        window.addEventListener('resize', this._handleResize)
    }

    componentDidUpdate(prevProps) {
        const height = this._getListHeight();
        if (height && height != this.state.listHeight) {
            this.setState({
                ...this.state,
                listHeight: this._getListHeight()
            })
        }

        window.addEventListener('resize', this._handleResize)
    }

    _handleResize = () => {
        if (this._getListHeight()) {
            this.setState({
                ...this.state,
                listHeight: this._getListHeight()
            })
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    _fetchUserPhoto = async () => {
        const userPhoto = await Client.api('/users/' + id + '/photo/$value', 'arrayBuffer');
        let parsedUserPhoto = null;
        if (userPhoto !== null) parsedUserPhoto = 'data:image/png;base64, ' + new Buffer(userPhoto, 'binary').toString('base64');
        return parsedUserPhoto;
    }

    _fetchMoreData = () => {
        //this.props.actions.fetchNextPage({skip:(this.page * this.perPage),take: this.perPage});
    }

    parseItems(items) {
        this.setState({
            items: items,
            scrollItems: [...items].slice(0, this.perPage),
        });
    }

    _setSearchTerm = (e) => {
        this.setState({
            ...this.state,
            searchTerm: e.target.value
        })
    }

    _filterSlideItems = (items) => {
        let filteredItems = [];
        let searchTerm = this.state.searchTerm;
        let lowercased = false
        if (searchTerm) {
            lowercased = searchTerm.toLowerCase();
            let returnItems = [];
            items.forEach((item, i) => {
                const flattened = JSON.stringify(item).toLowerCase();

                if (flattened.includes(lowercased)) {
                    returnItems.push(item);
                }
            });
            return returnItems;
        }
        return items;
    }

    _getBirthdays() {
        this.props.actions.getBirthDays('/dashboard/birthdays');
        //if (this.timer) clearInterval(this.timer);
        //this.timer = setInterval(this._getBirthdays.bind(this), getMS(360));
    }

    _mapBirthdays = (birthday, key, length) => {
        return <BirthdayItem popup={this.props.popup} small={(length ? length : this.props.birthdays.length > 1)} when={birthday.when} icon={birthday.avatar} name={birthday.name} key={key} />;
    }

    _mapBirthdaysSlide = (birthday, key) => {
        return <BirthdaySlideoutItem popup={this.props.popup} small={(this.props.birthdays.length > 1)} when={birthday.when} icon={birthday.avatar} name={birthday.name} id={birthday.id} key={key} day={birthday.date} avatar={ birthday.avatar} />;
    }

    _prepareDemo = (small = true) => {
        return (
            <Styled.Birthdays gridColumns={small}>
                <BirthdayItem small={small} when={this.props.translate('today')} icon={"https://gocnd.azureedge.net/demo/648x648__0000_440152_DEJON.jpg"} name={"Peter Strijker"} key={0} />
                <BirthdayItem small={small} when={this.props.translate('today')} icon={"https://gocnd.azureedge.net/demo/648x648__0003_424329_BOOVE.jpg"} name={"Sander Jonkman"} key={1} />
            </Styled.Birthdays>
        );
    }

    _openBirthdayUrl = () => {
        const { openAppInTarget, windowProperties } = getUserApplicationSettings();
        openPopup(`https://delve.office.com/?u=${this.props.userProfile.id}&v=editprofile`, openAppInTarget, windowProperties)
    }

    _getListHeight = () => {
        if (document.getElementById('popupWrapper') && document.getElementById('headerWrapper')) {
            const parentHeight = document.getElementById('popupWrapper').clientHeight;
            const headerHeight = document.getElementById('headerWrapper').clientHeight;
            const windowWidth = window.innerWidth;
            if (windowWidth <= 1024) {
                return parseInt(parentHeight) - (parseInt(headerHeight) + 25 + 66 + 52);
            }

            return parseInt(parentHeight) - (parseInt(headerHeight) + 25);
        }
        return false;
    }

    render() {
       

        if (this.props.popup) {

            if (window.tenantConfig.demo && window.tenantConfig.demo === "true") {
                return this._prepareDemo(false);
            }

            return (
                <Styled.Popup id="popupWrapper">
                    <div id="headerWrapper">
                        <Heading title={this.props.translate("birthdays")} positionBottom={true} />
                        <Search setSearchTerm={this._setSearchTerm} searchTerm={this.state.searchTerm} />
                    </div>
                    <Styled.Birthdays className={(this.props.darkmode ? 'darkmode' : '')}>
                        {/*this.props.upcomingBirthdays.length > 0 && this.props.upcomingBirthdays.map(this._mapBirthdaysSlide)*/}
                        {this.props.birthdays?.length == 0 ? <p>geen verjaardagen gevonden</p> :
                        this.state.listHeight ?
                            <Styled.ScrollWrapperElement>
                                <InfiniteScrollWrapper
                                    hasNextPage={(this.props.birthdays.length > 0 && (this.props.birthdays.length / this.perPage) >= this.page)}
                                    isNextPageLoading={this.props.fetching}
                                    items={this._filterSlideItems(this.props.birthdays)}
                                    loadNextPage={this._fetchMoreData}
                                    renderItem={this._mapBirthdaysSlide}
                                    itemHeight={80}
                                    listHeight={this.state.listHeight}
                                />
                            </Styled.ScrollWrapperElement>
                            : null}
                    </Styled.Birthdays>
                </Styled.Popup>
            );
        }

        if (window.tenantConfig.demo && window.tenantConfig.demo === "true") {
            return this._prepareDemo();
        }

        if ((this.props.userProfile.birthday && this.props.userProfile.birthday != '0001-01-01T00:00:00Z') && this.props.upcomingBirthdays.filter(b => b.when == 'Morgen' || b.when == 'Vandaag').length == 0) {
            return <div />;
        }

        return (
            <React.Fragment>
                {<Styled.WidgetHeading>
                    <Layout.Title className={(this.props.darkmode ? 'darkmode' : '')}>{this.props.title} </Layout.Title>
                    <Layout.LinkContainer>
                        <Layout.Link className={(this.props.darkmode ? 'darkmode' : '')} target="_blank" onClick={() => {
                            this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'Birthdays' ? false : 'Birthdays'));
                        }} href={null}>{this.props.translate("more")}</Layout.Link>
                    </Layout.LinkContainer>
                </Styled.WidgetHeading>
                }

                {((this.props.userProfile?.birthday == '0001-01-01T00:00:00Z') && !this.props.settings.setBirthdayDismissed) ? <Styled.AddBirthdayWrapper><Styled.AddBirthdayButton onClick={this._openBirthdayUrl}>Voeg je verjaardag toe</Styled.AddBirthdayButton><Styled.Dismissbirthdaybutton onClick={() => this.props.actions.setSettingValue(true, 'setBirthdayDismissed')}>x</Styled.Dismissbirthdaybutton></Styled.AddBirthdayWrapper> : null}
                <Styled.Birthdays gridColumns={(this.props.birthdays.length > 1)}>
                    {this.props.upcomingBirthdays?.length > 0 ? this.props.upcomingBirthdays.map(this._mapBirthdays, this.props.upcomingBirthdays.length) : <BirthdayItem none />}
                </Styled.Birthdays>
            </React.Fragment>
        );  
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userProfile : state.User.profile,
        settings : state.User.profile.data?.settings,
        birthdays: state.Birthday.birthdays,
        upcomingBirthdays: state.Birthday.upcoming,
        fetching: state.Birthday.fetching,
        nextPageAvailable: state.Birthday.nextPageAvailable,
        goClient: state.Client.GoClient,
        graphClient: state.Client.GraphClient,
        locale: state.Intl.locale,
        darkmode: state.Layout.darkmode
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...userActions, ...birthdayActions, ...slideoutActions }, dispatch) });
export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(Birthdays));
