// -- Common selectors
export const getCommonNewsItems = state => state.CommonNews.newsItems;
export const getTenantNewsItems = state => state.CommonNews.tenantNewsItems;
export const getCommonBlogItems = state => state.CommonBlogs.blogs;
export const getTenantBlogItems = state => state.CommonBlogs.tenantBlogs;
export const getFaqsItems = state => state.KnowledgeBase.faqs;
export const getCommonEventsItems = state => state.CommonEvents.events;
export const getTenantEventsItems = state => state.CommonEvents.tenantEventsItems;

// -- Self Service
export const getUserRequest = state => state.UserRequest.userRequest;
export const getRightsRequest = state => state.RightsRequest.rightsRequest;
export const getWorkplaceRequest = state => state.WorkPlaceRequest.workplaceRequest;

// -- KnowledgeBase and Learning selectors
export const getKnowledgeBaseItems = state => state.KnowledgeBase.knowledgeBases;
export const getLearningItems = state => state.Learning.learnings;
export const getRandomLearningPaths = state => state.Learning.randomLearningPaths;
export const getLearningPaths = state => state.Learning.learningPaths;
export const getRoles = state => state.Learning.roles;
export const getProducts = state => state.Learning.products;
export const getLevels = state => state.Learning.levels;
export const getModule = state => state.Learning.module;


// -- Client selectors
export const getGOClient = state => state.Client.GoClient;
export const getGraphClient = state => state.Client.GraphClient;
export const getLiquitClient = state => state.Client.LiquitClient;
export const getSharepointClient = state => state.Client.SharepointClient;
export const getYammerClient = state => state.Client.YammerClient;
export const getAuthProvider = state => state.Client.AuthProvider;
export const getClientsActivated = state => state.Client.clientsActivated;

// -- Widgets selectors
export const getActiveClients = state => state.Widgets.clients;
export const getWidgets = state => state.Widgets.widgets;

// -- Auth selectors
export const getAuth = state => state.Auth;

// -- User selectors
export const getUserProfile = state => state.User.profile;
export const getDataRequest = state => state.User.requestCompleted;
export const getUserProfileSettingData = state => state.User.profile.data;
export const getFeatureSliders = state => state.User.featureSliders;
export const getLocale = state => state.Intl.locale
export const getUserRequestCompleted = state => state.User.requestCompleted;
export const getLogging = state => state.User.logging;
export const getLastDebug = state => state.User.lastDebug;

// -- Regular selectors
export const getBlogItems = state => state.Blogs.blogs;
export const getITNewsItems = state => state.ITNews.items;
export const getNewsItems = state => state.News.newsItems;

// -- Notifications
export const getNotificationItems = state => state.Notifications.notifications;
export const getNotificationsHub = state => state.Notifications.Hub;
export const getIsNotificationsIsConnected = state => state.Notifications.isConnected;

// -- NotificationsCollector
export const getNotifications = state => state.NotificationCollector.notifications;

// -- SlideOut
export const getActiveSlideOut = state => state.SlideOut.activeSlideOut;
export const isUnmounting = state => state.SlideOut.isUnmounting;

// -- Teams
export const getTeams = state => state.Teams.teams

export const getSharepoint = state => state.Sharepoint.sharepoint

export const getAppOnline = state => state.App.appOnline

export const getSolutions = state => state.Solution.solutions;

export const getTasks = state => state.Tasks.tasks;

export const getMultiChannelBlogItems = state => state.MultiChannelBlog.blogItems;
export const getMultiChannelNewsItems = state => state.MultiChannelNews.newsItems;
export const getMultiChannelShoutItems = state => state.MultiChannelShout.shoutItems;
export const getMultichannelFeeds = state => state.MultiChannelFeed.feeds;
export const getMultiChannelSocialItems = state => state.MultiChannelSocial.socialItems;
export const getMultiChannelEvent = state => state.MultiChannelEvent.events;

export const getNewsRequestCompleted = state => state.MultiChannelNews.requestCompleted;
export const getShoutsRequestCompleted = state => state.MultiChannelShout.requestCompleted;
export const getSocialRequestCompleted = state => state.MultiChannelSocial.requestCompleted;

export const getPeople = state => state.People.people;
export const getPeoplePage = state => state.People.currentPage;
export const peopleAlreadyFetching = state => state.People.fetching;
export const getPeoplePageLength = state => state.People.pageLength;

export const getUpcomingBirthdays = state => state.Birthday.upcoming;
export const getAllBirthdays = state => state.Birthday.birthdays;
export const birthdaysAlreadyFetching = state => state.Birthday.fetching;

export const getMultiChannelNews = state => state.MultiChannelNews;
