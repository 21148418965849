import styled, { css } from 'styled-components'
import * as Variables from '../../../ThemeVariables';

export const MenuList = styled.ul`
	list-style: none;
	display: grid;
	height: calc(100% + 20px);
	top: -10px;
	${props => (props.parent === 'header-col-center' || props.parent === 'header-col-right') && css`
		text-align: right;

		grid-template-columns: repeat(2, 1fr);
		@media ( min-width: ${Variables.md} ) {
			display: block;
			grid-template-columns: none;
		}
	`}

	${props => props.parent === 'slideout' && css`
		grid-template-columns: 1fr;
    	grid-gap: 20px;

	`}

`;

export const MenuButtonList = styled.ul`
	list-style: none;
	text-align: right;

`;


export const ButtonWrapper = styled.div`
grid-area: notifications;
`;
export const MenuListItemInner = styled.div`
transform: skew(25deg, 0deg);
display: flex;
align-items: center;
height: 100%;
justify-content: center;
`;
export const MenuListItem = styled.li`

	height: 100%;
	display: inline-block;
	line-height: ${Variables.headerHeight};
	font-size: 20px;
	width: 45px;
	cursor: pointer;

	transform: skew(-25deg, 0deg);

	@media ( min-width: ${Variables.md} ) {
		width: 45px;
		font-size: 20px;
	}

	&:hover {
		opacity: 0.8;
	}

	text-align: center;
	${props => props.parent === 'header-col-center' && css`
		text-align: right;
	`}
	${props => (props.parent === 'header-col-center' || props.parent === 'header-col-right') && css`
		label {
			display: none;
		}

	`}

	${props => props.parent === 'slideout' && css`
		box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03);
		line-height: 1;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: left;
		display: grid;
		border-radius: 8px;


		font-size: 22px;
		grid-template-columns: 40px 1fr;
		padding: 15px;
		span {
			margin-left: 28px !important;
			top: 16px !important;
		}
		@media ( min-width: ${Variables.xs}) {
			font-size: 30px;
			grid-template-columns: 75px 1fr;
			padding: 20px;
			span {
				margin-left: 42px !important;
			}
		}

		&.darkmode {
			background: #1B2B3C;
			color: white;

			a {
				color: white;
			}
		}
	`}
`;

export const MenuListItemLabel = styled.label`
	font-size: 20px;
`;

export const MenuButtonListItem = styled.li`
	display: inline-block;
	line-height: ${Variables.headerHeight};
	font-size: 18px;
	text-align: center;


`;

export const Link = styled.a`
	color: ${props => props.darkmode ? '#FFFFFF' : '#636a73'};

	li {
		&:before {
				background: #e9ebee;
			content: '';
			width: 100%;
			height: 0%;
			position: absolute;
			left: 0;
			top: 0;
			transition: height .5s;
		}

		&.darkmode {
			&:before {
				background: #0d131b;
			}
		}
	}

	&.btn-active, &:hover {
		li {
			&:before {
				height: 100%;

			}
		}
	}

	&.greyout {
		opacity: .3;
	}
	&:after {
		content: '';
		position: absolute;
		width: 0%;
		height: 3px;
		background: ${Variables.mainColor};
		left: 0;
		bottom: -28px;
		transition: width .5s;
	}
	&.active {
		&:after {
			width: 100%;
		}
	}

`;

export const Nav = styled.nav`
	height: 100%;
	${props => props.parent === 'header-col-center' && css`
		display: none;
		@media ( min-width: ${Variables.xlg}) {
			display: block;
		}

	`}

	${props => props.parent === 'header-col-right' && css`
		display: none;
		@media ( min-width: ${Variables.md}) {
			display: block;
		}
		@media ( min-width: ${Variables.xlg}) {
			display: none;
		}
	`}

	grid-area: menuIcons;
`;
