import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { getGraphClient } from '../selectors';
import moment from 'moment/min/moment-with-locales';
export function* setDocuments() {

    yield takeEvery(actions.GET_DOCUMENTS, function* (action) {

        const Client = yield select(getGraphClient);
        if (!Client) return;
        yield put({
            type: actions.DOCUMENTS_REQUEST_COMPLETED,
            payload: false
        })

        let status = "orange"
        yield put({
            type: actions.SET_STATUS_DOCUMENTS,
            payload: status
        })
        let update = moment().unix();
        yield put({
            type: actions.SET_LAST_UPDATE_DOCUMENTS,
            payload: update
        })
        //const recentDocuments = yield Client.api('/me/drive/recent').orderby('lastModifiedDateTime desc').get();
        //const trendingDocuments = yield Client.api('/me/insights/trending').get();

        const recentDocuments = yield Client.api('/me/drive/recent?$orderby=lastModifiedDateTime desc&$top=30');
        const trendingDocuments = yield Client.api('/me/insights/trending?top=30');
        const sharedDocuments = yield Client.api('/me/drive/sharedWithMe?top=30');
        // error handling

        yield put({
            type: actions.DOCUMENTS_REQUEST_COMPLETED,
            payload: true
        })
        status = "green"
        yield put({
            type: actions.SET_STATUS_DOCUMENTS,
            payload: status
        })

        if (recentDocuments == null || trendingDocuments == null || sharedDocuments == null) return;
        if (!recentDocuments || !trendingDocuments || !sharedDocuments) return;

        yield put({
            type: actions.SET_DOCUMENTS,
            payload: {
                recent: recentDocuments.value,
                trending: trendingDocuments.value,
                shared: sharedDocuments.value
            }
        });

    });

}

export default function* rootSaga() {
    yield all([
        fork(setDocuments)
    ]);
}
