const translation = {
    'en': {
        locale: 'en',
        messages: {
            hello: 'Hi {name}',
            myProfile: 'My profile',
            vacationDays: 'Vacation days',
            editSettings: 'Change settings',
            openTutorial: 'Start tutorial',
            colorSetting: 'Dark mode',
            colorExplanation: 'GO has a dark mode for using in dark environments',
            autoDarkMode: 'Change mode based on sunset or sunrise',
            ownAppTile: 'Own app tiles',
            editAppTile: 'Edit tiles',
            logout: 'Logout',
            version: 'version',
            startMenu: 'START',
            helpMenu: 'HELP',
            storeMenu: 'STORE',
            selfMenu: 'SELF',
            tasks: 'Tasks',
            calendar: 'Calendar',
            appointment: 'Appointments',
            teams: 'Teams',
            tickets: 'Tickets',
            apps: 'Apps',
            settings: 'Settings',
            startLocalApps: 'Start local apps when possible',
            startAppsInNewWindow: 'Open apps in a browser tab',
            useOfficeWizard: 'Use Office wizard',
            showScrollbars: 'Show scrollbars',
            openAppsExistingWindow: 'Open apps in an existing browser tab',
            more: 'more',
            add: 'add',
            edit: 'edit',
            recent: 'recent',
            shared: 'shared',
            mine: 'mine',
            noItemsFound: 'no items',
            news: 'News',
            createNews: 'Create news message',
            createEvent: 'Create event',
            today: 'Today',
            tomorrow: 'Tomorrow',
            upcoming: 'Upcoming',
            earlierThisWeek: 'Earlier this week',
            open: 'Open',
            noDate: 'No date',
            openToDo: 'Open to-do',
            createTask: 'create task',
            createAppointment: 'create appointment',
            noAppointments: 'No upcoming appointments',
            events: 'Events',
            birthdays: 'Birthdays',
            noBirthdays: "No upcoming birthdays",
            hisBirthday: "{when} it's {name} birthday",
            congratulations: "Congratulations",
            openOneDrive: "open onedrive",
            documents: "Documents",
            kindOfDocument: "What kind of document",
            createBlog: 'Create blog',
            openOriginal: "open original",
            searchingFor: 'I am searching for..',
            search: "Search",
            whatToSearch: "What do you want to search?",
            all: 'all',
            persons: 'persons',
            files: 'files',
            colleagues: "colleagues",
            emails: "emails",
            createTicket: "create ticket",
            myTickets: "my tickets",
            itNews: "IT news",
            noLink: "no link to servicedesk",
            new: "create",
            my: "my",
            ticket: "ticket",
            tickets: "tickets",
            IT: "IT",
            theModernServiceDesk: "The modern service desk is a new way te be helped. Tell GoGo your problem or question and he will help you further. You can also immediately create a new notification yourself.",
            wantToUseModernServiceDesk: "Do you want to use our modern service desk?",
            noCustomer: 'Your IT environment is not yet registered with us. This is necessary to be able to use our modern service desk options. Feel free <a href="mailto: sales@avantage.nl">contact</a> us to make that happen.',
            wantToKnowMore: "Would you like to know more about the Avantage service desk? Then read",
            thisBlog: "this blog",
            eLearning: "E-Learning",
            knowledgeBase: "knowledgebase",
            tips: "tips",
            tipsNTricks: "Tips and tricks",
            help: "help",
            whenCustomer: "As soon as your environment has been added, you can use our modern service desk options. Notifications submitted via the chatbot or that you have submitted directly yourself are displayed here and you can view their status.",
            createATicketHere: "Below you can immediately make a report that will be picked up by the service desk. When you have provided a description of your question or problem, your report will be registered immediately.",
            didYouKnowChatbot: "Do you know that in GO you can also ask your question to GoGo? The easy chatbot immediately searches for answers in the knowledge bases and (if necessary) creates a report for you at our service desk.",
            current: "current",
            view: "View your",
            findProblemsHere: "Problems, questions or incidents that have been forwarded to the service desk can be found here.",
            noActiveTickets: "You currently have no active notifications",
            createANewTicket: "create a new ticket",
            noITNews: "At the moment there is no news about your IT environment",
            howCanIHelpYou: "How can I help you?",
            GoGo: "GoGo, your easy assistance",
            hi: "Hi",
            alwaysOnline: "always online",
            whatDoYouWantToKnow: "What do you want to know?",
            aboutWhatDoYouWantToKnow: "or about what?",
            answer: "Answer",
            example: "Example",
            setPreferences: "Set your e-learning preferences",
            thenWeWillGetYourLearnings: "so we can load some relevant elearingspaths for you",
            here: "here",
            searchLearning: "Search for learningpaths",
            gatherLearningsForYou: "We would like to collect relevant e-learning for you. Indicate your preferences by selecting your role(s), products and your level.",
            whichRoles: "What roles do you fulfill within your organization?",
            whichProducts: "Which products or services do you want to learn about?",
            previous: "previous",
            next: "next",
            save: "save",
            browseLearnings: "browse through learningpaths",
            learningsFound: "learnings found",
            searchLearnings: "search on title, role or product",
            or: "or",
            didYouKnow: "Did you know that you can also ask your question, request or problem to GoGo? This easy assistance immediately searches for answers in the knowledge bases for you and creates a notification for you if this is necessary.",
            createTicketHere: "You can create a report below that will be picked up by the service desk. Enter a description and your report will be registered.",
            active: "active",
            whatToReport: "What do you want to report?",
            yesCreateTicket: "Yes, create a notification",
            addAction: "Add action",
            addAnActionMessage: "Add an action, status update, question or other message to your notification.",
            busySending: "Busy sending..",
            busy: "Busy..",
            send: "send",
            whatsTheSubject: "What is the subject?",
            selectFromBelow: "Or select one from below",
            thanksKeepInTouch: "Thank you for your message! You will hear from us as soon as possible.",
            sorryWhatIsYourPhone: "Sorry! We notice that you are not satisfied, we would like to contact you by phone!",
            correctNumber: "Is this your phonenumber?",
            mayWeContactYou: "What number can we reach you on?",
            yesCallMe: "Yes, call me!",
            isAddedToEnvironment: "has been added to your environment",
            needAppPermission: "{name} requires approval from your manager, the request has been forwarded.",
            searchAvailableApps: "Search in available apps",
            found: "found",
            youGot: "You got",
            localApps: "local apps",
            smartTilesMessage: "smart tiles and app store</h3><p>With your own GO environment it is possible to use a real app store. All apps are automatically installed on your workplace and will then be shown as a smart tile on GO. No more need to bring your machine to the IT department!",
            notConnected: "not connected",
            addNewTile: "Add new tile",
            editTile: "Edit tile",
            insertUrl: "insert new url",
            uploadIcon: "upload icon",
            selectApplication: "Select application",
            removeAsFavorite: "remove as favorite",
            markAsFavorite: "mark as favorite",
            own: "own",
            tiles: "tiles",
            tile: "tile",
            local: "local",
            name: "name",
            applications: "Applications",
            language: "Language",
            choose: 'Choose',
            addOwnEvent: "Add own event",
            noPlannedItems: "nothing planned",
            wizardHomeHi: "Welcome to <b>GO</b>, the modern workplace! GO is your starting point where you have everything at your fingertips. Your apps, documents, business information and more. GO makes it easy to be productive anytime, anywhere, instantly. ",
            wizardHomeGoGo: "Do you have a question about video conferencing in Teams or is your laptop not working? Ask this to GoGo, the easy help! He searches the knowledge bases for you and calls in the service desk when necessary.",
            hasOfficeLocalTitle: "Do you have Microsoft Office installed locally?",
            hasOfficeLocal: "When you have installed Office on your computer or laptop, it is possible to open your documents from GO in the desktop app. Do you want to open your documents in the desktop app?",
            wantOfficeWizardTitle: "Do you want to use the smart Office Wizard?",
            wantOfficeWizard: "The smart wizard makes it easy to save new documents in the right place. Give the document a name and select a Team. ",
            inTabOrPopupTitle: "Open in a new tab or popup?",
            inTabOrPopup: "Applications that are not installed on your PC open in a new browser tab or in a separate popup? The popup choice provides a different workplace experience.",
            newTab: "New tab",
            popup: "Popup",
            shareBirthdayTitle: "Do you also want to share your birthday with your colleagues?",
            shareBirthday: 'If you\'re okay with us showing your birthday to your colleagues, then you can fill it in yourself via your <a target="_blank" href="{url}">office profile page</a>. Just make sure the option \'Everyone can see this\' is selected. <br /><br />It may take one day for your birthday to appear in the list',
            yes: "yes",
            no: "no",
            people: "colleagues",
            delete: "delete",
            important: 'Important',
            markAsRead: 'mark all as read',
            intranet: 'Intranet',
            subject: 'subject',
            refresh: 'refresh',
            refreshedAgo: 'Refreshed {timeAgo}',
            completed: 'Completed',
            move: 'move',
            self: 'Self',
            rights: 'Rights',
            change: 'Change',
            noRequestFound: 'No requests found',
            user: "User",
            workplace: "Workplace",
            requestWorkplace: "Request workplace",
            remove: "remove",
            requestNewUser: "Request a new user",
            removeUser: "Remove user",
            userManagement: "User management",
            requestInformation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
            requestType: "Request type",
            voornaam: "First name",
            tussenvoegsel: "Suffix",
            achternaam: "Last name",
            telefoonnummer: "Phonenumber",
            functie: "Function",
            afdeling: "Department",
            omschrijving: "Description",
            datumInDienst: "Date employed",
            datumUitDienst: "Datum out of service",
            youSure:"Are you sure?",
            addUserFormInfo: "Use the form below to request a new colleague at the Avantage Service Desk. In addition, you have insight into the call number and you can follow the contents of the call if you click on the call number. We advise you to report a new colleague as early as possible so that we can properly plan and carry out the work so that we can provide you with all information in a timely manner.",
            removeUserFormInfo: "With the form below you can request the removal of a colleague at the Avantage Service Desk. In addition, you have insight into the call number and you can follow the contents of the call if you click on the call number. We advise you to report a colleague on leave as early as possible so that we can properly plan and carry out the work so that the colleague no longer has access to the environment at the right time.",
            removeRightsFormInfo: "With the form below you can request changes to rights for a colleague at the Avantage Service Desk. In addition, you have insight into the call number and you can follow the contents of the call if you click on the call number. We aim to carry out the work within 3 working days.",
            addOffersFormInfo: "With the form below you can request a quote from our office staff. Specify what you want and what the expectations are for which you want to receive a quote. The more information you provide, the better we can provide you with a customized quote. If there are any questions, we will contact you. We aim to deliver the quote to you within 7 working days.",
            typeLicentie: "Type of license",
            activateDefender: "Activate defender",
            officeBackup: "Office365 backup",
            addToGroups: "Add to Office365 group(s)",
            createSharedMailbox: "Create shared mailbox",
            linkMailbox: "Link shared mailbox to",
            forwardMailTo: "Forward mail to",
            openPopup: "Open in popup",
            connectionErrorLiquitCloud: "An error occurred when logging in to Liquit Cloud with SSO, token was empty", /* was 'error connecting to Liquit cloud'*/
            connectionErrorStartingLiquit: "An error occurred while starting Liquit",
            isAddedToEnvironment: "has been added to your environment",
            liquitFailedToStart: "The application failed to start",
            notifierRender: "Rendering: ",
            commandHubInvokeGoLoginError: "Error occurred while starting the connection: ", /*Error while starting connection*/
            noWidgetsPresent: "No widgets have been created yet!",
            leftColumnIsEmpty: "Left column needs to have items, please add a widget to the layout",
            MoveTiles: "Move tiles",
            explanation: "explanation",
            close: "close",
            You: "You",
            enterDescription: "describe your issue here",
            liquitSSOError: "Liquit SSO login failed, token was empty",
        }
    },
    'nl': {
        locale: 'nl',
        messages: {
            hello: 'hallo',
            myProfile: 'Mijn profiel',
            vacationDays: 'Vakantiedagen',
            editSettings: 'Instellingen bewerken',
            openTutorial: 'Open tutorial',
            colorSetting: 'Kleurstelling',
            colorExplanation: 'GO komt standaard in een licht jasje. Selecteer de rechter, donkere variant om GO dark te gebruiken.',
            autoDarkMode: 'Met zonsopkomst en -ondergang automatisch kleur veranderen',
            ownAppTile: 'Eigen app tegels',
            editAppTile: 'Pas tegels aan',
            logout: 'Uitloggen',
            version: 'versie',
            startMenu: 'START',
            helpMenu: 'HULP',
            storeMenu: 'STORE',
            selfMenu: 'ZELF',
            tasks: 'Taken',
            calendar: 'Kalender',
            appointment: 'Afspraken',
            teams: 'Teams',
            tickets: 'Meldingen',
            apps: 'Apps',
            settings: 'Instellingen',
            startLocalApps: 'Lokale applicaties opstarten wanneer mogelijk',
            startAppsInNewWindow: 'Apps starten in nieuw tabblad',
            useOfficeWizard: 'Gebruik de Office wizard',
            showScrollbars: 'Toon scrollbars',
            openAppsExistingWindow: 'Gebruik bestaand tabblad bij opstarten app',
            more: 'meer',
            recent: 'recent',
            add: 'toevoegen',
            edit: 'bewerken',
            shared: 'gedeeld',
            mine: 'mijn',
            noItemsFound: 'geen items',
            news: 'Nieuws',
            createNews: 'maak een nieuwsbericht',
            createEvent: 'maak een event',
            today: 'Vandaag',
            tomorrow: 'Morgen',
            upcoming: 'Aankomend',
            earlierThisWeek: 'Eerder deze week',
            open: 'Openstaand',
            noDate: 'Geen datum',
            openToDo: 'Open to-do',
            createTask: 'Maak een taak',
            createAppointment: 'maak een afspraak',
            noAppointments: 'Je hebt vandaag en morgen geen afspraken',
            events: 'Evenementen',
            birthdays: 'Verjaardagen',
            noBirthdays: "Er zijn geen jarigen vandaag of morgen",
            hisBirthday: "{when} is {name} jarig",
            congratulations: "Gefeliciteerd",
            openOneDrive: "open onedrive",
            documents: "Documenten",
            kindOfDocument: "Wat voor document",
            createBlog: 'Maak een blog',
            openOriginal: "open origineel",
            searchingFor: 'Ik ben op zoek naar..',
            search: "Zoeken",
            whatToSearch: "Wat wil je zoeken?",
            all: 'alles',
            persons: 'personen',
            files: 'bestanden',
            colleagues: "collega's",
            emails: "emails",
            createTicket: "maak melding",
            myTickets: "mijn meldingen",
            itNews: "IT nieuws",
            noLink: "nog geen koppeling",
            new: "nieuw",
            my: "mijn",
            ticket: "melding",
            tickets: "meldingen",
            IT: "IT",
            theModernServiceDesk: "De moderne servicedesk is een nieuwe manier om geholpen te worden. Vertel aan GoGo je probleem of vraag en hij helpt je verder. Ook kun je direct een nieuwe melding zelf aanmaken.",
            wantToUseModernServiceDesk: "Wil je gebruiken maken van onze moderne servicedesk?",
            noCustomer: 'Jouw IT-omgeving is nog niet bij ons geregistreerd. Dat is wel nodig om gebruik te kunnen maken van onze moderne servicedesk mogelijkheden. Neem gerust <a href="mailto: sales@avantage.nl">contact</a> met ons op om dat te realiseren.',
            wantToKnowMore: "Wil je meer weten over de servicedesk van Avantage? Lees dan",
            thisBlog: "deze blog",
            eLearning: "E-Learning",
            knowledgeBase: "kennisbank",
            tips: "tips",
            tipsNTricks: "Tips 'n tricks",
            help: "hulp",
            whenCustomer: "Zodra jouw omgeving is toegevoegd, kan je gebruik maken van onze moderne servicedesk mogelijkheden. Meldingen die via de chatbot of die je zelf rechtstreeks hebt ingediend, worden hier weergeven en kun je de status daarvan inzien.",
            createATicketHere: "Hieronder kun je direct een melding maken die door de servicedesk wordt opgepakt. Wanneer je een omschrijving van jouw vraag of probleem hebt gegeven, wordt jouw melding direct geregistreerd.",
            didYouKnowChatbot: "Weet je dat je in GO jouw vraag ook aan de GoGo kunt stellen? Deze eenvoudige chatbot zoekt gelijk naar antwoorden in de kennisbanken en maakt (als dat nodig is) een melding bij onze servicedesk voor je aan.",
            current: "huidige",
            view: "Bekijk hier je",
            findProblemsHere: "Problemen, vragen of incidenten die zijn doorgezet naar de servicedesk, vindt je hier weer terug.",
            noActiveTickets: "Op dit moment heb je geen actieve meldingen",
            createANewTicket: "maak een nieuwe melding aan",
            noITNews: "Op dit moment is er geen nieuws over je IT omgeving",
            howCanIHelpYou: "Hoe kan ik je helpen?",
            GoGo: "GoGo, de eenvoudige hulp ",
            hi: "Hi",
            alwaysOnline: "altijd online",
            whatDoYouWantToKnow: "Wat wil je weten?",
            aboutWhatDoYouWantToKnow: "Waarover wil je wat weten?",
            answer: "Antwoord",
            example: "Voorbeeld",
            setPreferences: "Geef je voorkeuren",
            thenWeWillGetYourLearnings: "dan halen we relevante leerpaden van Microsoft voor je op!",
            here: "hier",
            searchLearning: "Zoek op leerpaden",
            gatherLearningsForYou: "We willen graag relevante e-learnings voor je verzamelen. Geef je voorkeuren aan door je rol(len), producten en je niveau te selecteren.",
            whichRoles: "Welke rollen vervul je binnen jouw organisatie?",
            whichProducts: "Over welke producten of diensten wil je iets leren?",
            previous: "vorige",
            next: "volgende",
            save: "opslaan",
            browseLearnings: "blader door alle leerpaden",
            learningsFound: "leerpaden gevonden",
            searchLearnings: "zoek op titel, rollen of producten",
            or: "of",
            didYouKnow: "Wist je dat je ook je vraag, verzoek of probleem aan GoGo kan stellen? Deze eenvoudige hulp zoekt gelijk voor je naar antwoorden in de kennisbanken en maakt een melding voor je aan als dit nodig is.",
            createTicketHere: "Je kunt hieronder een melding aanmaken die door de servicedesk wordt opgepakt. Geef een omschrijving op en je melding wordt geregistreerd.",
            active: "active",
            whatToReport: "Wat wil je melden?",
            yesCreateTicket: "Ja, maak een melding aan",
            addAction: "Voeg een actie toe",
            addAnActionMessage: "Voeg een actie, status update, vraag of een ander bericht toe aan je melding.",
            busySending: "Bezig met versturen..",
            busy: "Bezig..",
            send: "versturen",
            whatsTheSubject: "Wat is het onderwerp?",
            selectFromBelow: "Of gebruik één van onderstaande",
            thanksKeepInTouch: "Bedankt voor je melding! Je hoort zo snel mogelijk van ons.",
            sorryWhatIsYourPhone: "Sorry! We merken dat je niet tevreden bent, graag willen we je telefonisch benaderen!",
            correctNumber: "Klopt dit nummer?",
            mayWeContactYou: "Op welk nummer mogen we je benaderen?",
            yesCallMe: "Ja, bel mij!",
            isAddedToEnvironment: "is toegevoegd aan je omgeving",
            needAppPermission: "Voor {name} is goedkeuring van je manager nodig, de aanvraag is doorgestuurd.",
            searchAvailableApps: "Zoek in beschikbare apps",
            found: "gevonden",
            youGot: "Je hebt",
            localApps: "lokale apps",
            smartTilesMessage: "slimme tegels en app store</h3><p>Met een eigen GO omgeving is het mogelijk om een echte app store te gebruiken. Alle apps worden automatisch op je werkplek geinstalleerd en zal dan als een slimme tegel op GO worden getoond. Niet meer nodig om je machine langs de IT afdeling te brengen!",
            notConnected: "niet verbonden",
            addNewTile: "Voeg nieuwe tegel toe",
            editTile: "Pas tegel aan",
            insertUrl: "voer een nieuwe url in",
            uploadIcon: "upload icoon",
            selectApplication: "Selecteer applicatie",
            removeAsFavorite: "Verwijder als favoriet",
            markAsFavorite: "Markeer als favoriet",
            own: "eigen",
            tiles: "tegels",
            tile: "tegel",
            local: "lokaal",
            name: "naam",
            applications: "Applicaties",
            language: "Taal",
            choose: 'Kies',
            addOwnEvent: "Eigen event toevoegen",
            noPlannedItems: "niks gepland",
            wizardHomeHi: "Welkom op <b>GO</b>, de moderne werkplek! GO is je startplek waarop je direct alles in handbereik hebt. Je apps, documenten, bedrijfsinformatie en meer. Met GO wordt het eenvoudig om altijd, overal en direct productief te zijn. ",
            wizardHomeGoGo: "Heb je een vraag over videovergaderen in Teams of werkt je laptop niet? Stel deze aan GoGo, de eenvoudige hulp! Hij zoekt voor je in de kennisbanken en schakelt wanneer nodig de servicedesk in.",
            hasOfficeLocalTitle: "Heb je Microsoft Office lokaal geïnstalleerd?",
            hasOfficeLocal: "Wanneer je Office op je computer of laptop hebt geïnstalleerd, is het mogelijk om je documenten vanuit GO in de desktop-app te openen. Wil je jouw documenten in de desktop-app openen?",
            wantOfficeWizardTitle: "Wil je de slimme Office Wizard gebruiken?",
            wantOfficeWizard: "Met deze slimme wizard wordt het eenvoudig om nieuwe documenten op de juiste plek op te slaan. Geef het document een naam en selecteer een Team. ",
            inTabOrPopupTitle: "Openen in een nieuw tabblad of popup?",
            inTabOrPopup: "Applicaties die niet op je PC geïnstalleerd staan openen in een nieuw browser tabblad of in een aparte popup? De popup keuze zorgt voor een andere werkplek ervaring.",
            newTab: "Nieuw tabblad",
            popup: "Popup",
            shareBirthdayTitle: "Wil je ook je verjaardag met je collega's delen?",
            shareBirthday: 'Als je het oké vindt dat we je verjaardag laten zien aan je collega\'s, dan kun je deze zelf invullen via je <a target="_blank" href="{url}">office profiel pagina</a>. Zorg er wel voor dat de optie \'Iedereen kan dit zien\' is geselecteerd. <br /><br />Het kan één dag duren voordat je verjaardag zichtbaar wordt in de lijst',
            yes: "ja",
            no: "nee",
            people: "collega's",
            delete: "verwijder",
            important: 'Belangrijk',
            markAsRead: 'markeer alles gelezen',
            intranet: 'Intranet',
            subject: 'onderwerp',
            refresh: 'ververs',
            refreshedAgo: '{timeAgo} ververst',
            completed: 'Afgerond',
            move: 'verplaatsen',
            self: 'Zelf',
            rights: 'Rechten',
            change: 'Aanpassen',
            noRequestFound: 'Geen aanvragen gevonden',
            user: "Gebruiker",
            workplace: "Werkplek",
            requestWorkplace: "Werkplek aanvragen",
            remove: "verwijderen",
            requestNewUser: "Vraag een nieuwe gebruiker aan",
            removeUser: "Verwijder gebruiker",
            userManagement: "Gebruikersbeheer",
            requestInformation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
            requestType: "Aanvraag type",
            voornaam: "Voornaam",
            tussenvoegsel: "Tussenvoegsel",
            achternaam: "Achternaam",
            telefoonnummer: "Telefoonnummer",
            functie: "Functie",
            afdeling: "Afdeling",
            omschrijving: "Omschrijving",
            datumInDienst: "Datum in dienst",
            datumUitDienst: "Datum uit dienst",
            youSure:"Weet je het zeker?",
            addUserFormInfo: "Met het onderstaande formulier kunt u een nieuwe collega aanvragen bij de Servicedesk van Avantage. Daarnaast heeft u inzicht in het callnummer en kunt u de inhoudt van de call volgen als u op het callnummer klikt. Wij adviseren u zo vroeg mogelijk melding te maken van een nieuwe collega zodat wij de werkzaamheden netjes kunnen plannen en uitvoeren zodat we u tijdig van alle informatie kunnen voorzien.",
            removeUserFormInfo: "Met het onderstaande formulier kunt u een collega uitdienst melden bij de Servicedesk van Avantage. Daarnaast heeft u inzicht in het callnummer en kunt u de inhoudt van de call volgen als u op het callnummer klikt. Wij adviseren u zo vroeg mogelijk melding te maken van een collega uitdienst zodat wij de werkzaamheden netjes kunnen plannen en uitvoeren zodat de collega op het juiste moment geen toegang meer heeft op de omgeving.",
            addRightsFormInfo: "Met het onderstaande formulier kunt u aanpassingen op rechten voor een collega aanvragen bij de Servicedesk van Avantage. Daarnaast heeft u inzicht in het callnummer en kunt u de inhoudt van de call volgen als u op het callnummer klikt. Wij streven ernaar de werkzaamheden binnen 3 werkdagen uit te voeren.",
            addOffersFormInfo: "Met het onderstaande formulier kunt u een offerte aanvraag doen bij onze binnendienst. Specificeer wat u wenst en wat de verwachtingen zijn waarvoor u een offerte wilt ontvangen. Hoe meer informatie hoe beter wij kunnen zorgen voor een offerte op maat. Mochten er nog vragen zijn nemen wij contact met u op. Wij streven er naar om binnen 7 werkdagen de offerte bij u af te leveren.",
            typeLicentie: "Type licentie",
            activateDefender: "Activeer defender",
            officeBackup: "Office365 backup",
            addToGroups: "Toevoegen aan Office365 groepen",
            createSharedMailbox: "Aanmaken gedeelde mailbox",
            linkMailbox: "Gedeelde mailbox koppelen aan",
            forwardMailTo: "Forward mail naar",
            openPopup: "Open in een popup",
            liquitSSOError: "Liquit SSO inloggen is niet gelukt, token was leeg",
            connectionErrorLiquitCloud: "Er is een fout opgetreden bij het verbinden met Liquit Cloud",
            connectionErrorStartingLiquit: "Er is een fout opgetreden bij het opstarten van Liquit",
            isAddedToEnvironment: "is toegevoegd aan je omgeving",
            liquitFailedToStart: "De applicatie kon niet opstarten",
            notifierRender: "Rendering: ",
            commandHubInvokeGoLoginError: "Fout tijdens het starten van de verbinding: ", /*Error while starting connection*/
            noWidgetsPresent: "Er zijn nog geen widgets aangemaakt!",
            leftColumnIsEmpty: "Linkerkolom moet gevuld zijn, voeg alstublieft een widget toe aan de indeling",
            MoveTiles: "Beweeg tegels",
            explanation: "uitleg",
            close: "sluiten",
            You: "Jij",
            enterDescription: "beschrijf hier jouw melding"
        }
    }
}

export default translation;
