import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import clientActions from '../client/actions';
import layoutActions from '../layout/actions'
import userActions from '../user/actions';
import { getGOClient, getAuthProvider, getLocale } from '../selectors';
import { toast } from 'react-toastify';
import { getGraphClient, getGoClient, getSharepointClient, getPowerClient, getYammerClient, activateClients, } from '../../helpers/graph';
import translate from 'translate-google-api';
//const tenantConfig = window.tenantConfig;
import moment from 'moment';

export function* fetchUserWidgets() {
    yield takeEvery(actions.GET_WIDGETS, function* (action) {
        const GoClient = yield select(getGOClient);

        if (!GoClient) {
            return;
        }

        let widgets = {};

        if (!window.tenantConfig.common) {
            try {
                widgets = yield GoClient.api('/widgets');
            } catch (Error) {
                console.log("widgets fetch error: " + Error);
            }
        } else {
            widgets = {};
            widgets.config = { left: [], center: [], right: [] };
        }

        if (widgets == null || (widgets.config == null && !window.tenantConfig.common)) {
            toast(this.props.translate('noWidgetsPresent'), {autoClose: 1000*60*60*24});
        }

        if (widgets != null && widgets.config != null && widgets.config.left === []) {
            toast(this.props.translate('leftColumnIsEmpty'), {autoClose: 1000*60*60*24});
        }

        if(widgets.config.hasOwnProperty('layout')) {
            
            yield put({
                type: actions.SET_WIDGETS_LAYOUT,
                payload: widgets.config.layout
            });
            if(widgets.config.layout.value === 'fullscreen') {
                yield put({
                    type: layoutActions.SET_COLUMNS,
                    payload: {
                        columns: 1
                    }	
                });
            }
            if(widgets.config.layout.value === 'bigsmall') {
                yield put({
                    type: layoutActions.SET_COLUMNS,
                    payload: {
                        columns: 2
                    }	
                });
            }
            if(widgets.config.layout.value === '3split') {
                yield put({
                    type: layoutActions.SET_COLUMNS,
                    payload: {
                        columns: 3
                    }	
                });
            }
            delete widgets.config.layout;
        }
        if (window.tenantConfig.common) {

            widgets.config.left.push({
                component: "Apps",
                apiUrl: "/application/list",
                title: ""
            })

            widgets.config.center.push({
                component: "MultiNews",
                linkTitle: "meer",
                handleTitleInWidget: true,
                title: "Nieuws",
                noTitle: true
            })

            //widgets.config.center.push({
            //    component: "MultiFeed",
            //    linkTitle: "meer",
            //    handleTitleInWidget: true,
            //    title: "Feeds",
            //    noTitle: true
            //})


            //widgets.config.center.push({
            //    component: "ITNews",
            //    linkTitle: "meer",
            //    title: "IT Nieuws"
            //})

            widgets.config.center.push({
                component: "MultiBlog",
                linkTitle: "meer",
                handleTitleInWidget: true,
                title: "Blogs",
                noTitle: true
            })

            widgets.config.center.push({
                component: "People",
                linkTitle: "meer",
                title: "Je collega's",

            })

            widgets.config.right.push({
                "component": "CustomButton",
                "title": "Webinar - Stop een cyberaanval met micro netwerk segmentatie",
                "linkUrl": "https://www.avantage.nl/webinar-netwerksegmentatie/?utm_source=ontdekgobanner&utm_medium=email&utm_campaign=webinar-netwerksegmentatie&utm_content=handtekening",
                "backgroundImage": "https://www.avantage.nl/wp-content/uploads/2020/06/Unknown-32.jpeg",
                "noTitle": true
            })

            //widgets.config.right.push({
            //    component : "LinkList",
            //    title: "Links",
            //    linkList : [
            //        {
            //            "title" : "Vrij vragen",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Intranet",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Ziek melden",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Belangrijk",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Nieuws",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Corona update",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        },
            //        {
            //            "title" : "Wachtwoord reset",
            //            "url" : "https://www.avantage.nl",
            //            "target" : "_BLANK"
            //        }
            //    ]
            //})

            //widgets.config.right.push({
            //    component: "Yammer",
            //    linkTitle: "meer",
            //    title: "test"
            //})

            widgets.config.right.push({
                component: "EventsCommon",
                linkTitle: "meer",
                title: "Evenementen",
                handleTitleInWidget: true,
                noTitle: true
            })

            widgets.config.right.push({
                component: "Documents",
                linkTitle: "meer",
                title: "Documenten"
            })
        }

        let locale = yield select(getLocale);

        if (widgets) {
            window.allowCreateTiles = false;
            let hasGo = false
            for (let property in widgets.config) {
                if (widgets.config.hasOwnProperty(property)) {
                    
                    for (let item of widgets.config[property]) {
                        if (item == null) continue;
                        // if (item.component == "Apps") {
                        //     item.title = "Applicaties";
                        // }
                        if(item.component === 'Apps' && item.title === "" && item.apiUrl === "/application/list") window.allowCreateTiles = true;
                        console.log("item widget",item)
                        if (item.component === 'News' || item.component === 'Blog' || item.component === 'MultiNews' || item.component === 'MultiBlog' || item.component === 'EventsCommon' || item.component === 'Events' || item.component === 'MultiShout' || item.component === 'MultiEvent' || item.component == "Apps" || item.component == "WorkplaceBuddy") {
                            item.handleTitleInWidget = true;
                        }
                        if(item.sources) {
                            item.sources.forEach((source, index) => {
                                if(source.source == "go" && source.url) {
                                    hasGo = true;
                                }
                            })
                        }
                    }
                }
            }
            if(window.tenantConfig.common) {
                hasGo = true;
            }
            yield put({
                type: actions.SET_HAS_GO_WIDGET,
                payload: hasGo
            });
            yield activateClients(widgets);
            yield put({
                type: actions.SET_WIDGETS,
                payload: widgets.config
            });
        }
    });
}


export function* getWidget() {

}


export default function* rootSaga() {
    yield all([
        fork(fetchUserWidgets),
    ]);
}
