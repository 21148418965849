
export const hasOfficeSubscription = (licenseDetails) => {
    let hasOfficeSubscription = true; //temp!!

    licenseDetails.value.map(function (item) {

        // -- fetched current plans from: https://docs.microsoft.com/nl-nl/azure/active-directory/users-groups-roles/licensing-service-plan-reference
        switch (item.skuPartNumber) {
            case 'STANDARDPACK':
            case 'STANDARDWOFFPACK':
            case 'ENTERPRISEPACK':
            case 'DEVELOPERPACK':
                hasOfficeSubscription = true;
                break;
            default:


        }

        return true;
    })

    return { 'hasOfficeSubscription': hasOfficeSubscription };
};
