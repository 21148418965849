import actions from './actions';

const initState = { 
	columns: 3,
	darkmode : false,
	darkmodeAuto : false,
	sliderHidden : false
};


export default function layoutReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_COLUMNS:
            return { ...state, columns: action.payload.columns }
        case actions.HIDE_SLIDER:
        	return {
        		...state,
        		sliderHidden : action.payload
        	}
        case actions.SET_IS_LOADED:
            return {
                ...state,
                IsLoaded: true
            };
        case actions.SET_DARK_MODE:
        	return {
        		...state,
        		darkmode : action.payload
        	}
        case actions.START_DARKMODE_AUTO:
        	return {
        		...state,
        		darkmodeAuto : true
        	}
        case actions.STOP_DARKMODE_AUTO:
        	return {
        		...state,
        		darkmodeAuto : false
        	}
		default:
			return state;
	}
}
