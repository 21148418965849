import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { hasOfficeSubscription } from '../../helpers/licensedetail'
import { getGraphClient, getGOClient, getUserProfile, getUserProfileSettingData, getFeatureSliders, getLocale, getDataRequest } from '../selectors';
import slideOutActions from '../slideOut/actions';
import userActions from '../user/actions';
import widgetActions from '../widgets/actions';
import calendarActions from '../calendar/actions';
import notificationsActions from '../notification/actions';
import layoutActions from '../layout/actions';
import taskActions from '../tasks/actions';
import authActions from '../auth/actions';
import teamsActions from '../widgets/teams/actions';
import { IntlActions } from 'react-redux-multilingual'
import moment from 'moment/min/moment-with-locales';
import { Buffer } from 'buffer';
import translations from '../../translate/translations'

export function* getUserMessages() {
    yield takeEvery(actions.GET_MESSAGES, function* (action) {

        // -- Get client instance
        const Client = yield select(getGraphClient);;

        try {
            // -- Get Avatar
            //const messages = yield Client.api('/me/mailFolders/inbox/messages')
            //    //.top(11)
            //    .filter('isRead eq false')
            //    .count('true')
            //    .get();

            const messages = yield Client.api('/me/mailFolders/inbox/messages?$filter=isRead eq false&$count=true');
            if (messages == null || !messages) return;

            yield put({
                type: actions.SET_MESSAGES,
                payload: messages["@odata.count"]
            });
        } catch (exception) { }

    });
}

export function* getUserAppRoles() {
    yield takeEvery(actions.GET_APP_ROLES, function* (action) {

        // -- Get client instance
        const Client = yield select(getGraphClient);

        const userProfile = yield select(getUserProfile);


        const appRoles = yield Client.api('/users/' + userProfile.userPrincipalName + '/appRoleAssignments');
    });
}


export function* getUserApplications() {
    yield takeEvery(actions.GET_USER_APPLICATIONS, function* (action) {

        const Client = yield select(getGOClient);

        if (!Client) return;

        let applications = yield Client.api('/user/applications');
        if (applications == null || !applications) return;

        if (applications)
            yield put({
                type: actions.SET_USER_APPLICATIONS,
                payload: applications
            });
    });
}

export function* setUserData(data) {
    yield takeEvery(actions.SET_USER_DATA, function* (action) {

        const Client = yield select(getGOClient);
        if (!Client) return;

        const dataRequest = yield select(getDataRequest);
        if (!dataRequest) return;

        yield Client.post('/user/data', JSON.stringify(action.payload));

        yield put({
            type: actions.GET_USER_DATA,
            payload: action.payload
        });
    });
}

export function* addDebugData(data) {
    yield takeEvery(actions.SET_DEBUG_DATA, function* (action) {

        const Client = yield select(getGOClient);
        if (!Client) return;

        // let curTime = moment();

        yield put({
            type: actions.ADD_DEBUG_DATA,
            payload: action.payload
        });

        // const lastDebug = yield select(getLastDebug);
        //if (!lastDebug || curTime < lastDebug.setSeconds(lastDebug.getSeconds + 10)) return;

        // console.log('[API]: ', JSON.stringify(action.payload));

        yield Client.post('/user/debugging', JSON.stringify(action.payload));

        /*    yield put({
                type: actions.SET_LAST_DEBUG,
                payload: curTime
            });*/
    });
}



export function* getUserProfileData() {
    yield takeEvery(actions.GET_USER_PROFILE, function* (action) {

        try {

            // -- Get client instance
            const Client = yield select(getGraphClient);
            if (Client == null) return;

            yield put({
                type: actions.DATA_REQUEST_COMPLETED,
                payload: false
            });

            // -- Get Avatar
            const avatar = yield Client.api('/me/photo/$value', 'arrayBuffer'); //.responseType('arraybuffer').get();

            if (avatar != null && avatar) {
                const buf = new Buffer.from(avatar, 'binary');
                yield put({
                    type: actions.SET_USER_AVATAR,
                    payload: 'data:image/png;base64, ' + buf.toString('base64')
                });
            }

            // -- Get user profile
            const profile = yield Client.api('/me?$select=mySite,displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,birthday,surname,userPrincipalName,id');

            const GoClient = yield select(getGOClient);
            const user = yield GoClient.api('/user/me');
            if (user == null || !user) return;

            if (user.ResetUser) {
                localStorage.clear();
            }

            const licenseDetails = yield Client.api('/me/licenseDetails');
            if (licenseDetails == null || !licenseDetails) return;

            const hasOfficeSupscription = hasOfficeSubscription(licenseDetails);


            //build user data
            if (user.data != null) {
                user.data = JSON.parse(user.data);
            } else {
                user.data = {};
            }

            //build user settings
            if (!user.data.settings) {
                user.data.settings = { startLocalApps: true, showOfficeWizard: true, startAppsInNewWindow: true }
                window.userSettings = {}
            } else {
                window.userSettings = user.data.settings;
            }

            window.userSettings.rights = {};

            //set user rights
            if (window.tenantConfig.common) {
                const rights = { selfserviceIntranet: true };
                window.userSettings.rights = {...window.userSettings.rights, rights};
            }

            const hasRights = yield Client.api('/me/memberOf/microsoft.graph.group');
            if ((hasRights != null && hasRights.value.find(g => g.displayName == "go management") != null) ||
                 hasRights.value.find(g => g.id == window.tenantConfig.authorisationGroup)) {
                const rights = { ...user.data.settings.rights, selfserviceBeheer: true };
                user.data.settings.rights = rights;
                window.userSettings.rights = rights;
            }
            if (hasRights.value.find(g => g.id == window.tenantConfig.selfServiceGroup) || window.tenantConfig.selfServiceGroup == "" || window.tenantConfig.common) {
                const rights = { ...user.data.settings.rights, selfserviceIntranet: true };
                user.data.settings.rights = rights;
                window.userSettings.rights = rights;
            }
            if (!user.data.lastCallViews) {
                user.data.lastCallViews = {}
            }

            //check user locale
            if (user.data.settings?.locale != null) {
                let locale = yield select(getLocale);
                if (locale !== user.data.settings?.locale) {

                    yield put(IntlActions.setLocale(user.data.settings?.locale));
                    //moment.locale(user.data.settings?.locale);
                }
                moment.locale(user.data.settings?.locale); //always set moment locale
            } else {
                let locale = yield select(getLocale);
                user.data.settings.locale = locale;

                yield put({
                    type: userActions.SET_USER_DATA,
                    payload: { ...user.data }
                });
                moment.locale(locale);
            }

            let sliderKeys = [
                '/', '/online-hulp', '/self-service/nieuws', '/store'
            ]

            if (!user.data.settings.featureSliders) {
                // -- User has not seen the slider yet and is not a configured user.
                let payload = {};

                sliderKeys.forEach((item, i) => {
                    payload = {
                        ...payload,
                        [item]: {
                            visible: true
                        }
                    }
                });

                yield put({
                    type: actions.SET_FEATURE_SLIDERS,
                    payload: payload
                })

            } else {
                let featureSliders = { ...user.data.settings.featureSliders };
                let newFeatureSliders = {};
                sliderKeys.forEach((item, i) => {
                    if (!featureSliders[item]) {
                        newFeatureSliders = {
                            ...newFeatureSliders,
                            [item]: {
                                visible: true
                            }
                        }
                    } else {
                        newFeatureSliders = {
                            ...newFeatureSliders,
                            [item]: {
                                ...featureSliders[item]
                            }
                        }
                    }
                });

                user.data.settings.featureSliders = newFeatureSliders;

                yield put({
                    type: actions.SET_FEATURE_SLIDERS,
                    payload: newFeatureSliders
                })
            }

            yield put({
                type: actions.SET_USER_PROFILE,
                payload: { ...user, ...profile, ...hasOfficeSupscription }
            });

            if (user && user.values && user.values.leaveBalance != null) {
                const leaveBalance = user.values.leaveBalance;
                const daysLeft = Math.floor(leaveBalance / 8);
                yield put({
                    type: actions.SET_DAYS_LEFT,
                    payload: daysLeft
                });
            }

            yield put({
                type: actions.DATA_REQUEST_COMPLETED,
                payload: true
            });

            yield put({
                type: userActions.GET_MESSAGES
            });

            yield put({
                type: userActions.GET_APP_ROLES
            });

            yield put({
                type: taskActions.GET_TASKS
            });

            yield put({
                type: calendarActions.GET_CALENDAR
            });

            yield put({
                type: layoutActions.SET_IS_LOADED
            });

            yield put({
                type: teamsActions.GET_TEAMS
            });

        } catch (ex) { }

    });
}

export function* updateUserSettings(data) {
    yield takeEvery(actions.UPDATE_USER_SETTINGS, function* (action) {

        const Client = yield select(getGOClient);
        let userData = yield select(getUserProfileSettingData);

        if (action.payload.setting.indexOf("viewed") != -1 && Array.isArray(action.payload.value)) {
            let current = userData?.settings[action.payload.setting];
            let old = [];
            if(current?.payload) {
                current = current.payload
            }
            if (current) {
                let newItems = action.payload.value;
                old = current.filter(c => newItems.find(n => n.url == c.url) == null);
                old.forEach(i => {
                    if (i.date == null) i.date = new moment().format();
                });
                if (old.length > 30) old.splice(29, (old.length - 30))
                action.payload.value = action.payload.value.concat(old);
            }

            //cleanup
            current?.forEach((e, i) => {
                if (e.date != null && moment(e.date).diff(new moment(), 'days') > 14) {
                    current.splice(i, 1);
                }
            })
        }

        userData = {
            //...user.data,
            ...userData,
            settings: {
                //...user.data.settings,
                ...userData.settings,
                [action.payload.setting]: action.payload.value
            }
        }

        window.userSettings = userData.settings;

        yield put({
            type: actions.SET_USER_DATA,
            payload: userData
        })

    });
}

export function* setUserViewedCall(data) {
    yield takeEvery(actions.SET_USER_VIEWED_CALL, function* (action) {

        const Client = yield select(getGOClient);
        let userData = yield select(getUserProfileSettingData);

        let now = moment().unix();

        userData = {
            ...userData,
            lastCallViews: {
                ...userData.lastCallViews,
                [action.payload]: now
            }
        }

        yield put({
            type: actions.SET_USER_DATA,
            payload: userData
        })
    });
}

export function* setFeatureSliderVisible(data) {
    yield takeEvery(actions.SET_FEATURE_SLIDER_VISIBLE, function* (action) {

        let featureSliders = yield select(getFeatureSliders);

        featureSliders = {
            ...featureSliders,
            [action.payload.link]: {
                ...featureSliders[action.payload.link],
                visible: action.payload.visible
            }
        }

        yield put({
            type: actions.SET_FEATURE_SLIDERS,
            payload: featureSliders
        })


        const Client = yield select(getGOClient);
        let userData = yield select(getUserProfileSettingData);

        userData = {
            ...userData,
            settings: {
                ...userData.settings,
                featureSliders: featureSliders
            }
        }

        yield put({
            type: actions.SET_USER_DATA,
            payload: userData
        })
    });
}

export function* setFeatureSliders(data) {
    yield takeEvery(actions.SET_FEATURE_SLIDERS, function* (action) {
        // yield put({
        //     type: slideOutActions.SET_ACTIVE_SLIDEOUT,
        //     payload: false
        // })

    });
}


export function* removeUserViewedCall(data) {
    yield takeEvery(actions.REMOVE_USER_VIEWED_CALL, function* (action) {

        const Client = yield select(getGOClient);
        let userData = yield select(getUserProfileSettingData);

        let callViews = userData.lastCallViews;
        let newCallViews = {};

        for (const callID in callViews) {
            if (callViews.hasOwnProperty(callID)) {
                if (callID !== "" + action.payload) {
                    newCallViews = {
                        ...newCallViews,
                        [callID]: callViews[callID]
                    }
                }
            }
        }

        userData = {
            ...userData,
            lastCallViews: newCallViews
        }

        yield put({
            type: actions.SET_USER_DATA,
            payload: userData
        })
    });
}


export default function* rootSaga() {
    yield all([
        fork(getUserProfileData),
        fork(getUserAppRoles),
        fork(getUserMessages),
        fork(getUserApplications),
        fork(setUserData),
        fork(addDebugData),
        fork(updateUserSettings),
        fork(setUserViewedCall),
        fork(removeUserViewedCall),
        fork(setFeatureSliders),
        fork(setFeatureSliderVisible)
    ]);
}
