const actions = {
	SET_NOTIFICATIONS : 'SET_NOTIFICATIONS',
    PUSH_NOTIFICATION : 'PUSH_NOTIFICATION',
	CLEAR_NOTIFICATIONS : 'CLEAR_NOTIFICATIONS',
    pushNotification: (message, type) => ({
        type: actions.PUSH_NOTIFICATION,
        payload: {
			message : message,
			type : type
		}
    }),
};

export default actions;
