const actions = {
	GET_IT_NEWS : 'GET_IT_NEWS',
	SET_IT_NEWS : 'SET_IT_NEWS',
	SET_ACTIVE_IT_NEWS_ITEM : 'SET_ACTIVE_IT_NEWS_ITEM',
	COMMON_IT_NEWS_REQUEST_COMPLETED : 'COMMON_IT_NEWS_REQUEST_COMPLETED',

	getITNews : ( payload ) => ({
		type : actions.GET_IT_NEWS,
		payload : payload
	}),

	setActiveITNewsItem : ( payload ) => ({
		type : actions.SET_ACTIVE_IT_NEWS_ITEM,
		payload : payload
	})
};

export default actions;
