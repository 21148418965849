const actions = {
	GET_PEOPLE : 'GET_PEOPLE',
    SET_PEOPLE : 'SET_PEOPLE',
	GET_ALL_PEOPLE : 'GET_ALL_PEOPLE',
	STOP_FETCHING_PEOPLE : 'STOP_FETCHING_PEOPLE',
	GET_NEXT_PEOPLE_PAGE : 'GET_NEXT_PEOPLE_PAGE',
	SET_NEXT_PAGE_AVAILABLE : 'SET_NEXT_PAGE_AVAILABLE',
	SET_PEOPLE_FETCHING : 'SET_PEOPLE_FETCHING',
	SET_CURRENT_PAGE : 'SET_CURRENT_PAGE',
	GET_PERSON_AVATAR : 'GET_PERSON_AVATAR',
	SET_PEOPLE_AVATARS : 'SET_PEOPLE_AVATARS',
	START_STATUS_JOB : 'START_STATUS_JOB',
	GET_USER_STATUS : 'GET_USER_STATUS',
	STOP_STATUS_JOB : 'STOP_STATUS_JOB',

	startStatusJob : (payload) => ({
		type : actions.START_STATUS_JOB,
		payload : payload
	}),

	getUserStatus : (payload) => ({
		type : actions.GET_USER_STATUS,
		payload : payload
	}),

	stopStatusJob : (payload) => ({
		type : actions.STOP_STATUS_JOB,
		payload : payload
	}),

    getPeople: () => ({
        type: actions.GET_PEOPLE
    }),
	getNextPeoplePage : () => ({
		type : actions.GET_NEXT_PEOPLE_PAGE
	}),
	getAllPeople: () => ({
        type: actions.GET_ALL_PEOPLE
    }),

	findAvatar : (payload) => ({
		type : actions.GET_PERSON_AVATAR,
		payload : payload
	})
};

export default actions;
