const actions = {
	SET_MULTICHANNEL_SHOUT_ITEMS : 'SET_MULTICHANNEL_SHOUT_ITEMS',
	GET_MULTICHANNEL_SHOUT_ITEMS : 'GET_MULTICHANNEL_SHOUT_ITEMS',
	SET_ACTIVE_SHOUT_ITEM : 'SET_ACTIVE_SHOUT_ITEM',
	SHOUT_REQUEST_COMPLETED: 'SHOUT_REQUEST_COMPLETED',
	SET_LAST_SHOUT_VIEWED: 'SET_LAST_SHOUT_VIEWED',
	getMultichannelShoutItems : ( payload, title ) => ({
		type : actions.GET_MULTICHANNEL_SHOUT_ITEMS,
		payload: { title: title, payload: payload } 
	}),
	setActiveShoutItem : ( payload ) => ({
		type : actions.SET_ACTIVE_SHOUT_ITEM,
		payload : payload
	}),
	setLastViewed: (payload) => ({
		type: actions.SET_LAST_SHOUT_VIEWED,
		payload: payload
	})
};

export default actions;
