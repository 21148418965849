const actions = {
	GET_TASKS : 'GET_TASKS',
	SET_TASKS : 'SET_TASKS',
	ORDER_TASKS : 'ORDER_TASKS',
	UPDATE_TASK : 'UPDATE_TASK',
	UPDATE_SUB_TASK : 'UPDATE_SUB_TASK',
	UPDATE_TASK_EMAIL : 'UPDATE_TASK_EMAIL',
	UPDATE_TASK_PLANNER : 'UPDATE_TASK_PLANNER',
	SET_ACTIVE_TASK : 'SET_ACTIVE_TASK',
	getTasks: (payload) => ({ 
		type: actions.GET_TASKS,
		payload : payload
	}),	
	updateTask: (payload, key) => ({
		type:actions.UPDATE_TASK,
		payload: { payload : payload, key : key }
	}),
	updateSubTask: (payload, key, subTask) => ({
		type:actions.UPDATE_SUB_TASK,
		payload: { payload : payload, key : key, subTask : subTask }
	}),
	updateTaskEmail: (payload, key) => ({
		type:actions.UPDATE_TASK_EMAIL,
		payload: { payload : payload, key : key }
	}),
	updateTaskPlanner: (payload, key) => ({
		type:actions.UPDATE_TASK_PLANNER,
		payload: { payload : payload, key : key }
	}),
	setActiveTask : ( payload ) => ({
		type : actions.SET_ACTIVE_TASK,
		payload : payload
	}),
};

export default actions;
