const actions = {
	GET_GO_DATA : 'GET_GO_DATA',
    SET_GRAPH_CLIENT: 'SET_GRAPH_CLIENT',
    SET_GO_CLIENT: 'SET_GO_CLIENT',
    SET_SHAREPOINT_CLIENT: 'SET_SHAREPOINT_CLIENT',
    SET_POWER_CLIENT: 'SET_POWER_CLIENT',
    SET_LIQUIT_CLIENT: 'SET_LIQUIT_CLIENT',
    SET_YAMMER_CLIENT: 'SET_YAMMER_CLIENT',
    SET_AUTH_PROVIDER: 'SET_AUTH_PROVIDER',
    SET_AUTH: 'SET_AUTH',
    AAD_LOGIN_SUCCESS: 'AAD_LOGIN_SUCCESS',
    AAD_LOGOUT_SUCCESS: 'AAD_LOGOUT_SUCCESS',
    AAD_LOGIN_ERROR: 'AAD_LOGIN_ERROR',
    SET_IS_LOADED: 'SET_IS_LOADED',
    AAD_ACQUIRED_ACCESS_TOKEN_ERROR: 'AAD_ACQUIRED_ACCESS_TOKEN_ERROR',
    AAD_ACQUIRED_ID_TOKEN_ERROR: 'AAD_ACQUIRED_ID_TOKEN_ERROR',
    AAD_LOGIN_FAILED: 'AAD_LOGIN_FAILED',
    CLIENTS_ACTIVATED: 'CLIENTS_ACTIVATED',
	
	setAuthProvider : (client) => ({
		type : actions.SET_AUTH_PROVIDER,
		payload : client
    }),

    setAuth: (auth) => ({
        type: actions.SET_AUTH,
        payload: auth
    })
};

export default actions;
