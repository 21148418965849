const actions = {
	GET_BLOGS : 'GET_BLOGS',
	SET_BLOGS : 'SET_BLOGS',
		POST_BLOG :  'POST_BLOG',
	postBlog : ( payload ) => ({
	type : actions.POST_BLOG,
	payload : payload
}),
	getBlogs : ( payload ) => ({
		type : actions.GET_BLOGS,
		payload : payload
	})
};

export default actions;
