import moment from 'moment';

class NewsObject {
    constructor() {
        this._newsItem = {
            author : false,
            content : false,
            unix : false,
            moment : false,
            title : false,
            imageUrl : false,
            url : false,
            id : false,
            type: false,
            tenantId: false,
            site: false,
            dateModified: false,
            api: false,
        }
    }

    setID = (ID) => {
        this._newsItem = {
            ...this._newsItem,
            id : ID
        }
    }

    setAuthor = (author) => {
        this._newsItem = {
            ...this._newsItem,
            author : author
        }
    }

    setContent = (content) => {
        this._newsItem = {
            ...this._newsItem,
            content : content
        }
    }

    setSite = (site) => {
        this._newsItem = {
            ...this._newsItem,
            site: site
        }
    }

    setDate = (date, format = false) => {
        const parsedDate = moment(date, format);
        this._newsItem = {
            ...this._newsItem,
            unix : parsedDate.unix(),
            moment: parsedDate,
            date: date
        }
    }

    setDateModified = (date) => {
        this._newsItem = {
            ...this._newsItem,
            dateModified: date
        }
    }

    setTitle = (title) => {
        this._newsItem = {
            ...this._newsItem,
            title : title
        }
    }

    setType = (type) => {
        this._newsItem = {
            ...this._newsItem,
            type : type
        }
    }

    setImageUrl = (imageUrl) => {
        this._newsItem = {
            ...this._newsItem,
            imageUrl : imageUrl
        }
    }

    setTenantId = (tenantId) => {
        this._newsItem = {
            ...this._newsItem,
            tenantId: tenantId
        }
    }

    setUrl = (URL) => {
        this._newsItem = {
            ...this._newsItem,
            url : URL
        }
    }

    setApi = (api) => {
        this._newsItem = {
            ...this._newsItem,
            api : api
        }
    }

    getNewsObject = () => {
        for ( const property in this._newsItem ) {
            if ( this._newsItem.hasOwnProperty(property) ) {
                if (property !== 'url' && property !== 'tenantId' && property !== 'site' && property !=='dateModified' && property !=='api' ) {
                    if ( !this._newsItem[property] ) {
                        console.log('Missing in newsobject: ' + property)
                        return false;
                    }
                }

            }
        }
        return this._newsItem;
    }
}

export default NewsObject;
