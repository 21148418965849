import styled from 'styled-components'
import * as Variables from '../../../../ThemeVariables';

export const SearchInput = styled.input`
	width: calc(100% - 50px);
	line-height: ${Variables.headerHeight};
	font-size: 16px;
	background: transparent;
	float: left;

	&:focus {
		outline : none;
	}
`;

export const SearchWrapper = styled.div`
	/*width: calc(100% - 50px);*/
	width: 100%;

	background: #ebedf0;
	border-radius: 25px;
	overflow: hidden;

	&.greyout {
		opacity: .3;
	}


	height: ${Variables.headerHeight};

	@media ( max-width: ${Variables.mxs}) {
		display: none;
	}



	@media ( min-width: ${Variables.md} ) {
		${props => props.gridArea ? 'grid-area:' + props.gridArea : ''};
		width: 20vw;

		margin-left: 25px;
	}

	@media ( min-width: ${Variables.lgMin} ) {
		width: 20vw;
	}

	${props => props.wrapped ? 'display: block !important;' : ''};

	@media ( min-width: ${Variables.xlg} ) {
		display: block;
		width: 20vw;
		grid-area: search;
	}
`;

export const Magnifier = styled.div`
	width: 50px;
	float: left;
	line-height: ${Variables.headerHeight};
	text-align: center;

`;
