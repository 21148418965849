import styled from 'styled-components';
import * as Variables from '../../ThemeVariables';


export const StatusWrapper = styled.div`
    *{
      padding:0;
      margin:0;
      border:0;
      box-sizing:border-box;
      position:relative;
      font-family: 'Montserrat', sans-serif;
    }

    background: #F10000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StatusInner = styled.div`
    text-align: center;
    color: white;
    font-size: 35px;

    h4 {
        font-size: 35px;
    }
    p {
        font-size: 24px;
    }
`;
export const StatusLogo = styled.div`
    margin-bottom: 25px;
`;
