import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import actions from '../../../redux/slideOut/actions';
import callsActions from '../../../redux/common/calls/actions';
import Bubble from '../bubble';
import * as Styled from './styled';
import { getMS, capitalizeFirstLetter } from '../../../helpers/functions';

import { faConciergeBell, faTasks, faUserFriends, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
//import { fas } from '@fortawesome/free-solid-svg-icons'
import { faWindowRestore, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import ReactTooltip from 'react-tooltip';
import { withTranslate } from 'react-redux-multilingual'
import moment from 'moment/min/moment-with-locales';

class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shoutWidgets: [],
            birthdays: false
        };
    }


    componentDidMount() {
        this._getCalls();

        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(this._getCalls.bind(this), getMS(11));
        this._checkShoutItems();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.widgets.left?.length == 0 && this.props.widgets.left?.length > 0) {
            this._checkShoutItems();
            // if (this.state.shoutWidgets.length == 0 && this.props.widgets.left.length > 0) {
            //this._checkShoutItems();
        }

        //if (prevProps.showItem !== this.props.showItem) {
        ReactTooltip.rebuild();
        //}
    }

    _checkShoutItems = () => {
        //this.props.shoutItems.
        //if (this.props.shoutItems.length == 0 || this.state.shoutWidgets.length > 0) return;
        //const widgets = [...new Set(this.props.shoutItems.map(item => item.widget))];


        //this.props.widgets.find(w => w.)
        let widgets = this.findAll(this.props.widgets, "MultiShout");
        let birthdays = this.findAll(this.props.widgets, "Birthdays");

        this.setState({ shoutWidgets: widgets, birthdays: (birthdays?.length > 0) });
    }

    findAll = (obj, searchKey, results = []) => {
        const r = results;
        if (obj == null) return;
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value === searchKey && typeof value !== 'object') {
                r.push(obj);
            } else if (typeof value === 'object') {
                this.findAll(value, searchKey, r);
            }
        });
        return r;
    };

    //findAll = (array) => {
    //    let result = [];

    //    const searchItem = (item) => {
    //        Object.keys(item).forEach(key => {
    //            if (typeof item[key] === "object") {
    //                searchItem(item[key])
    //            }
    //            if (typeof item[key] === "string") {
    //                let searchAsRegEx = new RegExp(searchTerm, "gi");
    //                if (item[key].match(searchAsRegEx)) {
    //                    result.push(item.id)
    //                }
    //            }
    //        })
    //    };

    //    const getEachItem = (object) => {
    //        //object.forEach(item => {
    //        //    this.searchItem(item)
    //        //})
    //        Object.keys(object).forEach(item => {
    //            searchItem(item);
    //        });
    //        let uniqueResults = [...new Set(result)]
    //        return uniqueResults.length
    //    };

    //    return getEachItem(array);
    //}

    _getCalls = () => {
        this.props.actions.getCalls();
    }

    _filterShoutItems = (items, widget) => {
        return items.filter(i => i.widget == widget?.title && moment.unix(i.unix) > moment().add(-14, 'days') && this._getIsNew(i) === true);
    }

    _getIsNew = (item) => {
        let viewedShouts = this.props.lastShoutViews;
        let shout = viewedShouts?.find((shout) => shout.url === item.url);
        if (shout) {
            return !shout.viewed;
        }
        return true;
    }

    _renderShoutWidgets = (w, key) => {
        return <Styled.Link key={key}
            className={(this.props.slideoutData?.title === w.title ? 'btn-active' : '')}
            data-tip={capitalizeFirstLetter(w.title)}
            darkmode={this.props.darkmode}
            onClick={
                () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'MultiShout' ? false : 'MultiShout'), { title: w.title })
            }>
            <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                <Styled.MenuListItemInner>
                    {this._filterShoutItems(this.props.shoutItems, w).length > 0 ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this._filterShoutItems(this.props.shoutItems, w).length} /> : null}
                    <FontAwesomeIcon icon={['fas', w.icon ? w.icon : 'list']} />
                    <Styled.MenuListItemLabel>
                        {w.title}
                    </Styled.MenuListItemLabel>
                </Styled.MenuListItemInner>
            </Styled.MenuListItem>
        </Styled.Link>;
    }

    render() {

        return (
            <Styled.Nav parent={this.props.parent} slideout={this.props.slideout}>
                <ReactTooltip />
                <Styled.MenuList parent={this.props.parent}>

                    {this.state.birthdays &&
                        <Styled.Link
                            key="11"
                            className={(this.props.activeSlideOut === 'Birthdays' ? 'btn-active' : '')}
                            data-tip={capitalizeFirstLetter(this.props.translate('birthdays'))}
                            darkmode={this.props.darkmode}
                            onClick={
                                () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'Birthdays' ? false : 'Birthdays'))
                            }>
                            <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                                <Styled.MenuListItemInner>
                                    {/* this.props.upcomingBirthdays.filter(b => (b.when == 'Morgen' || b.when == 'Vandaag')).length > 0 ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.upcomingBirthdays.length} /> : null */}
                                    <FontAwesomeIcon icon={['fas', 'birthday-cake']} />

                                    <Styled.MenuListItemLabel>
                                        {this.props.translate('birthdays')}
                                    </Styled.MenuListItemLabel>
                                </Styled.MenuListItemInner>
                            </Styled.MenuListItem>
                        </Styled.Link>
                    }

                    {this.state.shoutWidgets?.length > 0 && this.state.shoutWidgets.map(this._renderShoutWidgets)}

                    <Styled.Link
                        key="12"
                        className={(this.props.activeSlideOut === 'People' ? 'btn-active' : '')}
                        data-tip={capitalizeFirstLetter(this.props.translate('people'))}
                        darkmode={this.props.darkmode}
                        onClick={
                            () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'People' ? false : 'People'))
                        }>
                        <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                            <Styled.MenuListItemInner>
                                {/*this.props.user.teams ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.user.teams} /> : null*/}
                                <FontAwesomeIcon icon={faUserFriends} />

                                <Styled.MenuListItemLabel>
                                    {this.props.translate('people')}
                                </Styled.MenuListItemLabel>
                            </Styled.MenuListItemInner>
                        </Styled.MenuListItem>
                    </Styled.Link>

                    <Styled.Link
                        key="13"
                        className={(this.props.activeSlideOut === 'tasks' ? 'btn-active' : '')}
                        data-tip={capitalizeFirstLetter(this.props.translate('tasks'))}
                        darkmode={this.props.darkmode}
                        onClick={
                            () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'tasks' ? false : 'tasks'))
                        }>
                        <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                            <Styled.MenuListItemInner>
                                {this.props.user.tasks ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.user.tasks} /> : null}
                                <FontAwesomeIcon icon={faTasks} />

                                <Styled.MenuListItemLabel>
                                    {this.props.translate('tasks')}
                                </Styled.MenuListItemLabel>
                            </Styled.MenuListItemInner>
                        </Styled.MenuListItem>
                    </Styled.Link>


                    <Styled.Link
                        key="14"
                        className={(this.props.activeSlideOut === 'calendar' ? 'btn-active' : '')}
                        data-tip={capitalizeFirstLetter(this.props.translate('calendar'))}
                        darkmode={this.props.darkmode}
                        onClick={
                            () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'calendar' ? false : 'calendar'))
                        }>

                        <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                            <Styled.MenuListItemInner>
                                {this.props.user.calendarItems ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.user.calendarItems} /> : null}
                                <FontAwesomeIcon icon={faCalendarAlt} />
                                <Styled.MenuListItemLabel>
                                    {this.props.translate('calendar')}
                                </Styled.MenuListItemLabel>
                            </Styled.MenuListItemInner>
                        </Styled.MenuListItem>
                    </Styled.Link>

                    {window.tenantConfig.hulpDeactivated !== "true" &&
                        <Styled.Link
                            key="15"
                            data-tip={capitalizeFirstLetter(this.props.translate('tickets'))}
                            darkmode={this.props.darkmode}
                            onClick={
                                () => {
                                    this.props.history.push('/online-hulp/mijn-meldingen');
                                    this.props.actions.setActiveSlideOut(false);
                                }
                            }>

                            <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                                <Styled.MenuListItemInner>
                                    {this.props.calls?.filter(i => i.status != "Afgesloten").length > 0 ? <Bubble style={{ position: 'absolute', left: '25px', top: '15px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.calls?.filter(i => i.status != "Afgesloten").length} /> : null}
                                    <FontAwesomeIcon icon={faConciergeBell} />

                                    <Styled.MenuListItemLabel>
                                        {this.props.translate('tickets')}
                                    </Styled.MenuListItemLabel>
                                </Styled.MenuListItemInner>
                            </Styled.MenuListItem>
                        </Styled.Link>
                    }

                    {/*window.tenantConfig.version == 'development' &&
                        <Styled.Link
                            className={(this.props.activeSlideOut === 'goapps' ? 'btn-active' : '')}
                            data-tip={this.props.translate('apps')}
                            darkmode={this.props.darkmode}
                            onClick={
                                () => this.props.actions.setActiveSlideOut((this.props.activeSlideOut === 'goapps' ? false : 'goapps'), { page: 'init' })
                            }>
                            <Styled.MenuListItem key="5" className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
                                <Styled.MenuListItemInner>
                                    <FontAwesomeIcon icon={faWindowRestore} />
                                    <Styled.MenuListItemLabel>
                                        {this.props.translate('apps')}
                                    </Styled.MenuListItemLabel>
                                </Styled.MenuListItemInner>
                            </Styled.MenuListItem>
                        </Styled.Link>
                    */}

                </Styled.MenuList>
            </Styled.Nav >
        );
    }
}

//LATEN STAAN, moet weer terug worden gezet als we met de moderne werkplek live gaan
//<Styled.Link className={(window.tenantConfig.version != 'development' ? 'greyout' : null)} data-tip="Binnenkort beschikbaar: meldingen" darkmode={this.props.darkmode} to="/#4" onClick={(window.tenantConfig.version == 'development') ? () => {
//    this.props.history.push('/online-hulp/mijn-meldingen');
//    this.props.actions.setActiveSlideOut(false);
//} : null}>

//    <Styled.MenuListItem className={(this.props.darkmode ? 'darkmode' : '')} parent={this.props.parent}>
//        {this.props.user.tasks ? <Bubble style={{ position: 'absolute', marginLeft: '15px', top: '-4px', zIndex: 1, fontSize: 12, lineHeight: '18px', width: '18px' }} number={this.props.calls?.length} /> : null}
//        <FontAwesomeIcon icon={faConciergeBell} />

//        <Styled.MenuListItemLabel>
//            Meldingen
//                            </Styled.MenuListItemLabel>
//    </Styled.MenuListItem>
//</Styled.Link>

const mapStateToProps = (state, ownProps) => {
    return {
        activeSlideOut: state.SlideOut.activeSlideOut,
        user: state.User,
        calls: state.Calls.items,
        darkmode: state.Layout.darkmode,
        birthdays: state.Birthday.birthdays,
        upcomingBirthdays: state.Birthday.upcoming,
        shoutItems: state.MultiChannelShout.shoutItems,
        lastShoutViews: state.User.profile.data?.settings?.viewedShouts,
        slideoutData: state.SlideOut.data,
        widgets: state.Widgets.widgets
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...callsActions }, dispatch) });

export default withTranslate(withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu)));
