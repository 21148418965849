
export const dataURItoBlob = (dataURI) => {
    var startIndex = dataURI.indexOf("base64,") + 7;
    var b64 = dataURI.substr(startIndex);

    const byteString = window.atob(b64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
};

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const capStringName = (stringName, limit) => {

    if (stringName.length > limit) {
        return stringName.substr(0, limit) + '..';
    }

    return stringName;
};

export const capString = (string, limit) => {

    if (string.length > limit) {
        return string.substr(0, limit);
    }

    return string;
};

export const stripHTML = (html) => {
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export const getTimeStamp = () => {
    return Math.floor(Date.now() / 1000);
};

export const getMS = (minutes) => {
    return (minutes * 60) * 1000
};

export const parseSharepointUrl = (url) => {
    if (url === null || url === "") return "";
    if (url?.includes('.com')) {
        let split = url.split('.com');
        return split[0] + '.com';
    }
    return url;
};



export const parsePictureUrl = (picture, resolution = 5) => {
    if (!picture) return '';
    if (!picture.includes('resolution')) {
        return picture.replace('?', '?resolution=' + resolution + '&');
    }
    return picture;
};


export const checkIfClaim = (group, claims) => {
    try {
        if (claims == null || claims === {}) return false;
        for (let claim of claims) {
            if (claim.indexOf(group) !== -1) return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};



let getDataTransfer = () => new DataTransfer();
const { concat } = Array.prototype;

try {
    getDataTransfer();
} catch (_) {
    getDataTransfer = () => new ClipboardEvent('').clipboardData;
}

export const createFileList = (file) => {
    // eslint-disable-next-line prefer-rest-params

    const files = concat.apply([], [file]);
    let i = 0;
    const { length } = files;

    const dataTransfer = getDataTransfer();

    for (; i < length; i++) {
        if (files[i] != null && files[i] instanceof File) {
            dataTransfer.items.add(files[i]);
        }
    }

    return dataTransfer.files;

};

export const fileToBase64 = (filename, filepath) => {
  return new Promise(resolve => {
    var file = new File([filename], filepath);
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function(event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};

export const blobToBase64 = (blob) => {
    return new Promise(resolve => {

        var reader = new FileReader();

        // Read file content on file loaded event
        reader.onload = function (event) {
            resolve(event.target.result);
        };

        // Convert data to base64
        reader.readAsDataURL(blob);
    });
};



const nameLookup = {
    one: 'OneNote',
    pptx: 'PowerPoint',
    xlsx: 'Excel',
    docx: 'Word',
    pdf: 'pdf',
    vsdx: 'visio',
    txt: 'txt'
}

export const getAppName = documentName => {
    const appName = documentName?.split('.').slice(-1)[0];


    if ( nameLookup[appName] ) {
        return nameLookup[appName];
    }

    return false;
}

const iconLookup = {
    one: 'OneNote.svg',
    pptx: 'PowerPoint.svg',
    PowerPoint : 'PowerPoint.svg',
    xlsx: 'Excel.svg',
    Excel : 'Excel.svg',
    docx: 'Word.svg',
    Word : 'Word.svg',
    pdf: 'pdf.svg',
    vsdx: 'visio.svg',
    txt: 'txt.svg'
}
export const getAppIcon = documentName => {
    const appName = documentName.split('.').slice(-1)[0];
    if ( iconLookup[appName] ) {
        return iconLookup[appName];
    }

    return 'OneDrive.svg';
}


export const findAll = (obj, searchKey, results = []) => {
    const r = results;
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value === searchKey && typeof value !== 'object') {
            r.push(obj);
        } else if (typeof value === 'object') {
            findAll(value, searchKey, r);
        }
    });
    return r;
};