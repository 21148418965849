import React, { Component } from 'react';
import { connect } from 'react-redux';

import DarkmodeSlide from '../../slideTypes/darkmodeSlide';
import ListSlide from '../../slideTypes/listSlide';
import VideoSlide from '../../slideTypes//videoSlide';
import SettingSlide from '../../slideTypes/settingSlide';
import ContentSlide from '../../slideTypes/contentSlide';
import Slide from '../../slider/slide';
import Slider from '../../slider';
import { withTranslate } from 'react-redux-multilingual';
import { capitalizeFirstLetter } from '../../../../../helpers/functions';

class Slides extends Component {
    constructor() {
        super()
    }
    render() {

        return (
            <Slider alwaysVisible={true} fadeIn={true}>
                <Slide next>
                    <ContentSlide
                        title={`Hi ${this.props.user.givenName}!`}
                        description={this.props.translate("wizardHomeHi") + " <img style='width:300px;display:block;margin:0 auto;margin-top:20px;' src='https://gocnd.azureedge.net/gowebsite/Avantage_GO.png' /><p style=\"text-align:center;margin-top:20px;\">"}
                    />
                </Slide>
                <Slide prev next>
                    <ContentSlide
                        title={`GoGo, de slimme hulp`}
                        description={this.props.translate("wizardHomeGoGo") + " <img style='width:50px;display:block;margin:0 auto;margin-top:20px;' src='https://gocnd.azureedge.net/gowebsite/Isaac_bot_GO-01.svg' />"}
                    />
                </Slide>
 
                <Slide prev next>
                    <SettingSlide
                        title={this.props.translate("hasOfficeLocalTitle")}
                        description={this.props.translate("hasOfficeLocal")}
                        trueVal={capitalizeFirstLetter(this.props.translate("yes"))}
                        defaultVal={true}
                        falseVal={capitalizeFirstLetter(this.props.translate("no"))}
                        setting="startLocalApps"
                    />
                </Slide>

                <Slide prev next>
                    <SettingSlide
                        title={this.props.translate("wantOfficeWizardTitle")}
                        description={this.props.translate("wantOfficeWizard")}
                        trueVal={capitalizeFirstLetter(this.props.translate("yes"))}
                        falseVal={capitalizeFirstLetter(this.props.translate("no"))}
                        defaultVal={true}
                        setting="showOfficeWizard"
                    />
                </Slide>

                <Slide prev next>
                    <SettingSlide
                        title={this.props.translate("inTabOrPopupTitle")}
                        description={this.props.translate("inTabOrPopup")}
                        trueVal={this.props.translate("newTab")}
                        falseVal={this.props.translate("popup")}
                        defaultVal={false}
                        setting="startAppsInNewWindow"
                    />
                </Slide>

                {window.tenantConfig?.birthdayEnabled == "true" &&
                    <Slide prev next>
                        <ContentSlide
                            title={this.props.translate("shareBirthdayTitle")}
                            description={this.props.translate("shareBirthday", {
                                url: `https://delve.office.com/?u=${this.props.user.id}&v=editprofile`
                            })}
                        />
                    </Slide>
                }

                <Slide prev close>
                    <DarkmodeSlide />
                </Slide> 
            </Slider>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.profile,
    };
};


export default withTranslate(connect(mapStateToProps)(Slides));
