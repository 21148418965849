import styled from 'styled-components'
import * as Variables from '../../../ThemeVariables';

export const SliderWrapper = styled.div`
	position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .6);
  width: 100%;
  z-index: 5;

  &.fade-in {
	  background: rgba(0, 0, 0, 1);
	  animation-iteration-count: 1;
	  animation: opac 5s normal forwards ease-in-out 5s;

	  @keyframes opac {
	    from {
	         background: rgba(0, 0, 0, 1);
	    }

	    to {
	         background: rgba(0, 0, 0, .85);
	    }


  }
}
`;

export const Slide = styled.div`
  height: 100vh;

`;

export const SlideInner = styled.div`
  height: 100%;

  width: 100%;
  display: flex;
  align-items: center;
`;

export const SlideInnerContent = styled.div`

  max-width: 600px;
  width: 600px;
  @media(max-width: ${Variables.md}) {
	  max-width: calc(100% - 40px);
	  width: calc(100% - 40px);
    iframe {
      max-width: 100%;
    }
  }
  height: auto;
  margin: 0 auto;
  background: white;
 	border-radius: 10px;
  padding: 30px;
`;

export const SlideTitle = styled.h1`
  font-size: 18px;
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 25px;
`;

export const CloseSlide = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  z-index: 5;
  cursor: pointer;
  &:after, &:before {
    content: '';
    right: 15px;
    width: 2px;
    height: 100%;
    background: black;
    position: absolute;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Button = styled.button`
    background: ${Variables.mainColor};
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    border-radius: 20px;
    bottom: 25px;
    position: absolute;
`;
