import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const Checkbox = props => (
	<Styled.Checkbox>
		<Styled.Label>{props.label}</Styled.Label>
		<Styled.CheckboxWrapper className={(props.checked ? 'active' : '')}>
			 <input
	          type="checkbox"
	          checked={props.checked}
	          onChange={props.onChange}
	        />
			<Styled.CheckboxCircle className={(props.checked ? 'active' : '')} />

		</Styled.CheckboxWrapper>
	</Styled.Checkbox>
)

Checkbox.propTypes = {
	onClick : PropTypes.func,
	value : PropTypes.bool,
	label : PropTypes.string
}

export default Checkbox;
