import  moment  from 'moment';
class BlogObject {
    constructor() {
        this._blogItem = {
            author : false,
            content : false,
            unix : false,
            moment : false,
            title : false,
            imageUrl : false,
            url : false,
            id : false,
            type : false,
            openExternal: false,
            tenantId: false,
            site: false,
            dateModified: false,
        }
    }
    setID = (ID) => {
        this._blogItem = {
            ...this._blogItem,
            id : ID
        }
    }

    setType = (type) => {
        this._blogItem = {
            ...this._blogItem,
            type : type
        }
    }

    setAuthor = (author) => {
        this._blogItem = {
            ...this._blogItem,
            author : author
        }
    }

    setSite = (site) => {
        this._blogItem = {
            ...this._blogItem,
            site: site
        }
    }

    setContent = (content) => {
        this._blogItem = {
            ...this._blogItem,
            content : content
        }
    }

    setDate = (date, format = false) => {
        const parsedDate = moment(date, format);
        this._blogItem = {
            ...this._blogItem,
            unix : parsedDate.unix(),
            moment : parsedDate
        }
    }

    setDateModified = (date) => {
        this._blogItem = {
            ...this._blogItem,
            dateModified: date
        }
    }

    setTitle = (title) => {
        this._blogItem = {
            ...this._blogItem,
            title : title
        }
    }

    setImageUrl = (imageUrl) => {
        this._blogItem = {
            ...this._blogItem,
            imageUrl : imageUrl
        }
    }

    setUrl = (URL) => {
        this._blogItem = {
            ...this._blogItem,
            url : URL
        }
    }

    setTenantId = (tenantId) => {
        this._blogItem = {
            ...this._blogItem,
            tenantId: tenantId
        }
    }

    setOpenExternal = (bool) => {
        this._blogItem = {
            ...this._blogItem,
            openExternal : bool
        }
    }

    getBlogObject = () => {
        for ( const property in this._blogItem ) {
            if ( this._blogItem.hasOwnProperty(property) ) {
                if( property !== 'url' && property !== 'imageUrl' && property !== 'openExternal' && property !== 'tenantId' && property !== 'site' && property !=='dateModified') {
                    if ( !this._blogItem[property] ) {
                        console.log("Missing blogitem: " + property);
                        return false;
                    }
                }

            }
        }
        return this._blogItem;
    }
}

export default BlogObject;
