import styled from 'styled-components';
import Box from '../../../styled/Box';
import * as Variables from '../../../../ThemeVariables';

export const BirthdayItem = styled.div`
`;

export const Wrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-rows: none;
	grid-template-columns: 80px 1fr 0px;
	grid-gap: 14px;
	padding: 10px;
    top: 5px;
    left: 6px;
    right: 18px;
    width: 430px;

    &.darkmode {
        color: ${Variables.mainTextColor.darkModeSoft};
    }
`;

export const Icon = styled.img`
	width: 65px;
	height: 65px;
	border-radius: 50%;
`;

export const Image = styled.div`

`;

export const Name = styled.div`
	line-height: 24px;
	font-size: 16px;
`;

export const Date = styled.div`
	line-height: 24px;
	font-size: 12px;
`;

export const LetterAvatar = styled.div`
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    background: ${Variables.mainColor};
    color: white;
    font-weight: bold;
`;

export const PersonAvatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: block;
    ${ props => props.img ? `background-image: url(${props.img})` : ''};
    background-size: cover;
`;