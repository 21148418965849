import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../../message/actions';
import userActions from '../../user/actions';
import store from "../../store";
import { getCommonNewsItems, getUserProfileSettingData, getGOClient, getUserProfile, getTenantNewsItems, getAuth } from '../../selectors'

export function* getRightsRequest() {
    yield takeEvery(actions.GET_RIGHTS_REQUEST, function* (action) {

        const rightsRequests = yield select(getRightsRequest);
        const Client = yield select(getGOClient);
        if (!Client) return;
        const requests = null;
        // const requests = yield Client.api("/selfService/user/tenant");

        if(requests == null | !requests) return;

        if (requests.length == 0 || (requests.length && ((rightsRequests.length === 0) || (rightsRequests && rightsRequests[0].Id !== requests[0].id)))) {

            yield put({
                type: actions.SET_RIGHTS_REQUEST,
                payload: requests
            });
        }
    });
}

export function* postRightsRequest() {
    yield takeEvery(actions.POST_RIGHTS_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;

        let incidentRequest = {
            calltypeid: 2,
            subject: action.payload.title,
            body: action.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
            phonenumber: userProfile.mobilePhone ? userProfile.mobilePhone : '',
        }

        let formFields = {
            emailadres: action.payload.emailadres,
            toevoegen: action.payload.toevoegen,
            rol: action.payload.rollen,
        }
        
        let result = false;
        result = yield GoClient.post('/app/incident/request', incidentRequest);

        //userRequests.push(formFields);


        if (result) {
            const userProfileData = yield select(getUserProfileSettingData);
            let rightsRequests = userProfileData?.settings?.calls
            if(!rightsRequests) rightsRequests = [];
            rightsRequests.push({
                callId: result.callId,
                status: result.status,
                type: incidentRequest.calltypeid,
                email: formFields.emailadres,
                rol: formFields.rol,
                toevoegen: formFields.toevoegen,
                typeDesc: formFields.toevoegen.value == "Toevoegen" ? "Toevoegen" : "Verwijderen",
                creationDatrequest: "RightsRequest",e: Date.now(),
                lastUpdate: Date.now(),
                
            })
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: rightsRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getRightsRequest);
            let request = requests
            formFields['callId'] = result.callId
            request.push(formFields)
            yield put({
                type: actions.SET_RIGHTS_REQUEST,
                payload:request
            })
            // yield put({
            //     type: actions.SET_USER_REQUEST,
            //     payload:userRequest
            // })

            // yield put({
            //     type: actions.GET_USER_REQUEST
            // })

            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Rights Request toegevoegd',
                    type: 'success'
                }
            });

            store.history.push('/self-service/rechten');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* updateRightsRequest() {
    yield takeEvery(actions.UPDATE_RIGHTS_REQUEST, function* (action) {
        const Auth = yield select(getAuth);
        const GoClient = yield select(getGOClient);
        const userProfile = yield(getUserProfile);
        //const userRequests = yield select(getUserRequest);
        if (!GoClient) return;
        let incidentRequest = {
            callid: action.payload.id,
            subject: action.payload.payload.title,
            body: action.payload.payload.body,
            emotionscore: 0.0,
            sentiment: "",
            emailaddress: "",
        }

        let formFields = {
            emailadres: action.payload.payload.emailadres,
            toevoegen: action.payload.payload.toevoegen,
            rollen: action.payload.payload.rollen,
        }
        
        //yield GoClient.post('/app/incident/action', incidentRequest);
        const userProfileData = yield select(getUserProfileSettingData);
        let rightsRequests = userProfileData?.settings?.calls
        let index = rightsRequests.findIndex(request => request.callId == incidentRequest.callid)
        if(index > -1) {
            if(!rightsRequests[index]) rightsRequests = [];
            rightsRequests[index] = {
                ...rightsRequests[index],
                type: incidentRequest.calltypeid,
                email: formFields.emailadres,
                rol: formFields.rollen,
                toevoegen: formFields.toevoegen,
                typeDesc: formFields.toevoegen.value == "Toevoegen" ? "Toevoegen" : "Verwijderen",
                lastUpdate: Date.now(),
            }
            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: rightsRequests,
                    setting: 'calls'
                }
            });
            const requests = yield select(getRightsRequest);
            let request = requests
            let index = request.findIndex(request => request.callId == incidentRequest.callid)
                request[index] = {
                    ...request[index],
                    formFields
                    }
                yield put({
                    type: actions.SET_RIGHTS_REQUEST,
                    payload:request
                })
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Rights Request geupdated',
                        type: 'success'
                    }
                });
            store.history.push('/self-service/rechten');
         } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }
    });
}

export function* deleteRightsRequest() {
    yield takeEvery(actions.DELETE_RIGHTS_REQUEST, function* (action) {

        const GoClient = yield select(getGOClient);
        if (!GoClient) return;

        const result = yield GoClient.delete('/common/news?id=' + action.payload);
        if (result) {
            if (result.ok) {
                yield put({
                    type: actions.GET_RIGHTS_REQUEST
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Rights Request verwijderd',
                        type: 'success'
                    }
                });

            } else {
                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Er is iets misgegaan',
                        type: 'error'
                    }
                });
            }
            store.history.push('/self-service/rechten');
        } else {
            yield put({
                type: messageActions.MESSAGE,
                payload: {
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }
            });
        }

    });
}

export function* cancelRightsRequest() {
    yield takeEvery(actions.CANCEL_RIGHTS_REQUEST, function* (singleAction) {
        const GoClient = yield select(getGOClient);
        if (!GoClient) return;


        let action = { 
            callid: singleAction.payload,
            subject: "Aanvraag annuleren",
            body: `
Aanvraag van rol/groep kan geannuleerd worden
`,
            sentiment: "",
            emotionscore: 0,
            emailaddress: "",
        }
        let result = false; 
        result = yield GoClient.post('/app/incident/action', action);

        const userProfileData = yield select(getUserProfileSettingData);
        let rightsRequest = userProfileData?.settings?.calls

        let index = rightsRequest.findIndex(request => request.callId == singleAction.payload)
        if(index > -1) {
            rightsRequest[index].status = "Geannuleerd";

            yield put({
                type: userActions.UPDATE_USER_SETTINGS,
                payload: {
                    value: rightsRequest,
                    setting: 'calls'
                }
            });
        }

                yield put({
                    type: actions.GET_RIGHTS_REQUEST
                })

                yield put({
                    type: messageActions.MESSAGE,
                    payload: {
                        message: 'Rights Request geannuleerd',
                        type: 'success'
                    }
                });

            store.history.push('/self-service/gebruiker');
    });
}

export default function* rootSaga() {
    yield all([
        fork(getRightsRequest),
        fork(postRightsRequest),
        fork(deleteRightsRequest),
        fork(cancelRightsRequest),
        fork(updateRightsRequest)
    ]);
}
