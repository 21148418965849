import styled, { css } from 'styled-components'
import { loadingPlaceholder } from '../../styled/KeyFrames';

export const Loader = props => css`
	background: #e9ebee;
	position: relative;
	overflow: hidden;

	&::after {
	    content: "";
	    display: block;
	    background-color: #DEDEDE;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
	    transform: translateX(0);
	    animation: 1.5s ${loadingPlaceholder} ease-in-out infinite;
	  }

	  &.darkmode {
	  	background: #1B2B3C;

	  	&:after {
	  		background-color: #121a24;
	  	}
	  }
`;


export const LoaderCard = styled.div `
	width:300px;
	float: left;
	border: 1px solid #ddd;
	margin-right: 10px;
`;


export const LoaderImageLoading = styled.div `
	 background: #e9ebee;
    height: 175px;

     position: relative;
	  overflow: hidden;
	  &::after {
	    content: "";
	    display: block;
	    background-color: #dddfe2;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
	    transform: translateX(0);
	    animation: 1.5s ${loadingPlaceholder} ease-in-out infinite;
	  }
`;

export const LoaderBars = styled.div `
	 height: 85px;
`;

export const LoaderBar = styled.div `
	 background: #e9ebee;
     margin: 0 13px 13px;
     height: 19px;

     &:nth-of-type(1) {
     	width: 80%;
     	margin-top: 17px;
     }

     &:nth-of-type(2) {
     	width: 30%;
     }

       position: relative;
	  overflow: hidden;
	  &::after {
	    content: "";
	    display: block;
	    background-color: #dddfe2;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
	    transform: translateX(0);
	    animation: 1.5s ${loadingPlaceholder} ease-in-out infinite;
	  }
`;
