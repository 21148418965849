const actions = {
	GET_WIDGETS : 'GET_WIDGETS',
	SET_WIDGETS: 'SET_WIDGETS',
	SET_WIDGETS_LAYOUT: 'SET_WIDGETS_LAYOUT',
	SET_CLIENTS: 'SET_CLIENTS',
	GET_CLIENTS: 'GET_CLIENTS',
	SET_HAS_GO_WIDGET: 'SET_HAS_GO_WIDGET',
};

export default actions;
