import actions from './actions';

const initState = { 
	events : [],
	activeEvent: false,
	requestCompleted: false
};


export default function eventsReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.EVENTS_REQUEST_COMPLETED:
			return {
				...state,
				requestCompleted: action.payload
			}
		case actions.SET_EVENTS:
			return {
				...state,
				events : action.payload
			}
		case actions.SET_ACTIVE_EVENT :
			return {
				...state,
				activeEvent: action.payload
			}
		default:
			return state;
	}
}
