import React from 'react';
import * as Styled from './styled';
import HeaderLoader from '../header';


const DocumentsLoader = props => (
	 <Styled.List>
        {/* <Styled.DocumentSection><HeaderLoader darkmode={props.darkmode} /> */}
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
        {/* </Styled.DocumentSection> */}
        {/* <Styled.DocumentSection><HeaderLoader darkmode={props.darkmode} /> */}
           	{/* <Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/>
           	<Styled.Item className={(props.darkmode ? 'darkmode' : '')}/> */}
        {/* </Styled.DocumentSection> */}
    </Styled.List>
)



export default DocumentsLoader; 