import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Styled from './styled';
import { bindActionCreators } from 'redux';
import layoutActions from '../../../../../redux/layout/actions';
import { faListUl, faCalendarAlt, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ListSlide extends Component {


    _setDarkMode = () => {
        this.props.actions.setDarkMode(!this.props.darkmode);
    }

    render() {
       return (
            <React.Fragment>
                <Styled.SlideTitle>Wat is er nieuw?</Styled.SlideTitle>
                <Styled.List>

                    <Styled.ListItem>
                        <Styled.ListItemImage>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                        </Styled.ListItemImage>
                        <Styled.ListItemContent>
                            <h3>Afspraken</h3>
                            <p>Klik op het getal en je ziet meteen welke afspraken er de komende week in je agenda staan. Die vergeet je niet meer!</p>
                        </Styled.ListItemContent>

                    </Styled.ListItem>

                    <Styled.ListItem>
                        <Styled.ListItemImage>
                            <FontAwesomeIcon icon={faListUl} />
                        </Styled.ListItemImage>
                        <Styled.ListItemContent>
                            <h3>Taken</h3>
                            <p>Als je 'Taken' aanklikt, weet je meteen waar je direct mee aan de slag moet of wat later kan. Je ziet ook de voortgang direct in beeld.</p>
                        </Styled.ListItemContent>

                    </Styled.ListItem>

                    <Styled.ListItem>
                        <Styled.ListItemImage>
                            <FontAwesomeIcon icon={faBell} />
                        </Styled.ListItemImage>
                        <Styled.ListItemContent>
                            <h3>Afspraken</h3>
                            <p> GO houdt je automatisch op de hoogte van nieuwsberichten en nieuwe taken die aan jou zijn toegewezen.</p>
                        </Styled.ListItemContent>

                    </Styled.ListItem>

                </Styled.List>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User,
        activeSlideOut: state.SlideOut.activeSlideOut,
        darkmode: state.Layout.darkmode,
        darkmodeAuto: state.Layout.darkmodeAuto
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...layoutActions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListSlide);
