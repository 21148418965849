import styled, { css } from 'styled-components'
import * as Variables from '../../../../ThemeVariables';
import { NavLink } from 'react-router-dom';
export const MenuButtonList = styled.ul`
	list-style: none;

	${props => props.parent === 'slideout' && css`
		grid-template-columns: 1fr;
    	grid-gap: 20px;
    	display: grid;
    	margin-bottom: 10px;

    	@media ( min-width: ${Variables.md}) {
    		display: none;
    	}
    	li {
    		height: auto !important;
    		background: #e9ebee;

    		&.darkmode {
    			background: #33475d;
    		}

    	}
    	a {
    		font-weight: 100 !important;
    		padding: 0 !important;
    		text-transform: initial !important;
    		&:before {
    			display: none;
    		}

    		span {
    			svg {
    				display: none !important;
    			}
    		}
    	}
	`}

	${props => props.parent === 'header-col-left' && css`
		display: none;
		height: 100%;
		grid-area: menuList;
		text-align: left;

		/*@media ( min-width: ${Variables.xs}) {*/
		@media(min-width: ${Variables.md}) {
			display: block;
		}

		svg {
			margin-right: 8px;
			display: inline !important;
		}

		a {
			span {
				span {
					display: none;
				}
			}
		}

		@media ( max-width: ${Variables.lgMin} ) {
			font-size: 22px;
		}

		@media ( min-width: ${Variables.lgMin} ) {
			a {
				span {
					span {
						display: inline;
					}
				}
			}
		}
	`}
`;

export const NavLinkInner = styled.div`
 transform: skew(25deg, 0deg);
` ;

export const SkewedNavLink = styled(NavLink)`
	transform: skew(-25deg, 0deg);
	&.active, &:hover {
		&:before {
			height: 100%;
		}
	}

	&:before {
		display: block;
		background: #e9ebee;
	}

	&:before {
		content: '';
	    width: 100%;
	    height: 0%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    transition: height .5s;
	}
	&.darkmode {
		&:before {
			background: #0d131b;
		}
	}


    padding: 0px 20px;

`;


export const MenuButtonListItem = styled.li`
	top: -10px;
    height: calc(100% + 20px);

	&.greyout {
		opacity: .3;
	}


	display: inline-block;


	a {
		text-decoration: none;

		text-transform: uppercase;
		font-weight: 750;
		font-size: 13px;
	}

	${props => props.parent === 'header-col-left' && css`
		a {
			width: 100%;
			height: 100%;
			color: black;
			display: flex;
			align-items: center;
			justify-content: center;
			transition
		}

		&.darkmode {
			a {
				color: white;
			}
		}
	`}

	${props => props.parent === 'slideout' && css`

		color: #636a73;
		box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03);
		border-radius: 8px;
		font-size: 22px;
		grid-template-columns: 40px 1fr;
		padding: 15px;
		@media ( min-width: ${Variables.xs}) {
			font-size: 30px;
			grid-template-columns: 75px 1fr;
			padding: 20px;
		}

		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: left;
		display: grid;


		a {
			font-size: 20px;
			color: #636a73;
		}

		&.darkmode {
			background: #1B2B3C;
			color: white;

			a {
				color: white;
			}
		}

	`}
`;
