import React, { Component } from 'react';
import { Offline, Online } from "react-detect-offline";
import * as Styled from './styled';
import Logo  from '../../assets/img/logo.svg';
class AppStatus extends Component {

    render() {
        return(

            <Styled.StatusWrapper>
                <Styled.StatusInner>
                    <Styled.StatusLogo>
                        <img src={Logo} />
                        <Offline>
                        <h4>Offline..</h4>
                        <p>Je hebt geen internetverbinding</p>
                        </Offline>
                    </Styled.StatusLogo>
                    <Online>
                        <h4>Online..</h4>
                        <p>Het lijkt erop dat er iets is misgegaan tijdens het aanmelden</p>
                    </Online>
                </Styled.StatusInner>
            </Styled.StatusWrapper>

        )
    }
}

export default AppStatus;
