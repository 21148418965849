import actions from './actions';

const initState = {};

export default function reportReducer(state = initState, action) {
	
	switch (action.type) {  
        case actions.SET_TILES:
            return { ...state, tiles: action.payload };
		default:
			return state;
	}
}
