import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import localforage from 'localforage';
import { getGraphClient, getSharepointClient, getBlogItems } from '../selectors'

export function* getBlogs() {
    yield takeEvery(actions.GET_BLOGS, function* (action) {
        try {
            const Client = yield select(getSharepointClient);
            //const GoClient = yield state.Client.GoClient;
            const GraphClient = yield select(getGraphClient);
            const blogItems = yield select(getBlogItems);
            if (!Client) return;

            let newsResponse = yield Client.api((action.payload.includes('?') ? action.payload.split('?')[0] : action.payload) + '/?$select=Author/Title,Author/Id,BlogAutheurId,BannerImageUrl,Title,Description,Created,FileRef,FileLeafRef,EncodedAbsUrl,LinkFilename&$expand=Author&$orderby=Created desc');

            //fallback when no BlogAutheurId field is present in SharePoint
            if (newsResponse == null || !newsResponse || !newsResponse.value) {
                newsResponse = yield Client.api((action.payload.includes('?') ? action.payload.split('?')[0] : action.payload) + '/?$select=Author/Title,Author/Id,BannerImageUrl,Title,Description,Created,FileRef,FileLeafRef,EncodedAbsUrl,LinkFilename&$expand=Author&$orderby=Created desc');
            }

            if (newsResponse == null || !newsResponse || !newsResponse.value) return;
            if (newsResponse.value.length === 0) return;

            let data = newsResponse.value;
            let blogs = data.slice(0, 12).map(function (n) {
                return { Id: n["odata.id"] };
            });
            let tasks = [];
            data = data.slice(0, 12);
            for (let i = 0; i <= data.length; i++) {
                if (data[i]) {
                    const d = data[i];

                    const sharepointUserId = (d['BlogAutheurId'] ? d['BlogAutheurId'] : d.Author['Id']);
                    const sharepointSite = action.payload.includes('_api') ? action.payload.split('_api')[0] : action.payload;
                    const sharepointUser = yield Client.api(sharepointSite + '/_api/web/getuserbyid(' + sharepointUserId + ')');

                    const avatar = yield GraphClient.api('/users/' + sharepointUser['UserPrincipalName'] + '/photo/$value', 'arrayBuffer');

                    let image = false;

                    if (avatar) {
                        image = 'data:image/png;base64, ' + new Buffer(avatar, 'binary').toString('base64');
                    }

                    blogs[i] = {
                        id: d["odata.id"],
                        title: d.Title,
                        description: d.Description,
                        picture: image,
                        date: d.Created,
                        url: d.EncodedAbsUrl,
                        author: sharepointUser['Title']
                        //blob: blob
                    };

                }
            }


            if (tasks.length) {
                yield Promise.all(tasks);
            }

            if (blogs.length && ((blogItems.length === 0) || (blogItems && blogItems[0].Id !== blogs[0].id))) {

                let old = blogItems.filter(n => !blogs.find(nn => nn.id == n.id));
                if (old != null && old.length > 0) {
                    //yield newsItems.forEach(n => {
                    for (let n of old) {
                        yield localforage.removeItem(n.id.toString());
                    }
                }

                for (let n of blogs) {
                    if (n.blob == null) continue;
                    yield localforage.setItem(n.id.toString(), n.blob);
                    delete n.image;
                    delete n.blob;
                }

                yield put({
                    type: actions.SET_BLOGS,
                    payload: blogs
                });
            }
        } catch (error) { /*console.log(error);*/ }
    });
}


export default function* rootSaga() {
    yield all([
        fork(getBlogs),
    ]);
}
