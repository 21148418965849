import actions from './actions';

const initState = {
	activeSlideOut : false,
	activeOverlaySlideOut : false,
	image : false,
	authorName : false,
	authorImage: false,
	data: false,
	isUnmounting : false,
};

export default function slideOut(state = initState, action) {
	switch (action.type) {
		case actions.SET_IS_MOUNTING:
			return {
				...state,
				isMounting : action.payload
			}
		case actions.SET_IS_UNMOUNTING:
			return {
				...state,
				isUnmounting : action.payload
			}
		case actions.SET_ACTIVE_SLIDEOUT:
			if ( action.payload.item === false || action.payload.item !== state.activeSlideOut ) {
				return {
					...state,
					activeSlideOut : action.payload.item,
					image : action.payload.image,
					authorName : action.payload.authorName,
					authorImage: action.payload.authorImage,
					data: action.payload.data,
					activeOverlaySlideOut : false,
					isUnmounting : false
				}
			}
			return {
				...state,
				activeSlideOut : action.payload.item,
				image : action.payload.image,
				authorName: action.payload.authorName,
				data: action.payload.data,
				authorImage : action.payload.authorImage,
				isUnmounting : false
			}
        case actions.SET_ACTIVE_OVERLAY_SLIDEOUT:
        	return {
        		...state,
           		activeOverlaySlideOut : action.payload.item
			}
		case actions.SET_SLIDEOUT_DATA:
			return {
				...state,
				data: action.payload
			}
		default:
			return state;
	}
}
