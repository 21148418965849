import actions from './actions';

const initState = {
    authProvider: false,
    readyForBoot: false
};


export default function authReducer(state = initState, action) {

    switch (action.type) {
        case 'AAD_LOGIN_SUCCESS':
            return {
                ...state,
                ...action.payload
            }
        case 'AAD_LOGIN_ERROR':
            return action.payload;
        case 'SET_AUTH_INFO':
            return {
                ...state,
                ...action.payload
            }
        case actions.READY_FOR_BOOT:
            return {
                ...state,
                readyForBoot: true,
                authProvider: action.payload
            };
        case actions.LOG_OUT:
            return {
                ...state
            }
        default:
            return state;
    }
}
