import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import wizardactions from '../../documentsWizard/actions'
import { Buffer } from 'buffer';
import { getGraphClient, getTeams, getAppOnline, getSharepointClient, getGOClient } from '../../selectors';
//const tenantConfig = window.tenantConfig;

export function* fetchSharePointSites() {
    yield takeEvery(actions.FETCH_SHAREPOINT_SITES, function* (action) { 
        yield put({
            type: actions.SET_IS_LOADING,
            payload: true
        });
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }
        let sharepointSites = [];
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;

        const searchResult = yield GraphClient.api('/sites?search='+action.payload);
        if(searchResult.value) {
            searchResult.value.forEach(result => {
                sharepointSites.push({isFolder:true, name: result.displayName, sites: result.id, webUrl: result.webUrl})
            })
            yield put({
                type: actions.SET_SHAREPOINT,
                payload: sharepointSites
            });

        }
        yield put({
            type: actions.SET_IS_LOADING,
            payload: false
        });
    }) 
}

export function* fetchSharePointDrive() {
    yield takeEvery(actions.FETCH_SHAREPOINT_DRIVE, function* (action) { 
        yield put({
            type: actions.SET_IS_LOADING,
            payload: true
        });
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }
        let sharepointSites = [];
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;

        const searchResult = yield GraphClient.api('/sites/'+action.payload.sites+'/drives');
        if(searchResult.value) {
            searchResult.value.forEach(result => {
                sharepointSites.push({isFolder: true, name: result.name, sites: action.payload.sites, drives: result.id, webUrl: result.webUrl})
            })
            yield put({
                type: actions.SET_SHAREPOINT_DRIVE,
                payload: sharepointSites
            });
        }
        yield put({
            type: actions.SET_IS_LOADING,
            payload: false
        });
    }) 
}

export function* fetchSharePointDriveChildren() {
    yield takeEvery(actions.FETCH_SHAREPOINT_DRIVE_CHILDREN, function* (action) { 
        yield put({
            type: actions.SET_IS_LOADING,
            payload: true
        });
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }
        let sharepointSites = [];
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;
        const searchResult = yield GraphClient.api('/sites/'+ action.payload.sites +'/drives/'+ action.payload.drives  +'/root/children');
        if(searchResult.value) {
            searchResult.value.forEach(result => {
                if(result.folder) {
                    sharepointSites.push({isFolder: true, name: result.name, sites: action.payload.sites, drives:action.payload.drives, item:result.id, webUrl: result.webUrl})
                } else {
                    sharepointSites.push({isFolder: false, name: result.name, sites: action.payload.sites, drives:action.payload.drives, item:result.id, webUrl: result.webUrl})
                }
            })

            yield put({
                type: actions.SET_SHAREPOINT_DRIVE_CHILDREN,
                payload: sharepointSites
            });
        }
        yield put({
            type: actions.SET_IS_LOADING,
            payload: false
        });
    }) 
}

export function* fetchSharePointFolder() {
    yield takeEvery(actions.FETCH_SHAREPOINT_FOLDER, function* (action) { 
        yield put({
            type: actions.SET_IS_LOADING,
            payload: true
        });
        const appOnline = yield select(getAppOnline);
        if (!appOnline) {
            return;
        }
        let sharepointSites = [];
        const GraphClient = yield select(getGraphClient);
        if (GraphClient == null) return;
        const searchResult = yield GraphClient.api('/sites/'+ action.payload.sites +'/drives/'+ action.payload.drives +'/items/'+ action.payload.item  +'/children');
        if(searchResult.value) {
            searchResult.value.forEach(result => {
                if(result.folder) {
                    sharepointSites.push({isFolder: true, name: result.name, sites: action.payload.sites, drives: action.payload.drives, item: result.id,  webUrl: result.webUrl})
                } else {
                    sharepointSites.push({isFolder: false, name: result.name, sites: action.payload.sites, drives: action.payload.drives, item: result.id,  webUrl: result.webUrl})
                }
            })
            yield put({
                type: actions.SET_SHAREPOINT_FOLDER,
                payload: sharepointSites
            });
        }
        yield put({
            type: actions.SET_IS_LOADING,
            payload: false
        });
    }) 
}

export function* clearSharePoint() {
    yield takeEvery(actions.CLEAR_SHAREPOINT, function* (action) { 
        
        if(action.payload) {
            if(action.payload.includes('Sharepoint')) {
                yield put({
                    type: actions.CLEAR_SHAREPOINT
                });
            }
            if(action.payload.includes('Drive')) {
                yield put({
                    type: actions.CLEAR_SHAREPOINT_DRIVE
                });
            }
            if(action.payload.includes('DriveChildren')) {
                yield put({
                    type: actions.CLEAR_SHAREPOINT_DRIVE_CHILDREN
                });
            }
            if(action.payload.includes('Folder')) {
                yield put({
                    type: actions.CLEAR_SHAREPOINT_FOLDER
                });
            }
        }

    }) 
}

export function* createFileFromTemplate() {
    yield takeEvery(actions.FILE_FROM_TEMPLATE, function* (action) {
        const GOClient = yield select(getGOClient);
        const GraphClient = yield select(getGraphClient);
        const goToken = yield GOClient.getToken();
        const graphToken = yield GraphClient.getToken();
        const sharepointClient = yield select(getSharepointClient);
        const sharepointToken = yield sharepointClient.getToken();
        const apiURL = window.apiURL + '/api/app/convert/office';
        const pfdUrl = window.apiURL + '/api/app/convert/pdf'
        let downloadUrl;
        if(action.payload?.local) {
            const getDownloadUrl = yield fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${action.payload.itemId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + graphToken,
                }
            });
            let response = yield getDownloadUrl.json();
            downloadUrl = response['@microsoft.graph.downloadUrl'];
            
        } else if(action.payload.useMetaData && action.payload?.downloadUrl) {
            downloadUrl = action.payload.downloadUrl
        }else {
                const getDownloadUrl = yield fetch(`https://graph.microsoft.com/v1.0/drives/${action.payload.driveId}/items/${action.payload.itemId}/content`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + graphToken,
                    }
                });
                downloadUrl = getDownloadUrl.url
            }
        let getDocumentBase64String;
        if(action.payload.type == "pdf") {
            getDocumentBase64String = yield fetch(pfdUrl+ "?&url=" + encodeURIComponent( downloadUrl ),
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + goToken,
                    'AuthorizationProxy': "Bearer " + graphToken
                }
            });
        } else {
            getDocumentBase64String = yield fetch(apiURL+ "?type="+action.payload.type+"&url=" + encodeURIComponent( downloadUrl ),
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + goToken,
                    'AuthorizationProxy': "Bearer " + graphToken
                }
            });
        }

        // const getDocumentBase64String = yield fetch(pfdUrl+ "?url=" + encodeURIComponent( downloadUrl ),
        // {
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + goToken,
        //         'AuthorizationProxy': "Bearer " + graphToken
        //     }
        // });

        if (!getDocumentBase64String.ok) {
            console.error('response failed',getDocumentBase64String);
            return null;
        }

        const responseData = yield getDocumentBase64String.json().catch(err => {
            console.error(`'fetch parse error: ${err}' happened!`,getDocumentBase64String);
            return null;
        });
        if (responseData.length === 0) return null;

        function base64ToBinary(receivedBase64String) {
            const binaryString = atob(receivedBase64String);
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
            return byteArray;
        }
        const binaryData = base64ToBinary(responseData);
        let fileName = "Nieuw document";
        if(action.payload.fileName) fileName = action.payload.fileName;

        // let body = {
        //     "name": fileName + '.' + type,
        //     "file": binaryData,
        //     "@microsoft.graph.conflictBehavior": "rename"
        // }
        let info;
        console.log("safeFIleTo", action.payload.saveFileTo)
        let url;
        console.log('bin data',binaryData)
        if(action.payload.saveWhere === "sharepoint") {
            url = `/drives/${action.payload.saveFileTo.drives}/items`;
            if (action.payload.saveFileTo.parentId) {
                url += `/${action.payload.saveFileTo.parentId}`;
            }
            else {
                url = `/drives/${action.payload.saveFileTo.drives}/root`;
            }
            url += `:/${fileName}.${action.payload.type}:/content`;
            info = yield GraphClient.put(url, binaryData);
        }
        if(action.payload.saveWhere === "teams") {
            info = yield GraphClient.put(`/drives/${action.payload.saveFileTo.parentDriveID}/items/${action.payload.saveFileTo.folder.id}:/${fileName}.${action.payload.type}:/content`, binaryData);
        }
        if(action.payload.saveWhere === "drive") {
            info = yield GraphClient.put(`/drives/${action.payload.saveFileTo.driveId}/items/${action.payload.saveFileTo.itemId}:/${fileName}.${action.payload.type}:/content`, binaryData);
        }


        yield put({
            type: wizardactions.SET_DOCUMENT_INFO,
            payload: info
        });
        yield put({
            type: wizardactions.SET_BASE64_INFO,
            payload: responseData
        });
        
    })
}


export default function* rootSaga() {
    yield all([
        fork(fetchSharePointSites),
        fork(fetchSharePointDrive),
        fork(fetchSharePointDriveChildren),
        fork(fetchSharePointFolder),
        fork(clearSharePoint),
        fork(createFileFromTemplate),
    ]);
}