const actions = {
	GET_TEAMS : 'GET_TEAMS',
    SET_TEAMS : 'SET_TEAMS',
	FETCH_TEAM_CHANNELS : 'FETCH_TEAM_CHANNELS',
	
    getTeams: () => ({
        type: actions.GET_TEAMS
    }),

	fetchTeamChannels: (teamID) => ({
		type : actions.FETCH_TEAM_CHANNELS,
		payload : teamID
	})
};

export default actions;
