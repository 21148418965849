import { all, put, fork, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import moment from 'moment';
import { getActiveSlideOut, isUnmounting } from '../selectors';

export function* setActiveSlideout() {
    yield takeLatest(actions.HANDLE_SLIDEOUT_MOUNTING, function* (action) {

        // -- Check if a slideout exists. If it does and the new slideOut value is not false, set it to unmounting to animate the old slideOut away, and the new one in.
        const activeSlideOut = yield select(getActiveSlideOut);
        const isSlideOutUnmounting = yield select(isUnmounting);

        if ( activeSlideOut ) {
            if (!isSlideOutUnmounting) {
                yield put({
                    type : actions.SET_IS_UNMOUNTING,
                    payload : true
                })
                yield delay(500)
            }

        }

        yield put({
            type : actions.SET_ACTIVE_SLIDEOUT,
            payload : action.payload
        })

    });
}


export default function* rootSaga() {
    yield all([
        fork(setActiveSlideout),
    ]);
}
