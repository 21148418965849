const actions = {
	GET_EVENTS : 'GET_EVENTS',
	SET_EVENTS : 'SET_EVENTS',
	SET_ACTIVE_EVENT: 'SET_ACTIVE_EVENT',
	EVENTS_REQUEST_COMPLETED: 'EVENTS_REQUEST_COMPLETED',

	setActiveEvent : (payload) => ({
		type: actions.SET_ACTIVE_EVENT,
		payload : payload
	}),

	getEvents : ( payload ) => ({
		type : actions.GET_EVENTS,
		payload : payload
	})
};

export default actions;
