import actions from './actions';

const initState = {
    recentDocuments : [],
    loading : false,
    documentInfo: {},
    base64Info: "",
};

export default function documentsWizardReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_RECENT_DOCUMENTS_FOR_APP:
			return {
                ...state,
                recentDocuments : action.payload
            }
        case actions.SET_RECENT_DOCUMENTS_LOADING:
            return {
                ...state,
                loading : action.payload
            }
        case actions.SET_DOCUMENT_INFO:
            return {
                ...state,
                documentInfo : action.payload
            }
        case actions.SET_BASE64_INFO:
            return {
                ...state,
                base64Info : action.payload
            }
		default:
			return state;
	}
}
