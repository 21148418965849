const actions = {
	GET_DOCUMENTS : 'GET_DOCUMENTS',
	SET_DOCUMENTS : 'SET_DOCUMENTS',
	DOCUMENTS_REQUEST_COMPLETED: 'DOCUMENTS_REQUEST_COMPLETED',
	SET_STATUS_DOCUMENTS: 'SET_STATUS_DOCUMENTS',
	SET_REFRESH_HIDDEN_DOCUMENTS: 'SET_REFRESH_HIDDEN_DOCUMENTS',
	SET_LAST_UPDATE_DOCUMENTS: 'SET_LAST_UPDATE_DOCUMENTS',

	getDocuments: (payload) => ({ 
		type: actions.GET_DOCUMENTS,
		payload : payload
	}),	
	setRefreshHiddenDocuments: (payload) => ({
		type : actions.SET_REFRESH_HIDDEN_DOCUMENTS,
		payload : payload 
	}),
};

export default actions;
