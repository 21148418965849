const actions = {
	GET_SOLUTIONS : 'GET_SOLUTIONS',
	SET_SOLUTIONS: 'SET_SOLUTIONS',
    GET_SOLUTION_CATEGORIES : 'GET_SOLUTION_CATEGORIES',
    SET_SOLUTION_CATEGORIES : 'SET_SOLUTION_CATEGORIES',

	SET_ACTIVE_SOLUTION : 'SET_ACTIVE_SOLUTION',
	DISABLE_SOLUTION : 'DISABLE_SOLUTION',
	ENABLE_SOLUTION : 'ENABLE_SOLUTION',

    getSolutionCategories: (payload) => ({
		type: actions.GET_SOLUTION_CATEGORIES
	}),

	setActiveSolution : (payload) => ({
		type : actions.SET_ACTIVE_SOLUTION,
		payload : payload
	}),

	disableSolution : (payload) => ({
		type : actions.DISABLE_SOLUTION,
		payload : payload
	}),

	enableSolution : (payload) => ({
		type : actions.ENABLE_SOLUTION,
		payload : payload
	})
};

export default actions;
