import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../redux/widgets/sharepoint/actions';
import { NavLink } from 'react-router-dom';
import Heading from '../../layout/heading';
import FeedsLoader from '../../layout/loader/documents';
import TeamLoader from '../../layout/loader/team';
import { getMS } from '../../../helpers/functions';
import * as Styled from './styled';
import ReactTooltip from 'react-tooltip';
import { faEllipsisH, faSave, faFolder, faFolderMinus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslate } from 'react-redux-multilingual'

class Sharepoint extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSharepointSite: false,

            breadCrumbs:[],
            search: '',
            height: 0,
        }
    }
    componentDidMount() {
        // if (this.props.teams == null) this._getTeams();
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(this._getSharepointSites.bind(this), getMS(5));
        this.props.actions.fetchSharepointSites(this.state.search); 
    }

    _setItemActive = (key) => {

        this.setState({
            activeSharepointSite: (this.state.activeSharepointSite === key ? false : key)
        })
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const height = this.divElement.clientHeight;
    //     console.log("height")
    //     console.log(this.divElement)
    // }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    _loadingItem() {
        return ( 
        <React.Fragment>
            <TeamLoader darkmode={this.props.darkmode} />
            <TeamLoader darkmode={this.props.darkmode} />
            <TeamLoader darkmode={this.props.darkmode} />
            <TeamLoader darkmode={this.props.darkmode} />
        </React.Fragment>
        )
    }

    handleSearch(event) {      
        let search = event.currentTarget.value;
        this.setState({ search: search });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.actions.fetchSharepointSites(this.state.search); 
            this.setState({breadCrumbs:[]})
            this.props.actions.clearSharepoint(['Sharepoint','Drive','DriveChildren','Folder']);
        }, 500);
    }

    _displayBreadCrumbs = () => {
        const breadCrumbs = this.state.breadCrumbs;
        //console.log("breadCrumbs", breadCrumbs)
        let crumbs = [];
        for(let i=0; i<breadCrumbs.length;i++) {
            if(i==0) {
                crumbs.push(
                    <Styled.Team onClick={ () => {                        
                        this.props.actions.fetchSharepointSites(breadCrumbs[i]); 
                        const newArray = breadCrumbs.slice(0,i+1)
                        this.setState({breadCrumbs:[], search:""})
                        this.props.actions.clearSharepoint(['Sharepoint','Drive','DriveChildren','Folder']);
                        this.props.actions.fetchSharepointSites("");
                    } }>
                        <FontAwesomeIcon  icon={faFolderMinus} color='#DBA90E'/>
                        <Styled.BreadCrumbsList>
                            <b>{breadCrumbs[i].name}</b>
                        </Styled.BreadCrumbsList>
                    </Styled.Team>)
            }//Search
            if(i==1) {
                crumbs.push(
                    <Styled.Team onClick={ () => {                        
                        this._fetchSharepointDrives(breadCrumbs[i]); 
                        const newArray = breadCrumbs.slice(0,1)
                        this.setState({breadCrumbs:[...newArray]})
                        this.props.actions.clearSharepoint(['Drive','DriveChildren','Folder']);
                    } }>
                        <FontAwesomeIcon  icon={faFolderMinus} color='#DBA90E'/>
                        <Styled.BreadCrumbsList>
                        <b>{breadCrumbs[i].name}</b>
                        </Styled.BreadCrumbsList>
                    </Styled.Team>)
            }//Sharepoint site
            if(i == 2) {
                crumbs.push(
                    <Styled.Team onClick={ () => {                        
                        this._fetchSharepointDriveChildren(breadCrumbs[i]); 
                        const newArray = breadCrumbs.slice(0,i)
                        this.setState({breadCrumbs:[...newArray]})
                        this.props.actions.clearSharepoint(['DriveChildren','Folder']);
                    } }>
                        <FontAwesomeIcon  icon={faFolderMinus} color='#DBA90E'/>
                        <Styled.BreadCrumbsList>
                        <b>{breadCrumbs[i].name}</b>
                        </Styled.BreadCrumbsList>
                    </Styled.Team>)
            }//Drive
            if(i > 2) {
                crumbs.push(
                    <Styled.Team onClick={ () => {                        
                        this._fetchSharepointFolder(breadCrumbs[i]); 
                        const newArray = breadCrumbs.slice(0,i)
                        this.setState({breadCrumbs:[...newArray]})
                        this.props.actions.clearSharepoint(['Folder']);
                    } }>
                        <FontAwesomeIcon  icon={faFolderMinus} color='#DBA90E'/>
                        <Styled.BreadCrumbsList>
                            <b>{breadCrumbs[i].name}</b>
                        </Styled.BreadCrumbsList>
                    </Styled.Team>)
            }//Drive children
        }
        return (
            crumbs.length ?
                crumbs[crumbs.length-1]
            : null
            )
    }

    _getSharepointSites = async () => {
        this.props.actions.getSharepointSites();
    }

    _fetchSharepointDrives = (item) => {
        this.props.actions.fetchSharepointDrive(item);
    }

    _fetchSharepointDriveChildren = (item) => {
        this.props.actions.fetchSharepointDriveChildren(item);
        if (this.props.saveButtonAction) {
            this.props.saveButtonAction(item)
        }
    }
    _fetchSharepointFolder = (item) => {
        this.props.actions.fetchSharepointFolder(item);
        if (this.props.saveButtonAction) {
            this.props.saveButtonAction(item)
        }
    }

    _mapSharepoint = (item, key) => {
        if(!item.isFolder) {
            return;
        }
        if (this.props.explorerMode) {
            return (
                <Styled.Team data-for={"sharepoint-tooltip"} data-tip={item.name} key={key+"Sharepoint"} folder={item.isFolder} className={(this.props.explorerMode ? 'small' : '') + ' ' + (this.props.darkmode ? 'darkmode' : '')}>
                    <div className={(this.props.darkmode ? 'darkmode' : '')} onClick={() => {
                        this._setItemActive(key); 
                        this._fetchSharepointDrives(item); 
                        this.setState({breadCrumbs: [...this.state.breadCrumbs,item]});
                        }}><FontAwesomeIcon  icon={faFolder} color='#FFCA28'/><p className={(this.props.darkmode ? 'darkmode' : '')}>{item.name}</p></div>
                </Styled.Team>
            )
        }
        return (
            <Styled.Team data-for={"sharepoint-tooltip"} key={key} folder={item.isFolder} className={(this.props.explorerMode ? 'small' : '') + ' ' + (this.props.darkmode ? 'darkmode' : '')}>
                <img src={item.photo} />
                <a target="_BLANK" className={(this.props.darkmode ? 'darkmode' : '')} href={item.webUrl}>{item.name}</a>
                <Styled.TeamsChannelList className={(this.state.activeSharepointSite === key ? 'active' : '') + ' ' + (this.props.explorerMode ? 'small' : '')}>
                    {this.state.activeSharepointSite === key ? this._fetchSharepointDrives(item) : null}
                </Styled.TeamsChannelList>
                <span className={(this.props.darkmode ? 'darkmode' : '')} onClick={() => this._setItemActive(key)}><FontAwesomeIcon icon={faEllipsisH} /></span>
            </Styled.Team>
        )
    }
    //
    _mapDrives = (item, key) => {
        if(!item.isFolder) {
            return;
        }
        if (this.props.explorerMode) {
            return (
                <Styled.Team data-for={"sharepoint-tooltip"} data-tip={item.name} key={key+"Drives"} folder={item.isFolder} className={(this.props.explorerMode ? 'small' : '') + ' ' + (this.props.darkmode ? 'darkmode' : '')}>
                    <div className={(this.props.darkmode ? 'darkmode' : '')} onClick={() => { 
                        if(item.isFolder) {
                            this._setItemActive(key);
                            this._fetchSharepointDriveChildren(item); 
                            this.setState({breadCrumbs: [...this.state.breadCrumbs,item]});
                        } else {
                            if (this.props.saveButtonAction) {
                                this.props.saveButtonAction(item)
                            }
                        }
                        } }><FontAwesomeIcon  icon={faFolder} color='#FFCA28'/><p className={(this.props.darkmode ? 'darkmode' : '')}>{item.name}</p></div>
                </Styled.Team>
            )
        }
    }
    //
    _mapDriveChildren = (item, key) => {
        if(!item.isFolder) {
            return;
        }
        if (this.props.explorerMode) {
            return (
                <Styled.Team data-for={"sharepoint-tooltip"} data-tip={item.name} key={key+"Children"} className={(this.props.explorerMode ? 'small' : '') + ' ' + (this.props.darkmode ? 'darkmode' : '') + ' ' + (item.isFolder ? 'folder' : 'document')}>
                    <div className={(this.props.darkmode ? 'darkmode' : '')} onClick={() => { 
                        if(item.isFolder) {
                            this._setItemActive(key); 
                            this._fetchSharepointFolder(item); 
                            this.setState({breadCrumbs: [...this.state.breadCrumbs,item]})
                        } else {
                            if (this.props.saveButtonAction) {
                                this.props.saveButtonAction(item)
                            }
                        }
                        // this.props.actions.clearSharepoint(['Folder']);
                        } }><FontAwesomeIcon  icon={faFolder} color='#FFCA28'/><p className={(this.props.darkmode ? 'darkmode' : '')}>{item.name}</p></div>
                </Styled.Team>
            )
        }
    }
    //
    _mapFolder = (item, key) => {
        if(!item.isFolder) {
            return;
        }
        if (this.props.explorerMode) {
            return (
                <Styled.Team data-for={"sharepoint-tooltip"} data-tip={item.name} key={key+"Folder"} folder={item.isFolder} className={(this.props.explorerMode ? 'small' : '')+ ' ' + (this.props.darkmode ? 'darkmode' : '')}>
                    <div className={(this.props.darkmode ? 'darkmode' : '')} onClick={() => { 
                        if(item.isFolder) {
                            this._setItemActive(key); 
                            this._fetchSharepointFolder(item); 
                            this.setState({breadCrumbs: [...this.state.breadCrumbs,item]})
                        } else {
                            if (this.props.saveButtonAction) {
                                this.props.saveButtonAction(item)
                            }
                        }
                        } }><FontAwesomeIcon  icon={faFolder} color='#FFCA28'/><p className={(this.props.darkmode ? 'darkmode' : '')}>{item.name}</p></div>
                </Styled.Team>
            )
        }
    }

    render() {
        return (
            <Styled.Sharepoint ref={ (divElement) => { this.divElement = divElement } }>
                <React.Fragment>
                <Styled.SearchWrapper wrapped={this.props.wrapped} slideout={this.props.slideout} gridArea={this.props.gridArea}>

                    <Styled.Magnifier>
                        <FontAwesomeIcon icon={faSearch} />
                    </Styled.Magnifier>
                    <form onSubmit={this.handleSearch.bind(this)}>
                        <Styled.SearchInput value={this.state.search} onChange={this.handleSearch.bind(this)} placeholder={"Zoeken naar SharePoint mappen..."} />
                    </form>
                </Styled.SearchWrapper >
            </React.Fragment>
                {/* <Styled.TopInfo>
                    <Styled.Search>
                        <Styled.SearchInput type='text' value={this.state.search} onChange={this.handleSearch.bind(this)}/>
                        <Styled.SearchButton onClick={() => { 
                            this.props.actions.fetchSharepointSites(this.state.search); 
                            this.setState({breadCrumbs:[]})
                            this.props.actions.clearSharepoint(['Sharepoint','Drive','DriveChildren','Folder']);
                            }}>Search</Styled.SearchButton>
                    </Styled.Search>
                </Styled.TopInfo> */}
                {!this.props.explorerMode ? <Heading title={'Sharepoint'} /> : null}
                {this.props.sharepointState.isLoading ? this._loadingItem() : 
                <React.Fragment>
                    {this.props.sharepointState.sharepointFolderSet ?
                    <Styled.ListTeam className={(this.props.darkmode ? 'darkmode' : '')}>
                        {this._displayBreadCrumbs()}
                        {this.props.sharepointFolder.length ? this.props.sharepointFolder.map(this._mapFolder) :  null }
                    </Styled.ListTeam> : null }
                    {this.props.sharepointState.sharepointDriveChildrenSet && !this.props.sharepointState.sharepointFolderSet?
                    <Styled.ListTeam className={(this.props.darkmode ? 'darkmode' : '')}>
                        {this._displayBreadCrumbs()}
                        {this.props.sharepointState.isLoading ? this._loadingItem : this.props.sharepointDriveChildren.map(this._mapDriveChildren)}
                    </Styled.ListTeam> : null }
                    {this.props.sharepointState.sharepointDriveSet && !this.props.sharepointState.sharepointDriveChildrenSet ?
                    <Styled.ListTeam className={(this.props.darkmode ? 'darkmode' : '')}>
                        {this._displayBreadCrumbs()}
                        {this.props.sharepointDrive.map(this._mapDrives)}
                    </Styled.ListTeam> : null }
                    {this.props.sharepointState.sharePointSet && !this.props.sharepointState.sharepointDriveSet ?
                    <Styled.ListTeam className={(this.props.darkmode ? 'darkmode' : '')}>
                        {this._displayBreadCrumbs()}
                        {this.props.sharepoint.map(this._mapSharepoint)}
                    </Styled.ListTeam> : !this.props.sharepointState.sharePointSet && !this.props.sharepointState.sharepointDriveSet && this.props.sharepointState.isLoading ?
                    this._loadingItem() : null}
                </React.Fragment>}
            </Styled.Sharepoint>

        )


    }

}

const mapStateToProps = (state, ownProps) => {

    return {
        GraphClient: state.Client.GraphClient,
        darkmode: state.Layout.darkmode,
        sharepoint: state.Sharepoint.sharepoint,
        sharepointDrive: state.Sharepoint.sharepointDrive,
        sharepointDriveChildren: state.Sharepoint.sharepointDriveChildren,
        sharepointFolder: state.Sharepoint.sharepointFolder,
        sharepointState: state.Sharepoint
    };
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(Sharepoint));
