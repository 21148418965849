import actions from './actions';

const initState = {
    workplaceRequest: []
};


export default function selfWorkplaceReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_WORKPLACE_REQUEST:
            return {
                ...state,
                workplaceRequest: [...state.workplaceRequest, action.payload]
            }
        default:
            return state;
    }
}
