import { all, put, fork, takeEvery, select, call } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import moment from 'moment';
import { getGraphClient } from '../selectors'
export function* orderCalendar() {
    yield takeEvery(actions.ORDER_CALENDAR, function* (action) {

        const calendarItems = action.payload;

        if (!calendarItems) {
            yield put({
                type: actions.SET_CALENDAR,
                payload: []
            });
            yield put({
                type: userActions.SET_USER_CALENDARITEMS,
                payload: 0
            });
        };

        const sortOnDate = ((a, b) => {
            if (a.start.dateTime > b.start.dateTime) {
                return -1;
            }

            if (a.start.dateTime < b.start.dateTime) {
                return 1;
            }

            return 0;
        });

        let orderedItems = {
            today: [],
            tomorrow: [],
            next: []
        };
        const today = moment().format("DD-MM-YYYY");
        const tomorrow = moment().add(1, 'day').format("DD-MM-YYYY");

        for (let i = 0; i <= calendarItems.length; i++) {
            if (calendarItems[i]) {
                const currentItem = calendarItems[i];

                if (currentItem.start) {
                    const startDate = moment(currentItem.start.dateTime).format("DD-MM-YYYY");
                    if (startDate === today) {
                        orderedItems.today.push(currentItem);
                    } else if (startDate === tomorrow) {
                        orderedItems.tomorrow.push(currentItem);
                    } else {
                        orderedItems.next.push(currentItem);
                    }
                }
            }
        }

        orderedItems.today.sort(sortOnDate);
        orderedItems.today.reverse();
        orderedItems.tomorrow.sort(sortOnDate);
        orderedItems.tomorrow.reverse();
        orderedItems.next.sort(sortOnDate);
        orderedItems.next.reverse();

        yield put({
            type: actions.SET_CALENDAR,
            payload: orderedItems
        });

        let countItems = 0;
        countItems = orderedItems.today.length + orderedItems.tomorrow.length; //+ orderedItems.next.length;

        yield put({
            type: userActions.SET_USER_CALENDARITEMS,
            payload: countItems
        });

    });
}

export function* setCalendar() {

    yield takeEvery(actions.GET_CALENDAR, function* (action) {

        const Client = yield select(getGraphClient);

        if (!Client) return;

        try {
            const todayIso = moment().toISOString();
            const nextweekIso = moment().add(7, 'days').toISOString();

            let url = `/me/calendarview?startdatetime=${todayIso}&enddatetime=${nextweekIso}&top=50`;
            const calendar = yield call(Client.api, url);


            if (calendar == null || !calendar) return;
            if (!calendar.value) return;

            yield put({
                type: actions.ORDER_CALENDAR,
                payload: calendar.value
            });
        }
        catch (error) {
            console.error(error);
        }

    });

}

export default function* rootSaga() {
    yield all([
        fork(setCalendar),
        fork(orderCalendar)
    ]);
}
