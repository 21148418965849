import { createGlobalStyle } from 'styled-components'
import * as Variables from './ThemeVariables';

export const GlobalStyle = createGlobalStyle`
  body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  }

  *{
  	padding:0;
  	margin:0;
  	border:0;
  	box-sizing:border-box;
  	position:relative;
  	font-family: 'Montserrat', sans-serif;
  }

  p {
  	font-size: 18px;
  	font-weight: 400;
  }




.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  box-shadow: none !important;
  border: none !important;
}

.ReactTable {
    border: none !important;
    .rt-thead{
        &.-header {
            box-shadow: none !important;

        }

        .rt-th {
            text-align: left !important;
            font-weight: bold !important;
        }
    }

    .rt-tbody {
        .rt-tr-group {
            border: none !important;

        }
    }

    .rt-tr {
        padding: 10px;
        margin-bottom: 15px;
        background: white;
        box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03) !important;
        border-radius: 8px;
        cursor: pointer;
    }

    .-pagination {
        border: none !important;
        box-shadow: none !important;
    }

    .pagination-bottom {
        .-pagination {
            .-previous, .-next, .-pageInfo, .select-wrap {
                padding: 10px;
                margin-bottom: 15px;
                background: white;
                box-shadow: 0px 0px 5px 4px rgba(0,0,0,0.03) !important;
                border-radius: 8px;
                cursor: pointer;
                button {
                    background: none;
                     color: black;
                    &:hover {
                        background: none;
                         color: black;
                    }
                }
            }
        }
    }
}
.darkmode {
  .ReactTable {
      .rt-thead{
          .rt-th {
              color: white;
          }
      }

      .rt-tr {
          background:${Variables.tileBackground.darkMode};
          color: white;
      }

      .-pageInfo {
          color: white !important;
      }

      .pagination-bottom {
          .-pagination {
              .-previous, .-next, .-pageInfo, .select-wrap {
                  background: ${Variables.tileBackground.darkMode};
                  button {
                       color: white;
                      &:hover {
                           color: white;
                      }
                  }
              }
          }
      }
  }
}
`;
