import styled from 'styled-components'

import * as Variables from '../../../ThemeVariables';

export const Button = styled.button`
	color: white;
	background: ${Variables.mainColor};
	font-weight: 600;
	text-align: center;
	line-height: 22px;
	padding: 0 10px;
	border-radius: 25px;
	display: inline-block;
	cursor: pointer;
	font-size: 14px;
	border: 2px solid ${Variables.mainColor};

    ${props => props.position === 'right' ? 'float: right; margin-left: 10px;' : ''};

	transition: color .5s, background .5s;
	&:hover {
		color: black;
		background: white;

		b { 
			display:inline;
		} 
	}

	&:focus {
		outline: none;
	}

	b { 
		display:none;
	} 
`;